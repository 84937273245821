import React, {useEffect, useState} from "react";
import styled from "styled-components";
import img_1 from "../images/1.png";
import img_2 from "../images/2.png";
import img_3 from "../images/3.png";
import img_4 from "../images/4.png";
import img_5 from "../images/5.png";
import img_11 from "../images/img-1.jpg";
import img_22 from "../images/img-2.jpg";
import img_33 from "../images/img-3.jpg";
import img_44 from "../images/img-4.jpg";
import img_55 from "../images/img-5.jpg";
import upIcon from "../images/button_top_w.svg";
import '../Landing.css';
import Pager1 from "./Pager1";
import ReactFullpage from "@fullpage/react-fullpage";
import Helmet from "react-helmet";
import TopButton from "./TopButton";
import $ from 'jquery';

const Container = styled.div`
  overflow: hidden;
  z-index: 1000;
`;

const Section = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  min-width: 100%;
  min-height: 100%;
  background: ${props => props.color};
  justify-content: center;
  align-items: center;
`;

const Slide = styled.div`
    width: 100%;
    height: 100%;
`;

const Button = styled.div`
  position: fixed;
  z-index: 99999;
  bottom: 5%;
  right: 5%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  visibility: ${props => (props.visible ? "visible" : "hidden")};
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
`;

const Landing = () => {
    const [page, setPage] = useState(1);
    let slider;

    const moveToPage = (num) => {
        slider.moveTo(num);
    };

    useEffect(() => {
        $('.first-section, .last-section').children().css({verticalAlign: 'top'});
        $(document).resize(() => {
            $('.copyright').height($(window).height());
            $('.fp-tableCell').each((elem) => {
                $(elem).find('div').css({height: $(elem).height() + 'px'});
            });
        });
        $('.copyright').height($(window).height());
        $('.fp-tableCell').each((_, elem) => {
            $(elem).find('div').css({height: $(elem).height() + 'px'});
        });
    }, []);

    return (
        <Container>
            <Button
                visible={page !== 1}
                onClick={() => {
                    slider.moveTo(1);
                }}
            >
                <img src={upIcon} alt="" style={{width: "50px"}}/>
            </Button>
            <Pager1 currentPage={page} onClick={moveToPage}/>
            <Helmet>
                <title>DeliQuick | 제휴안내</title>
            </Helmet>
            <ReactFullpage
                className={'landing-fullpage'}
                style={{zIndex: '1000'}}
                licenseKey={'9439CB05-0AE445C4-A175C2C9-8D09C23B'}
                onLeave={(origin, dest) => {
                    setPage(dest.index + 1);
                }}
                render={({state, fullpageApi}) => {
                    slider = fullpageApi;
                    return (
                        <ReactFullpage.Wrapper>
                            <Slide className={'section d-flex first-section'}>
                                <TopButton/>
                                <Section className={'i-section'} bg={img_1}>
                                    <img src={img_1} style={{width: '100%', alignSelf: 'start'}}
                                         alt=""
                                         className={'img-lg'}/>
                                    <img src={img_11} style={{width: '100%', alignSelf: 'start'}}
                                         alt=""
                                         className={'img-sm'}/>
                                </Section>
                            </Slide>
                            <Slide className={'section'} black={true}>
                                <Section className={'i-section fp-auto-height'}>
                                    <img src={img_2} style={{width: '100%'}} alt=""
                                         className={'img-lg'}/>
                                    <img src={img_22} style={{width: '100%'}} alt=""
                                         className={'img-sm'}/>
                                </Section>
                            </Slide>
                            <Slide className={'section'} black={true}>
                                <Section className={'i-section fp-auto-height'}>
                                    <img src={img_3} style={{width: '100%'}} alt=""
                                         className={'img-lg'}/>
                                    <img src={img_33} style={{width: '100%'}} alt=""
                                         className={'img-sm'}/>
                                </Section>
                            </Slide>
                            <Slide className={'section'} style={{background: '#575e7b'}}>
                                <Section className={'i-section fp-auto-height'}>
                                    <img src={img_4} style={{width: '100%'}} alt=""
                                         className={'img-lg'}/>
                                    <img src={img_44} style={{width: '100%'}} alt=""
                                         className={'img-sm'}/>
                                </Section>
                            </Slide>
                            <Slide className={'section last-section'} style={{background: 'rgb(85, 94, 124)'}}>
                                <Section style={{flexDirection: 'column'}} className={'i-section fp-auto-height'}>
                                    <div className={'img-sm'}>
                                        <img
                                            src={img_55}
                                            style={{width: '100%'}}
                                            alt=""
                                        />
                                    </div>
                                    <div className={'img-lg copyright'}>
                                        <img
                                            src={img_5}
                                            style={{height: '100%'}}
                                            alt=""
                                        />
                                    </div>
                                </Section>
                            </Slide>
                        </ReactFullpage.Wrapper>
                    )
                }}
            />
        </Container>
    );
};

export default Landing;
