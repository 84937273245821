import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { InputButton2 } from './Item2';
import axios from 'axios';

const KAKAO_KEY = `ac43fbb223e7b2d23566268550e58695`;

const Modal = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  .add-contents {
    width: 90%;
    height: 80%;
    border-radius: 4px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;

const Button = styled.button`
  border: none;
  outline: none;
  border: 1px solid #aaa;
  color: #666;
  border-radius: 4px;
  background: #fff;
  font-weight: bold;
  font-size: 14px;
  padding: 0.4rem 0.8rem;
  cursor: pointer;
  transition: 0.1s all ease-out;
  white-space: nowrap;
  &:hover {
    background: #dfdfdf;
    border-color: #dfdfdf;
  }
`;

const AddressItem = styled.div`
  display: flex;
  border-top: 1px solid #ccc;
  padding: 0.25rem 5%;
  width: 90%;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

function GetAddress({ setData }) {
  const [isOpen, setIsOpen] = useState(false);
  const [keyword, setKeyword] = useState();
  const [list, setList] = useState([]);
  const ref = useRef();
  const [selected, setSelected] = useState();
  const [detail, setDetail] = useState('');
  useOnClickOutside(ref, () => setIsOpen(false));

  const getAddress = () => {
    if (keyword) {
      axios({
        method: 'get',
        url: `https://dapi.kakao.com/v2/local/search/address.json?query=${keyword}&size=30`,
        headers: {
          Authorization: `KakaoAK ${KAKAO_KEY}`,
        },
      }).then((result) => {
        const { documents } = result.data;
        setList(documents);
      });
    }
  };

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>주소 찾기</Button>
      {isOpen && (
        <Modal>
          <div className="add-contents" ref={ref}>
            <h3>주소 찾기</h3>
            <div style={{ fontSize: '14px', textAlign: 'center', marginBottom: '1rem' }}>
              찾으시려는 도로명주소, 건물번호/건물명 혹은 <br />
              지번주소, 번지수/건물명을 입력해주세요.
            </div>
            <div style={{ display: 'flex', alignItems: 'center', width: '90%' }}>
              <InputButton2
                onChange={(e) => setKeyword(e.target.value)}
                placeholder=""
                name="address"
                onKeyDown={(e) => {
                  if (e.keyCode === 13) getAddress();
                }}
              />
              <Button onClick={() => getAddress()} style={{ marginLeft: '0.25rem' }}>
                검색
              </Button>
            </div>
            {list.length > 0 && (
              <>
                <div style={{ margin: '0.5rem', fontSize: '12px' }}>
                  <b>"{keyword}"</b> 검색 결과 <span style={{ color: '#aaa' }}>{list.length}건</span>
                </div>
                <div style={{ overflow: 'scroll', width: '90%', maxHeight: '50%' }}>
                  {list.map((item, index) => (
                    <AddressItem onClick={() => setSelected(item)} key={index}>
                      ({index + 1}) {item.address_name}
                    </AddressItem>
                  ))}
                </div>
                {selected && (
                  <div
                    style={{
                      margin: '0.5rem',
                      fontSize: '14px',
                      width: '90%',
                      borderTop: '1px solid #ccc',
                      paddingTop: '0.25rem',
                      textAlign: 'center',
                    }}
                  >
                    <div>선택한주소: {selected.address_name}</div>
                    <InputButton2 placeholder="상세 주소 입력" onChange={(e) => setDetail(e.target.value)} />
                    <Button
                      onClick={() => {
                        setData(selected.address_name + ' ' + detail);
                        setIsOpen(false);
                      }}
                    >
                      완료
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
        </Modal>
      )}
    </>
  );
}

export default GetAddress;

const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};
