import React from "react";
import { Route, Switch } from "react-router-dom";
import Landing from "./Landing/Landing";
import How from "./HowToUse/How";
import "./App.css";
import Survey2 from "./Survey/Survey2";

function App() {
  return (
    <Switch>
      <Route exact path="/" component={How} />
      <Route exact path="/partners" component={Landing} />
      <Route exact path="/ask" component={Survey2} />
    </Switch>
  );
}

export default App;
