import React, { useEffect } from "react";
import atlasSrc from "../images/atlas2.png";
import $ from "jquery";

let createjs, AdobeAn;
let canvas, stage, exportRoot, anim_container, dom_overlay_container, fnStartAnimation;

(function(_, an) {
  const cjs = window.createjs;
  const createjs = window.createjs;
  var p; // shortcut to reference prototypes
  var lib = {};
  var ss = {};
  var img = {};
  lib.ssMetadata = [
    {
      name: "movie_HTML5 Canvas_atlas_",
      frames: [
        [0, 3009, 140, 80],
        [142, 3009, 140, 80],
        [284, 3091, 140, 80],
        [426, 3091, 140, 80],
        [568, 3091, 140, 80],
        [710, 3091, 140, 80],
        [852, 3091, 140, 80],
        [994, 3091, 140, 80],
        [0, 3173, 140, 80],
        [142, 3173, 140, 80],
        [284, 3173, 140, 80],
        [426, 3173, 140, 80],
        [284, 3009, 140, 80],
        [426, 3009, 140, 80],
        [568, 3009, 140, 80],
        [710, 3009, 140, 80],
        [852, 3009, 140, 80],
        [994, 3009, 140, 80],
        [0, 3091, 140, 80],
        [142, 3091, 140, 80],
        [0, 2875, 1125, 132],
        [0, 0, 750, 2873]
      ]
    }
  ];

  // symbols:

  (lib.ani_0 = function() {
    this.initialize(ss["movie_HTML5 Canvas_atlas_"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.ani_1 = function() {
    this.initialize(ss["movie_HTML5 Canvas_atlas_"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.ani_10 = function() {
    this.initialize(ss["movie_HTML5 Canvas_atlas_"]);
    this.gotoAndStop(2);
  }).prototype = p = new cjs.Sprite();

  (lib.ani_11 = function() {
    this.initialize(ss["movie_HTML5 Canvas_atlas_"]);
    this.gotoAndStop(3);
  }).prototype = p = new cjs.Sprite();

  (lib.ani_12 = function() {
    this.initialize(ss["movie_HTML5 Canvas_atlas_"]);
    this.gotoAndStop(4);
  }).prototype = p = new cjs.Sprite();

  (lib.ani_13 = function() {
    this.initialize(ss["movie_HTML5 Canvas_atlas_"]);
    this.gotoAndStop(5);
  }).prototype = p = new cjs.Sprite();

  (lib.ani_14 = function() {
    this.initialize(ss["movie_HTML5 Canvas_atlas_"]);
    this.gotoAndStop(6);
  }).prototype = p = new cjs.Sprite();

  (lib.ani_15 = function() {
    this.initialize(ss["movie_HTML5 Canvas_atlas_"]);
    this.gotoAndStop(7);
  }).prototype = p = new cjs.Sprite();

  (lib.ani_16 = function() {
    this.initialize(ss["movie_HTML5 Canvas_atlas_"]);
    this.gotoAndStop(8);
  }).prototype = p = new cjs.Sprite();

  (lib.ani_17 = function() {
    this.initialize(ss["movie_HTML5 Canvas_atlas_"]);
    this.gotoAndStop(9);
  }).prototype = p = new cjs.Sprite();

  (lib.ani_18 = function() {
    this.initialize(ss["movie_HTML5 Canvas_atlas_"]);
    this.gotoAndStop(10);
  }).prototype = p = new cjs.Sprite();

  (lib.ani_19 = function() {
    this.initialize(ss["movie_HTML5 Canvas_atlas_"]);
    this.gotoAndStop(11);
  }).prototype = p = new cjs.Sprite();

  (lib.ani_2 = function() {
    this.initialize(ss["movie_HTML5 Canvas_atlas_"]);
    this.gotoAndStop(12);
  }).prototype = p = new cjs.Sprite();

  (lib.ani_3 = function() {
    this.initialize(ss["movie_HTML5 Canvas_atlas_"]);
    this.gotoAndStop(13);
  }).prototype = p = new cjs.Sprite();

  (lib.ani_4 = function() {
    this.initialize(ss["movie_HTML5 Canvas_atlas_"]);
    this.gotoAndStop(14);
  }).prototype = p = new cjs.Sprite();

  (lib.ani_5 = function() {
    this.initialize(ss["movie_HTML5 Canvas_atlas_"]);
    this.gotoAndStop(15);
  }).prototype = p = new cjs.Sprite();

  (lib.ani_6 = function() {
    this.initialize(ss["movie_HTML5 Canvas_atlas_"]);
    this.gotoAndStop(16);
  }).prototype = p = new cjs.Sprite();

  (lib.ani_7 = function() {
    this.initialize(ss["movie_HTML5 Canvas_atlas_"]);
    this.gotoAndStop(17);
  }).prototype = p = new cjs.Sprite();

  (lib.ani_8 = function() {
    this.initialize(ss["movie_HTML5 Canvas_atlas_"]);
    this.gotoAndStop(18);
  }).prototype = p = new cjs.Sprite();

  (lib.ani_9 = function() {
    this.initialize(ss["movie_HTML5 Canvas_atlas_"]);
    this.gotoAndStop(19);
  }).prototype = p = new cjs.Sprite();

  (lib.IMG_3F68175E4BAC1 = function() {
    this.initialize(ss["movie_HTML5 Canvas_atlas_"]);
    this.gotoAndStop(20);
  }).prototype = p = new cjs.Sprite();

  (lib.IMG_4963 = function() {
    this.initialize(ss["movie_HTML5 Canvas_atlas_"]);
    this.gotoAndStop(21);
  }).prototype = p = new cjs.Sprite();

  // helper functions:

  function mc_symbol_clone() {
    var clone = this._cloneProps(new this.constructor(this.mode, this.startPosition, this.loop));
    clone.gotoAndStop(this.currentFrame);
    clone.paused = this.paused;
    clone.framerate = this.framerate;
    return clone;
  }

  function getMCSymbolPrototype(symbol, nominalBounds, frameBounds) {
    var prototype = cjs.extend(symbol, cjs.MovieClip);
    prototype.clone = mc_symbol_clone;
    prototype.nominalBounds = nominalBounds;
    prototype.frameBounds = frameBounds;
    return prototype;
  }

  (lib.tab_g = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#5E9FFF")
      .s()
      .p("Ah6B7Qg0gzAAhIQAAhHA0gzQAzg0BHAAQBIAAA0A0QAzAzAABHQAABIgzAzQg0A0hIAAQhHAAgzg0g");

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-17.5, -17.5, 35, 35);

  (lib.t3 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#865320")
      .s()
      .p("AnkHlQjKjJAAkcQAAkbDKjKQDJjJEbABQEcgBDKDJQDJDKgBEbQABEcjJDJQjKDJkcAAQkbAAjJjJg");

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
  }).prototype = getMCSymbolPrototype(lib.t3, new cjs.Rectangle(-68.6, -68.6, 137.3, 137.3), null);

  (lib.t2 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#71717B")
      .s()
      .p(
        "AAMAvIAAgBQANgLAAgSQAAgRgNgMQgMgMgQAAQgRAAgNAMIgBACQgsgogxgeIAMgOQAvguBBAAQBAAAAuAuQAvAugBBBQABBCgvAtIgNANQgfgygmgsg"
      );
    this.shape.setTransform(50.25, -50.25);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics
      .f("#71717B")
      .s()
      .p(
        "AhXCdQANg4AEg7IABAAQASAAAMgMQAMgMAAgSQAAgRgMgMQgMgMgSAAIgBAAQgEg7gNg3IASgBQBCgBAtAvQAuAuAABAQAABBguAvQgtAthCABIgSgBg"
      );
    this.shape_1.setTransform(75.625, 0);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics
      .f("#71717B")
      .s()
      .p(
        "AiABeQgHgHgFgGQAzggAqglIABAAQANANARAAQAQAAAMgNQANgMAAgQQAAgRgNgNIAAgBQAlgqAggzQAGAFAHAHQAvAvgBBBQABBAgvAuQguAvhAgBQhBABgvgvg"
      );
    this.shape_2.setTransform(50.25, 50.25);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics
      .f("#71717B")
      .s()
      .p(
        "AhuAqQgvgtABhCIABgSQA3ANA7AEIAAABQAAASAMAMQAMAMARAAQASAAAMgMQAMgMAAgSIAAgBQA7gEA4gNIABASQgBBCgtAtQgvAuhBAAQhAAAgugug"
      );
    this.shape_3.setTransform(0, 75.625);

    this.shape_4 = new cjs.Shape();
    this.shape_4.graphics
      .f("#71717B")
      .s()
      .p(
        "AheBeQguguAAhAQAAhBAugvIAOgMQAeAxAoAsIgCABQgMANAAARQAAAQAMAMQAMANARAAQASAAALgNIABAAQAsAmAyAfIgNANQgtAvhCgBQhBABgugvg"
      );
    this.shape_4.setTransform(-50.25, 50.25);

    this.shape_5 = new cjs.Shape();
    this.shape_5.graphics
      .f("#71717B")
      .s()
      .p(
        "AgpBwQgugvAAhBQAAhAAuguQAugvBBABIASABQgOA7gDA3IgBAAQgSAAgMAMQgMAMAAARQAAASAMAMQAMAMASAAIABAAQADA3AOA8IgSABQhBgBgugtg"
      );
    this.shape_5.setTransform(-75.625, 0);

    this.shape_6 = new cjs.Shape();
    this.shape_6.graphics
      .f("#71717B")
      .s()
      .p(
        "AheCAQgugtAAhCQAAhBAuguQAuguBBAAQBCAAAtAuIANAOQgwAeguAoIgBgCQgLgMgSAAQgRAAgMAMQgMAMAAARQAAASAMALIACABQgoAugeAwg"
      );
    this.shape_6.setTransform(-50.25, -50.25);

    this.shape_7 = new cjs.Shape();
    this.shape_7.graphics
      .f("#71717B")
      .s()
      .p(
        "AAqBHIAAgBQAAgSgMgMQgMgMgSAAQgRAAgMAMQgMAMAAASIAAABQg3ADg7AOIgBgSQgBhBAvguQAuguBAAAQBBAAAvAuQAtAuABBBIgBASQg8gOg3gDg"
      );
    this.shape_7.setTransform(0, -75.625);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({
          state: [
            { t: this.shape_7 },
            { t: this.shape_6 },
            { t: this.shape_5 },
            { t: this.shape_4 },
            { t: this.shape_3 },
            { t: this.shape_2 },
            { t: this.shape_1 },
            { t: this.shape }
          ]
        })
        .wait(1)
    );
  }).prototype = getMCSymbolPrototype(lib.t2, new cjs.Rectangle(-84.4, -84.4, 168.8, 168.8), null);

  (lib.t1 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#212132")
      .s()
      .p(
        "AglAmQgQgQAAgWQAAgVAQgQQAQgQAVAAQAWAAAQAQQAQAQAAAVQAAAWgQAQQgQAQgWAAQgVAAgQgQgAgSgRQgHAHAAAKQAAALAHAIQAIAHAKABQALgBAHgHQAIgIAAgLQAAgKgIgHQgHgIgLAAQgKAAgIAIg"
      );
    this.shape.setTransform(45.05, -4.9);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics
      .f("#212132")
      .s()
      .p(
        "AglAmQgQgQAAgWQAAgVAQgQQAQgQAVAAQAWAAAQAQQAQAQAAAVQAAAWgQAQQgQAQgWAAQgVAAgQgQgAgSgSQgHAIAAAKQAAALAHAHQAIAIAKAAQALAAAHgIQAIgHAAgLQAAgKgIgIQgHgHgLAAQgKAAgIAHg"
      );
    this.shape_1.setTransform(-20.75, 39.825);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics
      .f("#212132")
      .s()
      .p(
        "AglAmQgQgQAAgWQAAgVAQgQQAQgQAVAAQAWAAAQAQQAQAQAAAVQAAAWgQAQQgQAQgWAAQgVAAgQgQgAgRgRQgIAHAAAKQAAALAIAIQAHAHAKAAQALAAAIgHQAHgIAAgLQAAgKgHgHQgIgIgLAAQgKAAgHAIg"
      );
    this.shape_2.setTransform(9.175, 4.875);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics
      .f("#212132")
      .s()
      .p(
        "AglAmQgQgQAAgWQAAgVAQgQQAQgQAVAAQAWAAAQAQQAQAQAAAVQAAAWgQAQQgQAQgWAAQgVAAgQgQgAgRgRQgIAHAAAKQAAALAIAHQAHAIAKAAQALAAAIgIQAHgHAAgLQAAgKgHgHQgIgIgLAAQgKAAgHAIg"
      );
    this.shape_3.setTransform(-23.525, -38.475);

    this.shape_4 = new cjs.Shape();
    this.shape_4.graphics
      .f("#327157")
      .s()
      .p("AgeAfQgNgNgBgSQABgRANgOQAMgMASAAQATAAANAMQANAOgBARQABASgNANQgNAOgTAAQgSAAgMgOg");
    this.shape_4.setTransform(32.25, 37.2);

    this.shape_5 = new cjs.Shape();
    this.shape_5.graphics
      .f("#327157")
      .s()
      .p("AgeAfQgNgNgBgSQABgSANgNQAMgNASAAQATAAANANQANANAAASQAAASgNANQgNANgTABQgSgBgMgNg");
    this.shape_5.setTransform(20.55, -39.9);

    this.shape_6 = new cjs.Shape();
    this.shape_6.graphics
      .f("#327157")
      .s()
      .p("AgfAfQgNgNABgSQgBgSANgNQANgNASAAQASAAANANQANANABASQgBASgNANQgNANgSABQgSgBgNgNg");
    this.shape_6.setTransform(-46.6, -1.2);

    this.shape_7 = new cjs.Shape();
    this.shape_7.graphics
      .f("#327157")
      .s()
      .p("AgfAfQgNgMABgTQgBgRANgNQAOgNARAAQATAAAMANQAOANAAARQAAATgOAMQgMANgTAAQgRAAgOgNg");
    this.shape_7.setTransform(-5.85, -10.225);

    this.shape_8 = new cjs.Shape();
    this.shape_8.graphics
      .f("#913246")
      .s()
      .p("AgdBvQgugNgXgpQgYgpAMgtQANguApgXQApgYAsAMQAuANAYApQAYApgMAtQgNAugpAXQgbAQgdAAQgOAAgQgEg");
    this.shape_8.setTransform(29.825, -17.225);

    this.shape_9 = new cjs.Shape();
    this.shape_9.graphics
      .f("#913246")
      .s()
      .p("Ag4BjQgpgYgNguQgMgsAYgpQAXgpAugNQAtgMApAYQApAXANAuQAMAtgYApQgYApguANQgPAEgOAAQgdAAgbgQg");
    this.shape_9.setTransform(29.825, 17.225);

    this.shape_10 = new cjs.Shape();
    this.shape_10.graphics
      .f("#913246")
      .s()
      .p("AhQBRQghgigBgvQABgvAhghQAigiAuAAQAwAAAhAiQAiAhgBAvQABAvgiAiQghAhgwABQgugBgighg");
    this.shape_10.setTransform(0, 34.45);

    this.shape_11 = new cjs.Shape();
    this.shape_11.graphics
      .f("#913246")
      .s()
      .p("AgdBvQgugNgXgpQgYgpAMgtQANguApgXQApgYAsAMQAuANAYApQAYApgMAsQgNAugpAYQgbAQgdAAQgOAAgQgEg");
    this.shape_11.setTransform(-29.825, 17.225);

    this.shape_12 = new cjs.Shape();
    this.shape_12.graphics
      .f("#913246")
      .s()
      .p("Ag4BjQgpgXgNguQgMgtAYgpQAXgpAugNQAtgMApAYQApAXANAuQAMAtgYApQgYApguANQgPAEgOAAQgdAAgbgQg");
    this.shape_12.setTransform(-29.825, -17.225);

    this.shape_13 = new cjs.Shape();
    this.shape_13.graphics
      .f("#913246")
      .s()
      .p("AhQBRQghghgBgwQABgvAhghQAighAuAAQAwAAAhAhQAiAigBAuQABAwgiAhQghAhgwAAQguAAgighg");
    this.shape_13.setTransform(0, -34.45);

    this.shape_14 = new cjs.Shape();
    this.shape_14.graphics
      .f("#918149")
      .s()
      .p("AmVGWQipioAAjuQAAjtCpioQCoipDtAAQDuAACpCpQCoCoAADtQAADuioCoQipCpjuAAQjtAAioipg");
    this.shape_14.setTransform(-0.025, 1.225);

    this.shape_15 = new cjs.Shape();
    this.shape_15.graphics
      .f("#91682B")
      .s()
      .p("AkKJ5Qh8g1hehfQjKjJAAkcQAAkbDKjKQDJjJEbAAQEcAADKDJQDJDKAAEbQAAEcjJDJQjKDKkcAAQiKAAiAg2g");

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({
          state: [
            { t: this.shape_15 },
            { t: this.shape_14 },
            { t: this.shape_13 },
            { t: this.shape_12 },
            { t: this.shape_11 },
            { t: this.shape_10 },
            { t: this.shape_9 },
            { t: this.shape_8 },
            { t: this.shape_7 },
            { t: this.shape_6 },
            { t: this.shape_5 },
            { t: this.shape_4 },
            { t: this.shape_3 },
            { t: this.shape_2 },
            { t: this.shape_1 },
            { t: this.shape }
          ]
        })
        .wait(1)
    );
  }).prototype = getMCSymbolPrototype(lib.t1, new cjs.Rectangle(-68.6, -68.6, 137.3, 137.3), null);

  (lib.screen = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib.IMG_4963();
    this.instance.parent = this;
    this.instance.setTransform(-147.8, -566.25, 0.3942, 0.3942);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));
  }).prototype = getMCSymbolPrototype(lib.screen, new cjs.Rectangle(-147.8, -566.2, 295.70000000000005, 1132.5), null);

  (lib.qr_targeting = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#FF2E48")
      .s()
      .p("AifCgIAAgoIEXAAIAAkXIAoAAIAAE/g");
    this.shape.setTransform(67.2, 65.6);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics
      .f("#FF2E48")
      .s()
      .p("AifCgIAAk/IAoAAIAAEXIEXAAIAAAog");
    this.shape_1.setTransform(-65.55, 65.6);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics
      .f("#FF2E48")
      .s()
      .p("AifCgIAAk/IE/AAIAAAoIkXAAIAAEXg");
    this.shape_2.setTransform(-65.55, -67.15);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics
      .f("#FF2E48")
      .s()
      .p("AB4CgIAAkXIkXAAIAAgoIE/AAIAAE/g");
    this.shape_3.setTransform(67.2, -67.15);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({
          state: [{ t: this.shape_3 }, { t: this.shape_2 }, { t: this.shape_1 }, { t: this.shape }]
        })
        .wait(1)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-81.5, -83.1, 164.7, 164.7);

  (lib.qr_bar = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#FF2E48")
      .s()
      .p("A2/AUIAAgnMAt/AAAIAAAng");
    this.shape.setTransform(-0.025, 0);

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-147.2, -2, 294.4, 4);

  (lib.phone_form = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#FFFFFF")
      .s()
      .p(
        "EgR6A0BQjAAAiKiJQiIiJAAjBMAAAhZbQAAjBCIiJQCKiJDAAAMAj1AAAQDAAACKCJQCICJAADBMAAABZbQAADBiICJQiKCJjBAAgEgVfgwTQhhBgABCIMAAABZXQgBCIBhBgQBgBgCHAAMAjxAAAQCIAABfhgQBghgAAiIMAAAhZXQAAiIhghgQhghgiIAAIlBAAIAABbQgBA7gqAqQgpApg7AAI1OAAQg6AAgqgpQgqgqAAg7IAAhbIlEAAQiHAAhgBgg"
      );
    this.shape.setTransform(0, 0.025);

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-161.3, -332.8, 322.6, 665.7);

  (lib.phone = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // phone
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#FFFFFF")
      .s()
      .p(
        "EgR6A0BQjAAAiKiJQiIiJAAjBMAAAhZbQAAjBCIiJQCKiJDAAAMAj1AAAQDAAACKCJQCICJAADBMAAABZbQAADBiICJQiKCJjBAAgEgVfgwTQhhBgABCIMAAABZXQgBCIBhBgQBgBgCHAAMAjxAAAQCIAABfhgQBghgAAiIMAAAhZXQAAiIhghgQhghgiIAAIlBAAIAABbQgBA7gqAqQgpApg7AAI1OAAQg6AAgqgpQgqgqAAg7IAAhbIlEAAQiHAAhgBgg"
      );
    this.shape.setTransform(0, 0.025);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics
      .f("#4C5672")
      .s()
      .p("Ag8A+QgagaAAgkQAAgjAagZQAZgaAjAAQAkAAAZAaQAaAZAAAjQAAAkgaAaQgZAZgkAAQgjAAgZgZg");
    this.shape_1.setTransform(-130.025, -275.575);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics
      .f("#4C5672")
      .s()
      .p("Ag8A+QgagaAAgkQAAgjAagZQAZgaAjAAQAkAAAZAaQAaAZAAAjQAAAkgaAaQgZAZgkAAQgjAAgZgZg");
    this.shape_2.setTransform(-44.275, -275.575);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics
      .f("#4C5672")
      .s()
      .p("Ag8A+QgagaAAgkQAAgjAagZQAZgaAjAAQAkAAAaAaQAZAZAAAjQAAAkgZAaQgaAZgkAAQgjAAgZgZg");
    this.shape_3.setTransform(126.975, -275.575);

    this.shape_4 = new cjs.Shape();
    this.shape_4.graphics
      .f("#4C5672")
      .s()
      .p("Ag8A+QgagaAAgkQAAgjAagZQAZgaAjAAQAkAAAaAaQAZAZAAAjQAAAkgZAaQgaAZgkAAQgjAAgZgZg");
    this.shape_4.setTransform(41.225, -275.575);

    this.shape_5 = new cjs.Shape();
    this.shape_5.graphics
      .f("#4C5672")
      .s()
      .p("AhHAwQgUAAgOgOQgOgOAAgUQAAgTAOgOQAOgOAUAAICPAAQAUAAAOAOQAOAOAAATQAAAUgOAOQgOAOgUAAg");
    this.shape_5.setTransform(103.925, 184.45);

    this.shape_6 = new cjs.Shape();
    this.shape_6.graphics
      .f("#4C5672")
      .s()
      .p("AiFAwQgUAAgOgOQgOgOAAgUQAAgTAOgOQAOgOAUAAIELAAQAUAAAOAOQAOAOAAATQAAAUgOAOQgOAOgUAAg");
    this.shape_6.setTransform(53.4, 184.45);

    this.shape_7 = new cjs.Shape();
    this.shape_7.graphics
      .f("#4C5672")
      .s()
      .p("AhwAwQgUAAgOgOQgOgOAAgUQAAgTAOgOQAOgOAUAAIDhAAQAUAAAOAOQAOAOAAATQAAAUgOAOQgOAOgUAAg");
    this.shape_7.setTransform(-95.975, 184.45);

    this.shape_8 = new cjs.Shape();
    this.shape_8.graphics
      .f("#4C5672")
      .s()
      .p("AhgAwQgVAAgNgOQgPgOAAgUQAAgTAPgOQANgOAVAAIDBAAQAVAAANAOQAPAOAAATQAAAUgPAOQgNAOgVAAg");
    this.shape_8.setTransform(-44.25, 184.45);

    this.shape_9 = new cjs.Shape();
    this.shape_9.graphics
      .f("#4C5672")
      .s()
      .p("AgpAwQgUAAgOgOQgOgOAAgUQAAgTAOgOQAOgOAUAAIBTAAQAUAAAOAOQAOAOAAATQAAAUgOAOQgOAOgUAAg");
    this.shape_9.setTransform(0.325, 184.45);

    this.shape_10 = new cjs.Shape();
    this.shape_10.graphics
      .f("#4C5672")
      .s()
      .p(
        "A1CDAQgJAAgFgFQgGgHAAgIIAAlXQAAgIAGgHQAFgFAJgBIFYAAQAJABAGAFQAGAHAAAIIAAFXQAAAIgGAHQgGAFgJAAgAPqCPQgIgBgGgFQgGgGAAgJIAAjzQAAgJAGgFQAGgHAIAAIFZAAQAIAAAGAHQAGAFAAAJIAADzQAAAJgGAGQgGAFgIABg"
      );
    this.shape_10.setTransform(1.175, 231.6);

    this.shape_11 = new cjs.Shape();
    this.shape_11.graphics
      .f()
      .s("#323248")
      .ss(2)
      .p("ADgAAQAABdhBBCQhCBBhdAAQhcAAhChBQhBhCAAhdQAAhcBBhCQBChBBcAAQBdAABCBBQBBBCAABcg");
    this.shape_11.setTransform(0.325, 231.55);

    this.shape_12 = new cjs.Shape();
    this.shape_12.graphics
      .f("#FFFFFF")
      .s()
      .p("AjGDHQhThTAAh0QAAh0BThSQBShSB0AAQB0AABTBSQBTBSAAB0QAAB0hTBTQhTBTh0AAQh0AAhShTg");
    this.shape_12.setTransform(0.325, 231.55);

    this.shape_13 = new cjs.Shape();
    this.shape_13.graphics
      .f("#323248")
      .s()
      .p("AyxMUQiOAAhmhlQhkhlgBiPIAAzOMAwVAAAIAATOQAACPhmBlQhkBliQAAg");
    this.shape_13.setTransform(1.05, 247.675);

    this.shape_14 = new cjs.Shape();
    this.shape_14.graphics
      .f("#323248")
      .s()
      .p("A4NHbIAApdQAAiPBlhkQBkhmCPAAMAlrAAAQCPAABkBmQBlBkAACPIAAJdg");
    this.shape_14.setTransform(-0.525, -271.2);

    this.shape_15 = new cjs.Shape();
    this.shape_15.graphics
      .f("#4C5672")
      .s()
      .p("EgR3Ax0QiIAAhghgQhghgAAiIMAAAhZXQAAiIBghgQBghgCIAAMAjvAAAQCIAABgBgQBgBgAACIMAAABZXQAACIhgBgQhgBgiIAAg");
    this.shape_15.setTransform(-0.025, 0.05);

    this.shape_16 = new cjs.Shape();
    this.shape_16.graphics
      .f("#FFFFFF")
      .s()
      .p("EgR5Ax0QiHAAhfhgQhghgAAiHMAAAhZaQAAiHBghgQBfhfCHAAMAjzAAAQCHAABgBfQBfBgAACHMAAABZaQAACHhfBgQhgBgiHAAg");
    this.shape_16.setTransform(-0.025, 0.05);

    this.shape_17 = new cjs.Shape();
    this.shape_17.graphics
      .f("#FFFFFF")
      .s()
      .p("EgR5A0BQjCAAiJiJQiIiJAAjBMAAAhZbQAAjBCIiJQCJiJDCAAMAj0AAAQDAAACJCJQCJCJAADBMAAABZbQAADBiJCJQiJCJjBAAg");
    this.shape_17.setTransform(0, 0.025);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({
          state: [
            { t: this.shape_17 },
            { t: this.shape_16 },
            { t: this.shape_15 },
            { t: this.shape_14 },
            { t: this.shape_13 },
            { t: this.shape_12 },
            { t: this.shape_11 },
            { t: this.shape_10 },
            { t: this.shape_9 },
            { t: this.shape_8 },
            { t: this.shape_7 },
            { t: this.shape_6 },
            { t: this.shape_5 },
            { t: this.shape_4 },
            { t: this.shape_3 },
            { t: this.shape_2 },
            { t: this.shape_1 },
            { t: this.shape }
          ]
        })
        .wait(90)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-161.3, -332.8, 322.6, 665.7);

  (lib.loading = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_2
    this.instance = new lib.ani_0();
    this.instance.parent = this;

    this.instance_1 = new lib.ani_1();
    this.instance_1.parent = this;

    this.instance_2 = new lib.ani_2();
    this.instance_2.parent = this;

    this.instance_3 = new lib.ani_3();
    this.instance_3.parent = this;

    this.instance_4 = new lib.ani_4();
    this.instance_4.parent = this;

    this.instance_5 = new lib.ani_5();
    this.instance_5.parent = this;

    this.instance_6 = new lib.ani_6();
    this.instance_6.parent = this;

    this.instance_7 = new lib.ani_7();
    this.instance_7.parent = this;

    this.instance_8 = new lib.ani_8();
    this.instance_8.parent = this;

    this.instance_9 = new lib.ani_9();
    this.instance_9.parent = this;

    this.instance_10 = new lib.ani_10();
    this.instance_10.parent = this;

    this.instance_11 = new lib.ani_11();
    this.instance_11.parent = this;

    this.instance_12 = new lib.ani_12();
    this.instance_12.parent = this;

    this.instance_13 = new lib.ani_13();
    this.instance_13.parent = this;

    this.instance_14 = new lib.ani_14();
    this.instance_14.parent = this;

    this.instance_15 = new lib.ani_15();
    this.instance_15.parent = this;

    this.instance_16 = new lib.ani_16();
    this.instance_16.parent = this;

    this.instance_17 = new lib.ani_17();
    this.instance_17.parent = this;

    this.instance_18 = new lib.ani_18();
    this.instance_18.parent = this;

    this.instance_19 = new lib.ani_19();
    this.instance_19.parent = this;

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({ state: [{ t: this.instance }] })
        .to({ state: [{ t: this.instance_1 }] }, 1)
        .to({ state: [{ t: this.instance_2 }] }, 1)
        .to({ state: [{ t: this.instance_3 }] }, 1)
        .to({ state: [{ t: this.instance_4 }] }, 1)
        .to({ state: [{ t: this.instance_5 }] }, 1)
        .to({ state: [{ t: this.instance_6 }] }, 1)
        .to({ state: [{ t: this.instance_7 }] }, 1)
        .to({ state: [{ t: this.instance_8 }] }, 1)
        .to({ state: [{ t: this.instance_9 }] }, 1)
        .to({ state: [{ t: this.instance_10 }] }, 1)
        .to({ state: [{ t: this.instance_11 }] }, 1)
        .to({ state: [{ t: this.instance_12 }] }, 1)
        .to({ state: [{ t: this.instance_13 }] }, 1)
        .to({ state: [{ t: this.instance_14 }] }, 1)
        .to({ state: [{ t: this.instance_15 }] }, 1)
        .to({ state: [{ t: this.instance_16 }] }, 1)
        .to({ state: [{ t: this.instance_17 }] }, 1)
        .to({ state: [{ t: this.instance_18 }] }, 1)
        .to({ state: [{ t: this.instance_19 }] }, 1)
        .wait(1)
    );

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#FFFFFF")
      .s()
      .p("EgkWBEPMAAAiIdMBItAAAMAAACIdg");
    this.shape.setTransform(69.175, 102.8);

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(20));
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-163.5, -333.9, 465.4, 873.4);

  (lib.link = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#6E96D3")
      .s()
      .p("AhQBSQgjgiAAgwQAAguAjgjQAhghAvgBQAvABAiAhQAjAjAAAuQAAAwgjAiQgiAhgvAAQgvAAghghg");
    this.shape.setTransform(115.5, 7.6);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics
      .f("#FFFFFF")
      .s()
      .p("AhhCgQgZgBgTgRQgSgTAAgZIAAjDQAAgZASgSQATgSAZgBIDDAAQAZABATASQASASAAAZIAADDQAAAZgSATQgTARgZABg");
    this.shape_1.setTransform(115.5, 7.6);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics
      .f("#323248")
      .s()
      .p("AgmA/QgKAAgHgHQgHgIAAgKIAAhLQAAgKAHgIQAHgHAKAAIBMAAQAKAAAIAHQAHAIAAAKIAABLQAAAKgHAIQgIAHgKAAg");
    this.shape_2.setTransform(-121.675, -17.35);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics
      .f("#323248")
      .s()
      .p(
        "AgkAjIgCgBIgBgDIABgDIACgBIBJAAIACABIABADQAAAEgDAAgAgXAKIgEgBQgBAAAAgBQAAAAAAgBQgBAAAAgBQAAAAAAgBIAAgiQAAAAAAgBQAAAAABgBQAAAAAAAAQAAgBABAAQACgCADAAIAwAAIADABIAAADIAAADIgDABIgtAAIAAAbIAvAAQADAAAAAEQAAAFgDAAg"
      );
    this.shape_3.setTransform(-40.375, -17.725);

    this.shape_4 = new cjs.Shape();
    this.shape_4.graphics
      .f("#323248")
      .s()
      .p(
        "AgkAjIgCgBIgBgDIABgDIACgBIAXAAIAAgRIABgCIACgBIAFABQABAAAAABQABAAAAAAQAAAAABABQAAAAAAAAIAAARIAnAAIACABIABADQAAAEgDAAgAARAPQAAAAAAgBQgBAAAAAAQAAgBAAgBQAAAAABgBIACgMIgtAAQgDAAAAgEIABgDIACgBIAuAAIABgKQAAgEgCgCIgEgBIgpAAQgBAAAAAAQAAAAgBAAQAAAAAAgBQAAAAAAAAQgBAAAAgBQAAAAAAAAQAAgBAAAAQAAgBAAAAIAAgDIACgBIAtAAQAHAAADADQADADAAAEIgBATIgEARIgCADIgEABQAAAAgBAAQAAAAgBAAQAAAAAAAAQgBgBAAAAg"
      );
    this.shape_4.setTransform(-49.175, -17.625);

    this.shape_5 = new cjs.Shape();
    this.shape_5.graphics
      .f("#323248")
      .s()
      .p(
        "AgbAiIAAhBQAAAAAAgBQAAAAAAgBQABAAAAAAQAAgBAAAAQABgBAAAAQAAAAABAAQABgBAAAAQABAAABAAIASAAIAFAAIAHABIAHACIAGADQACADABAEQACADAAAGIgBAHIgCAGIgFACIgEADIAMAaIABABIAAACIgCACIgCABIgCAAIgDgBIgBAAIgGgOIgFgIIgCgFIgBgCIgEAAIgGAAIgFAAIgGAAIAAAcIAAABIgCABIgFABQgBAAAAAAQgBgBAAAAQgBAAAAgBQAAAAAAgBgAgRgCIARAAQAIAAAFgDQAEgDAAgHQAAgHgEgCQgFgDgIAAIgRAAg"
      );
    this.shape_5.setTransform(-59.15, -17.725);

    this.shape_6 = new cjs.Shape();
    this.shape_6.graphics
      .f("#323248")
      .s()
      .p(
        "AAUAlIgFgFIgHADIgIABQgGAAgFgDQgGgDgDgFQgFgFgCgHQgBgHAAgGQAAgIABgHQACgGAFgFQADgFAGgDQAFgDAGAAQAHAAAGADQAFADAEAFQADAFACAGQACAIAAAHQAAAGgCAHQgCAHgDAFIABABIABABIABACIACACIABACIgBABIgBACIgBABIgBABIgBAAIgDgBgAgIgaQgDACgCAEQgDAEgBAFIgBALIABAJQABAFADAEQACAEADACQAFADADAAIAEgBIAEgBIgDgDIgDgDIAAgCIgBgBIABgDIADgDQAAAAABAAQAAAAAAAAQAAAAABAAQAAAAAAAAIACABIACACIACACIABABIABABIADgJIABgIIgBgKQgBgFgCgEQgDgEgEgDQgDgCgFAAQgDAAgFACg"
      );
    this.shape_6.setTransform(-66, -17.625);

    this.shape_7 = new cjs.Shape();
    this.shape_7.graphics
      .f("#323248")
      .s()
      .p(
        "AgkAjIgCgBIgBgDIABgDIACgBIBJAAIACABIABADQAAAEgDAAgAgXAMIgEgBQgBAAAAAAQAAgBAAAAQgBgBAAAAQAAgBAAAAIAAglIACgEQACgBADAAIAwAAIACABIABADIgBADIgCABIgtAAIAAALIAqAAQADAAAAAEIAAADIgDABIgqAAIAAALIAuAAQAEAAAAAEQAAAEgEAAg"
      );
    this.shape_7.setTransform(-76.275, -17.725);

    this.shape_8 = new cjs.Shape();
    this.shape_8.graphics
      .f("#323248")
      .s()
      .p(
        "AAaAsIgBgCIAAhTIABgCIACgBIAFABQABAAAAABQABAAAAAAQAAAAABABQAAAAAAAAIAABTQAAAAAAAAQgBAAAAABQAAAAgBAAQAAAAgBABIgFABIgCgBgAgcARQgHgIAAgNQAAgOAHgJQAHgIALAAQAKAAAHAIQAHAJAAAOQAAAMgHAJQgHAIgKAAQgLAAgHgIgAgVgVQgEAGAAALQAAAJAEAGQAEAGAHAAQAHAAADgGQAEgGAAgJQAAgLgEgGQgEgGgGAAQgHAAgEAGg"
      );
    this.shape_8.setTransform(-85.375, -17.575);

    this.shape_9 = new cjs.Shape();
    this.shape_9.graphics
      .f("#323248")
      .s()
      .p(
        "AATAsIAAgCIAAhTIAAgCIACgBIAFABQABAAABABQAAAAABAAQAAAAAAABQAAAAAAAAIAAAfIAKAAQABAAABABQAAAAABAAQAAABAAAAQAAABAAABIAAAEIgCABIgLAAIAAArQAAAAAAAAQAAAAAAABQgBAAAAAAQgBAAAAABIgGABIgCgBgAgmAWIgDgCQAAAAAAgBQAAAAAAAAQAAgBAAAAQAAAAAAgBIABgCQAMgJAFgKQAFgLAAgQIAAgCIABgCIACAAIAFABQAAAAABAAQAAABABAAQAAAAAAABQAAAAAAAAIAAABQAAANgDAKQAGAEAFAFQAHAHADAHIAAADIgCACQAAAAAAABQgBAAAAAAQgBAAAAAAQAAgBgBAAIgCgCQgCgEgFgHQgFgEgGgDQgHANgOAJIgBAAIgBAAg"
      );
    this.shape_9.setTransform(-93.8333, -17.575);

    this.shape_10 = new cjs.Shape();
    this.shape_10.graphics
      .f("#323248")
      .s()
      .p(
        "AgPAtIgDgCIgBgCIAAgbIAAgCIACgBIAFABQABAAAAAAQABAAAAABQAAAAABAAQAAABAAAAIAAAGIAkAAIAAgHIABgCIACgBIAFABQABAAAAAAQABABAAAAQAAAAABAAQAAABAAAAIAAAcQAAAAAAABQAAAAgBAAQAAABAAAAQAAAAgBAAIgDACgAgJAkIAkAAIAAgIIgkAAgAAcAHIgBgBIAAgvIABgCIACgBIAFABQABAAAAABQABAAAAAAQAAAAABAAQAAABAAAAIAAAvQAAAAAAAAQgBAAAAAAQAAABgBAAQAAAAgBAAIgFABIgCgBgAgSAHIgBgBIAAgHIgPAAIgCAAIgBgDIABgEIACgBIASAAIALgBIAJgBIADAAIABACIAAADIgCACQgFACgFAAIgFABIAAAGQAAAAAAAAQAAABAAAAQgBAAAAAAQgBABAAAAIgFABIgCgBgAAMAHIgLAAIgBgBIgBgDIAAgDIACAAIAKAAIAAgnIAAgCIACAAIAFAAQABAAAAABQABAAAAAAQAAAAABABQAAAAAAABIAAArQAAAAAAAAQgBABAAAAQAAAAgBAAQAAAAgBAAIgFABIgBAAgAgUgMIgGgEQgDgBgBgDQgCgDAAgDQAAgDACgDIAEgFIAGgCIAHgBIAIABIAFACIAEAFQACADAAADQAAADgCADIgEAEIgFAEIgIABIgHgBgAgTgfQgDACAAADQAAADADACQACABAEAAQAFAAACgBQADgCAAgDQAAgDgDgCQgDgBgEAAQgEAAgCABg"
      );
    this.shape_10.setTransform(-105.575, -17.75);

    this.shape_11 = new cjs.Shape();
    this.shape_11.graphics
      .f("#323248")
      .s()
      .p(
        "AApBGIgBgDIAAiFIABgDIADgBIAIABQAEABAAACIAACFQAAACgEABIgIABIgDgBgAgzAhQgBAAAAAAQgBgBAAAAQAAgBgBAAQAAgBAAgBQgCgDABgCIACgCQAXgNAOgOQANgQAAgMQAAgEgBgCQgCgCgGAAIglAAQgBAAAAAAQgBAAAAgBQgBAAAAAAQAAAAgBgBIgBgFIABgFQABAAAAAAQAAgBABAAQAAAAAAAAQABAAAAAAIArAAQAKAAAFAEQAFAFAAAIQAAAVgSATQgTAUgXALIgCAAIgCgBg"
      );
    this.shape_11.setTransform(78.0688, 7.225);

    this.shape_12 = new cjs.Shape();
    this.shape_12.graphics
      .f("#323248")
      .s()
      .p(
        "AgZBIQgBAAAAAAQgBgBgBAAQAAAAgBAAQAAgBgBAAQAAgBgBAAQAAgBAAAAQgBgBAAgBQAAAAAAgBIAAgYQAAgDACgCQABgBAAAAQABgBAAAAQABAAABAAQAAAAABAAIBBAAIAAgLIhDAAIgEgBQgCgBAAgEQAAgEACgCIAEgBIBNAAQABAAAAAAQABABABAAQAAAAABAAQAAABABAAQAAABABAAQAAABAAAAQABABAAAAQAAABAAABIAAAYQAAAAAAABQAAABgBAAQAAABAAAAQgBABAAAAQgBABAAAAQgBABAAAAQgBAAgBAAQAAAAgBAAIhBAAIAAALIBEAAQAAAAABAAQABAAAAABQABAAAAAAQAAAAABABQABABAAAEQAAAEgBABQgBABAAAAQAAAAgBAAQAAABgBAAQgBAAAAAAgAApABIgBgCIAAgLIgTAAQAAAAgBAAQAAAAgBAAQAAAAAAgBQgBAAAAAAIgBgFIABgGQAAAAABAAQAAgBAAAAQABAAAAAAQABAAAAAAIATAAIAAgOIgTAAIgDgBIgBgFIABgGQAAAAABAAQAAgBAAAAQABAAAAAAQABAAAAAAIATAAIAAgPIABgDIADgBIAJABQAEACAAACIAABAQAAACgEABIgJABIgDgBgAgjAAQgHgCgFgFQgFgEgDgGQgCgGAAgIQAAgHACgGQADgGAFgEQAFgFAHgCQAHgCAIAAQAIAAAHACQAFACAGAFQAEAEADAGQADAGAAAHQAAAIgDAGQgDAGgEAEQgGAFgFACQgHABgIAAQgJAAgGgBgAgcgxIgHAEQgDADgCADQgCAEAAAEQAAAGACADQABADAEADQADACAEABQADACAFAAQAFAAADgCQAEgBADgCIAFgGQAAgEAAgFIAAgIIgFgGIgHgEIgIgBIgIABg"
      );
    this.shape_12.setTransform(63.975, 6.975);

    this.shape_13 = new cjs.Shape();
    this.shape_13.graphics
      .f("#323248")
      .s()
      .p(
        "AgGAPIAAgCIAGgLIAAAAQgEAAgCgCQgCgCAAgEQAAgEADgDQACgCADAAQAEAAADACQACADAAAEIAAABIAAAEIgBAEIgDADIgDAFIgDADIgDACQAAAAgBAAQAAAAgBAAQAAAAAAAAQAAAAAAgBg"
      );
    this.shape_13.setTransform(55.625, 1.8);

    this.shape_14 = new cjs.Shape();
    this.shape_14.graphics
      .f("#323248")
      .s()
      .p(
        "AgXAjIAAhFQAAgGAEAAIAEAAIAEACIAEABIABAEIAAAKIAGgGIAGgEIAHgEIAGgCQABAAAAAAQABAAAAAAQABABAAAAQAAAAABABQABACAAADIgBAEQAAABAAABQgBAAAAABQAAAAgBAAQAAAAgBABIgGADIgJAGIgIAIQgDADAAADIAAAjIgBADIgEACIgEACIgEAAQgEAAAAgGg"
      );
    this.shape_14.setTransform(51.575, 9);

    this.shape_15 = new cjs.Shape();
    this.shape_15.graphics
      .f("#323248")
      .s()
      .p(
        "AghA3IAAhuQAAgFAEAAIAEABIAEABIADACIACACIAABCIAQgQIAQgQIACgBIADAAIADABIADACIACAEIgBACIgHAHIgHAGIgGAGIANAVIANAVIAAADQAAABAAAAQAAAAAAABQgBAAAAABQgBAAAAAAQgDACgEAAIgDAAIgDgCIgHgMIgIgMIgEgGIgDgGIgEAFIgFAEIgEAFIAAAVQAAABAAAAQAAABgBAAQAAAAAAAAQAAABgBAAIgDACIgEACIgEAAQgEAAAAgGg"
      );
    this.shape_15.setTransform(44.075, 7);

    this.shape_16 = new cjs.Shape();
    this.shape_16.graphics
      .f("#323248")
      .s()
      .p("AgFAGQgDgCAAgEQAAgDADgCQACgDADAAQAEAAACADQADACAAADQAAAEgDACQgCADgEAAQgDAAgCgDg");
    this.shape_16.setTransform(36.475, 12.575);

    this.shape_17 = new cjs.Shape();
    this.shape_17.graphics
      .f("#323248")
      .s()
      .p(
        "AgOAkQgIgEgEgFQgFgFgCgIQgDgGAAgIQAAgHADgHQACgHAFgGQAFgFAHgEQAHgDAHAAQAJABAHADQAGADAFAFQAFAGACAIQADAGAAAHQAAAIgDAHQgDAHgEAGQgFAFgHAEQgGADgJAAQgIgBgGgDgAgIgXQgEACgCADQgDAFgCAEIgBAJQAAAFABAFQACAFADAEQADADAEACQADACAEAAQAFAAAEgCQAEgDADgEIAEgJIABgJIgBgKIgFgIIgGgFIgJgBQgEAAgEACg"
      );
    this.shape_17.setTransform(30.525, 9);

    this.shape_18 = new cjs.Shape();
    this.shape_18.graphics
      .f("#323248")
      .s()
      .p(
        "AgOAkQgGgDgFgFQgFgFgCgHQgCgIAAgIQAAgIACgHQACgIAFgEQAFgGAGgCQAHgDAIAAQAHAAAGACQAFACAFAEIAGAIIADAKIgBAEQgBAAAAABQAAAAgBAAQAAAAgBAAQgBAAAAAAIgEAAIgDgCIgDgBIgBgDIgBgFIgEgDIgFgDIgGgBQgEAAgEACQgEACgCADQgDAEgBAFQgCAFAAAFQAAANAGAGQAGAGAIAAIAHAAQADgCADgCIAEgFQACgDAAgEIABgCIADgBIADgCIAEAAIADABQAAAAAAAAQABAAAAABQAAAAAAABQAAAAAAABQgBAIgDAFQgDAGgEAEQgFADgGACQgGACgGAAQgIAAgHgDg"
      );
    this.shape_18.setTransform(22.925, 9.05);

    this.shape_19 = new cjs.Shape();
    this.shape_19.graphics
      .f("#323248")
      .s()
      .p("AgFAGQgDgCAAgEQAAgDADgCQACgDADAAQAEAAACADQADACAAADQAAAEgDACQgCADgEAAQgDAAgCgDg");
    this.shape_19.setTransform(15.775, 12.575);

    this.shape_20 = new cjs.Shape();
    this.shape_20.graphics
      .f("#323248")
      .s()
      .p(
        "AghA3IAAhuQAAgFAEAAIAEABIAEABIADACIACACIAABCIAQgQIAQgQIACgBIADAAIADABIADACIACAEIgBACIgHAHIgHAGIgGAGIANAVIANAVIAAADQAAABAAAAQAAAAAAABQgBAAAAABQgBAAAAAAQgDACgEAAIgDAAIgDgCIgHgMIgIgMIgEgGIgDgGIgEAFIgFAEIgEAFIAAAVQAAABAAAAQAAABgBAAQAAAAAAAAQAAABgBAAIgDACIgEACIgEAAQgEAAAAgGg"
      );
    this.shape_20.setTransform(10.125, 7);

    this.shape_21 = new cjs.Shape();
    this.shape_21.graphics
      .f("#323248")
      .s()
      .p(
        "AgOAkQgGgDgFgFQgFgFgCgHQgCgIAAgIQAAgIACgHQACgIAFgEQAFgGAGgCQAHgDAIAAQAHAAAGACQAFACAFAEIAGAIIADAKIgBAEQgBAAAAABQgBAAAAAAQgBAAAAAAQgBAAAAAAIgEAAIgDgCIgDgBIgBgDIgBgFIgEgDIgFgDIgGgBQgEAAgEACQgEACgCADQgDAEgBAFQgCAFAAAFQAAANAGAGQAGAGAIAAIAHAAQADgCADgCIAEgFQACgDAAgEIABgCIADgBIADgCIAEAAIADABQAAAAAAAAQABAAAAABQAAAAAAABQAAAAAAABQgBAIgDAFQgDAGgEAEQgFADgGACQgGACgGAAQgIAAgHgDg"
      );
    this.shape_21.setTransform(1.625, 9.05);

    this.shape_22 = new cjs.Shape();
    this.shape_22.graphics
      .f("#323248")
      .s()
      .p(
        "AgHA0IAAhFQAAgGAFAAIACAAIAEACIADACIABADIAABDIgBADIgDACIgEACIgCAAQgFAAAAgGgAAAggIgCgBIgEgDQAAAAgBgBQAAAAAAAAQAAAAAAgBQgBAAAAgBIAAgMQABgGAFAAIACABIAEABIADADIABADIAAAMQAAAGgEAAg"
      );
    this.shape_22.setTransform(-4.35, 7.3);

    this.shape_23 = new cjs.Shape();
    this.shape_23.graphics
      .f("#323248")
      .s()
      .p(
        "AgRAlQgFgDgEgEQgEgEgCgGQgCgFAAgGIAAgpQAAgFAFAAIADAAIAEABIAEACIABADIAAApIABAHQACADACACQACACACAAIAFABIAEgBIAGgCIAGgDIAFgEIAAgwQAAgFAEAAIAEAAIAEABIADACQABABAAAAQAAAAAAAAQABABAAAAQAAAAAAABIAABBQAAABAAAAQAAAAgBABQAAAAAAAAQAAABgBAAIgDABIgEABIgEABQAAAAgBAAQgBAAAAgBQgBAAAAAAQAAAAAAgBIgBgEIAAgDIgFADIgGADIgGACIgFABQgIAAgFgCg"
      );
    this.shape_23.setTransform(-10.775, 9.025);

    this.shape_24 = new cjs.Shape();
    this.shape_24.graphics
      .f("#323248")
      .s()
      .p(
        "AAYAzQgEAAAAgFIAAgcIgEADIgFADIgGACIgFABQgIAAgHgDQgGgDgFgGQgEgEgDgIQgCgGAAgIQAAgHACgHQADgHAEgFQAFgFAGgEQAHgDAIAAIAFABIAGADIAFADIAEAFIAAgIQAAgFAEAAIAEAAIAEACIADABIABADIAABZIgBADIgDACIgEABgAgPgdQgFAHAAALQAAAGABAEQABADADAEQACAEAEACQAEACAFAAQADAAADgCIAGgDIAFgGQACgCABgEIAAgOIgDgHIgEgGIgHgEQgDgCgDAAQgKAAgFAHg"
      );
    this.shape_24.setTransform(-19.425, 10.025);

    this.shape_25 = new cjs.Shape();
    this.shape_25.graphics
      .f("#323248")
      .s()
      .p(
        "AgHA0IAAhFQAAgGAEAAIADAAIAEACIAEACIABADIAABDIgBADIgEACIgEACIgDAAQgEAAAAgGgAABggIgEgBIgDgDQAAAAgBgBQAAAAAAAAQAAAAAAgBQAAAAAAgBIAAgMQgBgGAGAAIACABIAEABIAEADIABADIAAAMQgBAGgFAAg"
      );
    this.shape_25.setTransform(-25.45, 7.3);

    this.shape_26 = new cjs.Shape();
    this.shape_26.graphics
      .f("#323248")
      .s()
      .p(
        "AAGA9QgKgEgFgIQgGgHAAgOIAAhTQAAgGAFAAIADABIAFABIACACIABADIAABTQAAAJAEAFQADAFAGADQAAAAABABQAAAAAAAAQAAABABAAQAAABAAAAIgCAEIgDADIgDABIgCgBg"
      );
    this.shape_26.setTransform(-28.825, 7.075);

    this.shape_27 = new cjs.Shape();
    this.shape_27.graphics
      .f("#323248")
      .s()
      .p(
        "AgOAkQgHgCgFgGQgEgFgCgHQgDgHAAgIQAAgIADgHQACgHAEgGQAFgFAHgDQAHgEAHAAQAKAAAGAEQAIADAEAGQAEAGACAFIABANQAAACgCACQgCADgEAAIguAAQAAAKAHAGQAFAFAHABIAGgBIAHgCIAEgEIAEgFQAAgBAAAAQAAgBAAAAQABgBAAAAQABAAABgBIAHgBIADAAIABADQAAAFgCAFQgDAFgFAEQgFADgGACQgGADgHAAQgIAAgHgEgAgEgZIgGADQgEAEgBACQgCAEgBAFIAmAAQgBgEgCgEIgEgGIgHgDIgGgBg"
      );
    this.shape_27.setTransform(-35.75, 8.95);

    this.shape_28 = new cjs.Shape();
    this.shape_28.graphics
      .f("#323248")
      .s()
      .p(
        "AATA3IAAgGIgDACIgGADIgFACIgFABQgJAAgGgDQgGgDgFgGQgFgEgCgIQgCgHAAgIQAAgHACgHQACgGAFgFQAEgFAGgEQAHgDAIAAIAGABIAGADIAFAEIADAEIAAgwQAAgFAFAAIADABIAFABIADACIABACIAABsQAAABAAAAQAAABAAAAQAAAAgBAAQAAABAAAAIgDACIgFACIgDAAQgFAAAAgGgAgPAAQgFAHAAALIABAKIAEAIQACAEAEACQAEACAFAAQADAAADgBIAGgEIAFgGIACgHIAAgOIgCgHIgFgFIgGgEIgGgBQgKAAgFAFg"
      );
    this.shape_28.setTransform(-44.275, 7);

    this.shape_29 = new cjs.Shape();
    this.shape_29.graphics
      .f("#323248")
      .s()
      .p(
        "AgGAOQgCgCAAgEIAAgBIAAgFQAAgBAAAAQAAgBAAAAQAAAAABAAQAAgBAAAAIADgEIADgEIADgFIADgCIACABIAAADIgHAMIABAAQADgBADACQACADAAAEQAAADgDADQgCACgEABQgDgBgDgCg"
      );
    this.shape_29.setTransform(-50.225, 1.3);

    this.shape_30 = new cjs.Shape();
    this.shape_30.graphics
      .f("#323248")
      .s()
      .p(
        "AAsBGIgBgDIAAhQIgZAAQAAAAgBAAQAAAAgBAAQAAAAgBgBQAAAAAAAAQgCgCAAgDIABgGQAAAAABAAQAAgBABAAQAAAAABAAQAAAAABAAIAZAAIAAgoIABgDIADgBIAJABQAEABAAACIAACFQAAACgEABIgJABIgDgBgAg1AjQgDgBgBgDQgBAAAAgBQAAAAAAAAQgBgBAAgBQAAAAABgBQAAAAAAAAQAAgBAAAAQAAAAAAgBQABAAAAAAQAUgPAIgQQAIgSAAgZIAAgFQAAAAAAAAQAAgBAAAAQAAAAAAgBQABAAAAAAQAAAAAAgBQABAAAAAAQAAAAABAAQAAAAABAAQAEAAAFACQAEABAAACIAAADQAAAUgGAQQAKAGAIAIQALALAFAMQAAAAAAABQABAAAAABQAAAAAAABQAAABAAAAQgBACgDACQgBABAAAAQgBAAAAAAQgBAAAAAAQgBAAAAAAQgBAAAAgBQgBAAAAgBQgBAAAAgBQAAAAAAgBQgFgHgJgKQgHgHgJgFQgMAUgVAPIgDAAIgBAAg"
      );
    this.shape_30.setTransform(-64.4062, 7.225);

    this.shape_31 = new cjs.Shape();
    this.shape_31.graphics
      .f("#323248")
      .s()
      .p(
        "AArBGIgBgDIAAiFIABgDIADgBIAIABQAEABAAACIAACFQAAACgEABIgIABIgDgBgAAQBCIgBgDIAAhCIgIAAQgBATgHALQgKAOgOAAQgOAAgIgOQgKgMAAgXQAAgWAKgOQAJgNANAAQAOAAAKANQAGALACARIAIAAIAAguIABgDIADgBIAIABQAEABAAACIAAB8QAAABAAAAQAAAAgBABQAAAAgBABQgBAAgBAAIgIACIgDgBgAglgiQgEAJAAARQAAARAEAJQAFAJAHAAQAIAAAFgJQAEgJAAgRQAAgQgEgKQgGgJgHAAQgHAAgFAJg"
      );
    this.shape_31.setTransform(-78.15, 7.225);

    this.shape_32 = new cjs.Shape();
    this.shape_32.graphics
      .f("#323248")
      .s()
      .p(
        "AgHA0IAAhFQAAgGAFAAIACAAIAEACIADACIABADIAABDIgBADIgDACIgEACIgCAAQgFAAAAgGgAAAggIgCgBIgEgDQAAAAgBgBQAAAAAAAAQAAAAAAgBQgBAAAAgBIAAgMQABgGAFAAIACABIAEABIADADIABADIAAAMQAAAGgEAAg"
      );
    this.shape_32.setTransform(-86.7, 7.3);

    this.shape_33 = new cjs.Shape();
    this.shape_33.graphics
      .f("#323248")
      .s()
      .p(
        "AgXAjIAAhFQAAgGAEAAIAEAAIAEACIAEABIABAEIAAAKIAGgGIAGgEIAHgEIAGgCQAAAAABAAQABAAAAAAQABABAAAAQABAAAAABQABACAAADIgBAEQAAABAAABQgBAAAAABQAAAAgBAAQAAAAgBABIgGADIgJAGIgIAIQgDADAAADIAAAjIgBADIgEACIgEACIgEAAQgEAAAAgGg"
      );
    this.shape_33.setTransform(-91.475, 9);

    this.shape_34 = new cjs.Shape();
    this.shape_34.graphics
      .f("#323248")
      .s()
      .p(
        "AATAjIAAgFIgEADIgFAEIgFACIgFABQgJAAgGgDQgGgDgFgGQgEgFgCgHQgDgHAAgIQAAgHADgGQACgHAFgGQAFgFAHgDQAHgDAGAAIAMABIAHADIAAgCQAAgGAEAAIAEAAIAEACIADABIACAEIAABDQAAABAAAAQAAABgBAAQAAAAAAAAQAAABgBAAIgDACIgEACIgEAAQgEAAAAgGgAgOgQQgGAGAAALQAAAGACAEQAAAEADAEQADAEADABQAEACAFAAIAGgBIAHgEQACgDACgEQACgDAAgFIAAgTQgCgEgFgEQgGgCgFAAQgJAAgGAHg"
      );
    this.shape_34.setTransform(-99.425, 9);

    this.shape_35 = new cjs.Shape();
    this.shape_35.graphics
      .f("#323248")
      .s()
      .p(
        "AgfAzQgEAAgBgFIAAhbQABgFAEAAIADAAIAFACIADABIABADIAAAHIAEgFIAFgDIAGgDIAFgBQAIAAAHADQAHAEAEAFQAEAFADAHQACAHAAAHQAAAIgCAGQgDAHgEAFQgFAGgGADQgHADgJAAIgEgBIgGgCIgFgDIgEgDIAAAbQAAABAAAAQAAAAAAABQAAAAAAAAQgBAAAAABIgDACIgFABgAgFgiIgGAEIgFAGIgDAHIAAAOIADAGQACAEADACQADACADABQADACACAAQAGAAAEgCQADgCADgEIAEgHIABgKQAAgLgFgHQgFgHgLAAIgFACg"
      );
    this.shape_35.setTransform(-107.75, 10.025);

    this.shape_36 = new cjs.Shape();
    this.shape_36.graphics
      .f("#323248")
      .s()
      .p(
        "AATAjIAAgFIgEADIgFAEIgFACIgFABQgJAAgGgDQgGgDgFgGQgEgFgCgHQgDgHAAgIQAAgHADgGQACgHAFgGQAFgFAHgDQAHgDAGAAIAMABIAHADIAAgCQAAgGAEAAIAEAAIAEACIADABIACAEIAABDQAAABAAAAQAAABgBAAQAAAAAAAAQAAABgBAAIgDACIgEACIgEAAQgEAAAAgGgAgOgQQgGAGAAALQAAAGACAEQAAAEADAEQADAEADABQAEACAFAAIAGgBIAHgEQACgDACgEQACgDAAgFIAAgTQgCgEgFgEQgGgCgFAAQgJAAgGAHg"
      );
    this.shape_36.setTransform(-116.825, 9);

    this.shape_37 = new cjs.Shape();
    this.shape_37.graphics
      .f("#323248")
      .s()
      .p(
        "AgZA1QgMgHgEgMQgBgGAAgHIABgCIAEgCIAFAAIADAAIADACIAAAEQAAAHADAEQADAFAEACQADADAFABQAEABAGAAIAHgBIAJgDQAEgCADgDQAEgDAAgFIgCgFIgDgEIgEgDIgFgDIgRgIIgPgGIgDgBIgDgBQgIgEgEgGQgEgGgBgJQABgIADgGQADgGAFgEQAGgEAHgCQAHgCAHAAQAIAAAHACQAJADAFAEQAGAEADAGQADAHAAAJIgBACIgFABIgEABIgEAAIgCgCIgBgDIAAgCQABgEgDgEIgGgGIgHgDIgIgBIgHABIgHADIgGAEQgCADgBAFQABADABADQABADADACIAEADIAHADIAMAHIAPAFIACABIACABQAJADAFAGQAEAGAAAJQAAAJgDAHQgEAGgGAEQgGAEgIACQgHACgIAAQgQAAgKgHg"
      );
    this.shape_37.setTransform(-125.9, 6.975);

    this.shape_38 = new cjs.Shape();
    this.shape_38.graphics
      .f("#ADB4C5")
      .s()
      .p("A0BFHQgzAAgkgkQglgkAAgzIAAmXQAAgzAlgkQAkgkAzAAMAoDAAAQAzAAAlAkQAkAkAAAzIAAGXQAAAzgkAkQglAkgzAAg");
    this.shape_38.setTransform(0.025, 0.025);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({
          state: [
            { t: this.shape_38 },
            { t: this.shape_37 },
            { t: this.shape_36 },
            { t: this.shape_35 },
            { t: this.shape_34 },
            { t: this.shape_33 },
            { t: this.shape_32 },
            { t: this.shape_31 },
            { t: this.shape_30 },
            { t: this.shape_29 },
            { t: this.shape_28 },
            { t: this.shape_27 },
            { t: this.shape_26 },
            { t: this.shape_25 },
            { t: this.shape_24 },
            { t: this.shape_23 },
            { t: this.shape_22 },
            { t: this.shape_21 },
            { t: this.shape_20 },
            { t: this.shape_19 },
            { t: this.shape_18 },
            { t: this.shape_17 },
            { t: this.shape_16 },
            { t: this.shape_15 },
            { t: this.shape_14 },
            { t: this.shape_13 },
            { t: this.shape_12 },
            { t: this.shape_11 },
            { t: this.shape_10 },
            { t: this.shape_9 },
            { t: this.shape_8 },
            { t: this.shape_7 },
            { t: this.shape_6 },
            { t: this.shape_5 },
            { t: this.shape_4 },
            { t: this.shape_3 },
            { t: this.shape_2 },
            { t: this.shape_1 },
            { t: this.shape }
          ]
        })
        .wait(1)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-140.5, -32.7, 281.1, 65.5);

  (lib.ClipGroup_0 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_2 (mask)
    var mask = new cjs.Shape();
    mask._off = true;
    mask.graphics.p(
      "AB6LWQnHgvmGlaQjUi7iAjMIAAAAQgkg1gThCQgzirBWieQBWidCsgzQCQgqCLA4QCJA4BLB+IABgBIgBgCQBKBzB+BqQCvCVCpAQQCuARDGiAIIoKhQj4Cyj9BJQi7A3i+AAQhFAAhFgHg"
    );
    mask.setTransform(113.6658, 73.2951);

    // Layer_3
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#6C1D2A")
      .s()
      .p("AibEeQh2hBgniBQgmiBBBh2QBAh2CCgmQCBgmB2BAQB2BBAnCBQAlCBhAB2QhBB2iBAmQgwAOguAAQhOAAhLgog");
    this.shape.setTransform(103.45, 114.175);

    var maskedShapeInstanceList = [this.shape];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_0, new cjs.Rectangle(70.8, 81.6, 65.3, 65), null);

  (lib.Group_1 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#313247")
      .s()
      .p(
        "AAGA8QgGAAgFgDQgFgEgDgHQgDgGAAgIIAAhUQAAgDACgCQACgCAEAAQADAAACACQACACAAADIAABUQABAGABADQACAEADAAIAEAAQACAAADACQACADAAADQAAADgDACQgDACgFAAg"
      );
    this.shape.setTransform(189.1, 6.175);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics
      .f("#313247")
      .s()
      .p(
        "AgSAlQgLgFgEgKQgGgJAAgNQAAgMAFgJQAFgKAKgFQAJgGAMAAQANAAAHAGQAKAFADAJQAFAJAAANIgCAEQgCABgDAAIg6AAQABAFADAFQADAHAHAEQAHAEAIAAIAKgCQAEgBAEgDIAEgCQAEAAACACQACACAAADQABADgDABQgFAEgJADQgIADgGAAQgNAAgJgGgAgMgZQgHAEgDAHIgDAIIAzAAQgBgFgCgEQgDgHgGgDQgFgEgHAAQgIAAgGAEg"
      );
    this.shape_1.setTransform(181.7, 7.975);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics
      .f("#313247")
      .s()
      .p(
        "AAPA4QgIAAgGgEQgGgEgDgHQgDgGAAgJIAAglIgIAAQgDAAgCgCQAAgBgBAAQAAgBAAAAQgBgBAAAAQAAgBAAgBQAAgDACgCIAFgCIAIAAIAAgWQAAgDACgCQACgDADAAQADAAACADQACACAAADIAAAWIANAAIAFACQACACAAADQAAABAAABQAAAAAAABQAAAAgBABQAAAAgBABQgCACgDAAIgNAAIAAAlQAAAFADAFQAEAEAFAAIAFAAIAFACQACADAAADQAAADgDADQgCACgFAAg"
      );
    this.shape_2.setTransform(173.525, 6.625);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics
      .f("#313247")
      .s()
      .p(
        "AgVAlQgJgFgGgKQgGgKAAgMQAAgLAGgKQAGgKAJgFQAKgGALAAQAMAAAKAGQAJAFAGAKQAGAKAAALQAAAMgGAKQgFAKgKAFQgJAGgNAAQgMAAgJgGgAgNgYQgGADgEAHQgDAGAAAIQAAAJADAGQAEAHAGADQAFAEAIAAQAJAAAFgEQAHgEADgGQADgGAAgJQAAgIgDgGQgDgGgHgEQgFgEgJAAQgIAAgFAEg"
      );
    this.shape_3.setTransform(165.325, 7.975);

    this.shape_4 = new cjs.Shape();
    this.shape_4.graphics
      .f("#313247")
      .s()
      .p(
        "AAmA6QgCgCAAgEIAAguIhHAAIAAAuQAAAEgCACQgCACgEAAQgDAAgCgCQgDgDAAgDIAAhnQAAgEADgCQACgCADAAQAEAAACACQACACAAAEIAAAsIBHAAIAAgsQAAgEACgCQACgCAEAAQAEAAACACQACACAAAEIAABnQAAAEgCACQgDACgDAAQgDAAgDgCg"
      );
    this.shape_4.setTransform(153.375, 6.175);

    this.shape_5 = new cjs.Shape();
    this.shape_5.graphics
      .f("#313247")
      .s()
      .p(
        "AAaAoQgCgDAAgCIAAglQAAgJgDgFQgDgFgGgEQgGgCgGAAQgGAAgGACQgFADgDAEQgDAEAAAGIAAArQAAADgCACQgCACgEABQgDgBgCgCQgDgCAAgDIAAhEQAAgEADgCQADgCACAAQADAAADACQACACAAAEIAAABQAEgDAEgCQAIgEAJgBQALABAIAEQAIAEAGAJQAFAKAAAMIAAAlQAAADgDACQgCACgDABQgEgBgCgCg"
      );
    this.shape_5.setTransform(136.975, 7.95);

    this.shape_6 = new cjs.Shape();
    this.shape_6.graphics
      .f("#313247")
      .s()
      .p(
        "AATApQgBgBgBAAQAAAAgBgBQAAAAAAgBQAAAAgBgBIgPg3IgOA3IgDAEQgCABgDAAIgFgBQAAgBgBAAQAAAAgBgBQAAAAAAgBQAAAAgBgBIgWhDQgBgFABgDQABgDAFAAQABAAAAAAQABAAABAAQAAAAABABQAAAAABAAIADAGIAQA0IAQg1IADgFQACgBACAAQADAAACABIACAFIAPA1IARg0QACgHAGAAQAEAAABADQABACgBAGIgXBDQAAABAAAAQAAABgBAAQAAABAAAAQgBAAAAABQgBAAAAAAQgBABAAAAQgBAAgBAAQAAAAgBAAIgEgBg"
      );
    this.shape_6.setTransform(126, 7.975);

    this.shape_7 = new cjs.Shape();
    this.shape_7.graphics
      .f("#313247")
      .s()
      .p(
        "AgVAlQgKgFgFgKQgGgJABgNQgBgMAGgJQAFgKAKgFQAJgGAMAAQAMAAAKAGQAJAFAGAKQAGAKgBALQABAMgGAKQgFAKgKAFQgJAGgNAAQgNAAgIgGgAgNgYQgGADgEAHQgEAGAAAIQAAAJAEAGQAEAHAGADQAGAEAHAAQAJAAAFgEQAGgEAEgGQADgGAAgJQAAgIgDgGQgEgGgGgEQgFgEgJAAQgHAAgGAEg"
      );
    this.shape_7.setTransform(115.65, 7.975);

    this.shape_8 = new cjs.Shape();
    this.shape_8.graphics
      .f("#313247")
      .s()
      .p(
        "AgaApQgCgCAAgEIAAhEQAAgEACgCQABgCAEAAQAEAAACACQACADAAADIAAADQADgEAGgDQAHgFAJAAQAJAAAFADQAEADgBAFIgCADQAAAAAAAAQAAABgBAAQAAAAgBAAQAAAAgBAAIgDAAQgJgCgJACQgHACgEAEQgFAFAAAGIAAAtQAAAEgCACQgCACgEAAQgEAAgBgCg"
      );
    this.shape_8.setTransform(107.8841, 7.925);

    this.shape_9 = new cjs.Shape();
    this.shape_9.graphics
      .f("#313247")
      .s()
      .p(
        "AgNA5QgMgGgHgHQgIgIgFgMQgEgLAAgNQAAgMAEgLQAFgMAIgIQAJgIAKgFQAKgFAMAAQAMAAAJADQAIADAJAHIACACIABACIAAADQAAADgCACIgFACQgEAAgBgCIgNgIQgGgCgKAAQgJAAgGADQgJAEgGAHQgGAFgDAKQgDAIAAAJQAAAKADAIQADAKAGAFQAGAHAJADQAHAEAIAAQAIAAAHgDQAIgCAGgFQACgCADAAIAFACQABADABADIgBADIgCACQgJAHgIADQgKAEgLAAQgKAAgMgFg"
      );
    this.shape_9.setTransform(97.55, 6.175);

    this.shape_10 = new cjs.Shape();
    this.shape_10.graphics
      .f("#313247")
      .s()
      .p(
        "AgFA5QgFgEgDgHQgDgGAAgIIAAhUQAAgDACgCQADgCADAAQADAAACACQACACAAADIAABUQAAAFACAEQABAEAEAAIAEAAQADAAACACQACACAAAEQAAADgDACQgCACgGAAQgGAAgFgDg"
      );
    this.shape_10.setTransform(85.15, 6.175);

    this.shape_11 = new cjs.Shape();
    this.shape_11.graphics
      .f("#313247")
      .s()
      .p(
        "AgVAmQgHgFgGgIQgEgJgBgNIAAglQAAgEACgCQADgCADAAQAEAAACACQACACAAAEIAAAlQAAAJADAFQADAFAGAEQAGACAGAAQAGAAAFgCQAGgDADgEQADgFAAgFIAAgrQAAgEACgCQACgCADAAQAEAAADACQABACABAEIAABEQgBAEgBACQgDACgEAAQgCAAgDgCQgCgCAAgEIAAgCQgEAEgEACQgIAFgKAAQgJAAgKgFg"
      );
    this.shape_11.setTransform(77.05, 8.025);

    this.shape_12 = new cjs.Shape();
    this.shape_12.graphics
      .f("#313247")
      .s()
      .p(
        "AgVAlQgKgFgFgKQgGgKAAgMQAAgLAGgKQAFgKAKgFQAKgGALAAQAMAAAKAGQAKAFAFAKQAFAKABALQgBANgFAJQgFAKgKAFQgIAGgOAAQgMAAgJgGgAgNgYQgGAEgEAGQgDAHAAAHQAAAHADAIQAEAGAGAEQAFAEAIAAQAIAAAHgEQAFgDAEgHQADgGAAgJQAAgIgDgGQgEgHgFgDQgHgEgIAAQgIAAgFAEg"
      );
    this.shape_12.setTransform(66.65, 7.975);

    this.shape_13 = new cjs.Shape();
    this.shape_13.graphics
      .f("#313247")
      .s()
      .p(
        "AgSAlQgKgFgFgKQgGgJAAgNQAAgLAFgKQAGgKAJgFQAKgGALAAQANAAAHAGQAKAFAEAJQAEAJAAANIgCAEQgCABgDAAIg6AAQABAFADAFQAEAHAGAEQAHAEAIAAIAKgCQAEgBAEgDIAFgCQADAAACACQACACAAADQAAADgCABQgFAEgIADQgJADgGAAQgNAAgJgGgAgMgZQgHAEgDAHIgDAIIAzAAQgBgFgCgEQgDgHgFgDQgGgEgHAAQgIAAgGAEg"
      );
    this.shape_13.setTransform(57.125, 7.975);

    this.shape_14 = new cjs.Shape();
    this.shape_14.graphics
      .f("#313247")
      .s()
      .p(
        "AgRA7QgJgDgGgGQgIgGgCgHQgCgDACgDQABgCAEgBQADgBADACIAEAEIAGAHQADADAHACQAGACAGAAQAIAAAGgCQAGgDAEgEQAEgFAAgGQAAgHgHgHQgGgHgPgBQgTgDgKgIQgLgJAAgOQAAgKAGgHQAHgIAIgDQAMgEAJAAQAIAAAJADQAHADAFAEQAFAFAEAGQACADAAAEQgBADgCABQgEACgDgBQgDgBgCgDQgCgEgEgEQgEgDgEgBQgEgCgHAAQgKAAgJAEQgHAEAAAKQAAAEACAEQACAFAHADQAHADAKABQASACALAIQAKAKAAAPQAAAJgDAGQgEAHgGAEQgIAFgGACQgHACgJAAQgJAAgJgDg"
      );
    this.shape_14.setTransform(46.8375, 6.175);

    this.shape_15 = new cjs.Shape();
    this.shape_15.graphics
      .f("#313247")
      .s()
      .p(
        "AgIAWIgBgDQAAgDACgCIADgFQACgDAAgEIgBgDIgGgGQgBgCAAgDQAAgEADgDQADgDAEAAQAFAAADAEQADAEAAAHIgBAJIgFAKQgCAFgDADQgDADgCAAQgBAAAAAAQgBAAAAAAQgBgBAAAAQAAAAAAAAg"
      );
    this.shape_15.setTransform(35.125, 12.575);

    this.shape_16 = new cjs.Shape();
    this.shape_16.graphics
      .f("#313247")
      .s()
      .p(
        "AgTA3QgJgFgFgJQgFgJAAgLQAAgEACgCQACgCADAAQAEAAACACQACACAAAEQAAAHADAFQADAFAGADQAFADAGAAQAHAAAFgDQAGgDACgFQADgFAAgHQAAgHgDgFQgDgGgFgDQgFgCgHAAIgZAAQgEAAgCgCQgCgDAAgDIAAgpIACgGQACgCAEAAIAzAAQADAAACACQACACAAAEQAAADgCACQgCACgDAAIgrAAIAAAbIARAAQAMAAAIAFQAKAGAEAHQAFAJAAALQAAAKgFAKQgFAJgJAFQgIAFgMAAQgLAAgIgFg"
      );
    this.shape_16.setTransform(28.475, 6.175);

    this.shape_17 = new cjs.Shape();
    this.shape_17.graphics
      .f("#313247")
      .s()
      .p(
        "AgTA1QgIgHgFgOQgEgMAAgUQAAgUAEgMQAFgOAIgGQAHgHAMAAQAMAAAIAHQAIAIAFANQAEAOAAARQAAASgEAOQgFAOgIAHQgIAHgMAAQgLAAgIgHgAgLgmQgFAHgCAJQgCALAAALQAAAMACALQADAKAEAFQAFAGAGAAQAHAAAFgGQAEgFADgKQACgKAAgNQAAgNgCgJQgCgJgFgHQgFgGgHAAQgGAAgFAGg"
      );
    this.shape_17.setTransform(19.075, 6.175);

    this.shape_18 = new cjs.Shape();
    this.shape_18.graphics
      .f("#313247")
      .s()
      .p(
        "AgcA8QgDAAgCgCQgCgCAAgEQAAgDACgCIArgyIAHgLQACgFAAgEQAAgHgCgEQgDgGgEgCQgFgDgFAAQgEAAgGADQgFAEgCAEQgDAGAAAFQAAAEgCACQgCACgEAAQgDAAgCgCQgCgCAAgEQgBgKAFgIQAGgJAHgFQAJgEAJAAQALAAAHAEQAHAFAFAIQAEAHAAAMQAAAGgCAEIgFAKIgpAvIArAAQACAAADADQACACABADQgBADgCADQgDACgCAAg"
      );
    this.shape_18.setTransform(10.2, 6.175);

    this.shape_19 = new cjs.Shape();
    this.shape_19.graphics
      .f("#313247")
      .s()
      .p(
        "AAGA6QgCgDAAgDIAAhdIgKAFIgFABQgDAAgCgCQgDgDAAgDIACgEIADgDIAXgJIACAAIABAAQAEAAACACQACADAAADIAABnQAAADgCADQgDACgDAAQgDAAgDgCg"
      );
    this.shape_19.setTransform(2, 6.175);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({
          state: [
            { t: this.shape_19 },
            { t: this.shape_18 },
            { t: this.shape_17 },
            { t: this.shape_16 },
            { t: this.shape_15 },
            { t: this.shape_14 },
            { t: this.shape_13 },
            { t: this.shape_12 },
            { t: this.shape_11 },
            { t: this.shape_10 },
            { t: this.shape_9 },
            { t: this.shape_8 },
            { t: this.shape_7 },
            { t: this.shape_6 },
            { t: this.shape_5 },
            { t: this.shape_4 },
            { t: this.shape_3 },
            { t: this.shape_2 },
            { t: this.shape_1 },
            { t: this.shape }
          ]
        })
        .wait(1)
    );
  }).prototype = getMCSymbolPrototype(lib.Group_1, new cjs.Rectangle(0, 0, 190.8, 14.9), null);

  (lib.Group = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f()
      .s("#FFFFFF")
      .ss(0.3)
      .p(
        "AAAgfQgMAAgKAKQgJAJAAAMQAAANAJAKQAKAJAMAAQANAAAJgJQAKgKAAgNQAAgMgKgJQgJgKgNAAgAAAgNQAFAAAFAEQAEAFAAAEQAAAGgEAEQgFAEgFAAQgFAAgEgEQgEgEAAgGQAAgEAEgFQAEgEAFAAg"
      );
    this.shape.setTransform(6.825, 6.775);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics
      .f("#313247")
      .s()
      .p(
        "AgWAXQgJgKAAgNQAAgMAJgJQAKgKAMAAQANAAAJAKQAKAJAAAMQAAANgKAKQgJAJgNAAQgMAAgKgJgAgJgJQgEAFAAAEQAAAGAEAEQAEAEAFAAQAFAAAFgEQAEgEAAgGQAAgEgEgFQgFgEgFAAQgFAAgEAEg"
      );
    this.shape_1.setTransform(6.825, 6.775);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics
      .f()
      .s("#FFFFFF")
      .ss(0.3)
      .p(
        "AAAhFQgTAAgOAPQgPAOAAAUQAAABAGAUQAMAdAeAjQAegjAMgdQAHgNAAgIQAAgUgPgOQgOgPgUAAgAAABYQgDAAgDgDQgmgqgOgjQgIgQAAgMQAAgcAUgTQATgUAbAAQAcAAATAUQAUAUAAAbQAAALgIARQgOAjgmAqQgDADgEAAg"
      );
    this.shape_2.setTransform(6.825, 8.875);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics
      .f("#313247")
      .s()
      .p(
        "AgGBVQgmgqgOgjQgIgQAAgMQAAgcAUgTQATgUAbAAQAcAAATAUQAUAUAAAbQAAALgIARQgOAjgmAqQgDADgEAAQgDAAgDgDgAghg2QgPAOAAAUIAGAVQAMAdAeAjQAegjAMgdQAHgNAAgIQAAgUgPgOQgOgPgUAAQgTAAgOAPg"
      );
    this.shape_3.setTransform(6.825, 8.875);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({
          state: [{ t: this.shape_3 }, { t: this.shape_2 }, { t: this.shape_1 }, { t: this.shape }]
        })
        .wait(1)
    );
  }).prototype = getMCSymbolPrototype(lib.Group, new cjs.Rectangle(-0.8, -0.9, 15.3, 19.599999999999998), null);

  (lib.casta4 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#834A25")
      .s()
      .p("AjKAaQhNgOAAgYQAAgPBPgGQA+gECKAAQCLAAA+AEQBPAGAAAPQAAAWhWAPQhRANh0AAQh/AAhIgMg");
    this.shape.setTransform(0, 16.125);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics
      .f("#91572B")
      .s()
      .p(
        "AjqBsQgVgEgTgIQg4gYgggqQgigtAAg7QAAgRB0gRQB+gSCaAAQCcAAB9ASQB0ARAAARQAAA7giAtQggAqg4AYQgSAHgUAFQgiAJiZAIQgyACgqAAQh8AAhEgTg"
      );
    this.shape_1.setTransform(0.175, 7.9907);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics
      .f("#6D8A3D")
      .s()
      .p(
        "AhxBXQgugJhFAAQhDAAgwgKQgvgJAAgOQAAgHgFgFQgJgGgUgEQgigHAAgQQAAgPAigHQAUgEAJgGQAFgFAAgIQAAgNAvgJQAwgKBDAAQBCAAAxgJQAvgKBCAAQBDAAAvAKQAxAJBCAAQBDAAAwAKQAvAJAAANQAAAIAGAFQAHAGAVAEQAiAHAAAPQAAAQgiAHQgVAEgHAGQgGAFAAAHQAAAOgvAJQgwAKhDAAQhFAAguAJQgvAKhDAAQhCAAgvgKg"
      );
    this.shape_2.setTransform(0, 1.075);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics
      .f("#2F232A")
      .s()
      .p("AkQAmQhxgQAAgWQAAgVBxgQQBxgPCfAAQCgAABxAPQBxAQAAAVQAAAWhxAQQhxAPigAAQifAAhxgPg");
    this.shape_3.setTransform(0.175, -4.575);

    this.shape_4 = new cjs.Shape();
    this.shape_4.graphics
      .f("#382A32")
      .s()
      .p("AkLBgQhqgPgVgfQgcgdALglIARghQASgZBrgRQBxgSCeAAQCdAABvASQBmAQAbAaIAOAfQAJAkgXAgQgQAehsAPQhoAQipgBQioAAhlgOg");
    this.shape_4.setTransform(-0.0137, -9.65);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({
          state: [{ t: this.shape_4 }, { t: this.shape_3 }, { t: this.shape_2 }, { t: this.shape_1 }, { t: this.shape }]
        })
        .wait(1)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-45.9, -20.7, 91.8, 41.4);

  (lib.casta3 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#917E2B")
      .s()
      .p(
        "AmRBIQgEgCAAgFIAAhKQAAgaB3gSQB3gSCnAAQCoAAB3ASQB3ASAAAaIAABJQAAAFgEACQgEACgEgDIhCg2QgUgQgYgGQgZgHgaAFIjGAhQgkAGgkgGIjAghQg0gJgqAgIhGA4QAAAAgBAAQAAABgBAAQgBAAAAAAQgBAAAAAAIgEAAg"
      );
    this.shape.setTransform(-0.025, 6.59);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics
      .f("#7D263B")
      .s()
      .p("AkPAoQhxgQAAgYQAAgXBxgRQBwgQCfAAQCgAABwAQQBxARAAAXQAAAYhxAQQhwARigAAQifAAhwgRg");
    this.shape_1.setTransform(-0.025, 13.5);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics
      .f("#913246")
      .s()
      .p("AkTBJQhygSAAgZIAAg8QAAgYBygSQBzgRCgAAQCiAAByARQByASAAAYIAAA8QAAAZhyASQhyASiiAAQigAAhzgSg");
    this.shape_2.setTransform(-0.025, 10.45);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics
      .f("#834A25")
      .s()
      .p("AkRAgQhygQAAgRQAAgSBygOQB1gPCcAAQCdAAB2APQBxAOAAASQAAARhxAQQh4ARibAAQiaAAh3gRg");
    this.shape_3.setTransform(-0.025, 1.875);

    this.shape_4 = new cjs.Shape();
    this.shape_4.graphics
      .f("#91572B")
      .s()
      .p(
        "AkYBzQh0gQAAgRQAAg8AigsQAggqA4gYQATgIATgEQBZgZBigFQCUgJCIAnQAVAEATAIQA4AYAgAqQAiAsAAA8QAAARh0AQQh9ASicAAQiaAAh+gSg"
      );
    this.shape_4.setTransform(-0.025, -6.2019);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({
          state: [{ t: this.shape_4 }, { t: this.shape_3 }, { t: this.shape_2 }, { t: this.shape_1 }, { t: this.shape }]
        })
        .wait(1)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-40.6, -19.5, 81.2, 39);

  (lib.b_finger = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#181821")
      .s()
      .p("Ah4B5QgygyAAhHQAAhGAygyQAygyBGAAQBHAAAyAyQAyAyAABGQAABHgyAyQgyAyhHAAQhGAAgygyg");

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-17.1, -17.1, 34.2, 34.2);

  (lib.b_eyes = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#181821")
      .s()
      .p("AgXBcQgKgKAAgOIgBiGQgBgOALgLQAJgKAPAAQANAAAKAKQALAKAAAOIACCGQAAAOgKAKQgKALgOAAIgCAAQgMAAgLgKg");
    this.shape.setTransform(19.9001, -0.0488);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics
      .f("#181821")
      .s()
      .p("AgXBcQgKgKAAgPIgCiEQAAgPAKgKQAKgLAPAAQANgBAKALQALAKAAAOIACCFQAAAPgKAKQgKALgPAAIgBAAQgNAAgKgKg");
    this.shape_1.setTransform(-19.8761, 0.0001);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({ state: [{ t: this.shape_1 }, { t: this.shape }] })
        .wait(1)
    );
  }).prototype = getMCSymbolPrototype(lib.b_eyes, new cjs.Rectangle(-23.4, -10.2, 46.9, 20.4), null);

  (lib.a_mouse = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#536E78")
      .s()
      .p(
        "AhUAXQgSgVgHgVQgEgLgBgGQgCgJAEgJQADgIAJgCIAFAAIDAAAIAHAAIAFADQAGADAAAFQADAOgHAUQgQAqgfAXQgbATggAAIgCAAQg1AAgigqgAgpACQASATAbAAQAbABASgWQAJgLAEgMIh1AAQAAALAOAOg"
      );
    this.shape.setTransform(-0.1655, 1.7603);

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
  }).prototype = getMCSymbolPrototype(lib.a_mouse, new cjs.Rectangle(-11.7, -4.7, 23.2, 13), null);

  (lib.a_eyes = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f()
      .s("#536E78")
      .ss(8, 1, 1)
      .p("AgshGIBZBGIhZBH");
    this.shape.setTransform(18.8552, 1.7043, 0.7235, 0.9276, 180);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics
      .f()
      .s("#536E78")
      .ss(8, 1, 1)
      .p("AgshGIBZBGIhZBH");
    this.shape_1.setTransform(-19.15, 0.75);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({ state: [{ t: this.shape_1 }, { t: this.shape }] })
        .wait(1)
    );
  }).prototype = getMCSymbolPrototype(lib.a_eyes, new cjs.Rectangle(-27.6, -10.3, 53.7, 22.6), null);

  (lib.a_amrL = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#181821")
      .s()
      .p("AimFiIgNgKQhvhcgYimQgZilBNiPQBMiPCFgkQCEgkBvBcIACACQBdBMAbCHQAbCFg0B/IgfBMQg9CUh/AtQgrAPgoAAQhQAAhHg6g");
    this.shape.setTransform(61.3172, 62.955);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics
      .f("#7F4D22")
      .s()
      .p("AgaAxQgUgLgHgXQgGgVALgUQAMgUAWgHQAVgGAUALQAUAMAHAWQAGAVgLAUQgLAUgXAHQgIACgHAAQgNAAgNgHg");
    this.shape_1.setTransform(98.0365, -70.4135);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics
      .f("#7F4D22")
      .s()
      .p("AgaAxQgVgMgGgWQgGgVALgUQALgUAWgHQAWgGAUALQAUALAGAXQAHAVgLAUQgMAUgWAHQgIACgHAAQgNAAgNgHg");
    this.shape_2.setTransform(72.4034, -46.725);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics
      .f("#7F4D22")
      .s()
      .p("AgaAxQgUgMgHgWQgGgVALgUQALgUAXgHQAVgGAUALQAUAMAHAWQAGAVgLAUQgMAUgWAHQgIACgHAAQgNAAgNgHg");
    this.shape_3.setTransform(75.8135, -66.125);

    this.shape_4 = new cjs.Shape();
    this.shape_4.graphics
      .f("#91682B")
      .s()
      .p(
        "AhWIZQgngOgQgmQgmASgngOQgmgPgRgmQglASgngOQgmgOgRglQgRglAOgmQAMgjAhgSQgth3gBiVQgCiUAnhrQA9ijCchLQCbhLCZA5QCaA5BFCdQBGCgg8CiQgoBrhhBvQhkBxhvA9QANAigNAjQgOAngkARQgUAJgUAAQgRAAgSgHg"
      );
    this.shape_4.setTransform(90.3863, -30.0597);

    this.shape_5 = new cjs.Shape();
    this.shape_5.graphics
      .f("#74747E")
      .s()
      .p(
        "AhrIsQgsgPgSgrQgrAUgqgQQgrgQgTgpQgSgpAPgrQALgeAagTQAZgTAfgDIE+tUIDrBXIk/NUQAWAWAHAfQAHAfgMAfQgPAqgpATQgWAKgXAAQgTAAgTgHg"
      );
    this.shape_5.setTransform(55.5217, 49.2407);

    this.shape_6 = new cjs.Shape();
    this.shape_6.graphics
      .f("#181821")
      .s()
      .p("AmlGwIAStmQCbAWDDgUQCygRCLByQCLBxARCzQARCyhxCLQhyCLizARQiIANh/AAQhiAAhbgHg");
    this.shape_6.setTransform(47.9169, 65.5989);

    this.shape_7 = new cjs.Shape();
    this.shape_7.graphics
      .f("#6C271F")
      .s()
      .p(
        "ACHO9Qjxhsi1i+Qk8lMhIoEQgnkYAjjuIABAAQAHhBAbg9QBJikCohAQCng/CkBJQCJA9BFCFQBECDgaCRIACABIAAgDQgSCIAbCiQAlDkB2B5QB6B9DpAgIgSNmQkvgZjxhtg"
      );
    this.shape_7.setTransform(-62.1527, -0.377);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({
          state: [
            { t: this.shape_7 },
            { t: this.shape_6 },
            { t: this.shape_5 },
            { t: this.shape_4 },
            { t: this.shape_3 },
            { t: this.shape_2 },
            { t: this.shape_1 },
            { t: this.shape }
          ]
        })
        .wait(1)
    );
  }).prototype = getMCSymbolPrototype(lib.a_amrL, new cjs.Rectangle(-131.8, -109.5, 263.70000000000005, 219.1), null);

  (lib.Tween2 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib.a_mouse();
    this.instance.parent = this;
    this.instance.setTransform(50.95, -1.45, 1, 0.7576);

    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#232736")
      .s()
      .p("AhPBQQgighAAgvQAAguAighQAhgiAuAAQAvAAAhAiQAiAhAAAuQAAAvgiAhQghAigvAAQguAAghgig");
    this.shape.setTransform(-49.675, -1.025);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics
      .f("#181821")
      .s()
      .p("AgmAnQgRgQAAgXQAAgWARgQQAQgRAWAAQAXAAAQARQARAQAAAWQAAAXgRAQQgQARgXAAQgWAAgQgRg");
    this.shape_1.setTransform(-44.975, -56.675);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics
      .f("#181821")
      .s()
      .p("AggDzIAAnlIBAAAIABHlg");
    this.shape_2.setTransform(-44.975, -32.325);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics
      .f("#181821")
      .s()
      .p("Ah8B9Qg0g0AAhJQAAhIA0g0QA0g0BIAAQBJAAA0A0QA0A0AABIQAABJg0A0Qg0A0hJAAQhIAAg0g0g");
    this.shape_3.setTransform(-47.325, -1.025);

    this.shape_4 = new cjs.Shape();
    this.shape_4.graphics
      .f("#2A2E40")
      .s()
      .p("AheBDQgTgaAPgmQAOgmAogcQAngcAogBQApAAASAaQATAbgOAmQgOAlgoAcQgnAcgoABIgDAAQgmAAgTgag");
    this.shape_4.setTransform(12.3082, -34.45);

    this.shape_5 = new cjs.Shape();
    this.shape_5.graphics
      .f("#181821")
      .s()
      .p("AmJGLQijijAAjoQAAjnCjikQCkijDlgBQDnABCjCjQCjCkAADnQAADoijCjQijCkjnAAQjlAAikikg");
    this.shape_5.setTransform(32.625, 0.35);

    this.shape_6 = new cjs.Shape();
    this.shape_6.graphics
      .f("#6B6B77")
      .s()
      .p("AmsG8Qixi4gBkEQAAkDCyi3QCyi4D6AAQD7AACyC4QCxC3AAEDQAAEEixC4QixC3j8AAQj6AAiyi3g");
    this.shape_6.setTransform(29.05, 0.325);

    this.shape_7 = new cjs.Shape();
    this.shape_7.graphics
      .f("#773A22")
      .s()
      .p(
        "AlcLzQifg+h5hxQh4hyhCiTQhDiYAAinQAAimBDiYQBCiUB4hwQB5hyCfg+QCkhBC4AAQC4AAClBBQCfA+B5ByQB4BwBCCUQBDCYAACmQAACnhDCYQhCCTh4ByQh5BxifA+QilBAi4AAQi3AAilhAg"
      );
    this.shape_7.setTransform(-1.425, 0);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({
          state: [
            { t: this.shape_7 },
            { t: this.shape_6 },
            { t: this.shape_5 },
            { t: this.shape_4 },
            { t: this.shape_3 },
            { t: this.shape_2 },
            { t: this.shape_1 },
            { t: this.shape },
            { t: this.instance }
          ]
        })
        .wait(1)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-89.6, -81.9, 179.3, 163.9);

  (lib.Tween1 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib.a_mouse();
    this.instance.parent = this;
    this.instance.setTransform(50.95, -1.45);

    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#232736")
      .s()
      .p("AhPBQQgighAAgvQAAguAighQAhgiAuAAQAvAAAhAiQAiAhAAAuQAAAvgiAhQghAigvAAQguAAghgig");
    this.shape.setTransform(-49.675, -1.025);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics
      .f("#181821")
      .s()
      .p("AgmAnQgRgQAAgXQAAgWARgQQAQgRAWAAQAXAAAQARQARAQAAAWQAAAXgRAQQgQARgXAAQgWAAgQgRg");
    this.shape_1.setTransform(-44.975, -56.675);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics
      .f("#181821")
      .s()
      .p("AggDzIAAnlIBAAAIABHlg");
    this.shape_2.setTransform(-44.975, -32.325);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics
      .f("#181821")
      .s()
      .p("Ah8B9Qg0g0AAhJQAAhIA0g0QA0g0BIAAQBJAAA0A0QA0A0AABIQAABJg0A0Qg0A0hJAAQhIAAg0g0g");
    this.shape_3.setTransform(-47.325, -1.025);

    this.shape_4 = new cjs.Shape();
    this.shape_4.graphics
      .f("#2A2E40")
      .s()
      .p("AheBDQgTgaAPgmQAOgmAogcQAngcAogBQApAAASAaQATAbgOAmQgOAlgoAcQgnAcgoABIgDAAQgmAAgTgag");
    this.shape_4.setTransform(12.3082, -34.45);

    this.shape_5 = new cjs.Shape();
    this.shape_5.graphics
      .f("#181821")
      .s()
      .p("AmJGLQijijAAjoQAAjnCjikQCkijDlgBQDnABCjCjQCjCkAADnQAADoijCjQijCkjnAAQjlAAikikg");
    this.shape_5.setTransform(32.625, 0.35);

    this.shape_6 = new cjs.Shape();
    this.shape_6.graphics
      .f("#6B6B77")
      .s()
      .p("AmsG8Qixi4gBkEQAAkDCyi3QCyi4D6AAQD7AACyC4QCxC3AAEDQAAEEixC4QixC3j8AAQj6AAiyi3g");
    this.shape_6.setTransform(29.05, 0.325);

    this.shape_7 = new cjs.Shape();
    this.shape_7.graphics
      .f("#773A22")
      .s()
      .p(
        "AlcLzQifg+h5hxQh4hyhCiTQhDiYAAinQAAimBDiYQBCiUB4hwQB5hyCfg+QCkhBC4AAQC4AAClBBQCfA+B5ByQB4BwBCCUQBDCYAACmQAACnhDCYQhCCTh4ByQh5BxifA+QilBAi4AAQi3AAilhAg"
      );
    this.shape_7.setTransform(-1.425, 0);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({
          state: [
            { t: this.shape_7 },
            { t: this.shape_6 },
            { t: this.shape_5 },
            { t: this.shape_4 },
            { t: this.shape_3 },
            { t: this.shape_2 },
            { t: this.shape_1 },
            { t: this.shape },
            { t: this.instance }
          ]
        })
        .wait(1)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-89.6, -81.9, 179.3, 163.9);

  (lib.tambourine = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // finger
    this.instance = new lib.b_finger("synched", 0);
    this.instance.parent = this;
    this.instance.setTransform(28.85, 61.3);

    this.timeline.addTween(
      cjs.Tween.get(this.instance)
        .to(
          {
            x: 22.85,
            y: 63.3
          },
          7
        )
        .wait(8)
        .to({ startPosition: 0 }, 0)
        .to({ x: 28.85, y: 61.3 }, 8)
        .wait(8)
    );

    // t1
    this.instance_1 = new lib.t1();
    this.instance_1.parent = this;
    this.instance_1.setTransform(-5, 0);

    this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(31));

    // t2
    this.instance_2 = new lib.t2();
    this.instance_2.parent = this;
    this.instance_2.setTransform(-1, 0, 0.9952, 0.9952);

    this.timeline.addTween(
      cjs.Tween.get(this.instance_2)
        .to({ x: -8, y: 3 }, 7)
        .wait(8)
        .to(
          {
            x: -1,
            y: 0
          },
          8
        )
        .wait(8)
    );

    // t3
    this.instance_3 = new lib.t3();
    this.instance_3.parent = this;
    this.instance_3.setTransform(3.55, -3.25);

    this.timeline.addTween(
      cjs.Tween.get(this.instance_3)
        .to({ x: -12.45, y: 3.75 }, 8)
        .wait(8)
        .to(
          {
            x: -10.15,
            y: 2.75
          },
          0
        )
        .to({ x: 3.55, y: -3.25 }, 8)
        .wait(7)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-92, -84, 175, 171);

  (lib.tab = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib.tab_g("synched", 0);
    this.instance.parent = this;
    this.instance.setTransform(0, 0, 0.7993, 0.7993);
    this.instance.alpha = 0.1016;

    this.timeline.addTween(
      cjs.Tween.get(this.instance)
        .to(
          {
            scaleX: 1.4857,
            scaleY: 1.4857,
            alpha: 0.25
          },
          5
        )
        .wait(1)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-26, -26, 52, 52);

  (lib.t = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib.tambourine();
    this.instance.parent = this;
    this.instance.setTransform(1, 0);

    this.timeline.addTween(
      cjs.Tween.get(this.instance)
        .to({ scaleX: 0.9518 }, 4)
        .wait(4)
        .to({ scaleX: 1 }, 4)
        .wait(4)
        .to({ scaleX: 0.9518 }, 4)
        .wait(4)
        .to({ scaleX: 1 }, 4)
        .wait(4)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-84, -84, 168, 168.1);

  (lib.qr_barM = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib.qr_bar("synched", 0);
    this.instance.parent = this;
    this.instance.setTransform(0.05, 0);

    this.timeline.addTween(
      cjs.Tween.get(this.instance)
        .to({ y: 160.8 }, 25)
        .to({ y: 6.7 }, 24)
        .wait(1)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-147.2, -2, 294.5, 164.8);

  (lib.qr = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#E8E8EA")
      .s()
      .p("Egi5ACEQgVAAgPgPQgPgPAAgVIAAihQAAgVAPgPQAPgPAVAAMBFzAAAQAVAAAPAPQAPAPAAAVIAAChQAAAVgPAPQgPAPgVAAg");
    this.shape.setTransform(0, 255.125);

    this.instance = new lib.Group();
    this.instance.parent = this;
    this.instance.setTransform(-102, 177, 1, 1, 0, 0, 0, 6.8, 9.1);
    this.instance.alpha = 0.3984;

    this.instance_1 = new lib.Group_1();
    this.instance_1.parent = this;
    this.instance_1.setTransform(7.7, 178.85, 1, 1, 0, 0, 0, 95.4, 7.4);
    this.instance_1.alpha = 0.3984;

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics
      .f("#2E2E43")
      .s()
      .p(
        "AjLDLQhUhUAAh3QAAh2BUhUQBVhVB2AAQB4AABTBVQBVBUAAB2QAAB3hVBUQhUBVh2AAQh3AAhVhVgAiViWQhAA/ABBXQAABYA+A/QA/A/BXAAQBZAAA+g/QA/g/AAhYQAAhXg/g/Qg/g/hXAAQhYAAg+A/g"
      );
    this.shape_1.setTransform(72.4, -29.725);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics
      .f("#2E2E43")
      .s()
      .p("AAAB6QgyAAgkgjQgjgkAAgzQAAgxAjgkQAkgkAyAAIAAAAQAzAAAkAkQAjAkAAAxQAAAzgjAkQgkAjgzAAg");
    this.shape_2.setTransform(72.425, -29.725);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics
      .f("#2E2E43")
      .s()
      .p(
        "AjKDLQhVhUAAh3QAAh2BVhUQBThVB3AAQB3AABVBVQBUBUAAB2QAAB3hUBUQhVBVh3AAQh2AAhUhVgAiWiWQg/A/AABXQAABYA+A/QBAA/BXAAQBZAAA+g/QA/g+AAhZQAAhXg/g/Qg+g/hZAAQhXAAg/A/g"
      );
    this.shape_3.setTransform(-77.85, 120.775);

    this.shape_4 = new cjs.Shape();
    this.shape_4.graphics
      .f("#136965")
      .s()
      .p("AhVBXQgkgkAAgzQAAgyAkgjQAjgkAyAAQAyAAAlAkQAjAjABAyQgBAzgjAkQglAjgyAAQgyAAgjgjg");
    this.shape_4.setTransform(-77.85, 120.775);

    this.shape_5 = new cjs.Shape();
    this.shape_5.graphics
      .f("#2E2E43")
      .s()
      .p(
        "AjKDLQhVhUAAh3QAAh2BVhUQBThVB3AAQB3AABVBVQBUBUAAB2QAAB3hUBUQhVBVh3AAQh2AAhUhVgAiWiWQg/A/AABXQAABYA+A/QBAA/BXAAQBZAAA+g/QA/g+AAhZQAAhXg/g/Qg+g/hZAAQhXAAg/A/g"
      );
    this.shape_5.setTransform(-77.85, -29.725);

    this.shape_6 = new cjs.Shape();
    this.shape_6.graphics
      .f("#B93540")
      .s()
      .p("AhVBXQgkgkAAgzQAAgxAkgkQAjgkAyAAQAyAAAlAkQAjAkABAxQgBAzgjAkQglAjgyAAQgyAAgjgjg");
    this.shape_6.setTransform(-77.85, -29.725);

    this.shape_7 = new cjs.Shape();
    this.shape_7.graphics
      .f("#2E2E43")
      .s()
      .p("AgpBUIAAinIBUAAIAACng");
    this.shape_7.setTransform(30.7, 41.275);

    this.shape_8 = new cjs.Shape();
    this.shape_8.graphics
      .f("#2E2E43")
      .s()
      .p("AgnAmIAAhLIBPAAIAABLg");
    this.shape_8.setTransform(47.55, 95.65);

    this.shape_9 = new cjs.Shape();
    this.shape_9.graphics
      .f("#2E2E43")
      .s()
      .p("AgqArIAAhUIBUAAIAABUg");
    this.shape_9.setTransform(14, 78.9);

    this.shape_10 = new cjs.Shape();
    this.shape_10.graphics
      .f("#313247")
      .s()
      .p("AgpAqIAAhTIBTAAIAABTg");
    this.shape_10.setTransform(-52.875, 45.475);

    this.shape_11 = new cjs.Shape();
    this.shape_11.graphics
      .f("#2E2E43")
      .s()
      .p("AgpAoIAAhPIBTAAIAABPg");
    this.shape_11.setTransform(-36.15, 45.275);

    this.shape_12 = new cjs.Shape();
    this.shape_12.graphics
      .f("#2E2E43")
      .s()
      .p("AgqArIABhTIBSAAIABBTgAApgoIAAgCIABACg");
    this.shape_12.setTransform(-44.5, 37.1);

    this.shape_13 = new cjs.Shape();
    this.shape_13.graphics
      .f("#2E2E43")
      .s()
      .p("AgnAoIAAhPIBPAAIAABPg");
    this.shape_13.setTransform(-11.2, -54.675);

    this.shape_14 = new cjs.Shape();
    this.shape_14.graphics
      .f("#2E2E43")
      .s()
      .p("AgoApIAAhRIBRAAIAABRg");
    this.shape_14.setTransform(-2.725, -38.125);

    this.shape_15 = new cjs.Shape();
    this.shape_15.graphics
      .f("#2E2E43")
      .s()
      .p("AgnAoIAAhPIBPAAIAABPg");
    this.shape_15.setTransform(30.575, -21.55);

    this.shape_16 = new cjs.Shape();
    this.shape_16.graphics
      .f("#2E2E43")
      .s()
      .p("AgmAnIAAhNIBNAAIAABNg");
    this.shape_16.setTransform(30.675, -4.625);

    this.shape_17 = new cjs.Shape();
    this.shape_17.graphics
      .f("#2E2E43")
      .s()
      .p("AgnAoIAAhPIBPAAIAABPg");
    this.shape_17.setTransform(-36.025, 120.55);

    this.shape_18 = new cjs.Shape();
    this.shape_18.graphics
      .f("#2E2E43")
      .s()
      .p("AgoAoIAAhPIBRAAIAABPg");
    this.shape_18.setTransform(-11.075, 11.9);

    this.shape_19 = new cjs.Shape();
    this.shape_19.graphics
      .f("#2E2E43")
      .s()
      .p("AgnAoIAAhPIBPAAIAABPg");
    this.shape_19.setTransform(-27.925, 20.525);

    this.shape_20 = new cjs.Shape();
    this.shape_20.graphics
      .f("#2E2E43")
      .s()
      .p("AApAoIhRAAIAAhRIBRAAIAABRIABAAIgBACgAgpAoIABAAIAAACgAgoAog");
    this.shape_20.setTransform(-36.15, 28.825);

    this.shape_21 = new cjs.Shape();
    this.shape_21.graphics
      .f("#2E2E43")
      .s()
      .p("AglAoIAAhPIBLAAIAABPg");
    this.shape_21.setTransform(97.55, 79.025);

    this.shape_22 = new cjs.Shape();
    this.shape_22.graphics
      .f("#2E2E43")
      .s()
      .p("AgnApIAAhRIBPAAIAABRg");
    this.shape_22.setTransform(-102.875, 62.175);

    this.shape_23 = new cjs.Shape();
    this.shape_23.graphics
      .f("#2E2E43")
      .s()
      .p("AgnBSIAAijIBPAAIAACjg");
    this.shape_23.setTransform(30.6, -50.5);

    this.shape_24 = new cjs.Shape();
    this.shape_24.graphics
      .f("#2E2E43")
      .s()
      .p("AhTApIAAhQICmAAIABBQg");
    this.shape_24.setTransform(-48.6, 12.1);

    this.shape_25 = new cjs.Shape();
    this.shape_25.graphics
      .f("#2E2E43")
      .s()
      .p("AhSAqIAAhRIgCAAIACgCIAAACICnABIAABQg");
    this.shape_25.setTransform(76.7, 28.675);

    this.shape_26 = new cjs.Shape();
    this.shape_26.graphics
      .f("#2E2E43")
      .s()
      .p("AhSBRIAAhPIBTAAIAAhTIBSAAIAACig");
    this.shape_26.setTransform(34.9, 141.45);

    this.shape_27 = new cjs.Shape();
    this.shape_27.graphics
      .f("#2E2E43")
      .s()
      .p("AhRBSIAAhOIBRAAIAAhUIAAAAIAAgBIAAABIBSAAIAACig");
    this.shape_27.setTransform(-98.75, 74.525);

    this.shape_28 = new cjs.Shape();
    this.shape_28.graphics
      .f("#2E2E43")
      .s()
      .p("AgoB9IAAj5IBRAAIAAD5g");
    this.shape_28.setTransform(-19.45, -4.675);

    this.shape_29 = new cjs.Shape();
    this.shape_29.graphics
      .f("#2E2E43")
      .s()
      .p("AACBTIgDhSIhOAAIAAhSICgAAIAACkg");
    this.shape_29.setTransform(85.1, 16.4);

    this.shape_30 = new cjs.Shape();
    this.shape_30.graphics
      .f("#2E2E43")
      .s()
      .p("Ah7BRIAAihIBPAAIAABRICoAAIAABQg");
    this.shape_30.setTransform(-27.675, 141.55);

    this.shape_31 = new cjs.Shape();
    this.shape_31.graphics
      .f("#2E2E43")
      .s()
      .p("AgoB8IAAhRIhUAAIAAhQIBSAAIAAhVIBSAAIAACnIBUAAIAAgCIACACIgCAAIAABPgAAoh6IAAgBIACABgAAoh6g");
    this.shape_31.setTransform(-2.65, 137.2);

    this.shape_32 = new cjs.Shape();
    this.shape_32.graphics
      .f("#2E2E43")
      .s()
      .p("Ah6B9IAAhPIBSgEIAAimICjAAIAABVIhSAAIAACkg");
    this.shape_32.setTransform(72.675, 137.225);

    this.shape_33 = new cjs.Shape();
    this.shape_33.graphics
      .f("#2E2E43")
      .s()
      .p("AhRB6IAAiiIBRAAIAAhRIBSAAIAADzg");
    this.shape_33.setTransform(93.35, 137.375);

    this.shape_34 = new cjs.Shape();
    this.shape_34.graphics
      .f("#2E2E43")
      .s()
      .p("AArB7IAAhTIhUAAIAAhSIhTAAIAAhQICjAAIAABRIBVAAIAACkgAB8gpIAAgBIABABgAB8gpg");
    this.shape_34.setTransform(14.075, -38);

    this.shape_35 = new cjs.Shape();
    this.shape_35.graphics
      .f("#2E2E43")
      .s()
      .p("AgrBSIhPAAIAAilID1AAIAAClIhSAAIAAhQIhUAAIAABQIACAAIgCACg");
    this.shape_35.setTransform(-27.675, -42.2);

    this.shape_36 = new cjs.Shape();
    this.shape_36.graphics
      .f("#2E2E43")
      .s()
      .p("Ah7B7IAAikIClAAIAAhRIBSAAIAAChIhPAAIAABUg");
    this.shape_36.setTransform(89.1, 45.625);

    this.shape_37 = new cjs.Shape();
    this.shape_37.graphics
      .f("#2E2E43")
      .s()
      .p("AhRDSIAAlMIBRAAIAAhVIgBAAIABgCIAAACIBRAAIAAClIhQAAIAAD8gABRjPIAAgCIACACgABRjPg");
    this.shape_37.setTransform(-31.8, -13.025);

    this.shape_38 = new cjs.Shape();
    this.shape_38.graphics
      .f("#2E2E43")
      .s()
      .p("AimClIAAhRIBTAAIAAj4IBSAAIAAD1ICoAAIAABUg");
    this.shape_38.setTransform(18.175, 7.95);

    this.shape_39 = new cjs.Shape();
    this.shape_39.graphics
      .f("#2E2E43")
      .s()
      .p("AgoDRIAAj6IhUAAIAAimIBTAAIAABQIBTAAIAAhQIBTAAIAAClIhSAAIAAD7gAh9gpIABAAIAAACgAh8gpgAB9jPIAAgBIABABgAB9jPg");
    this.shape_39.setTransform(-2.725, -13.1);

    this.shape_40 = new cjs.Shape();
    this.shape_40.graphics
      .f("#2E2E43")
      .s()
      .p(
        "AAqEiIABAAIAAABgAArEiIAAhUIhSAAIAAhUIhWAAIAAikIhTAAIABhTIBSAAIAAilID6AAIAACjIACAAIgCACIAAgCIhSACIAABTIBSAAIAABQIhQAAIAABVIBPAAIAABVIBVAAIAABSgAglh/IBPAAIAAhQIhPAAgAArEig"
      );
    this.shape_40.setTransform(55.725, 37.3);

    this.shape_41 = new cjs.Shape();
    this.shape_41.graphics
      .f("#2E2E43")
      .s()
      .p(
        "AAoD7IAAhWIhRAAIAAj1IhWAAIAABOIhSAAIAAhRIhTAAIABhTIClAAIAAhUICnAAIAAFKIBXAAIAAj0IBSAAIAACkIBTAAIgBBSIhUAAIAABWIhSAAIAABTg"
      );
    this.shape_41.setTransform(-2.725, 49.725);

    this.shape_42 = new cjs.Shape();
    this.shape_42.graphics
      .f("#2E2E43")
      .s()
      .p(
        "Ah9EjIAAhUIhTAAIAAhRIhSAAIAAmeIClAAIAABPIBSAAIAAhRIBRAAIAABSICpAAIAABRIBUAAIAABUIhTAAIAABVIhTgCIAAhUIhXAAIAAhPIhRAAIAAGegAjOgtIBQAAIAAijIhQAAgADPjQIAAgBIABABgADPjQg"
      );
    this.shape_42.setTransform(-77.85, 37.2);

    this.shape_43 = new cjs.Shape();
    this.shape_43.graphics
      .f("#2E2E43")
      .s()
      .p(
        "ADUGhIAAhSIhVAAIAAilIhTAAIAAimImiAAIAAioIgBAAIABgBIAAABICjAAIAAhWIhNAAIAAilIBQAAIAABTIBVAAIAABWIFMAAIAACkIhQAAIAACoIBQAAIAACnICnAAIAABRIhRAAIgEBTgAh5hUICiAAIAAhOIiiAAgAjQmfIAAgBIABABg"
      );
    this.shape_43.setTransform(-44.575, 82.925);

    this.shape_44 = new cjs.Shape();
    this.shape_44.graphics
      .f("#2E2E43")
      .s()
      .p(
        "AkjGgIACAAIAAACgAkhGgIAAimIhUAAIAAioIhUAAIAAhTIhWAAIAAhSIhRAAIAAinIBVAAIAABSID6AAIAAFMIBUAAIAAhSIBQAAIAAhTIhSAAIAAinIBUAAIAAj5IBWAAIAAClIBRAAIAAilIBSAAIAABUIBTAAIAABSIBVAAIAACnIBSAAIAABSIBUAAIAAhOIClAAIAAChIhOAAIAABSIinAAIgEhNIhRAAIAACiIhSAEIAABRIipAAIgBhSIj3AAIAABUIBQAAIAABRgAAsD6IBQAAIAAhQIhQAAgAgkBQID0AAIAAj1Ij0AAgAl3D6IACAAIAAABgADRlNIAAgBIACABgADRlNg"
      );
    this.shape_44.setTransform(38.95, 99.85);

    this.shape_45 = new cjs.Shape();
    this.shape_45.graphics
      .f("#2E2E43")
      .s()
      .p("AlIAyQgUAAgPgOQgPgPAAgVQAAgUAPgOQAPgPAUAAIKRAAQAUAAAQAPQAOAOAAAUQAAAVgOAPQgQAOgUAAg");
    this.shape_45.setTransform(-16.9, -156.775);

    this.shape_46 = new cjs.Shape();
    this.shape_46.graphics
      .f("#444D68")
      .s()
      .p("AnWAyQgUAAgPgOQgPgPAAgVQAAgUAPgOQAPgPAUAAIOtAAQAUAAAPAPQAPAOAAAUQAAAVgPAPQgPAOgUAAg");
    this.shape_46.setTransform(-2.7, -156.775);

    this.shape_47 = new cjs.Shape();
    this.shape_47.graphics
      .f("#B93540")
      .s()
      .p("AioDwQgRAAgMgMQgLgLgBgRQADh4AahtQAwjHBqgLIA1AAQBpALAxDHQAbBtADB4QgBARgMALQgMAMgRAAg");
    this.shape_47.setTransform(-2.7, -181.175);

    this.shape_48 = new cjs.Shape();
    this.shape_48.graphics
      .f("#D5D4D6")
      .s()
      .p("Ag+DwQAQgBAMgLQALgLABgRQgDh4gahtQgxjHhpgLIASAAQCnARBwB9QBzB9AACqQAAARgNAMQgMANgRAAg");
    this.shape_48.setTransform(20.65, -181.175);

    this.shape_49 = new cjs.Shape();
    this.shape_49.graphics
      .f("#136965")
      .s()
      .p("AikDwQgRAAgNgNQgMgMABgRQgBiqBzh9QBwh9CogRIASAAQhqALgwDHQgbBtgDB4QABARAMALQALALAQABg");
    this.shape_49.setTransform(-26.05, -181.175);

    this.shape_50 = new cjs.Shape();
    this.shape_50.graphics
      .f("#444D68")
      .s()
      .p("AgsAgIAAgSQAAgSANgOQANgMASAAQATAAANAMQANAOAAASIAAASg");
    this.shape_50.setTransform(-2.7, -208.3);

    this.shape_51 = new cjs.Shape();
    this.shape_51.graphics
      .f("#2E2E43")
      .s()
      .p("AAbCUQgNgHgHgOIhrjMQgKgTAHgVQAGgVAUgKQATgLAVAHQAVAGAKAUIBrDMQAKATgHAVQgGAVgUAKQgLAHgNAAQgOAAgNgIg");
    this.shape_51.setTransform(25.4, -155.4743);

    this.shape_52 = new cjs.Shape();
    this.shape_52.graphics
      .f("#2E2E43")
      .s()
      .p("AhNCWQgUgLgGgVQgHgVAKgTIBrjMQAKgTAVgHQAVgGAUAKQATAKAHAVQAGAVgKATIhrDMQgHAOgNAHQgMAIgPAAQgNAAgLgGg");
    this.shape_52.setTransform(-30.8038, -155.903);

    this.shape_53 = new cjs.Shape();
    this.shape_53.graphics
      .f("#2E2E43")
      .s()
      .p("AglCQQgPgPAAgWIAAjVQAAgWAPgPQAQgQAVAAQAWAAAPAQQAQAPAAAWIAADVQAAAWgQAPQgPAQgWAAQgVAAgQgQg");
    this.shape_53.setTransform(-2.7, -155.475);

    this.shape_54 = new cjs.Shape();
    this.shape_54.graphics
      .f("#2E2E43")
      .s()
      .p(
        "AhOBTQghghAAgvIAAhdQAAgKAIgIQAGgHALAAQAKAAAIAHQAHAIAAAKIAABdQAAAaATASQARATAZAAQAaAAASgTQASgSAAgaIAAhdQAAgKAIgIQAHgHAKAAQALAAAHAHQAHAIAAAKIAABdQAAAvghAhQghAhguAAQgtAAghghg"
      );
    this.shape_54.setTransform(29, -97.675);

    this.shape_55 = new cjs.Shape();
    this.shape_55.graphics
      .f("#2E2E43")
      .s()
      .p("AgQBsQgIgHAAgKIAAi1QAAgKAIgIQAHgHAJAAQAKAAAIAHQAHAIAAAKIAAC1QAAAKgHAHQgIAIgKAAQgJAAgHgIg");
    this.shape_55.setTransform(37.725, -97.675);

    this.shape_56 = new cjs.Shape();
    this.shape_56.graphics
      .f("#2E2E43")
      .s()
      .p(
        "AAqB1IhzhiQgJgIAAgLQAAgKAJgIIBzhhQAIgHAKABQAKABAHAHQAGAIAAALQgBAKgIAGIhcBOIBcBPQAIAHABAKQAAAKgGAIQgIAJgLAAQgJAAgHgGg"
      );
    this.shape_56.setTransform(93.2768, -98.3518);

    this.shape_57 = new cjs.Shape();
    this.shape_57.graphics
      .f("#2E2E43")
      .s()
      .p("AgRCoQgHgIAAgKIAAkrQAAgLAHgHQAIgHAJAAQAKAAAHAHQAIAHAAALIAAErQAAAKgIAIQgHAHgKAAQgJAAgIgHg");
    this.shape_57.setTransform(84.15, -103.625);

    this.shape_58 = new cjs.Shape();
    this.shape_58.graphics
      .f("#2E2E43")
      .s()
      .p(
        "AhUBWQglgkABgzQABgwAjgkQAjgjAxgBQAhgBAdAQQAdARARAdIAAAAQANAaAAAUQAAALgHAGQgIAHgKAAIilAAQADAYARARQAVAWAeAAQATAAARgKQARgKAKgRQAFgIAKgDQAKgDAJAFQAJAGACAJQADAKgFAJQgRAdgcAQQgdARggAAQgzAAgjglgAghg+QgPAJgKAPIB5gBQgKgPgRgJQgQgJgSAAIgCAAQgRABgQAJg"
      );
    this.shape_58.setTransform(-61.0513, -98.3508);

    this.shape_59 = new cjs.Shape();
    this.shape_59.graphics
      .f("#2E2E43")
      .s()
      .p(
        "AhQBWQgkgkABgzQABgwAjgkQAjgjAvgBQAigBAdAQQAdARARAdQAGAJgDAKQgDAKgJAFQgJAFgKgDQgJgCgGgJQgJgRgRgKQgRgKgUAAIgBAAQgcABgVAVQgVAVgBAdQAAAdAVAWQAWAWAdAAQAUAAARgKQARgKAJgRQAGgIAJgDQAKgDAJAFQAJAGADAJQADAKgGAJQgQAdgdAQQgcARghAAQgyAAgkglg"
      );
    this.shape_59.setTransform(65.9516, -98.3508);

    this.shape_60 = new cjs.Shape();
    this.shape_60.graphics
      .f("#2E2E43")
      .s()
      .p("AAfBCIhghgQgIgHABgLQgBgKAIgHQAHgIALAAQAJAAAIAIIBgBgQAIAHgBALQABAKgIAHQgHAIgLAAQgJAAgIgIg");
    this.shape_60.setTransform(5.75, -93.775);

    this.shape_61 = new cjs.Shape();
    this.shape_61.graphics
      .f("#2E2E43")
      .s()
      .p(
        "AiMCNQg6g6AAhTQAAhSA6g6QA6g6BSgBQBSABA7A6QA6A6AABSQAABTg6A6Qg7A7hSgBQhSABg6g7gAhphoQgsArAAA9QAAA+AsArQAsAsA9ABQA+gBAsgsQAsgrAAg+QAAg9gsgrQgsgtg+ABQg9gBgsAtg"
      );
    this.shape_61.setTransform(-6.525, -106.05);

    this.shape_62 = new cjs.Shape();
    this.shape_62.graphics
      .f("#2E2E43")
      .s()
      .p("AgRASQgIgHABgLQgBgKAIgHQAIgIAJAAQAKAAAIAIQAIAHgBAKQABALgIAHQgIAIgKAAQgJAAgIgIg");
    this.shape_62.setTransform(47.55, -118.725);

    this.shape_63 = new cjs.Shape();
    this.shape_63.graphics
      .f("#2E2E43")
      .s()
      .p("AgQCBQgIgIAAgKIAAjeQAAgKAIgHQAHgHAJAAQAKAAAHAHQAIAHAAAKIAADeQAAAKgIAIQgHAHgKAAQgJAAgHgHg");
    this.shape_63.setTransform(47.55, -99.725);

    this.shape_64 = new cjs.Shape();
    this.shape_64.graphics
      .f("#2E2E43")
      .s()
      .p("AgRASQgIgHAAgLQAAgKAIgHQAHgIAKAAQALAAAHAIQAIAHAAAKQAAALgIAHQgHAIgLAAQgKAAgHgIg");
    this.shape_64.setTransform(-33.125, -118.725);

    this.shape_65 = new cjs.Shape();
    this.shape_65.graphics
      .f("#2E2E43")
      .s()
      .p("AgRCBQgHgIAAgKIAAjeQAAgKAHgHQAIgHAJAAQAKAAAIAHQAHAHAAAKIAADeQAAAKgHAIQgIAHgKAAQgJAAgIgHg");
    this.shape_65.setTransform(-33.125, -99.725);

    this.shape_66 = new cjs.Shape();
    this.shape_66.graphics
      .f("#2E2E43")
      .s()
      .p(
        "AiCDHQgKABgHgIQgIgIAAgJIAAlcQAAgLAIgHQAHgHAKgBIBXAAQBSABA6A6QA7A6AABSQAABTg7A6Qg6A7hSgBgAhpCWIA+AAQA9gBAsgsQAsgrAAg+QAAg9gsgrQgsgtg9ABIg+AAg"
      );
    this.shape_66.setTransform(-91.375, -106.05);

    this.shape_67 = new cjs.Shape();
    this.shape_67.graphics
      .f("#2E2E43")
      .s()
      .p("AgRCoQgHgHAAgKIAAktQAAgKAHgIQAIgHAJAAQAKAAAIAHQAHAIAAAKIAAEtQAAAKgHAHQgIAIgKAAQgJAAgIgIg");
    this.shape_67.setTransform(-43.025, -103.7);

    this.shape_68 = new cjs.Shape();
    this.shape_68.graphics
      .lf(["rgba(221,221,221,0)", "#DDDDDD"], [0, 1], 0, -157.9, 0, 327.3)
      .s()
      .p("EgaHAnoMAAAhLzQAAhbBAhAQBAhBBbAAMAtZAAAQBbAABABBQBABAAABbMAAABLzg");
    this.shape_68.setTransform(-0.6, -11.675);

    this.shape_69 = new cjs.Shape();
    this.shape_69.graphics
      .f("#E8E8EA")
      .s()
      .p("EgarApsMAAAhP3QAAhdBChBQBBhCBdAAMAuXAAAQBdAABCBCQBBBBAABdMAAABP3g");
    this.shape_69.setTransform(-0.025, 1.55);

    this.shape_70 = new cjs.Shape();
    this.shape_70.graphics
      .f("#C9C9CC")
      .s()
      .p("EgbfAp8MAAAhQWQAAhdBChBQBChDBdAAMAv9AAAQBdAABCBDQBCBBAABdMAAABQWg");
    this.shape_70.setTransform(-0.025, 0);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({
          state: [
            { t: this.shape_70 },
            { t: this.shape_69 },
            { t: this.shape_68 },
            { t: this.shape_67 },
            { t: this.shape_66 },
            { t: this.shape_65 },
            { t: this.shape_64 },
            { t: this.shape_63 },
            { t: this.shape_62 },
            { t: this.shape_61 },
            { t: this.shape_60 },
            { t: this.shape_59 },
            { t: this.shape_58 },
            { t: this.shape_57 },
            { t: this.shape_56 },
            { t: this.shape_55 },
            { t: this.shape_54 },
            { t: this.shape_53 },
            { t: this.shape_52 },
            { t: this.shape_51 },
            { t: this.shape_50 },
            { t: this.shape_49 },
            { t: this.shape_48 },
            { t: this.shape_47 },
            { t: this.shape_46 },
            { t: this.shape_45 },
            { t: this.shape_44 },
            { t: this.shape_43 },
            { t: this.shape_42 },
            { t: this.shape_41 },
            { t: this.shape_40 },
            { t: this.shape_39 },
            { t: this.shape_38 },
            { t: this.shape_37 },
            { t: this.shape_36 },
            { t: this.shape_35 },
            { t: this.shape_34 },
            { t: this.shape_33 },
            { t: this.shape_32 },
            { t: this.shape_31 },
            { t: this.shape_30 },
            { t: this.shape_29 },
            { t: this.shape_28 },
            { t: this.shape_27 },
            { t: this.shape_26 },
            { t: this.shape_25 },
            { t: this.shape_24 },
            { t: this.shape_23 },
            { t: this.shape_22 },
            { t: this.shape_21 },
            { t: this.shape_20 },
            { t: this.shape_19 },
            { t: this.shape_18 },
            { t: this.shape_17 },
            { t: this.shape_16 },
            { t: this.shape_15 },
            { t: this.shape_14 },
            { t: this.shape_13 },
            { t: this.shape_12 },
            { t: this.shape_11 },
            { t: this.shape_10 },
            { t: this.shape_9 },
            { t: this.shape_8 },
            { t: this.shape_7 },
            { t: this.shape_6 },
            { t: this.shape_5 },
            { t: this.shape_4 },
            { t: this.shape_3 },
            { t: this.shape_2 },
            { t: this.shape_1 },
            { t: this.instance_1 },
            { t: this.instance },
            { t: this.shape }
          ]
        })
        .wait(1)
    );
  }).prototype = getMCSymbolPrototype(lib.qr, new cjs.Rectangle(-228.5, -268.3, 457, 536.7), null);

  (lib.b_body = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // eyes
    this.instance = new lib.b_eyes();
    this.instance.parent = this;
    this.instance.setTransform(-33, -59.2);

    this.timeline.addTween(
      cjs.Tween.get(this.instance)
        .wait(34)
        .to(
          {
            regY: -0.1,
            scaleY: 0.35,
            y: -59.25
          },
          6
        )
        .to({ regY: 0, scaleY: 1, y: -59.2 }, 5)
        .wait(25)
    );

    // body
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#501534")
      .s()
      .p("ACaBSIk9gSQgegCgUgXQgUgXACgdQACgeAXgUQAXgTAeACIE9ARQAeADAUAWQAUAXgCAdQgCAegXAUQgVASgaAAIgGAAg");
    this.shape.setTransform(-72.475, 9.2998);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics
      .f("#5E182D")
      .s()
      .p("ACuF1ImJgbQhLgEgxg4Qgyg5AFhKIA/lnQAFhKA4gyQA5gxBKAFIFiAUQBKAFAyA4QAxA4gFBLIgYFtQgFBKg4AyQgzAthCAAIgNgBg");
    this.shape_1.setTransform(-75.4748, 24.979);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics
      .f("#181821")
      .s()
      .p("ANlBRI7MgmQgaAAgSgTQgRgTAAgZQABgaASgSQATgRAaAAIbLAmQAaABASATQASASAAAZQgBAagTASQgRASgZAAIgCgBg");
    this.shape_2.setTransform(2.525, -26.6481);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics
      .f("#771B2A")
      .s()
      .p("AgQO7QoGgLkmi+QlEjSAJmMQAZk1Bdk7QBIj1BsjqIbMAmQBiDvA9D3QBOE/AME2QgJGMlMDEQkbCmnbAAIg9gBg");
    this.shape_3.setTransform(0.0628, 67.0601);

    this.shape_4 = new cjs.Shape();
    this.shape_4.graphics
      .f("#5E7D85")
      .s()
      .p(
        "AlTHNQh0gqhbhQQgDghgBgiQgDicA6iNQA4iKBohrQBohsCIg8QCLg+CcgCQBygCBqAfQA+BMAhBZQAjBdACBjQAED8i8C2Qi9C1kNAEIgMAAQh7AAhygqg"
      );
    this.shape_4.setTransform(-30.401, -69.4242);

    this.shape_5 = new cjs.Shape();
    this.shape_5.graphics
      .f("#434C63")
      .s()
      .p("AhQBRQgXgYAIgnQAHgnAigiQAhghAngHQAogHAXAXQAXAWgIApQgHAmgiAiQghAhgnAHQgKACgJAAQgaAAgSgRg");
    this.shape_5.setTransform(-74.875, -114.4);

    this.shape_6 = new cjs.Shape();
    this.shape_6.graphics
      .f("#292D3F")
      .s()
      .p("AnGG2QjBivgEj9QgEj7C8i2QC8i1EOgFQEOgEDCCwQDCCvADD9QAED7i8C2Qi8C2kPAEIgLAAQkGAAi+isg");
    this.shape_6.setTransform(-40.475, -80.075);

    this.shape_7 = new cjs.Shape();
    this.shape_7.graphics
      .f("#181821")
      .s()
      .p("AnOHnQjGjEgEkZQgFkXDAjKQBbhfB2g2QB7g3CIgCQERgEDHDDQDFDEAFEZQAEEXi/DKQjADJkUAFIgKAAQkMAAjCi/g");
    this.shape_7.setTransform(-33.3, -79.476);

    this.shape_8 = new cjs.Shape();
    this.shape_8.graphics
      .f("#771B2A")
      .s()
      .p(
        "AgUOwQjAgEiuhOQiohLh/iGQiAiGhEirQhGixAEi/QAEjABOiuQBLioCGh/QCGiACrhEQCxhGC/AEQDAAECuBOQCoBLB/CGQCACGBECrQBGCxgEC/QgEDAhOCuQhLCoiGB/QiGCAirBEQimBCi0AAIgWAAg"
      );
    this.shape_8.setTransform(3.425, -68.1723);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({
          state: [
            { t: this.shape_8 },
            { t: this.shape_7 },
            { t: this.shape_6 },
            { t: this.shape_5 },
            { t: this.shape_4 },
            { t: this.shape_3 },
            { t: this.shape_2 },
            { t: this.shape_1 },
            { t: this.shape }
          ]
        })
        .wait(70)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-114.4, -162.6, 228.9, 325.29999999999995);

  (lib.b_armR = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // t
    this.instance = new lib.t();
    this.instance.parent = this;
    this.instance.setTransform(-92.8, -81.2);

    this.text = new cjs.Text("", "30px 'VarelaRound-Regular'", "#1A1A1F");
    this.text.textAlign = "center";
    this.text.lineHeight = 38;
    this.text.parent = this;
    this.text.setTransform(-123.9, -105.55);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({ state: [{ t: this.text }, { t: this.instance }] })
        .wait(69)
    );

    // arm
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#501534")
      .s()
      .p("AiKECQhrg6gjh0QgjhzA6hrQA6hrB0gjQBzgjBrA6QBrA5AjB1QAjBzg6BrQg6Brh0AjQgsANgpAAQhGAAhDgkg");
    this.shape.setTransform(5.8867, 52.675);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics
      .f("#5F182D")
      .s()
      .p(
        "AjaIHQjjgxi4hkQj7iHinjhIKwnEQBxB2DNArIASAEIAjAFQArADAsgFQCJgSBShnQBriECpgTQCogSCFBqQCEBqATCpQASCphqCEQj0Exl8BbQiWAkiPAAQiEAAh/gfg"
      );
    this.shape_1.setTransform(16.5874, 34.1404);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics
      .f("#181821")
      .s()
      .p("AmWCaQgghZgMhFQgaioBkiJQBkiKCngaQCngaCJBkQCKBkAaCnIANAiQASAoAYAiQASAaAYAZIqvHFQhwiWg/iwg");
    this.shape_2.setTransform(-65.0433, -12.8683);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({ state: [{ t: this.shape_2 }, { t: this.shape_1 }, { t: this.shape }] })
        .wait(69)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-176.8, -165.2, 298.20000000000005, 254.29999999999998);

  (lib.b_armL = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // arm
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#501534")
      .s()
      .p("AiCEGQhtg2gnh0QgmhyA3htQA2hsBzgnQBzgmBsA2QBtA3AmBzQAnByg3BtQg2Bth0AmQgvAQguAAQhCAAg/ggg");
    this.shape.setTransform(61.6664, 30.1121);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics
      .f("#5F182D")
      .s()
      .p(
        "ArcNiICOssQCkgBCrh3IAPgKQAugfApg5QBRhwgTiCQgXioBmiIQBliICogXQCpgYCHBmQCIBmAYCpQA2GDjBFSQiUECjkCUQi9CCjHBDQjHBDjMAAQhJAAhKgJg"
      );
    this.shape_1.setTransform(57.6238, 0.0111);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics
      .f("#181821")
      .s()
      .p("AhVFuQhcgog3gmQiMhfgginQgfimBfiMQBgiNCngfQCmggCMBgIAiANQAqAPAoAGQAgAFAjAAIiOMqQi6gViphKg");
    this.shape_2.setTransform(-42.6906, 40.4859);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({ state: [{ t: this.shape_2 }, { t: this.shape_1 }, { t: this.shape }] })
        .wait(63)
    );

    // c_bottom
    this.instance = new lib.casta4("synched", 0);
    this.instance.parent = this;
    this.instance.setTransform(-56, 27.95, 1, 1, 0, 0, 0, 34.7, -0.5);

    this.timeline.addTween(
      cjs.Tween.get(this.instance)
        .wait(10)
        .to({ startPosition: 0 }, 0)
        .wait(10)
        .to({ startPosition: 0 }, 0)
        .wait(43)
    );

    // c_top
    this.instance_1 = new lib.casta3("synched", 0);
    this.instance_1.parent = this;
    this.instance_1.setTransform(-56.95, -6.05, 1, 1, 0, 0, 0, 33.4, 0.2);

    this.timeline.addTween(
      cjs.Tween.get(this.instance_1)
        .to(
          {
            regY: 0.3,
            rotation: 23.0001,
            x: -52.15,
            y: -6.1
          },
          10
        )
        .wait(7)
        .to({ startPosition: 0 }, 0)
        .to(
          {
            regY: 0.2,
            rotation: 0,
            x: -56.95,
            y: -6.05
          },
          3
        )
        .wait(10)
        .to({ startPosition: 0 }, 0)
        .to(
          {
            regX: 33.3,
            rotation: 12.9997,
            x: -54.2,
            y: -6.2
          },
          5
        )
        .wait(4)
        .to({ startPosition: 0 }, 0)
        .to(
          {
            regX: 33.4,
            rotation: 0,
            x: -56.95,
            y: -6.05
          },
          3
        )
        .wait(4)
        .to({ startPosition: 0 }, 0)
        .to(
          {
            regX: 33.3,
            rotation: 12.9997,
            x: -54.2,
            y: -6.2
          },
          4
        )
        .wait(3)
        .to({ startPosition: 0 }, 0)
        .to({ regX: 33.4, rotation: 0, x: -56.95, y: -6.05 }, 2)
        .wait(8)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-136.6, -87.4, 267.5, 174.9);

  (lib.a_head = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // eyes
    this.instance = new lib.a_eyes();
    this.instance.parent = this;
    this.instance.setTransform(49.2, -12.4);

    this.timeline.addTween(
      cjs.Tween.get(this.instance)
        .to({ regY: -0.1, scaleY: 0.81, y: -12.45 }, 5)
        .to(
          {
            regY: 0,
            scaleY: 1,
            y: -12.4
          },
          5
        )
        .wait(70)
    );

    // mouse
    this.instance_1 = new lib.Tween1("synched", 0);
    this.instance_1.parent = this;

    this.instance_2 = new lib.Tween2("synched", 0);
    this.instance_2.parent = this;

    this.instance_3 = new lib.a_mouse();
    this.instance_3.parent = this;
    this.instance_3.setTransform(50.95, -1.45);

    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#232736")
      .s()
      .p("AhPBQQgighAAgvQAAguAighQAhgiAuAAQAvAAAhAiQAiAhAAAuQAAAvgiAhQghAigvAAQguAAghgig");
    this.shape.setTransform(-49.675, -1.025);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics
      .f("#181821")
      .s()
      .p("AgmAnQgRgQAAgXQAAgWARgQQAQgRAWAAQAXAAAQARQARAQAAAWQAAAXgRAQQgQARgXAAQgWAAgQgRg");
    this.shape_1.setTransform(-44.975, -56.675);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics
      .f("#181821")
      .s()
      .p("AggDzIAAnlIBAAAIABHlg");
    this.shape_2.setTransform(-44.975, -32.325);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics
      .f("#181821")
      .s()
      .p("Ah8B9Qg0g0AAhJQAAhIA0g0QA0g0BIAAQBJAAA0A0QA0A0AABIQAABJg0A0Qg0A0hJAAQhIAAg0g0g");
    this.shape_3.setTransform(-47.325, -1.025);

    this.shape_4 = new cjs.Shape();
    this.shape_4.graphics
      .f("#2A2E40")
      .s()
      .p("AheBDQgTgaAPgmQAOgmAogcQAngcAogBQApAAASAaQATAbgOAmQgOAlgoAcQgnAcgoABIgDAAQgmAAgTgag");
    this.shape_4.setTransform(12.3082, -34.45);

    this.shape_5 = new cjs.Shape();
    this.shape_5.graphics
      .f("#181821")
      .s()
      .p("AmJGLQijijAAjoQAAjnCjikQCkijDlgBQDnABCjCjQCjCkAADnQAADoijCjQijCkjnAAQjlAAikikg");
    this.shape_5.setTransform(32.625, 0.35);

    this.shape_6 = new cjs.Shape();
    this.shape_6.graphics
      .f("#6B6B77")
      .s()
      .p("AmsG8Qixi4gBkEQAAkDCyi3QCyi4D6AAQD7AACyC4QCxC3AAEDQAAEEixC4QixC3j8AAQj6AAiyi3g");
    this.shape_6.setTransform(29.05, 0.325);

    this.shape_7 = new cjs.Shape();
    this.shape_7.graphics
      .f("#773A22")
      .s()
      .p(
        "AlcLzQifg+h5hxQh4hyhCiTQhDiYAAinQAAimBDiYQBCiUB4hwQB5hyCfg+QCkhBC4AAQC4AAClBBQCfA+B5ByQB4BwBCCUQBDCYAACmQAACnhDCYQhCCTh4ByQh5BxifA+QilBAi4AAQi3AAilhAg"
      );
    this.shape_7.setTransform(-1.425, 0);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({ state: [{ t: this.instance_1 }] })
        .to({ state: [{ t: this.instance_2 }] }, 5)
        .to(
          {
            state: [
              { t: this.shape_7 },
              { t: this.shape_6 },
              { t: this.shape_5 },
              { t: this.shape_4 },
              { t: this.shape_3 },
              { t: this.shape_2 },
              { t: this.shape_1 },
              { t: this.shape },
              { t: this.instance_3 }
            ]
          },
          5
        )
        .to({ state: [{ t: this.instance_1 }] }, 26)
        .to({ state: [{ t: this.instance_2 }] }, 5)
        .to(
          {
            state: [
              { t: this.shape_7 },
              { t: this.shape_6 },
              { t: this.shape_5 },
              { t: this.shape_4 },
              { t: this.shape_3 },
              { t: this.shape_2 },
              { t: this.shape_1 },
              { t: this.shape },
              { t: this.instance_3 }
            ]
          },
          5
        )
        .wait(34)
    );
    this.timeline.addTween(
      cjs.Tween.get(this.instance_1)
        .to({ _off: true }, 5)
        .wait(31)
        .to({ _off: false }, 0)
        .to({ _off: true }, 5)
        .wait(39)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-89.6, -81.9, 179.3, 163.9);

  (lib.a_body = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // body
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#6C1D2A")
      .s()
      .p("AgjCyQgQgQABgXIAAkVQgBgXAQgQQAOgRAVAAQAWAAAPARQAOAQAAAXIAAEVQAAAXgOAQQgPARgWAAQgVAAgOgRg");
    this.shape.setTransform(10.55, 7.225);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics
      .f("#6C1D2A")
      .s()
      .p("AgjCyQgQgQAAgXIAAkVQAAgXAQgQQAPgRAUAAQAWAAAPARQAPAQAAAXIAAEVQAAAXgPAQQgPARgWAAQgUAAgPgRg");
    this.shape_1.setTransform(28.675, 7.225);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics
      .f("#6C1D2A")
      .s()
      .p("AgkCyQgOgQAAgXIAAkVQAAgXAOgQQAPgRAVAAQAVAAAPARQAQAQgBAXIAAEVQABAXgQAQQgPARgVAAQgVAAgPgRg");
    this.shape_2.setTransform(46.8, 7.225);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics
      .f("#232736")
      .s()
      .p("Ah/CKQg2g6AAhQQAAhQA2g5QA1g5BKAAQBLAAA1A5QA2A5AABQQAABQg2A6Qg1A5hLAAQhKAAg1g5g");
    this.shape_3.setTransform(111.75, 7.225);

    this.shape_4 = new cjs.Shape();
    this.shape_4.graphics
      .f("#773A22")
      .s()
      .p(
        "AojQTQj8hqjCjDQjEjDhqj8QhukGgBkdIAAjKQABkhDNjNQBghhB+g1QCBg3CPAAIZLAAQDPAACTCTQCUCTAADQIAAGPQAAEdhvEGQhrD8jCDDQjDDDj9BqQkFBvkeAAQkdAAkGhvg"
      );
    this.shape_4.setTransform(0, 49.175);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({
          state: [{ t: this.shape_4 }, { t: this.shape_3 }, { t: this.shape_2 }, { t: this.shape_1 }, { t: this.shape }]
        })
        .wait(1)
    );

    // head
    this.instance = new lib.a_head();
    this.instance.parent = this;
    this.instance.setTransform(4.55, -82.6);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));
  }).prototype = getMCSymbolPrototype(lib.a_body, new cjs.Rectangle(-140.6, -164.5, 281.29999999999995, 329.1), null);

  (lib.a_armR = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib.ClipGroup_0();
    this.instance.parent = this;
    this.instance.setTransform(-23.05, 44.6, 1, 1, 0, 0, 0, 113.7, 73.4);

    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#181821")
      .s()
      .p("An6icQCDhUCJiOQB8iCCzgDQCzgECCB9QCBB8ADC0QAECyh9CCQikCriwB/g");
    this.shape.setTransform(86.2048, 35.5202);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics
      .f("#6C271F")
      .s()
      .p(
        "AB6LWQnHgvmGlaQjUi7iAjMIAAAAQgkg1gThCQgzirBWieQBWidCsgzQCQgqCLA4QCJA4BLB+IABgBIgBgCQBKBzB+BqQCvCVCpAQQCuARDGiAIIoKhQj4Cyj9BJQi7A3i+AAQhFAAhFgHg"
      );
    this.shape_1.setTransform(-23.0842, 44.4951);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics
      .f("#4D2718")
      .s()
      .p("AgaAxQgUgMgGgWQgHgVALgUQALgUAWgHQAVgGAVALQAUALAHAWQAGAWgLAUQgMAUgVAHQgIACgIAAQgNAAgNgHg");
    this.shape_2.setTransform(43.1852, -92.3898);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics
      .f("#4D2718")
      .s()
      .p("AgaAxQgVgMgFgWQgHgVALgUQALgUAXgHQAUgGAVALQAUALAGAWQAHAVgLAVQgLAUgXAHQgHACgIAAQgNAAgNgHg");
    this.shape_3.setTransform(29.25, -67.0898);

    this.shape_4 = new cjs.Shape();
    this.shape_4.graphics
      .f("#4D2718")
      .s()
      .p("AgaAxQgUgMgGgWQgHgVALgUQALgVAWgGQAWgGAUALQAUALAHAWQAGAWgLAUQgMAUgVAHQgIACgIAAQgNAAgNgHg");
    this.shape_4.setTransform(22.9352, -90.7898);

    this.shape_5 = new cjs.Shape();
    this.shape_5.graphics
      .f("#623422")
      .s()
      .p(
        "AAvIWQgmgOgQglQgQgiALgkQh0g0hthoQhqhngwhnQhKidA5ikQA4ijCVhFQCUhFCgA9QCiA/BKCdQAwBoAKCTQALCVgjB7QAiAOAPAiQASAlgOAmQgOAnglARQglARgngPQgOAoglARQgmARgmgOQgOAmgmASQgUAKgVAAQgRAAgRgGg"
      );
    this.shape_5.setTransform(49.1413, -63.8254);

    this.shape_6 = new cjs.Shape();
    this.shape_6.graphics
      .f("#74747E")
      .s()
      .p(
        "AA8ITQgrgPgSgpQgOgdAFggQAEgfATgYIlpsWIDjhqIFpMWQAfAAAaARQAcARANAdQAUApgQArQgPArgqATQgpATgrgQQgQArgqAUQgXAKgXAAQgSAAgTgHg"
      );
    this.shape_6.setTransform(87.9432, 10.9482);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({
          state: [
            { t: this.shape_6 },
            { t: this.shape_5 },
            { t: this.shape_4 },
            { t: this.shape_3 },
            { t: this.shape_2 },
            { t: this.shape_1 },
            { t: this.shape },
            { t: this.instance }
          ]
        })
        .wait(1)
    );
  }).prototype = getMCSymbolPrototype(lib.a_armR, new cjs.Rectangle(-136.8, -117.8, 273.70000000000005, 235.8), null);

  (lib.a = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // armR
    this.instance = new lib.a_armR();
    this.instance.parent = this;
    this.instance.setTransform(-141.5, -26.4, 0.9984, 0.9984, 52.6576, 0, 0, -99.4, 10.9);

    this.timeline.addTween(
      cjs.Tween.get(this.instance)
        .to(
          {
            regX: -99.5,
            regY: 10.8,
            scaleX: 0.9985,
            scaleY: 0.9985,
            rotation: 12.2878,
            x: -141.6
          },
          10
        )
        .to(
          {
            scaleX: 1,
            scaleY: 1,
            rotation: -10.9783,
            x: -141.65,
            y: -26.45
          },
          6
        )
        .wait(3)
        .to({ rotation: -3.4898 }, 3)
        .to({ rotation: -10.9783 }, 3)
        .wait(3)
        .to({ rotation: -3.4898 }, 3)
        .wait(7)
        .to(
          {
            scaleX: 0.9995,
            scaleY: 0.9995,
            rotation: -6.5608,
            y: -26.55
          },
          0
        )
        .to({ scaleX: 0.9971, scaleY: 0.9971, rotation: 34.8438 }, 10)
        .to(
          {
            scaleX: 0.9984,
            scaleY: 0.9984,
            rotation: 52.6537,
            x: -141.5,
            y: -26.45
          },
          6
        )
        .wait(3)
        .to({ regX: -99.4, regY: 10.9, rotation: 62.9995, x: -141.55, y: -26.35 }, 3)
        .to(
          {
            regX: -99.5,
            regY: 10.8,
            rotation: 52.6537,
            x: -141.5,
            y: -26.45
          },
          3
        )
        .wait(3)
        .to({ regX: -99.4, regY: 10.9, rotation: 62.9995, x: -141.55, y: -26.35 }, 3)
        .to(
          {
            regX: -99.5,
            regY: 10.8,
            rotation: 52.6537,
            x: -141.5,
            y: -26.45
          },
          3
        )
        .wait(4)
    );

    // body
    this.instance_1 = new lib.a_body();
    this.instance_1.parent = this;
    this.instance_1.setTransform(-57.65, 0);

    this.timeline.addTween(
      cjs.Tween.get(this.instance_1)
        .to({ rotation: 5.998 }, 19)
        .wait(3)
        .to(
          {
            regX: -0.1,
            rotation: 4.9993,
            x: -57.6
          },
          0
        )
        .wait(3)
        .to({ regX: 0, rotation: 5.998, x: -57.65 }, 0)
        .wait(6)
        .to(
          {
            rotation: 4.9993,
            x: -57.6
          },
          0
        )
        .wait(3)
        .to({ rotation: 5.998 }, 0)
        .wait(4)
        .to(
          {
            regX: -0.1,
            regY: -0.1,
            scaleX: 0.9996,
            scaleY: 0.9996,
            rotation: 5.9993,
            x: -57.65
          },
          0
        )
        .to(
          {
            regX: 0,
            regY: 0,
            scaleX: 0.9999,
            scaleY: 0.9999,
            rotation: 0.0009,
            x: -57.6,
            y: 0.05
          },
          19
        )
        .wait(3)
        .to(
          {
            regX: -0.1,
            regY: 0.1,
            scaleX: 1,
            scaleY: 1,
            rotation: 0.9994,
            x: -57.65
          },
          0
        )
        .wait(3)
        .to({ regY: 0, rotation: 0, y: 0 }, 0)
        .wait(3)
        .to(
          {
            regY: -0.1,
            y: -0.05
          },
          0
        )
        .wait(3)
        .to({ rotation: 0.9994 }, 0)
        .wait(3)
        .to(
          {
            regX: 0,
            regY: 0,
            rotation: 0,
            x: -57.6,
            y: 0.05
          },
          0
        )
        .wait(4)
    );

    // armL
    this.instance_2 = new lib.a_amrL();
    this.instance_2.parent = this;
    this.instance_2.setTransform(-0.25, -3.25, 1, 1, -63.4881, 0, 0, -66.7, -47.6);

    this.timeline.addTween(
      cjs.Tween.get(this.instance_2)
        .to(
          {
            scaleX: 0.9997,
            scaleY: 0.9997,
            rotation: 4.7921,
            y: -3.5
          },
          10
        )
        .to({ regX: -66.6, scaleX: 1, scaleY: 1, rotation: 31.2395, x: -0.2, y: -3.4 }, 6)
        .wait(3)
        .to(
          {
            regX: -66.5,
            rotation: 37.6907,
            x: -0.1
          },
          3
        )
        .to({ regX: -66.6, rotation: 31.2395, x: -0.2 }, 3)
        .wait(3)
        .to(
          {
            regX: -66.5,
            scaleX: 0.9983,
            scaleY: 0.9983,
            rotation: 37.6171
          },
          3
        )
        .to({ regX: -66.6, scaleX: 1, scaleY: 1, rotation: 31.2395 }, 3)
        .wait(4)
        .to(
          {
            regX: -66.5,
            scaleX: 0.9985,
            scaleY: 0.9985,
            rotation: 26.316,
            x: -0.25,
            y: -3.5
          },
          0
        )
        .to(
          {
            regX: -66.6,
            scaleX: 0.9983,
            scaleY: 0.9983,
            rotation: -41.8694,
            x: -0.3,
            y: -3.3
          },
          10
        )
        .to({ rotation: -60.9034, y: -3.4 }, 6)
        .wait(3)
        .to(
          {
            regX: -66.5,
            rotation: -56.1552,
            x: -0.2,
            y: -3.45
          },
          3
        )
        .to({ regX: -66.6, rotation: -60.9034, x: -0.3, y: -3.4 }, 3)
        .wait(3)
        .to(
          {
            regX: -66.5,
            scaleX: 0.9968,
            scaleY: 0.9968,
            rotation: -56.1693,
            x: -0.25,
            y: -3.45
          },
          3
        )
        .to({ regX: -66.6, scaleX: 0.9983, scaleY: 0.9983, rotation: -60.9034, x: -0.3, y: -3.4 }, 3)
        .wait(4)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-253.6, -197.4, 474.5, 415.8);

  (lib.b_armRM = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib.b_armR();
    this.instance.parent = this;
    this.instance.setTransform(115.9, 56.05, 1, 1, -3.7051, 0, 0, 88.2, 18);

    this.timeline.addTween(
      cjs.Tween.get(this.instance)
        .to(
          {
            regX: 88.3,
            regY: 17.9,
            rotation: 0,
            x: 116,
            y: 55.95
          },
          5
        )
        .to({ regX: 88.2, regY: 18, rotation: -3.7051, x: 115.9, y: 56.05 }, 5)
        .to(
          {
            regX: 88.3,
            regY: 17.9,
            rotation: 0,
            x: 116,
            y: 55.95
          },
          5
        )
        .to({ regX: 88.2, regY: 18, rotation: -3.7051, x: 115.9, y: 56.05 }, 5)
        .to(
          {
            regX: 88.3,
            regY: 17.9,
            rotation: 0,
            x: 116,
            y: 55.95
          },
          5
        )
        .to({ regX: 88.2, regY: 18, rotation: -3.7051, x: 115.9, y: 56.05 }, 5)
        .wait(2)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-155.8, -127.1, 309.5, 265.5);

  (lib.b = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // armL
    this.instance = new lib.b_armL();
    this.instance.parent = this;
    this.instance.setTransform(81.05, 8, 1, 1, 0, 0, 0, 92.4, -53.7);

    this.timeline.addTween(
      cjs.Tween.get(this.instance)
        .to(
          {
            regY: -53.6,
            scaleX: 0.9999,
            scaleY: 0.9999,
            rotation: 10.0005,
            y: 8.05
          },
          10
        )
        .wait(7)
        .to({ regY: -53.7, scaleX: 1, scaleY: 1, rotation: 0, y: 8 }, 3)
        .wait(10)
        .to(
          {
            regX: 92.3,
            rotation: 5.9982,
            x: 81
          },
          5
        )
        .wait(4)
        .to({ regX: 92.4, rotation: 0, x: 81.05 }, 3)
        .wait(4)
        .to(
          {
            regX: 92.3,
            rotation: 5.9982,
            x: 81
          },
          4
        )
        .wait(3)
        .to({ regX: 92.4, rotation: 0, x: 81.05 }, 2)
        .wait(8)
    );

    // body
    this.instance_1 = new lib.b_body();
    this.instance_1.parent = this;
    this.instance_1.setTransform(43.9, 0);

    this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(63));

    // armR
    this.instance_2 = new lib.b_armRM();
    this.instance_2.parent = this;
    this.instance_2.setTransform(-64.65, -65.35);

    this.timeline.addTween(cjs.Tween.get(this.instance_2).wait(63));
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-220.5, -181.3, 378.9, 344);

  (lib.bM = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib.b();
    this.instance.parent = this;
    this.instance.setTransform(160.8, 123.45, 1, 1, -0.9993, 0, 0, 127.5, 107.7);

    this.timeline.addTween(
      cjs.Tween.get(this.instance)
        .to(
          {
            regX: 127.4,
            regY: 107.6,
            rotation: 0,
            x: 160.75,
            y: 123.4
          },
          5
        )
        .wait(1)
        .to({ regX: 127.5, regY: 107.7, rotation: 1.0002 }, 0)
        .to(
          {
            regX: 127.4,
            regY: 107.6,
            rotation: 0
          },
          5
        )
        .wait(1)
        .to({ regX: 127.5, regY: 107.7, rotation: -0.9993, x: 160.8, y: 123.45 }, 0)
        .to(
          {
            regX: 127.4,
            regY: 107.6,
            rotation: 0,
            x: 160.75,
            y: 123.4
          },
          5
        )
        .wait(1)
        .to({ regX: 127.5, regY: 107.7, rotation: 1.0002 }, 0)
        .to(
          {
            regX: 127.4,
            regY: 107.6,
            rotation: 0
          },
          5
        )
        .wait(1)
        .to({ regX: 127.5, regY: 107.7, rotation: -0.9993, x: 160.8, y: 123.45 }, 0)
        .to(
          {
            regX: 127.4,
            regY: 107.6,
            rotation: 0,
            x: 160.75,
            y: 123.4
          },
          5
        )
        .wait(1)
        .to({ regX: 127.5, regY: 107.7, rotation: 1.0002 }, 0)
        .to(
          {
            regX: 127.4,
            regY: 107.6,
            rotation: 0
          },
          5
        )
        .wait(1)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-190.8, -169.9, 384.8, 351.70000000000005);

  // stage content:
  (lib.movie_HTML5Canvas = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // phone_form
    this.instance = new lib.phone_form("synched", 0);
    this.instance.parent = this;
    this.instance.setTransform(960, 663);
    this.instance._off = true;

    this.timeline.addTween(
      cjs.Tween.get(this.instance)
        .wait(54)
        .to({ _off: false }, 0)
        .wait(366)
    );

    // Layer_6 (mask)
    var mask = new cjs.Shape();
    mask._off = true;
    var mask_graphics_157 = new cjs.Graphics().p(
      "EgR5A0BQjCAAiIiJIAAAAQiJiJAAjBIAAAAMAAAhZbQAAjBCJiJIAAAAQCIiJDCAAIAAAAMAj0AAAQDBAACICJIAAAAQCJCJAADBIAAAAMAAABZbQAADBiJCJIAAAAQiJCJjBAAIAAAAg"
    );

    this.timeline.addTween(
      cjs.Tween.get(mask)
        .to({
          graphics: null,
          x: 0,
          y: 0
        })
        .wait(157)
        .to({ graphics: mask_graphics_157, x: 959.9998, y: 662.625 })
        .wait(263)
    );

    // Layer_5
    this.instance_1 = new lib.IMG_3F68175E4BAC1();
    this.instance_1.parent = this;
    this.instance_1.setTransform(812, 342, 0.2628, 0.2629);
    this.instance_1._off = true;

    let maskedShapeInstanceList = [this.instance_1];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(
      cjs.Tween.get(this.instance_1)
        .wait(157)
        .to({ _off: false }, 0)
        .wait(263)
    );

    // Layer_3 (mask)
    var mask_1 = new cjs.Shape();
    mask_1._off = true;
    var mask_1_graphics_157 = new cjs.Graphics().p(
      "EgR5A0BQjCAAiIiJIAAAAQiJiJAAjBIAAAAMAAAhZbQAAjBCJiJIAAAAQCIiJDCAAIAAAAMAj0AAAQDBAACICJIAAAAQCJCJAADBIAAAAMAAABZbQAADBiJCJIAAAAQiJCJjBAAIAAAAg"
    );

    this.timeline.addTween(
      cjs.Tween.get(mask_1)
        .to({
          graphics: null,
          x: 0,
          y: 0
        })
        .wait(157)
        .to({ graphics: mask_1_graphics_157, x: 959.9998, y: 663.025 })
        .wait(263)
    );

    // movie
    this.instance_2 = new lib.loading();
    this.instance_2.parent = this;
    this.instance_2.setTransform(914.65, 638.55, 0.653, 0.653, 0, 0, 0, 0.2, 0.1);

    this.instance_3 = new lib.screen();
    this.instance_3.parent = this;
    this.instance_3.setTransform(959.8, 942.25);
    this.instance_3._off = true;

    maskedShapeInstanceList = [this.instance_2, this.instance_3];

    for (let shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_1;
    }

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({ state: [] })
        .to({ state: [{ t: this.instance_3 }, { t: this.instance_2 }] }, 157)
        .to({ state: [{ t: this.instance_3 }] }, 27)
        .to({ state: [{ t: this.instance_3 }] }, 66)
        .to({ state: [{ t: this.instance_3 }] }, 11)
        .to({ state: [{ t: this.instance_3 }] }, 14)
        .to({ state: [{ t: this.instance_3 }] }, 20)
        .to({ state: [{ t: this.instance_3 }] }, 20)
        .to({ state: [{ t: this.instance_3 }] }, 31)
        .to({ state: [{ t: this.instance_3 }] }, 34)
        .wait(40)
    );
    this.timeline.addTween(
      cjs.Tween.get(this.instance_3)
        .wait(157)
        .to({ _off: false }, 0)
        .wait(93)
        .to({ y: 833.5 }, 11)
        .wait(14)
        .to({ y: 703.5 }, 20)
        .wait(20)
        .to({ y: 416.5 }, 31)
        .wait(74)
    );

    // tab
    this.instance_4 = new lib.tab();
    this.instance_4.parent = this;
    this.instance_4.setTransform(997, 419);
    this.instance_4._off = true;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_4)
        .wait(153)
        .to({ _off: false }, 0)
        .to({ _off: true }, 6)
        .wait(261)
    );

    // link
    this.instance_5 = new lib.link("synched", 0);
    this.instance_5.parent = this;
    this.instance_5.setTransform(960, 366.8);
    this.instance_5.alpha = 0;
    this.instance_5._off = true;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_5)
        .wait(88)
        .to({ _off: false }, 0)
        .to(
          {
            y: 403.6,
            alpha: 1
          },
          8
        )
        .wait(58)
        .to({ y: 406.6 }, 0)
        .to({ y: 403.6 }, 5)
        .wait(261)
    );

    // bar
    this.instance_6 = new lib.qr_barM("synched", 12);
    this.instance_6.parent = this;
    this.instance_6.setTransform(959.95, 589.4);
    this.instance_6.alpha = 0;
    this.instance_6._off = true;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_6)
        .wait(62)
        .to({ _off: false }, 0)
        .to(
          {
            alpha: 1,
            startPosition: 30
          },
          18
        )
        .wait(340)
    );

    // targeting
    this.instance_7 = new lib.qr_targeting("synched", 0);
    this.instance_7.parent = this;
    this.instance_7.setTransform(957.3, 670.55, 1.4, 1.4);
    this.instance_7.alpha = 0;
    this.instance_7._off = true;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_7)
        .wait(50)
        .to({ _off: false }, 0)
        .to(
          {
            scaleX: 1,
            scaleY: 1,
            x: 957.35,
            alpha: 1
          },
          4
        )
        .wait(366)
    );

    // Layer_12 (mask)
    var mask_2 = new cjs.Shape();
    mask_2._off = true;
    var mask_2_graphics_0 = new cjs.Graphics().p("EAEoBPmMAlsgaZMAjLAyPMglsAaZg");
    var mask_2_graphics_14 = new cjs.Graphics().p("EAFzBMTMAmRgZaMAh3AzBMgmRAZag");
    var mask_2_graphics_15 = new cjs.Graphics().p("EAF5BMVMAmNgZXMAh0Ay7MgmOAZYg");
    var mask_2_graphics_16 = new cjs.Graphics().p("EAF/BMFMAmTgZPMAhoAzEMgmTAZOg");
    var mask_2_graphics_17 = new cjs.Graphics().p("EAGHBLrMAmUgZMMAhlAzFMgmUAZMg");
    var mask_2_graphics_18 = new cjs.Graphics().p("EAGcBKrMAmigY4MAhKAzYMgmiAY3g");
    var mask_2_graphics_19 = new cjs.Graphics().p("EAHABJDMAm3gYYMAgfAzzMgm2AYYg");
    var mask_2_graphics_20 = new cjs.Graphics().p("EAH1BGtMAnRgXsMAflA0XMgnRAXsg");
    var mask_2_graphics_21 = new cjs.Graphics().p("EAI9BDhMAnzgW0MAeZA1EMgnzAWzg");
    var mask_2_graphics_22 = new cjs.Graphics().p("EAKmA/JMAokgVbMAckA2FMgolAVbg");
    var mask_2_graphics_23 = new cjs.Graphics().p("EAMrA5vMApagTzMAaYA3MMgpaATzg");
    var mask_2_graphics_24 = new cjs.Graphics().p("EAPeAy+MAqZgRmMAXdA4hMgqZARmg");
    var mask_2_graphics_25 = new cjs.Graphics().p("EATHAq1MArdgOzMATvA58MgreAOzg");
    var mask_2_graphics_26 = new cjs.Graphics().p("EAXgAhuMAsdgLjMAPZA7RMgsdALjg");
    var mask_2_graphics_27 = new cjs.Graphics().p("AbbaBMAtGgIyMALtA8HMgtGAIyg");
    var mask_2_graphics_28 = new cjs.Graphics().p("Ae1TyMAthgGaMAIjA8rMgthAGag");
    var mask_2_graphics_29 = new cjs.Graphics().p("EAhsAO4MAtwgEcMAF7A8/MgtwAEdg");
    var mask_2_graphics_30 = new cjs.Graphics().p("EAj3ALJMAt4gDBMAEBA9KMgt4ADBg");
    var mask_2_graphics_31 = new cjs.Graphics().p("EAlnAIVMAt9gB1MACdA9QMgt9AB1g");
    var mask_2_graphics_32 = new cjs.Graphics().p("EAm2AGWMAt/gBCMABYA9TMgt/ABCg");
    var mask_2_graphics_33 = new cjs.Graphics().p("EAnuAFBMAuAgAdMAAmA9UMguAAAcg");
    var mask_2_graphics_34 = new cjs.Graphics().p("EAoJAEPMAuAgANMAASA9UMguAAAOg");
    var mask_2_graphics_35 = new cjs.Graphics().p("EAobAD1MAuAgABMAACA9UMguAAABg");
    var mask_2_graphics_36 = new cjs.Graphics().p("EAofADsMAuAgABMAAAA9VMguAAAAg");
    var mask_2_graphics_37 = new cjs.Graphics().p("EAogBA/MAAAg9UMAuAAAAMAAAA9Ug");

    this.timeline.addTween(
      cjs.Tween.get(mask_2)
        .to({
          graphics: mask_2_graphics_0,
          x: 495.897,
          y: 830.8697
        })
        .wait(14)
        .to({
          graphics: mask_2_graphics_14,
          x: 498.7132,
          y: 814.7678
        })
        .wait(1)
        .to({
          graphics: mask_2_graphics_15,
          x: 498.5525,
          y: 814.4511
        })
        .wait(1)
        .to({
          graphics: mask_2_graphics_16,
          x: 498.6323,
          y: 813.6037
        })
        .wait(1)
        .to({
          graphics: mask_2_graphics_17,
          x: 499.2199,
          y: 811.2215
        })
        .wait(1)
        .to({
          graphics: mask_2_graphics_18,
          x: 499.9975,
          y: 806.6075
        })
        .wait(1)
        .to({
          graphics: mask_2_graphics_19,
          x: 501.4287,
          y: 798.9712
        })
        .wait(1)
        .to({ graphics: mask_2_graphics_20, x: 503.524, y: 787.5814 })
        .wait(1)
        .to({
          graphics: mask_2_graphics_21,
          x: 506.5215,
          y: 771.6344
        })
        .wait(1)
        .to({ graphics: mask_2_graphics_22, x: 510.1804, y: 750.238 })
        .wait(1)
        .to({
          graphics: mask_2_graphics_23,
          x: 514.8946,
          y: 722.6683
        })
        .wait(1)
        .to({
          graphics: mask_2_graphics_24,
          x: 520.4305,
          y: 687.8767
        })
        .wait(1)
        .to({
          graphics: mask_2_graphics_25,
          x: 526.6825,
          y: 644.9392
        })
        .wait(1)
        .to({
          graphics: mask_2_graphics_26,
          x: 533.4112,
          y: 595.0559
        })
        .wait(1)
        .to({
          graphics: mask_2_graphics_27,
          x: 539.0135,
          y: 551.2272
        })
        .wait(1)
        .to({
          graphics: mask_2_graphics_28,
          x: 543.2653,
          y: 514.9121
        })
        .wait(1)
        .to({ graphics: mask_2_graphics_29, x: 546.304, y: 485.5576 })
        .wait(1)
        .to({
          graphics: mask_2_graphics_30,
          x: 548.8402,
          y: 462.7186
        })
        .wait(1)
        .to({ graphics: mask_2_graphics_31, x: 550.4546, y: 445.326 })
        .wait(1)
        .to({
          graphics: mask_2_graphics_32,
          x: 551.6639,
          y: 432.8791
        })
        .wait(1)
        .to({ graphics: mask_2_graphics_33, x: 552.385, y: 424.4448 })
        .wait(1)
        .to({
          graphics: mask_2_graphics_34,
          x: 553.0674,
          y: 419.5529
        })
        .wait(1)
        .to({
          graphics: mask_2_graphics_35,
          x: 553.2841,
          y: 416.9236
        })
        .wait(1)
        .to({
          graphics: mask_2_graphics_36,
          x: 553.4772,
          y: 416.0004
        })
        .wait(1)
        .to({ graphics: mask_2_graphics_37, x: 553.575, y: 415.9 })
        .wait(383)
    );

    // bg_mask
    this.instance_8 = new lib.qr();
    this.instance_8.parent = this;
    this.instance_8.setTransform(960.15, 636, 0.7456, 0.7456, 0, 0, 0, 0.2, 1.2);

    this.shape = new cjs.Shape();
    this.shape.graphics
      .lf(["#323248", "#272739"], [0.004, 1], 81.6, -34.9, -30.6, -9.6)
      .s()
      .p("AtS3bQDvg0D7AAQHoAAFwCwQCmBQBdBjQBfBlAABlQABA+glA7MgaAAl5g");
    this.shape.setTransform(307.3482, 538.604, 0.7454, 0.7454);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics
      .lf(["#323248", "#272739"], [0.004, 1], 46.2, 33.2, 17.4, 23.3)
      .s()
      .p("Auc56QBSgRBJgLIgGgXIG2jwIRJEiIAbA8QAhBLAbBOQBVD3gICtQAABBgeA+IgGALMgbrAq6QgMAugdAug");
    this.shape_1.setTransform(312.8898, 581.9876, 0.7454, 0.7454);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics
      .lf(["#323248", "#272739"], [0.004, 1], -3.8, -15.4, 1.2, 7.6)
      .s()
      .p("AouCrQggAAgXgLQgYgKgBgQIBnkLQATglA2AAIP8AAQAiAAAYAMQAYAMgBAQIgwEMQgBAOgVAKQgUAJgeAAg");
    this.shape_2.setTransform(593.8058, 700.9654, 0.7448, 0.7448);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics
      .lf(["#323248", "#272739"], [0.824, 1], -15.9, 14.2, 33.4, -19.6)
      .s()
      .p("ApFDIQghAAgYgLQgZgMgCgRIAThSIAngJIAxjFQgCgRAWgMQAWgLAhAAIPXAAIB/gfIAjA9QAEAHgBAHIgvEgQgBAPgVALQgWAKgfAAg");
    this.shape_3.setTransform(594.3686, 705.1179, 0.7448, 0.7448);

    this.shape_4 = new cjs.Shape();
    this.shape_4.graphics
      .lf(["#323248", "#272739"], [0.004, 1], -3.5, -14.5, 1.2, 7.1)
      .s()
      .p("AodCrQggAAgXgLQgYgKgCgQICfkLQATglA1AAIOlAAQAiAAAYAMQAYAMgCAQIhmEMQgBAOgVAKQgVAJgdAAg");
    this.shape_4.setTransform(486.2878, 700.9654, 0.7448, 0.7448);

    this.shape_5 = new cjs.Shape();
    this.shape_5.graphics
      .lf(["#323248", "#272739"], [0.824, 1], -15.5, 13.9, 32.8, -19.2)
      .s()
      .p("Ao1DIQggAAgZgLQgYgMgCgRIAThSIAngJIBnjFQgCgRAXgMQAVgLAhAAIOAAAIB/gfIAjA9QAEAHgBAHIhmEgQgBAPgWALQgVAKgfAAg");
    this.shape_5.setTransform(486.8638, 705.1179, 0.7448, 0.7448);

    this.shape_6 = new cjs.Shape();
    this.shape_6.graphics
      .lf(["#323248", "#272739"], [0.004, 1], -3.6, -14.8, 1.1, 7)
      .s()
      .p("ApPCrQgfAAgYgLQgXgKgCgQIC/kLQATglA2AAIPmAAQAiAAAYAMQAYAMgBAQIidEMQgCAOgUAKQgVAJgeAAg");
    this.shape_6.setTransform(378.5091, 700.9654, 0.7448, 0.7448);

    this.shape_7 = new cjs.Shape();
    this.shape_7.graphics
      .lf(["#323248", "#272739"], [0.824, 1], -17, 14.1, 34.4, -21.1)
      .s()
      .p("ApmDIQghAAgYgLQgZgMgCgRIAThSIAngJICJjFQgCgRAWgMQAWgLAhAAIPBAAIB/gfIAjA9QAEAHgBAHIicEgQgCAPgVALQgWAKgfAAg");
    this.shape_7.setTransform(379.0719, 705.1179, 0.7448, 0.7448);

    this.shape_8 = new cjs.Shape();
    this.shape_8.graphics
      .lf(["#323248", "#272739"], [0.004, 1], -3.6, -14.8, 1.2, 7.3)
      .s()
      .p("AoCCrQggAAgXgLQgYgKgBgQIBnkLQATglA1AAIOlAAQAiAAAYAMQAYAMgBARIgwELQgBAOgVAKQgUAJgeAAg");
    this.shape_8.setTransform(602.4832, 665.1385, 0.7448, 0.7448);

    this.shape_9 = new cjs.Shape();
    this.shape_9.graphics
      .lf(["#323248", "#272739"], [0.824, 1], -15, 13.6, 31.6, -18.3)
      .s()
      .p(
        "AoZDIQghAAgZgLQgYgMgCgRIAThSIAngJIAxjFQgCgRAWgLQAWgMAhAAIN/AAIB/gfIAjA9IAAAAQAEAGgBAIIgvEgQgBAPgVALQgWAKgfAAg"
      );
    this.shape_9.setTransform(603.0454, 669.3096, 0.7448, 0.7448);

    this.shape_10 = new cjs.Shape();
    this.shape_10.graphics
      .lf(["#323248", "#272739"], [0.004, 1], -3.5, -14.2, 1.1, 7.1)
      .s()
      .p("AoICrQgfAAgYgLQgXgKgCgQICekLQAUglA1AAIN5AAQAiAAAYAMQAYAMgCARIhmELQgBAOgVAKQgVAJgdAAg");
    this.shape_10.setTransform(500.7005, 665.1385, 0.7448, 0.7448);

    this.shape_11 = new cjs.Shape();
    this.shape_11.graphics
      .lf(["#323248", "#272739"], [0.824, 1], -15.1, 13.7, 31.8, -18.5)
      .s()
      .p("AofDIQghAAgYgLQgZgMgBgRIAThSIAmgJIBojFQgCgRAWgLQAWgMAhAAINUAAIB/gfIAiA9QAFAGgBAIIhmEgQgBAPgWALQgWAKgeAAg");
    this.shape_11.setTransform(501.2626, 669.3096, 0.7448, 0.7448);

    this.shape_12 = new cjs.Shape();
    this.shape_12.graphics
      .lf(["#323248", "#272739"], [0.004, 1], -3.5, -14.4, 1.1, 7)
      .s()
      .p("AozCrQggAAgXgLQgYgKgBgQIC/kLQAUglA0AAIOwAAQAiAAAYAMQAYAMgCARIiSELQgBAOgVAKQgUAJgeAAg");
    this.shape_12.setTransform(397.4095, 665.1385, 0.7448, 0.7448);

    this.shape_13 = new cjs.Shape();
    this.shape_13.graphics
      .lf(["#323248", "#272739"], [0.824, 1], -16.2, 14.1, 33.4, -20)
      .s()
      .p("ApLDIQghAAgYgLQgZgMgBgRIAThSIAmgJICJjFQgCgRAWgLQAWgMAhAAIOLAAIB+gfIAjA9QAEAHAAAHIiSEgQgCAPgVALQgWAKgeAAg");
    this.shape_13.setTransform(397.9855, 669.3096, 0.7448, 0.7448);

    this.shape_14 = new cjs.Shape();
    this.shape_14.graphics
      .lf(["#323248", "#272739"], [0.004, 1], -3.4, -14.2, 1.2, 7.2)
      .s()
      .p("AnbCrQggAAgXgLQgYgKgCgQIBzkLQATglA1AAINNAAQAiAAAYAMQAYAMgBAQIgwEMQgBAOgVAKQgUAJgeAAg");
    this.shape_14.setTransform(612.371, 629.3488, 0.7448, 0.7448);

    this.shape_15 = new cjs.Shape();
    this.shape_15.graphics
      .lf(["#323248", "#272739"], [0.824, 1], -13.8, 13.2, 30.3, -17.1)
      .s()
      .p("AnzDIQggAAgZgMQgYgMgCgQIAThSIAngJIA7jFQgBgRAWgLQAWgMAgAAIMoAAIB/gfIAjA9QAEAHgBAGIgvEhQgBAQgWAJQgVALgfAAg");
    this.shape_15.setTransform(612.9331, 633.5013, 0.7448, 0.7448);

    this.shape_16 = new cjs.Shape();
    this.shape_16.graphics
      .lf(["#323248", "#272739"], [0.004, 1], -3.5, -14.2, 1.1, 6.9)
      .s()
      .p("An3CrQggAAgXgLQgYgKgBgQICTkLQATglA1AAINkAAQAiAAAXAMQAYAMgBAQIhmEMQgBAOgVAKQgVAJgdAAg");
    this.shape_16.setTransform(514.6663, 629.3488, 0.7448, 0.7448);

    this.shape_17 = new cjs.Shape();
    this.shape_17.graphics
      .lf(["#323248", "#272739"], [0.824, 1], -14.9, 13.1, 31, -18.4)
      .s()
      .p("AoODIQghAAgYgMQgZgMgCgQIAThSIAngJIBdjFQgCgRAWgLQAWgMAhAAIM+AAIB+gfIAjA9QAEAIAAAFIhmEhQgCAQgVAJQgWALgeAAg");
    this.shape_17.setTransform(515.2423, 633.5013, 0.7448, 0.7448);

    this.shape_18 = new cjs.Shape();
    this.shape_18.graphics
      .lf(["#323248", "#272739"], [0.004, 1], -3.4, -14.2, 1, 6.5)
      .s()
      .p("AoCCrQggAAgXgLQgYgKgBgQICpkLQATglA1AAINjAAQAiAAAYAMQAYAMgBAQIiSEMQgCAOgUAKQgVAJgeAAg");
    this.shape_18.setTransform(417.1292, 629.3488, 0.7448, 0.7448);

    this.shape_19 = new cjs.Shape();
    this.shape_19.graphics
      .lf(["#323248", "#272739"], [0.824, 1], -15.6, 12.8, 31, -19.1)
      .s()
      .p(
        "AoZDIQghAAgZgMQgYgMgCgQIAThSIAngJIBzjFQgCgRAWgLQAWgMAhAAIM9AAIB/gfIAjA9IAAAAQAEAHgBAGIiSEhQgBAQgVAJQgWALgfAAg"
      );
    this.shape_19.setTransform(417.6913, 633.5013, 0.7448, 0.7448);

    this.shape_20 = new cjs.Shape();
    this.shape_20.graphics
      .lf(["#323248", "#272739"], [0.004, 1], -3.4, -14.1, 1.1, 6.9)
      .s()
      .p("AnGCrQgfAAgYgLQgXgKgCgQIBokLQATglA1AAIMsAAQAiAAAYAMQAYAMgBAQIg7EMQgBAOgVAKQgUAJgeAAg");
    this.shape_20.setTransform(621.0484, 593.5219, 0.7448, 0.7448);

    this.shape_21 = new cjs.Shape();
    this.shape_21.graphics
      .lf(["#323248", "#272739"], [0.824, 1], -13.8, 12.5, 29, -16.9)
      .s()
      .p("AndDIQggAAgZgLQgYgMgCgRIAThSIAngJIAwjFQgCgRAXgLQAWgMAgAAIMHAAIB/gfIAjA9QAEAHgBAHIg6EgQgBAPgWALQgVAKgfAAg");
    this.shape_21.setTransform(621.6059, 597.693, 0.7448, 0.7448);

    this.shape_22 = new cjs.Shape();
    this.shape_22.graphics
      .lf(["#323248", "#272739"], [0.004, 1], -3.4, -14, 1, 6.4)
      .s()
      .p("AnFCrQggAAgXgLQgYgKgCgQICJkLQAHgUAGgGQAKgLAbAAIMiAAQAhAAAYAMQAYAMgBAQIhmEMQgCAOgUAKQgVAJgeAAg");
    this.shape_22.setTransform(528.6507, 593.5219, 0.7448, 0.7448);

    this.shape_23 = new cjs.Shape();
    this.shape_23.graphics
      .lf(["#323248", "#272739"], [0.824, 1], -14, 12.2, 28.8, -17.2)
      .s()
      .p("AndDIQggAAgZgLQgZgMgBgRIAThSIAmgJIBSjFQgBgRAVgLQAWgMAiAAILlAAIB/gfIAjA9QADAGAAAIIhmEgQgBAPgVALQgXAKgeAAg");
    this.shape_23.setTransform(529.2247, 597.693, 0.7448, 0.7448);

    this.shape_24 = new cjs.Shape();
    this.shape_24.graphics
      .lf(["#323248", "#272739"], [0.004, 1], -3.3, -13.7, 1, 6.4)
      .s()
      .p("AnhCrQggAAgXgLQgYgKgBgQICpkLQAUglA0AAIMiAAQAhAAAYAMQAYAMgBAQIiSEMQgBAOgVAKQgVAJgeAAg");
    this.shape_24.setTransform(436.4206, 593.5219, 0.7448, 0.7448);

    this.shape_25 = new cjs.Shape();
    this.shape_25.graphics
      .lf(["#323248", "#272739"], [0.824, 1], -14.8, 12.3, 29.7, -18.2)
      .s()
      .p("An4DIQghAAgZgLQgYgMgCgRIAThSIAngJIBzjFQgCgRAWgLQAWgMAhAAIL7AAIB/gfIAjA9QAEAHAAAHIiSEgQgCAPgVALQgWAKgeAAg");
    this.shape_25.setTransform(436.9966, 597.693, 0.7448, 0.7448);

    this.shape_26 = new cjs.Shape();
    this.shape_26.graphics
      .lf(["#323248", "#272739"], [0.004, 1], -5.1, -54.1, 2.8, 29.6)
      .s()
      .p("A4WGoQhPAAg4gbQg4gbAAgnIF3qVQAAgmA4gbQA4gbBPAAMAq2AAAQBVgBA5AfQA5AegJApIizKVQgHAjg3AYQg2AZhKAAg");
    this.shape_26.setTransform(543.8457, 546.7775, 0.7449, 0.7449);

    this.shape_27 = new cjs.Shape();
    this.shape_27.graphics
      .lf(["#323248", "#272739"], [0.004, 1], 30.7, 39.2, 5.2, 6.7)
      .s()
      .p("A5UHJQhSAAg6gdQg7gdAAgqIGFrJQAAgpA6geQA6gdBTAAMAskAAAQBYAAA7AhQA7AggIAtIi5LJQgIAmg4AaQg5AahNAAg");
    this.shape_27.setTransform(543.8442, 546.7775, 0.7449, 0.7449);

    this.shape_28 = new cjs.Shape();
    this.shape_28.graphics
      .lf(["#323248", "#272739"], [0.004, 1], 69.5, 228.4, -24.7, -60.2)
      .s()
      .p("Eg3QAY4MAAAgxvMBm7AAAQDXAACRBxQCQBxgVCYMgFYAm+QgSCEiLBaQiLBZi7AAg");
    this.shape_28.setTransform(507.5934, 619.6874, 0.7454, 0.7454);

    this.shape_29 = new cjs.Shape();
    this.shape_29.graphics
      .lf(["#323248", "#272739"], [0.004, 1], 37.2, 303.7, 20.1, 174.4)
      .s()
      .p("Eg3QAjEMAAAhGHMBm7AAAQDXABCRCfQCQCfgVDWMgF2A7yQgFA2gpAlQgoAlg3AAg");
    this.shape_29.setTransform(507.5934, 668.2516, 0.7454, 0.7454);

    this.shape_30 = new cjs.Shape();
    this.shape_30.graphics
      .lf(["#323248", "#272739"], [0.004, 1], 0, 81.5, 0, -13.6)
      .s()
      .p("EiV/ALMIAA2XMEr/AAAIAAWXg");
    this.shape_30.setTransform(959.7166, 889.0659, 0.7456, 0.7456);

    this.shape_31 = new cjs.Shape();
    this.shape_31.graphics
      .lf(["#323248", "#272739"], [0.004, 1], 27.9, 227.4, -7, -56.8)
      .s()
      .p("EgiwAGtIAAtZMBFhAAAIAANZg");
    this.shape_31.setTransform(1509.3045, 803.4899, 0.7454, 0.7454);

    this.shape_32 = new cjs.Shape();
    this.shape_32.graphics
      .lf(["#323248", "#272739"], [0.004, 1], 76.6, 100, 13.4, -8.4)
      .s()
      .p("AjZFrQiahCh3h3Qh3h3hBiZQhEigAAivIXNAAIAAJNQh4B/ifBHQilBIi2AAQiuAAighDg");
    this.shape_32.setTransform(1619.7947, 169.3218, 0.7454, 0.7454);

    this.shape_33 = new cjs.Shape();
    this.shape_33.graphics
      .lf(["#323248", "#272739"], [0.004, 1], 80.1, 12.8, -43.9, -7)
      .s()
      .p("Ap4DnIAAnNITxAAIAAHNg");
    this.shape_33.setTransform(1423.1523, 772.8157, 0.7454, 0.7454);

    this.shape_34 = new cjs.Shape();
    this.shape_34.graphics
      .lf(["#323248", "#272739"], [0.004, 1], 166.3, 594.6, 28.5, 101.9)
      .s()
      .p("EgG3BDyMAAAiHjINvAAMAAACHjg");
    this.shape_34.setTransform(1628.5534, 482.511, 0.7454, 0.7454);

    this.shape_35 = new cjs.Shape();
    this.shape_35.graphics
      .lf(["#323248", "#272739"], [0.004, 1], 18.2, 33.6, 178.1, 327.8)
      .s()
      .p("EgeNA0TMAAAholMA8bAAAMAAABolg");
    this.shape_35.setTransform(388.1147, 386.7429, 0.7454, 0.7454);

    this.shape_36 = new cjs.Shape();
    this.shape_36.graphics
      .lf(["#323248", "#272739"], [0.004, 1], -14, -465.5, -1.3, -41.7)
      .s()
      .p("EgqJA9oMAAAh7PMBUSAAAMAAAB7Pg");
    this.shape_36.setTransform(445.0277, 431.2632, 0.7454, 0.7454);

    this.shape_37 = new cjs.Shape();
    this.shape_37.graphics
      .lf(["#323248", "#272739"], [0.004, 1], 81, 42.6, -1.3, -3.7)
      .s()
      .p("Aj+EsIAAhyIhqAAIAAnlILRAAIAAHlIhqAAIAAByg");
    this.shape_37.setTransform(1110.9321, 604.1826, 0.7454, 0.7454);

    this.shape_38 = new cjs.Shape();
    this.shape_38.graphics
      .lf(["#323248", "#272739"], [0.004, 1], 128.5, 72.2, -0.3, -0.2)
      .s()
      .p("AnfHgQjHjHAAkZQAAkYDHjHQDHjHEYAAQEZAADHDHQDHDHAAEYQAAEZjHDHQjHDHkZAAQkYAAjHjHg");
    this.shape_38.setTransform(1317.3209, 603.8844, 0.7454, 0.7454);

    this.shape_39 = new cjs.Shape();
    this.shape_39.graphics
      .lf(["#323248", "#272739"], [0.004, 1], -122.8, -226.5, 241, 444.9)
      .s()
      .p("EgmFARnQg1AAgmglQglgnAAg1IAA/LQAAg1AlgnQAmglA1AAMBMLAAAQA1AAAmAlQAlAmAAA2IAAfLQAAA2glAmQgmAlg1AAg");
    this.shape_39.setTransform(1220.9192, 604.1826, 0.7454, 0.7454);

    this.shape_40 = new cjs.Shape();
    this.shape_40.graphics
      .f("#323248")
      .s()
      .p("EiV/BUYMAAAiovMEr/AAAMAAACovg");
    this.shape_40.setTransform(959.7166, 539.8687, 0.7456, 0.7456);

    maskedShapeInstanceList = [
      this.instance_8,
      this.shape,
      this.shape_1,
      this.shape_2,
      this.shape_3,
      this.shape_4,
      this.shape_5,
      this.shape_6,
      this.shape_7,
      this.shape_8,
      this.shape_9,
      this.shape_10,
      this.shape_11,
      this.shape_12,
      this.shape_13,
      this.shape_14,
      this.shape_15,
      this.shape_16,
      this.shape_17,
      this.shape_18,
      this.shape_19,
      this.shape_20,
      this.shape_21,
      this.shape_22,
      this.shape_23,
      this.shape_24,
      this.shape_25,
      this.shape_26,
      this.shape_27,
      this.shape_28,
      this.shape_29,
      this.shape_30,
      this.shape_31,
      this.shape_32,
      this.shape_33,
      this.shape_34,
      this.shape_35,
      this.shape_36,
      this.shape_37,
      this.shape_38,
      this.shape_39,
      this.shape_40
    ];

    for (let shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_2;
    }

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({
          state: [
            { t: this.shape_40 },
            { t: this.shape_39 },
            { t: this.shape_38 },
            { t: this.shape_37 },
            { t: this.shape_36 },
            { t: this.shape_35 },
            { t: this.shape_34 },
            { t: this.shape_33 },
            { t: this.shape_32 },
            { t: this.shape_31 },
            { t: this.shape_30 },
            { t: this.shape_29 },
            { t: this.shape_28 },
            { t: this.shape_27 },
            { t: this.shape_26 },
            { t: this.shape_25 },
            { t: this.shape_24 },
            { t: this.shape_23 },
            { t: this.shape_22 },
            { t: this.shape_21 },
            { t: this.shape_20 },
            { t: this.shape_19 },
            { t: this.shape_18 },
            { t: this.shape_17 },
            { t: this.shape_16 },
            { t: this.shape_15 },
            { t: this.shape_14 },
            { t: this.shape_13 },
            { t: this.shape_12 },
            { t: this.shape_11 },
            { t: this.shape_10 },
            { t: this.shape_9 },
            { t: this.shape_8 },
            { t: this.shape_7 },
            { t: this.shape_6 },
            { t: this.shape_5 },
            { t: this.shape_4 },
            { t: this.shape_3 },
            { t: this.shape_2 },
            { t: this.shape_1 },
            { t: this.shape },
            { t: this.instance_8 }
          ]
        })
        .wait(420)
    );

    // phone
    this.instance_9 = new lib.phone();
    this.instance_9.parent = this;
    this.instance_9.setTransform(774.4, 1439, 1, 1, -34.9923);

    this.timeline.addTween(
      cjs.Tween.get(this.instance_9)
        .wait(14)
        .to(
          {
            scaleX: 0.9985,
            scaleY: 0.9985,
            rotation: -33.5803,
            x: 781.85,
            y: 1407.95
          },
          0
        )
        .to({ scaleX: 1, scaleY: 1, rotation: 0, x: 960, y: 663 }, 23, cjs.Ease.cubicInOut)
        .wait(383)
    );

    // a
    this.instance_10 = new lib.a();
    this.instance_10.parent = this;
    this.instance_10.setTransform(984.15, 558.65, 0.4994, 0.4994, 22.5407, 0, 0, -27.4, 30.5);
    this.instance_10.alpha = 0;
    this.instance_10._off = true;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_10)
        .wait(109)
        .to({ _off: false }, 0)
        .to(
          {
            regX: -27.7,
            regY: 30.4,
            rotation: 22.5374,
            x: 1150.95,
            y: 553,
            alpha: 1
          },
          19
        )
        .wait(292)
    );

    // b
    this.instance_11 = new lib.bM();
    this.instance_11.parent = this;
    this.instance_11.setTransform(1074.4, 808.5, 0.4993, 0.4993, -26.8501, 0, 0, 145.8, 149.5);
    this.instance_11.alpha = 0;
    this.instance_11._off = true;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_11)
        .wait(117)
        .to({ _off: false }, 0)
        .to(
          {
            regY: 149.6,
            rotation: -26.8125,
            x: 857.4,
            alpha: 1
          },
          19
        )
        .wait(284)
    );

    // bg
    this.instance_12 = new lib.qr();
    this.instance_12.parent = this;
    this.instance_12.setTransform(960, 668.5);

    this.shape_41 = new cjs.Shape();
    this.shape_41.graphics
      .lf(["#323248", "#272739"], [0.004, 1], 81.6, -34.9, -30.6, -9.6)
      .s()
      .p("AtS3bQDvg0D7AAQHoAAFwCwQCmBQBdBjQBfBlAABlQABA+glA7MgaAAl5g");
    this.shape_41.setTransform(85.05, 538.575);

    this.shape_42 = new cjs.Shape();
    this.shape_42.graphics
      .lf(["#323248", "#272739"], [0.004, 1], 46.2, 33.2, 17.4, 23.3)
      .s()
      .p("Auc56QBSgRBJgLIgGgXIG2jwIRJEiIAbA8QAhBLAbBOQBVD3gICtQAABBgeA+IgGALMgbrAq6QgMAugdAug");
    this.shape_42.setTransform(92.4842, 596.775);

    this.shape_43 = new cjs.Shape();
    this.shape_43.graphics
      .lf(["#323248", "#272739"], [0.004, 1], -3.8, -15.4, 1.2, 7.6)
      .s()
      .p("AouCrQggAAgXgLQgYgKgBgQIBnkLQATglA2AAIP8AAQAiAAAYAMQAYAMgBAQIgwEMQgBAOgVAKQgUAJgeAAg");
    this.shape_43.setTransform(469.7044, 756.975);

    this.shape_44 = new cjs.Shape();
    this.shape_44.graphics
      .lf(["#323248", "#272739"], [0.824, 1], -15.9, 14.2, 33.4, -19.6)
      .s()
      .p("ApFDIQghAAgYgLQgZgMgCgRIAThSIAngJIAxjFQgCgRAWgMQAWgLAhAAIPXAAIB/gfIAjA9QAEAHgBAHIgvEgQgBAPgVALQgWAKgfAAg");
    this.shape_44.setTransform(470.46, 762.55);

    this.shape_45 = new cjs.Shape();
    this.shape_45.graphics
      .lf(["#323248", "#272739"], [0.004, 1], -3.5, -14.5, 1.2, 7.1)
      .s()
      .p("AodCrQggAAgXgLQgYgKgCgQICfkLQATglA1AAIOlAAQAiAAAYAMQAYAMgCAQIhmEMQgBAOgVAKQgVAJgdAAg");
    this.shape_45.setTransform(325.3544, 756.975);

    this.shape_46 = new cjs.Shape();
    this.shape_46.graphics
      .lf(["#323248", "#272739"], [0.824, 1], -15.5, 13.9, 32.8, -19.2)
      .s()
      .p("Ao1DIQggAAgZgLQgYgMgCgRIAThSIAngJIBnjFQgCgRAXgMQAVgLAhAAIOAAAIB/gfIAjA9QAEAHgBAHIhmEgQgBAPgWALQgVAKgfAAg");
    this.shape_46.setTransform(326.1278, 762.55);

    this.shape_47 = new cjs.Shape();
    this.shape_47.graphics
      .lf(["#323248", "#272739"], [0.004, 1], -3.6, -14.8, 1.1, 7)
      .s()
      .p("ApPCrQgfAAgYgLQgXgKgCgQIC/kLQATglA2AAIPmAAQAiAAAYAMQAYAMgBAQIidEMQgCAOgUAKQgVAJgeAAg");
    this.shape_47.setTransform(180.6544, 756.975);

    this.shape_48 = new cjs.Shape();
    this.shape_48.graphics
      .lf(["#323248", "#272739"], [0.824, 1], -17, 14.1, 34.4, -21.1)
      .s()
      .p("ApmDIQghAAgYgLQgZgMgCgRIAThSIAngJICJjFQgCgRAWgMQAWgLAhAAIPBAAIB/gfIAjA9QAEAHgBAHIicEgQgCAPgVALQgWAKgfAAg");
    this.shape_48.setTransform(181.41, 762.55);

    this.shape_49 = new cjs.Shape();
    this.shape_49.graphics
      .lf(["#323248", "#272739"], [0.004, 1], -3.6, -14.8, 1.2, 7.3)
      .s()
      .p("AoCCrQggAAgXgLQgYgKgBgQIBnkLQATglA1AAIOlAAQAiAAAYAMQAYAMgBARIgwELQgBAOgVAKQgUAJgeAAg");
    this.shape_49.setTransform(481.3544, 708.875);

    this.shape_50 = new cjs.Shape();
    this.shape_50.graphics
      .lf(["#323248", "#272739"], [0.824, 1], -15, 13.6, 31.6, -18.3)
      .s()
      .p(
        "AoZDIQghAAgZgLQgYgMgCgRIAThSIAngJIAxjFQgCgRAWgLQAWgMAhAAIN/AAIB/gfIAjA9IAAAAQAEAGgBAIIgvEgQgBAPgVALQgWAKgfAAg"
      );
    this.shape_50.setTransform(482.1091, 714.475);

    this.shape_51 = new cjs.Shape();
    this.shape_51.graphics
      .lf(["#323248", "#272739"], [0.004, 1], -3.5, -14.2, 1.1, 7.1)
      .s()
      .p("AoICrQgfAAgYgLQgXgKgCgQICekLQAUglA1AAIN5AAQAiAAAYAMQAYAMgCARIhmELQgBAOgVAKQgVAJgdAAg");
    this.shape_51.setTransform(344.7044, 708.875);

    this.shape_52 = new cjs.Shape();
    this.shape_52.graphics
      .lf(["#323248", "#272739"], [0.824, 1], -15.1, 13.7, 31.8, -18.5)
      .s()
      .p("AofDIQghAAgYgLQgZgMgBgRIAThSIAmgJIBojFQgCgRAWgLQAWgMAhAAINUAAIB/gfIAiA9QAFAGgBAIIhmEgQgBAPgWALQgWAKgeAAg");
    this.shape_52.setTransform(345.4591, 714.475);

    this.shape_53 = new cjs.Shape();
    this.shape_53.graphics
      .lf(["#323248", "#272739"], [0.004, 1], -3.5, -14.4, 1.1, 7)
      .s()
      .p("AozCrQggAAgXgLQgYgKgBgQIC/kLQAUglA0AAIOwAAQAiAAAYAMQAYAMgCARIiSELQgBAOgVAKQgUAJgeAAg");
    this.shape_53.setTransform(206.0294, 708.875);

    this.shape_54 = new cjs.Shape();
    this.shape_54.graphics
      .lf(["#323248", "#272739"], [0.824, 1], -16.2, 14.1, 33.4, -20)
      .s()
      .p("ApLDIQghAAgYgLQgZgMgBgRIAThSIAmgJICJjFQgCgRAWgLQAWgMAhAAIOLAAIB+gfIAjA9QAEAHAAAHIiSEgQgCAPgVALQgWAKgeAAg");
    this.shape_54.setTransform(206.8028, 714.475);

    this.shape_55 = new cjs.Shape();
    this.shape_55.graphics
      .lf(["#323248", "#272739"], [0.004, 1], -3.4, -14.2, 1.2, 7.2)
      .s()
      .p("AnbCrQggAAgXgLQgYgKgCgQIBzkLQATglA1AAINNAAQAiAAAYAMQAYAMgBAQIgwEMQgBAOgVAKQgUAJgeAAg");
    this.shape_55.setTransform(494.6294, 660.825);

    this.shape_56 = new cjs.Shape();
    this.shape_56.graphics
      .lf(["#323248", "#272739"], [0.824, 1], -13.8, 13.2, 30.3, -17.1)
      .s()
      .p("AnzDIQggAAgZgMQgYgMgCgQIAThSIAngJIA7jFQgBgRAWgLQAWgMAgAAIMoAAIB/gfIAjA9QAEAHgBAGIgvEhQgBAQgWAJQgVALgfAAg");
    this.shape_56.setTransform(495.3841, 666.4);

    this.shape_57 = new cjs.Shape();
    this.shape_57.graphics
      .lf(["#323248", "#272739"], [0.004, 1], -3.5, -14.2, 1.1, 6.9)
      .s()
      .p("An3CrQggAAgXgLQgYgKgBgQICTkLQATglA1AAINkAAQAiAAAXAMQAYAMgBAQIhmEMQgBAOgVAKQgVAJgdAAg");
    this.shape_57.setTransform(363.4544, 660.825);

    this.shape_58 = new cjs.Shape();
    this.shape_58.graphics
      .lf(["#323248", "#272739"], [0.824, 1], -14.9, 13.1, 31, -18.4)
      .s()
      .p("AoODIQghAAgYgMQgZgMgCgQIAThSIAngJIBdjFQgCgRAWgLQAWgMAhAAIM+AAIB+gfIAjA9QAEAIAAAFIhmEhQgCAQgVAJQgWALgeAAg");
    this.shape_58.setTransform(364.2278, 666.4);

    this.shape_59 = new cjs.Shape();
    this.shape_59.graphics
      .lf(["#323248", "#272739"], [0.004, 1], -3.4, -14.2, 1, 6.5)
      .s()
      .p("AoCCrQggAAgXgLQgYgKgBgQICpkLQATglA1AAINjAAQAiAAAYAMQAYAMgBAQIiSEMQgCAOgUAKQgVAJgeAAg");
    this.shape_59.setTransform(232.5044, 660.825);

    this.shape_60 = new cjs.Shape();
    this.shape_60.graphics
      .lf(["#323248", "#272739"], [0.824, 1], -15.6, 12.8, 31, -19.1)
      .s()
      .p(
        "AoZDIQghAAgZgMQgYgMgCgQIAThSIAngJIBzjFQgCgRAWgLQAWgMAhAAIM9AAIB/gfIAjA9IAAAAQAEAHgBAGIiSEhQgBAQgVAJQgWALgfAAg"
      );
    this.shape_60.setTransform(233.2591, 666.4);

    this.shape_61 = new cjs.Shape();
    this.shape_61.graphics
      .lf(["#323248", "#272739"], [0.004, 1], -3.4, -14.1, 1.1, 6.9)
      .s()
      .p("AnGCrQgfAAgYgLQgXgKgCgQIBokLQATglA1AAIMsAAQAiAAAYAMQAYAMgBAQIg7EMQgBAOgVAKQgUAJgeAAg");
    this.shape_61.setTransform(506.2794, 612.725);

    this.shape_62 = new cjs.Shape();
    this.shape_62.graphics
      .lf(["#323248", "#272739"], [0.824, 1], -13.8, 12.5, 29, -16.9)
      .s()
      .p("AndDIQggAAgZgLQgYgMgCgRIAThSIAngJIAwjFQgCgRAXgLQAWgMAgAAIMHAAIB/gfIAjA9QAEAHgBAHIg6EgQgBAPgWALQgVAKgfAAg");
    this.shape_62.setTransform(507.0278, 618.325);

    this.shape_63 = new cjs.Shape();
    this.shape_63.graphics
      .lf(["#323248", "#272739"], [0.004, 1], -3.4, -14, 1, 6.4)
      .s()
      .p("AnFCrQggAAgXgLQgYgKgCgQICJkLQAHgUAGgGQAKgLAbAAIMiAAQAhAAAYAMQAYAMgBAQIhmEMQgCAOgUAKQgVAJgeAAg");
    this.shape_63.setTransform(382.2294, 612.725);

    this.shape_64 = new cjs.Shape();
    this.shape_64.graphics
      .lf(["#323248", "#272739"], [0.824, 1], -14, 12.2, 28.8, -17.2)
      .s()
      .p("AndDIQggAAgZgLQgZgMgBgRIAThSIAmgJIBSjFQgBgRAVgLQAWgMAiAAILlAAIB/gfIAjA9QADAGAAAIIhmEgQgBAPgVALQgXAKgeAAg");
    this.shape_64.setTransform(383, 618.325);

    this.shape_65 = new cjs.Shape();
    this.shape_65.graphics
      .lf(["#323248", "#272739"], [0.004, 1], -3.3, -13.7, 1, 6.4)
      .s()
      .p("AnhCrQggAAgXgLQgYgKgBgQICpkLQAUglA0AAIMiAAQAhAAAYAMQAYAMgBAQIiSEMQgBAOgVAKQgVAJgeAAg");
    this.shape_65.setTransform(258.4044, 612.725);

    this.shape_66 = new cjs.Shape();
    this.shape_66.graphics
      .lf(["#323248", "#272739"], [0.824, 1], -14.8, 12.3, 29.7, -18.2)
      .s()
      .p("An4DIQghAAgZgLQgYgMgCgRIAThSIAngJIBzjFQgCgRAWgLQAWgMAhAAIL7AAIB/gfIAjA9QAEAHAAAHIiSEgQgCAPgVALQgWAKgeAAg");
    this.shape_66.setTransform(259.1778, 618.325);

    this.shape_67 = new cjs.Shape();
    this.shape_67.graphics
      .lf(["#323248", "#272739"], [0.004, 1], -5.1, -54.1, 2.8, 29.6)
      .s()
      .p("A4WGoQhPAAg4gbQg4gbAAgnIF3qVQAAgmA4gbQA4gbBPAAMAq2AAAQBVgBA5AfQA5AegJApIizKVQgHAjg3AYQg2AZhKAAg");
    this.shape_67.setTransform(402.5802, 549.9);

    this.shape_68 = new cjs.Shape();
    this.shape_68.graphics
      .lf(["#323248", "#272739"], [0.004, 1], 30.7, 39.2, 5.2, 6.7)
      .s()
      .p("A5UHJQhSAAg6gdQg7gdAAgqIGFrJQAAgpA6geQA6gdBTAAMAskAAAQBYAAA7AhQA7AggIAtIi5LJQgIAmg4AaQg5AahNAAg");
    this.shape_68.setTransform(402.5781, 549.9);

    this.shape_69 = new cjs.Shape();
    this.shape_69.graphics
      .lf(["#323248", "#272739"], [0.004, 1], 69.5, 228.4, -24.7, -60.2)
      .s()
      .p("Eg3QAY4MAAAgxvMBm7AAAQDXAACRBxQCQBxgVCYMgFYAm+QgSCEiLBaQiLBZi7AAg");
    this.shape_69.setTransform(353.6832, 647.35);

    this.shape_70 = new cjs.Shape();
    this.shape_70.graphics
      .lf(["#323248", "#272739"], [0.004, 1], 37.2, 303.7, 20.1, 174.4)
      .s()
      .p("Eg3QAjEMAAAhGHMBm7AAAQDXABCRCfQCQCfgVDWMgF2A7yQgFA2gpAlQgoAlg3AAg");
    this.shape_70.setTransform(353.6832, 712.5);

    this.shape_71 = new cjs.Shape();
    this.shape_71.graphics
      .lf(["#323248", "#272739"], [0.004, 1], 0, 81.5, 0, -13.6)
      .s()
      .p("EiV/ALMIAA2XMEr/AAAIAAWXg");
    this.shape_71.setTransform(960, 1008.5);

    this.shape_72 = new cjs.Shape();
    this.shape_72.graphics
      .lf(["#323248", "#272739"], [0.004, 1], 27.9, 227.4, -7, -56.8)
      .s()
      .p("EgiwAGtIAAtZMBFhAAAIAANZg");
    this.shape_72.setTransform(1697.5, 893.925);

    this.shape_73 = new cjs.Shape();
    this.shape_73.graphics
      .lf(["#323248", "#272739"], [0.004, 1], 76.6, 100, 13.4, -8.4)
      .s()
      .p("AjZFrQiahCh3h3Qh3h3hBiZQhEigAAivIXNAAIAAJNQh4B/ifBHQilBIi2AAQiuAAighDg");
    this.shape_73.setTransform(1845.725, 43.175);

    this.shape_74 = new cjs.Shape();
    this.shape_74.graphics
      .lf(["#323248", "#272739"], [0.004, 1], 80.1, 12.8, -43.9, -7)
      .s()
      .p("Ap4DnIAAnNITxAAIAAHNg");
    this.shape_74.setTransform(1581.925, 852.775);

    this.shape_75 = new cjs.Shape();
    this.shape_75.graphics
      .lf(["#323248", "#272739"], [0.004, 1], 166.3, 594.6, 28.5, 101.9)
      .s()
      .p("EgG3BDyMAAAiHjINvAAMAAACHjg");
    this.shape_75.setTransform(1857.475, 463.325);

    this.shape_76 = new cjs.Shape();
    this.shape_76.graphics
      .lf(["#323248", "#272739"], [0.004, 1], 18.2, 33.6, 178.1, 327.8)
      .s()
      .p("EgeNA0TMAAAholMA8bAAAMAAABolg");
    this.shape_76.setTransform(193.4, 334.85);

    this.shape_77 = new cjs.Shape();
    this.shape_77.graphics
      .lf(["#323248", "#272739"], [0.004, 1], -14, -465.5, -1.3, -41.7)
      .s()
      .p("EgqJA9oMAAAh7PMBUSAAAMAAAB7Pg");
    this.shape_77.setTransform(269.75, 394.575);

    this.shape_78 = new cjs.Shape();
    this.shape_78.graphics
      .lf(["#323248", "#272739"], [0.004, 1], 81, 42.6, -1.3, -3.7)
      .s()
      .p("Aj+EsIAAhyIhqAAIAAnlILRAAIAAHlIhqAAIAAByg");
    this.shape_78.setTransform(1163.075, 626.55);

    this.shape_79 = new cjs.Shape();
    this.shape_79.graphics
      .lf(["#323248", "#272739"], [0.004, 1], 128.5, 72.2, -0.3, -0.2)
      .s()
      .p("AnfHgQjHjHAAkZQAAkYDHjHQDHjHEYAAQEZAADHDHQDHDHAAEYQAAEZjHDHQjHDHkZAAQkYAAjHjHg");
    this.shape_79.setTransform(1439.95, 626.15);

    this.shape_80 = new cjs.Shape();
    this.shape_80.graphics
      .lf(["#323248", "#272739"], [0.004, 1], -122.8, -226.5, 241, 444.9)
      .s()
      .p("EgmFARnQg1AAgmglQglgnAAg1IAA/LQAAg1AlgnQAmglA1AAMBMLAAAQA1AAAmAlQAlAmAAA2IAAfLQAAA2glAmQgmAlg1AAg");
    this.shape_80.setTransform(1310.625, 626.55);

    this.shape_81 = new cjs.Shape();
    this.shape_81.graphics
      .f("#323248")
      .s()
      .p("EiV/BUYMAAAiovMEr/AAAMAAACovg");
    this.shape_81.setTransform(960, 540.15);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({
          state: [
            { t: this.shape_81 },
            { t: this.shape_80 },
            { t: this.shape_79 },
            { t: this.shape_78 },
            { t: this.shape_77 },
            { t: this.shape_76 },
            { t: this.shape_75 },
            { t: this.shape_74 },
            { t: this.shape_73 },
            { t: this.shape_72 },
            { t: this.shape_71 },
            { t: this.shape_70 },
            { t: this.shape_69 },
            { t: this.shape_68 },
            { t: this.shape_67 },
            { t: this.shape_66 },
            { t: this.shape_65 },
            { t: this.shape_64 },
            { t: this.shape_63 },
            { t: this.shape_62 },
            { t: this.shape_61 },
            { t: this.shape_60 },
            { t: this.shape_59 },
            { t: this.shape_58 },
            { t: this.shape_57 },
            { t: this.shape_56 },
            { t: this.shape_55 },
            { t: this.shape_54 },
            { t: this.shape_53 },
            { t: this.shape_52 },
            { t: this.shape_51 },
            { t: this.shape_50 },
            { t: this.shape_49 },
            { t: this.shape_48 },
            { t: this.shape_47 },
            { t: this.shape_46 },
            { t: this.shape_45 },
            { t: this.shape_44 },
            { t: this.shape_43 },
            { t: this.shape_42 },
            { t: this.shape_41 },
            { t: this.instance_12 }
          ]
        })
        .wait(420)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(960, 540.2, 960, 1264);
  // library properties:
  lib.properties = {
    id: "B1666071E35741A98659FD8915333ACF",
    width: 1920,
    height: 1000,
    fps: 30,
    color: "#FFFFFF",
    opacity: 1.0,
    manifest: [{ src: atlasSrc, id: "movie_HTML5 Canvas_atlas_" }],
    preloads: []
  };

  // bootstrap callback support:

  (lib.Stage = function(canvas) {
    createjs.Stage.call(this, canvas);
  }).prototype = p = new createjs.Stage();

  p.setAutoPlay = function(autoPlay) {
    this.tickEnabled = autoPlay;
  };
  p.play = function() {
    this.tickEnabled = true;
    this.getChildAt(0).gotoAndPlay(this.getTimelinePosition());
  };
  p.stop = function(ms) {
    if (ms) this.seek(ms);
    this.tickEnabled = false;
  };
  p.seek = function(ms) {
    this.tickEnabled = true;
    this.getChildAt(0).gotoAndStop((lib.properties.fps * ms) / 1000);
  };
  p.getDuration = function() {
    return (this.getChildAt(0).totalFrames / lib.properties.fps) * 1000;
  };

  p.getTimelinePosition = function() {
    return (this.getChildAt(0).currentFrame / lib.properties.fps) * 1000;
  };

  an.bootcompsLoaded = an.bootcompsLoaded || [];
  if (!an.bootstrapListeners) {
    an.bootstrapListeners = [];
  }

  an.bootstrapCallback = function(fnCallback) {
    an.bootstrapListeners.push(fnCallback);
    if (an.bootcompsLoaded.length > 0) {
      for (var i = 0; i < an.bootcompsLoaded.length; ++i) {
        fnCallback(an.bootcompsLoaded[i]);
      }
    }
  };

  an.compositions = an.compositions || {};
  an.compositions["B1666071E35741A98659FD8915333ACF"] = {
    getStage: function() {
      return exportRoot.getStage();
    },
    getLibrary: function() {
      return lib;
    },
    getSpriteSheet: function() {
      return ss;
    },
    getImages: function() {
      return img;
    }
  };

  an.compositionLoaded = function(id) {
    an.bootcompsLoaded.push(id);
    for (var j = 0; j < an.bootstrapListeners.length; j++) {
      an.bootstrapListeners[j](id);
    }
  };

  an.getComposition = function(id) {
    return an.compositions[id];
  };

  an.makeResponsive = function(isResp, respDim, isScale, scaleType, domContainers) {
    var lastW,
      lastH,
      lastS = 1;
    window.addEventListener("resize", resizeCanvas);
    resizeCanvas();

    function resizeCanvas() {
      var w = lib.properties.width,
        h = lib.properties.height;
      var iw = window.innerWidth,
        ih = window.innerHeight;
      var pRatio = window.devicePixelRatio || 1,
        xRatio = iw / w,
        yRatio = ih / h,
        sRatio = 1;
      if (isResp) {
        if (iw < 700) {
          if ((respDim === "width" && lastW === iw) || (respDim === "height" && lastH === ih)) {
            sRatio = lastS;
          } else if (!isScale) {
            if (iw < w || ih < h) sRatio = Math.min(xRatio, yRatio);
          } else if (scaleType === 1) {
            sRatio = Math.min(xRatio, yRatio);
          } else if (scaleType === 2) {
            sRatio = Math.max(xRatio, yRatio);
          }
        }
      }

      domContainers[0].width = w * pRatio * sRatio;
      domContainers[0].height = h * pRatio * sRatio;
      if (domContainers)
        domContainers.forEach(function(container) {
          container.style.width = w * sRatio + "px";
          let tt2 = h * sRatio;
          container.style.height = tt2 > ih ? ih + "px" : tt2 + "px";
        });
      stage.scaleX = pRatio * sRatio;
      stage.scaleY = pRatio * sRatio;
      lastW = iw;
      lastH = ih;
      lastS = sRatio;
      stage.tickOnUpdate = false;
      stage.update();
      stage.tickOnUpdate = true;

      $("#canvas2").css("margin-left", function() {
        return ($(window).width() - $(this).width()) / 2;
      });
    }
  };
})((createjs = createjs || {}), (AdobeAn = AdobeAn || {}));

function init() {
  createjs = window.createjs;
  canvas = document.getElementById("canvas2");
  anim_container = document.getElementById("animation_container2");
  dom_overlay_container = document.getElementById("dom_overlay_container2");
  var comp = AdobeAn.getComposition("B1666071E35741A98659FD8915333ACF");
  var lib = comp.getLibrary();
  var loader = new createjs.LoadQueue(false);
  loader.addEventListener("fileload", function(evt) {
    handleFileLoad(evt, comp);
  });
  loader.addEventListener("complete", function(evt) {
    handleComplete(evt, comp);
  });
  loader.loadManifest(lib.properties.manifest);
}

function handleFileLoad(evt, comp) {
  var images = comp.getImages();
  if (evt && evt.item.type === "image") {
    images[evt.item.id] = evt.result;
  }
}

function handleComplete(evt, comp) {
  //This function is always called, irrespective of the content. You can use the variable "stage" after it is created in token create_stage.
  var lib = comp.getLibrary();
  var ss = comp.getSpriteSheet();
  var queue = evt.target;
  var ssMetadata = lib.ssMetadata;
  for (let i = 0; i < ssMetadata.length; i++) {
    ss[ssMetadata[i].name] = new createjs.SpriteSheet({
      images: [queue.getResult(ssMetadata[i].name)],
      frames: ssMetadata[i].frames
    });
  }
  var preloaderDiv = document.getElementById("_preload_div_");
  preloaderDiv.style.display = "none";
  canvas.style.display = "block";
  exportRoot = new lib.movie_HTML5Canvas();
  stage = new lib.Stage(canvas);
  //Registers the "tick" event listener.
  fnStartAnimation = function() {
    stage.addChild(exportRoot);
    createjs.Ticker.setFPS(lib.properties.fps);
    createjs.Ticker.addEventListener("tick", stage);
  };
  //Code to support hidpi screens and responsive scaling.
  AdobeAn.makeResponsive(true, "both", true, 2, [canvas, preloaderDiv, anim_container, dom_overlay_container]);
  AdobeAn.compositionLoaded(lib.properties.id);
  fnStartAnimation();
}

const Section2 = () => {
  useEffect(() => {
    init();
  }, []);
  return (
    <>
      <div id="animation_container2" style={{ background: "#37374f", overflow: "hidden", margin: "0 auto" }}>
        <canvas
          id="canvas2"
          style={{
            display: "none",
            background: "#37374f"
          }}
        />
        <div
          id="dom_overlay_container2"
          style={{
            pointerEvents: "none",
            overflow: "hidden",
            width: "100%",
            height: "100vh"
          }}
        />
      </div>
    </>
  );
};

export default Section2;
