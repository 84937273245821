import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import qs from 'query-string';
import Lottie from 'react-lottie';
import '../App.css';
import Section1 from './Section1.js';
import Section2 from './Section2.js';
import Section3 from './Section3';
import animation from './ani.json';
import video from './screen.mp4';
import video2 from './screen2.mp4';
import ReactFullpage from '@fullpage/react-fullpage';
import Footer from '../Footer';
import SelectLang from './SelectLang';
import Pager from './Pager';
import upIcon from '../images/button_top_w.svg';
import Helmet from 'react-helmet';
import $ from 'jquery';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animation
};

const Container = styled.div`
  overflow: hidden;
  background: #37374f;
`;

const Section = styled.div`
  display: flex;
  position: relative;
  min-width: 100%;
  min-height: 100%;
  background: ${props => props.color};
  justify-content: center;
  align-items: center;
`;

const Phone = styled.div`
  position: absolute;
  z-index: 10;
  bottom: 30px;
  width: 100%;
`;

const TextMiddle2 = styled.div`
  position: absolute;
  z-index: 5;
  font-size: 20px;
  font-weight: bold;
  color: #e6ba6b;
  user-select: none;
  @media screen and (max-width: 780px) {
    font-size: 16px;
  }
  @media screen and (max-width: 375px) {
    font-size: 12px;
  }
`;

const Text = styled.div`
  position: absolute;
  color: white;
  z-index: 5;
  top: 10%;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  user-select: none;
  @media screen and (max-width: 780px) {
    top: 15%;
    font-size: 18px;
  }
  @media screen and (max-width: 375px) {
    font-size: 20px;
  }
  .gold {
    margin-top: 1rem;
    font-size: 20px;
    color: #e6ba6b;
    @media screen and (max-width: 780px) {
      font-size: 14px;
    }
  }
`;

const Text2 = styled.div`
  position: absolute;
  color: white;
  z-index: 5;
  top: 5%;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  user-select: none;
  @media screen and (max-width: 780px) {
    top: 15%;
    font-size: 18px;
  }
  @media screen and (max-width: 375px) {
    font-size: 20px;
  }
  .gold {
    margin-top: 1rem;
    font-size: 20px;
    color: #e6ba6b;
    @media screen and (max-width: 780px) {
      font-size: 14px;
    }
  }
`;

const Button = styled.div`
  position: fixed;
  z-index: 99999;
  bottom: 5%;
  right: 5%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
`;

const Slide = styled.div`
  width: 100%;
  height: 100%;
`;

const FluidDiv = styled.div`
  display: flex;
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
  bottom: 0;
  width: ${props => props.width};
  height: ${props => props.height};
`;

const How = props => {
  const [page, setPage] = useState(1);
  const [dimension, setDimension] = useState({
    width: '1920px',
    height: '1080px'
  });
  const [text1Height, setText1Height] = useState(0);
  const lang = qs.parse(props.history.location.search).lang || 'kr';
  let slider;

  const video1Ref = useRef();
  const video2Ref = useRef();
  const text1HeightRef = useRef();

  const moveToPage = num => {
    slider.moveTo(num);
  };

  const onPlayVideo = e => {
    e.target.playbackRate = 2.0;
  };

  const resizePhone = () => {
    ['.video1Text', '.video2Text'].forEach(it => {
      const textHeight = $(it).position().top + $(it).height() + 70;
      const ableHeight = window.innerHeight - textHeight;
      // let w = window.innerWidth - 20
      let h = ableHeight;
      var pRatio = 1,
        sRatio = 1;
      const $video = $(it)
        .parent()
        .find('video');
      // const width = w * pRatio * sRatio;
      let height = h * pRatio * sRatio;
      height = height > ableHeight ? ableHeight : height;
      $video.css({ minHeight: height, height: height });
    });
  };

  useEffect(() => {
    function handleResize() {
      let height = window.outerHeight + 20;
      height = height < 300 ? 300 : height;
      setDimension({ width: window.outerWidth + 'px', height: height + 'px' });
      resizePhone();
      let div = Math.round($(window).height() / 2) + 145;
      $('.section1Text').css({ top: div + 'px' });
    }

    let div = Math.round($(window).height() / 2) + 145;
    $('.section1Text').css({ top: div + 'px' });
    let height = window.outerHeight + 20;
    height = height < 600 ? 600 : height;
    setDimension({ width: window.outerWidth + 'px', height: height + 'px' });
    resizePhone();
    window.addEventListener('resize', handleResize);
    const t1Height =
      $(text1HeightRef.current).position().top + $(text1HeightRef.current).height() + 70;
    setText1Height(t1Height);
  }, []);

  return (
    <Container>
      <Button visible={page !== 1} onClick={() => slider.moveTo(1)}>
        <img src={upIcon} alt="" style={{ width: '50px' }} />
      </Button>
      <Pager currentPage={page} onClick={moveToPage} />
      <Helmet>
        <title>DeliQuick | How To Use</title>
      </Helmet>
      <ReactFullpage
        dragAndMove={true}
        licenseKey={'9439CB05-0AE445C4-A175C2C9-8D09C23B'}
        onLeave={(origin, dest) => {
          const page = dest.index + 1;
          setPage(page);
          video1Ref.current.currentTime = 0;
          video2Ref.current.currentTime = 0;
          switch (page) {
            case 3:
              video1Ref.current.play();
              break;
            case 4:
              video2Ref.current.play();
              break;
            default:
              return;
          }
          $(window).trigger('slide');
        }}
        render={({ state, fullpageApi }) => {
          slider = fullpageApi;
          return (
            <ReactFullpage.Wrapper>
              <Slide className={'section'}>
                <SelectLang current={lang} style={{ position: 'fixed' }} />
                <Section id={'section1'}>
                  <Section1 curPage={page} />
                  <TextMiddle2 className={'section1Text'}>{data.a_1[lang]}</TextMiddle2>
                </Section>
              </Slide>
              <Slide className={'section'}>
                <Section id={'section2'}>
                  <Text ref={text1HeightRef}>
                    {data.a[lang].split('<br/>').map((text, index) => (
                      <div key={index}>{text}</div>
                    ))}
                    <div className="gold">{data.b[lang]}</div>
                  </Text>
                  <Section2 showNum={page} textHeight={text1Height} />
                </Section>
              </Slide>
              <Slide className={'section'}>
                <Section id={'section2'}>
                  <Text className={'video1Text'}>
                    {data.c[lang].split('<br/>').map((text, index) => (
                      <div key={index}>{text}</div>
                    ))}

                    <div className="gold">{data.d[lang]}</div>
                  </Text>
                  <Phone className={'phoneDiv'}>
                    {
                      <video
                        className={'video'}
                        style={{ margin: '0 auto' }}
                        ref={video1Ref}
                        src={video}
                        playsInline={true}
                        autoPlay={true}
                        onPlay={onPlayVideo}
                        width="100%"
                        loop
                      />
                    }
                  </Phone>
                </Section>
                <FluidDiv width={dimension.width} height={dimension.height}>
                  <Lottie options={defaultOptions} width={'100%'} />
                </FluidDiv>
              </Slide>
              <Slide className={'section'}>
                <Section id={'section3'}>
                  <Text2 className={'video2Text'}>
                    {data.e[lang].split('<br/>').map((text, index) => (
                      <div key={index}>{text}</div>
                    ))}
                    <div className="gold">{data.f[lang]}</div>
                  </Text2>
                  <Phone className={'phoneDiv'}>
                    {
                      <video
                        src={video2}
                        ref={video2Ref}
                        style={{ margin: '0 auto' }}
                        width="100%"
                        playsInline={true}
                        autoPlay={true}
                        onPlay={onPlayVideo}
                        loop
                      />
                    }
                  </Phone>
                  <Section3 showNum={page} />
                </Section>
              </Slide>
              <Slide className={'section'} style={{ background: 'rgb(85, 94, 124)' }}>
                <Section>
                  <Footer />
                </Section>
              </Slide>
            </ReactFullpage.Wrapper>
          );
        }}
      />
    </Container>
  );
};

export default withRouter(How);

const data = {
  a_1: {
    kr: `외국인 관광객을 위한 QR배달 시스템`,
    en: `Tourist friendly, QR food delivery & more`,
    cn: `为外国观光客准备的QR送餐系统`,
    jp: `外国人観光客のためのQRコード配達システム`
  },
  a: {
    kr: `APP을 설치하지 않아도 돼요 <br/> 호텔에서 스마트하게 주문하세요`,
    en: `Enjoy local platform service without installing <br/> Smart order, you can search best food nearby`,
    cn: `不用安装APP也可以使用 <br/> 在酒店里智能地订餐吧`,
    jp: `アプリを設置する必要はありません。 <br/> ホテルからスマートに注文ができちゃいます。`
  },
  b: {
    kr: `다운로드가 필요없는 모바일 웹 플랫폼`,
    en: `No need download and sign in`,
    cn: `无需下载的手机网页平台`,
    jp: `ダウンロードの必要がないモバイルウェブプラットホーム`
  },
  c: {
    kr: `이제 프론트에 부탁하지 마세요`,
    en: `You never order delivery in same way again <br/> Fast and very easy to use`,
    cn: `现在开始不用拜托前台 <br/> 简单、快速、简便地订购外卖食品`,
    jp: `もう、フロントに頼まなくてもいいですよ。<br/> 手早く簡単に、配達してほしい食べ物を注文してみてください。`
  },
  d: {
    kr: `마음에 드는 가게를 선택하여 먹고싶은 메뉴 추가하기`,
    en: `Select menu as you like and add to cart`,
    cn: `选择喜欢的店铺，添加想吃的菜单`,
    jp: `気に入ったお店を選んで、食べたいメニューを追加する`
  },
  e: {
    kr: `내 음식, 잘 오고 있나? <br/> 실시간으로 주문 현황을 확인할 수 있어요`,
    en: `Reliable, Selected menu and Reasonable price<br/>Real-time track your order status`,
    cn: `我的食物，来得怎么样?<br/>可实时确认订购现状`,
    jp: `注文した料理はちゃんと来ているかな？<br/>リアルタイムで注文状況が確認できます。`
  },
  f: {
    kr: `설레는 마음으로 주문한 음식 기다리기`,
    en: `I can't wait`,
    cn: `怀着激动的心情等待订购的菜品`,
    jp: `ワクワクしながら注文料理を待つ`
  }
};
