import React, { useState } from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import logo from '../images/logo.svg';
import askImg from '../images/question.svg';
import Item2 from './Item2';
import axios from 'axios';
import loader from '../images/ani.png';
import thank from '../images/thank.png';

const Container = styled.div`
  font-family: 'Noto Sans KR', 'Noto Sans', sans-serif;
  color: #333;
  user-select: none;
  .content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
  }
  .inner-contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    padding: 0 5%;
    max-width: 500px;
    margin-bottom: 2rem;
  }
  .lg {
    font-size: 24px;
    @media screen and (max-width: 480px) {
      font-size: 20px;
    }
  }
  .md {
    font-size: 18px;
    @media screen and (max-width: 480px) {
      font-size: 14px;
    }
  }
  .sm {
    font-size: 14px;
    @media screen and (max-width: 480px) {
      font-size: 12px;
    }
  }
`;

const Header = styled.div`
  position: sticky;
  top: 0;
  z-index: 100;
  height: 48px;
  background: white;
  width: 100%;
  border-bottom: 2px solid #e6e6e6;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 100;
  height: 48px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = styled.div`
  background-image: linear-gradient(to top, #f22c5d, #ff464b);
  border-radius: 15px;
  width: 230px;
  height: 36px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;

const RESTAURANT = '맛집';
const LODGEMENT = '숙박업체'

const Survey2 = () => {
  const [page, setPage] = useState(0);
  const [data, setData] = useState({});
  const [direction, setDirection] = useState('left');

  const onSubmit = (newData) => {
    const values = (newData) => {
      if (typeof Object.values === 'undefined') {
        Object.values = function (obj) {
          var array = [];
          for (var key in obj) {
            array.push(obj[key]);
          }
          return array;
        };
      }

      return Object.values(newData);
    };
    let html = ``;

    values(newData).forEach((value, index) => {
      let newValue = typeof value === 'string' ? value : JSON.stringify(value);
      html += `${index + 1}: ${newValue} <br/>`;
    });
    const data = { text: html };
    axios.post('https://us-central1-deliquick-93b6b.cloudfunctions.net/api/sendmail', data).then((res) => {
      if (res.data.status === 'SUCCESS') {
        setPage(data[2] === RESTAURANT ? askList2.length + 2 : askList.length + 2);
      }
    });
  };

  return (
    <Container>
      <Helmet>
        <title>DeliQuick | 문의하기</title>
      </Helmet>
      <Header>
        <img src={logo} alt="" />
      </Header>
      <div className="content">
        <div className="inner-contents" id="inner-contents">
          {page === 0 && (
            <>
              <img src={askImg} style={{ width: '80%' }} alt="" />
              <div className="lg">QR코드 신청하기</div>
              <div
                className="sm"
                style={{
                  margin: '0.5rem 0',
                  textAlign: 'center',
                }}
              >
                'DeliQuick'은 숙박시설 F&B를위한 <br />
                조/석식과 주변 맛집 룸딜리버리를 제공합니다.
              </div>
              <div className="md" style={{ color: '#ff3250', marginBottom: '3rem' }}>
                지금 무료로 QR 코드를 신청 할 수 있습니다.
              </div>
            </>
          )}
          {data[2] !== RESTAURANT &&
            askList.map((item, index) => (
              <React.Fragment key={index}>
                {page === index + 1 && (
                  <Item2
                    data={item}
                    lastPage={askList && askList.length}
                    index={index + 1}
                    page={page}
                    setPage={setPage}
                    currentData={data}
                    setData={setData}
                    direction={direction}
                    setDirection={setDirection}
                    onSubmit={onSubmit}
                    length={askList.length}
                  />
                )}
              </React.Fragment>
            ))}
          {data[2] === RESTAURANT &&
            askList2.map((item, index) => (
              <React.Fragment key={index}>
                {page === index + 1 && (
                  <>
                    <Item2
                      data={item}
                      lastPage={askList && askList.length}
                      index={index + 3}
                      page={page}
                      setPage={setPage}
                      currentData={data}
                      setData={setData}
                      direction={direction}
                      setDirection={setDirection}
                      onSubmit={onSubmit}
                      length={askList2.length}
                    />
                  </>
                )}
              </React.Fragment>
            ))}
          {page === (data[2] === RESTAURANT ? askList2.length + 1 : askList.length + 1) && (
            <>
              <img src={loader} style={{ width: '90px' }} alt="" />
              <div style={{ color: '#333', marginTop: '0.5rem', fontSize: '12px' }}>잠시만 기다려주세요...</div>
            </>
          )}
          {page === (data[2] === RESTAURANT ? askList2.length + 2 : askList.length + 2) && (
            <>
              <img src={thank} style={{ maxWidth: '260px', width: '100%' }} alt="" />
              <div
                className="md"
                style={{
                  color: '#333',
                  marginTop: '1rem',
                  textAlign: 'center',
                }}
              >
                신청이 정상적으로 완료되었습니다. <br />
                감사합니다.
              </div>
            </>
          )}
        </div>
      </div>
      <Footer>
        {page === 0 && (
          <Button
            style={{
              width: '100%',
              borderRadius: '0px',
              height: '100%',
              color: '#fff',
              fontSize: '18px',
            }}
            onClick={() => {
              setPage(1);
            }}
          >
            신청하기
          </Button>
        )}

        {page === (data[2] === RESTAURANT ? askList2.length + 2 : askList.length + 2) && (
          <Button
            style={{
              width: '100%',
              borderRadius: '0px',
              height: '100%',
              color: '#fff',
              fontSize: '18px',
            }}
          >
            <a href="https://deliquick.co.kr/">홈으로 돌아가기</a>
          </Button>
        )}
      </Footer>
    </Container>
  );
};

export default Survey2;

const askList = [
  {
    question: '델리퀵을 알게 된 <br/><b>경로</b>는 어디인가요?',
    answerArray: ['카페', '포털 검색', '보도 자료 (인터넷 기사)', `지인 소개`, `SNS`, `기타`],
    must: true,
  },
  {
    question: '<b>매장 형태</b>를 선택해주세요.',
    answerArray: [LODGEMENT, RESTAURANT],
    must: true,
  },
  {
    question: '<b>숙소</b>의 <b>형태</b>를 알려주세요.',
    answerArray: ['호텔', '모텔', '게스트하우스', `쉐어하우스 (airbnb 등)`, `펜션/리조트`, `기타`],
    must: true,
  },
  {
    question: `<b>숙소</b> 또는 <b>회사명</b>을 알려주세요.`,
    input: '숙소, 회사명 입력',
    must: true,
  },
  {
    question: `담당자님의 <b>성함</b>을 알려주세요.`,
    input: '여기에 답변을 입력해 주세요.',
    must: true,
  },
  {
    question: `담당자님의 <b>이메일</b>을 알려주세요.`,
    input: '여기에 답변을 입력해 주세요.',
    must: true,
  },
  {
    question: `담당자님의 <b>연락처</b>를 알려주세요.`,
    input: '여기에 답변을 입력해 주세요.',
    must: true,
  },
  {
    question: `시설의 <b>위치</b>를 알려주세요.`,
    address: true,
  },
  {
    question: `추가 <b>문의 사항</b>이 있으신가요? <br/> 편하게 말씀해 주세요.`,
    textarea: '여기에 답변을 입력해 주세요.',
  },
];

// 음식점용
const askList2 = [
  {
    question: '델리퀵을 알게 된 <br/><b>경로</b>는 어디인가요?',
    answerArray: ['카페', '포털 검색', '보도 자료 (인터넷 기사)', `지인 소개`, `SNS`, `기타`],
    must: true,
  },
  {
    question: '<b>매장 형태<b/>를 선택해주세요.',
    answerArray: ['숙박업체', '맛집'],
    must: true,
  },
  {
    question: `<b>상호</b>를 알려주세요. (간판상호)`,
    input: '여기에 답변을 입력해 주세요.',
    must: true,
  },
  {
    question: `매장 <b>전화번호</b>와 <br/>담당자님의 <b>핸드폰번호</b>를 알려주세요.`,
    input: '여기에 답변을 입력해 주세요.',
    must: true,
  },
  {
    question: `<b>주소</b>를 알려주세요.`,
    address: true,
  },
  {
    question: `<b>업종</b>을 선택해주세요.
    <br/>
      *해당하는 카테고리가 없으면 기타에 작성해주세요.
    `,
    answerArray: ['한식 (분식, 족발, 보쌈)', '중식', '일식', `치킨`, `피자/버거`, `샐러드`, `디저트`, `기타`],
    must: true,
  },
  {
    question: `<b>최소주문금액</b>과 <b>배달비</b>를 알려주세요.`,
    input: '여기에 답변을 입력해 주세요.',
    must: true,
  },
  {
    question: `<b>영업시간</b>과 <b>휴무일</b>을 알려주세요.`,
    time: true,
  },
  {
    question: `추가 <b>문의 사항</b>이 있으신가요? <br/> 편하게 말씀해 주세요.`,
    textarea: '여기에 답변을 입력해 주세요.',
  },
];
