import React, { useEffect } from "react";
import atlasSrc from "../images/atlas.png";
import $ from "jquery";

let createjs, AdobeAn;
let canvas, stage, exportRoot, anim_container, dom_overlay_container, fnStartAnimation;

(function(_, an) {
  const cjs = window.createjs;
  const createjs = window.createjs;

  var p; // shortcut to reference prototypes
  var lib = {};
  var ss = {};
  var img = {};
  lib.ssMetadata = [
    {
      name: "movie_HTML5 Canvas_atlas_",
      frames: [
        [754, 1082, 44, 44],
        [0, 1082, 281, 208],
        [283, 1082, 289, 202],
        [800, 1082, 29, 29],
        [574, 1082, 178, 119],
        [831, 1082, 28, 27],
        [861, 1082, 24, 22],
        [0, 0, 1920, 1080]
      ]
    }
  ];

  // symbols:

  (lib._10_star_4 = function() {
    this.initialize(ss["movie_HTML5 Canvas_atlas_"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib._5_star_f = function() {
    this.initialize(ss["movie_HTML5 Canvas_atlas_"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib._6_star_f2 = function() {
    this.initialize(ss["movie_HTML5 Canvas_atlas_"]);
    this.gotoAndStop(2);
  }).prototype = p = new cjs.Sprite();

  (lib._7_star_1 = function() {
    this.initialize(ss["movie_HTML5 Canvas_atlas_"]);
    this.gotoAndStop(3);
  }).prototype = p = new cjs.Sprite();

  (lib._7_star_f3 = function() {
    this.initialize(ss["movie_HTML5 Canvas_atlas_"]);
    this.gotoAndStop(4);
  }).prototype = p = new cjs.Sprite();

  (lib._8_star_2 = function() {
    this.initialize(ss["movie_HTML5 Canvas_atlas_"]);
    this.gotoAndStop(5);
  }).prototype = p = new cjs.Sprite();

  (lib._9_star_3 = function() {
    this.initialize(ss["movie_HTML5 Canvas_atlas_"]);
    this.gotoAndStop(6);
  }).prototype = p = new cjs.Sprite();

  (lib.bgpngcopy = function() {
    this.initialize(ss["movie_HTML5 Canvas_atlas_"]);
    this.gotoAndStop(7);
  }).prototype = p = new cjs.Sprite();

  // helper functions:

  function mc_symbol_clone() {
    var clone = this._cloneProps(new this.constructor(this.mode, this.startPosition, this.loop));
    clone.gotoAndStop(this.currentFrame);
    clone.paused = this.paused;
    clone.framerate = this.framerate;
    return clone;
  }

  function getMCSymbolPrototype(symbol, nominalBounds, frameBounds) {
    var prototype = cjs.extend(symbol, cjs.MovieClip);
    prototype.clone = mc_symbol_clone;
    prototype.nominalBounds = nominalBounds;
    prototype.frameBounds = frameBounds;
    return prototype;
  }

  (lib.ufo_glow = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#3EFFC8")
      .s()
      .p(
        "AhNLNQggggAAguIAAiQImNAAIhdCxQgPAcgaAQQgbAPgeAAQgcAAgXgNQgogVgOgrQgOgsAWgoIAnhLIjhAAQgrAAgfgfQgegfAAgrQAAgsAegeQAfgfArAAIBAAAQADlcDskCQBwh8CVhNQCZhOCqgRIAAglQAAgnAcgcQAbgbAmAAQAnAAAbAbQAcAcAAAnIAAAlQCqARCZBOQCVBNBwB8QDsECADFcIA/AAQAsAAAfAfQAeAeAAAsQAAArgeAfQgfAfgsAAIjlAAIAsBUQAVAogNAsQgOArgoAWQgZAMgaAAQgfAAgagPQgagQgPgcIhii6ImIAAIAACQQAAAuggAgQggAgguAAQgtAAggggg"
      );
    this.shape.setTransform(0.025, 0.025);

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-108.5, -74.9, 217.1, 149.9);

  (lib.Tween23 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib._9_star_3();
    this.instance.parent = this;
    this.instance.setTransform(-12, -11);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-12, -11, 24, 22);

  (lib.Tween22 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib._9_star_3();
    this.instance.parent = this;
    this.instance.setTransform(-12, -11);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-12, -11, 24, 22);

  (lib.Tween21 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib._9_star_3();
    this.instance.parent = this;
    this.instance.setTransform(-12, -11);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-12, -11, 24, 22);

  (lib.Tween20 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib._9_star_3();
    this.instance.parent = this;
    this.instance.setTransform(-12, -11);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-12, -11, 24, 22);

  (lib.Tween9 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib._7_star_1();
    this.instance.parent = this;
    this.instance.setTransform(-14.5, -14.5);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-14.5, -14.5, 29, 29);

  (lib.Tween8 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib._7_star_1();
    this.instance.parent = this;
    this.instance.setTransform(-14.5, -14.5);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-14.5, -14.5, 29, 29);

  (lib.Tween7 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib._7_star_f3();
    this.instance.parent = this;
    this.instance.setTransform(-89, -59.5);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-89, -59.5, 178, 119);

  (lib.Tween6 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib._7_star_f3();
    this.instance.parent = this;
    this.instance.setTransform(-89, -59.5);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-89, -59.5, 178, 119);

  (lib.Tween2 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib._5_star_f();
    this.instance.parent = this;
    this.instance.setTransform(-140.5, -104);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-140.5, -104, 281, 208);

  (lib.Tween1 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib._5_star_f();
    this.instance.parent = this;
    this.instance.setTransform(-140.5, -104);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-140.5, -104, 281, 208);

  (lib.Symbol16 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib._10_star_4();
    this.instance.parent = this;

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(0, 0, 44, 44);

  (lib.Symbol13 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib._8_star_2();
    this.instance.parent = this;
    this.instance.setTransform(0, 0, 0.9998, 0.9999);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(0, 0, 28, 27);

  (lib.Symbol7 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_2 (mask)
    var mask = new cjs.Shape();
    mask._off = true;
    var mask_graphics_15 = new cjs.Graphics().p(
      "AgbQdIgUgDIgRgHIgNgJIgNgJIgJgMIgNgJIgFgPIgJgMIgFgPIgEgPIgBgSIADgVIADgVIAMgbIAlgiIgDgEMAnkgdAIAqgSIAdgDIAZAAIARAGIARAHIARAGIANAJIAJAMIAJAMIAJAMIAJAMIAEAPIABASIABASIABASIgHAYIgMAbIgeAjMgnlAdAIgvAVIgcADIgVgDg"
    );
    var mask_graphics_16 = new cjs.Graphics().p(
      "AjbQdIgVgDIgRgHIgNgJIgNgJIgJgMIgNgJIgFgPIgJgMIgEgPIgFgPIgBgSIADgVIADgVIAMgbIAlgiIgDgEMAnkgdAIAqgSIAdgDIAZAAIARAGIARAHIARAGIANAJIAJAMIAJAMIAJAMIAJAMIAEAPIABASIABASIABASIgHAYIgMAbIgeAjMgnkAdAIguAVIgdADIgVgDg"
    );
    var mask_graphics_17 = new cjs.Graphics().p(
      "AmcQdIgVgDIgRgHIgNgJIgNgJIgJgMIgNgJIgFgPIgJgMIgEgPIgFgPIgBgSIADgVIADgVIAMgbIAmgiIgDgEMAnjgdAIArgSIAdgDIAZAAIAQAGIARAHIARAGIANAJIAJAMIAJAMIAJAMIAJAMIAFAPIAAASIABASIABASIgHAYIgMAbIgeAjMgnkAdAIguAVIgdADIgVgDg"
    );
    var mask_graphics_18 = new cjs.Graphics().p(
      "ApdQdIgVgDIgRgHIgNgJIgNgJIgJgMIgNgJIgFgPIgIgMIgFgPIgFgPIgBgSIADgVIAEgVIALgbIAmgiIgDgEMAnjgdAIArgSIAdgDIAZAAIARAGIAQAHIARAGIANAJIAJAMIAJAMIAJAMIAJAMIAFAPIAAASIABASIABASIgHAYIgMAbIgeAjMgnkAdAIguAVIgdADIgVgDg"
    );
    var mask_graphics_19 = new cjs.Graphics().p(
      "AseQdIgVgDIgRgHIgNgJIgNgJIgJgMIgNgJIgFgPIgIgMIgFgPIgFgPIgBgSIADgVIAEgVIALgbIAmgiIgDgEMAnjgdAIArgSIAdgDIAZAAIARAGIARAHIAQAGIANAJIAJAMIAJAMIAJAMIAJAMIAFAPIABASIAAASIABASIgHAYIgLAbIgfAjMgnkAdAIguAVIgdADIgVgDg"
    );
    var mask_graphics_20 = new cjs.Graphics().p(
      "AvfQdIgVgDIgRgHIgNgJIgNgJIgJgMIgNgJIgEgPIgJgMIgFgPIgFgPIgBgSIADgVIAEgVIALgbIAmgiIgDgEMAnkgdAIAqgSIAdgDIAZAAIARAGIARAHIARAGIAMAJIAJAMIAJAMIAJAMIAJAMIAFAPIABASIAAASIABASIgHAYIgLAbIgfAjMgnjAdAIgvAVIgdADIgVgDg"
    );
    var mask_graphics_21 = new cjs.Graphics().p(
      "AygQdIgVgDIgRgHIgNgJIgNgJIgJgMIgNgJIgEgPIgJgMIgFgPIgFgPIgBgSIAEgVIADgVIALgbIAmgiIgDgEMAnkgdAIAqgSIAdgDIAZAAIARAGIARAHIARAGIANAJIAIAMIAJAMIAJAMIAJAMIAFAPIABASIAAASIABASIgHAYIgLAbIgfAjMgnjAdAIgvAVIgdADIgVgDg"
    );
    var mask_graphics_22 = new cjs.Graphics().p(
      "A0DRyIgVgEIgRgGIgNgJIgNgJIgJgMIgNgJIgFgPIgJgNIgEgPIgFgPIgBgSIADgVIADgVIAMgaIAmgiIgDgEMAnjgdAIArgTIAdgCIAZAAIARAGIAQAGIARAGIANAKIAJAMIAJAMIAJAMIAJAMIAFAPIAAASIABASIABASIgHAYIgMAbIgeAiMgnkAdAIguAWIgdADIgVgDg"
    );
    var mask_graphics_23 = new cjs.Graphics().p(
      "A0DT5IgVgDIgRgGIgNgJIgNgJIgJgMIgNgJIgFgQIgJgMIgEgPIgFgPIgBgSIADgVIADgVIAMgbIAmghIgDgEMAnjgdAIArgTIAdgCIAZAAIAQAGIARAGIARAGIANAKIAJAMIAJAMIAJAMIAJAMIAFAPIAAASIABASIABASIgHAYIgMAbIgeAiMgnkAdAIguAWIgdADIgVgEg"
    );
    var mask_graphics_24 = new cjs.Graphics().p(
      "A0DWBIgVgDIgRgGIgNgJIgNgJIgJgMIgNgJIgFgQIgJgMIgEgPIgFgPIgBgSIADgVIADgVIAMgbIAmghIgDgEMAnjgdAIArgTIAdgDIAZABIAQAGIARAGIARAGIANAJIAJANIAJAMIAJAMIAJAMIAFAPIAAASIABASIABASIgHAYIgMAbIgeAiMgnkAdAIguAWIgdADIgVgEg"
    );
    var mask_graphics_25 = new cjs.Graphics().p(
      "A0DYJIgVgDIgRgGIgNgJIgNgJIgJgMIgNgKIgFgPIgJgMIgEgPIgFgPIgBgSIADgVIADgVIAMgbIAmghIgDgFMAnjgc/IArgTIAdgDIAZABIAQAGIARAGIARAGIANAJIAJANIAJAMIAJAMIAJAMIAFAPIAAASIABASIABASIgHAYIgMAbIgeAiMgnkAdAIguAWIgdADIgVgEg"
    );
    var mask_graphics_26 = new cjs.Graphics().p(
      "A0DaRIgVgDIgRgGIgNgJIgNgJIgJgMIgNgKIgFgPIgJgMIgEgPIgFgPIgBgSIADgVIADgVIAMgbIAmgiIgDgEMAnjgc/IArgTIAdgDIAZABIAQAGIARAGIARAGIANAJIAJAMIAJANIAJAMIAJAMIAFAPIAAASIABASIABASIgHAYIgMAbIgeAiMgnkAdAIguAWIgdADIgVgEg"
    );
    var mask_graphics_27 = new cjs.Graphics().p(
      "A0DcZIgVgDIgRgGIgNgJIgNgJIgJgMIgNgKIgFgPIgJgMIgEgPIgFgPIgBgSIADgVIADgVIAMgbIAmgiIgDgEMAnjgc/IArgTIAdgDIAZABIAQAGIARAGIARAGIANAJIAJAMIAJANIAJAMIAJAMIAFAPIAAASIABASIABASIgHAYIgMAbIgeAiMgnkAdAIguAWIgdADIgVgEg"
    );
    var mask_graphics_28 = new cjs.Graphics().p(
      "A0DehIgVgDIgRgGIgNgJIgNgJIgJgMIgNgKIgFgPIgJgMIgEgPIgFgPIgBgSIADgVIADgVIAMgbIAmgiIgDgEMAnjgc/IArgTIAdgDIAZABIARAGIAQAGIARAGIANAJIAJAMIAJANIAJAMIAJAMIAFAPIAAASIABASIABASIgHAXIgMAbIgeAiMgnkAdBIguAWIgdADIgVgEg"
    );
    var mask_graphics_29 = new cjs.Graphics().p(
      "EgU2AgpIgUgDIgRgGIgNgJIgNgJIgJgNIgNgJIgFgPIgJgMIgFgPIgEgPIgBgSIADgVIADgVIAMgbIAlgiIgDgEMAnkgdAIAqgSIAdgDIAZABIARAGIARAGIARAGIANAIIAJAMIAJAMIAJANIAJAMIAEAPIABASIABASIABASIgHAYIgMAbIgeAiMgnkAdBIgvAWIgdADIgVgEg"
    );
    var mask_graphics_64 = new cjs.Graphics().p(
      "AgbQdIgUgDIgRgHIgNgJIgNgJIgJgMIgNgJIgFgPIgJgMIgFgPIgEgPIgBgSIADgVIADgVIAMgbIAlgiIgDgEMAnkgdAIAqgSIAdgDIAZAAIARAGIARAHIARAGIANAJIAJAMIAJAMIAJAMIAJAMIAEAPIABASIABASIABASIgHAYIgMAbIgeAjMgnlAdAIgvAVIgcADIgVgDg"
    );
    var mask_graphics_65 = new cjs.Graphics().p(
      "AjbQdIgVgDIgRgHIgNgJIgNgJIgJgMIgNgJIgFgPIgJgMIgEgPIgFgPIgBgSIADgVIADgVIAMgbIAlgiIgDgEMAnkgdAIAqgSIAdgDIAZAAIARAGIARAHIARAGIANAJIAJAMIAJAMIAJAMIAJAMIAEAPIABASIABASIABASIgHAYIgMAbIgeAjMgnkAdAIguAVIgdADIgVgDg"
    );
    var mask_graphics_66 = new cjs.Graphics().p(
      "AmcQdIgVgDIgRgHIgNgJIgNgJIgJgMIgNgJIgFgPIgJgMIgEgPIgFgPIgBgSIADgVIADgVIAMgbIAmgiIgDgEMAnjgdAIArgSIAdgDIAZAAIAQAGIARAHIARAGIANAJIAJAMIAJAMIAJAMIAJAMIAFAPIAAASIABASIABASIgHAYIgMAbIgeAjMgnkAdAIguAVIgdADIgVgDg"
    );
    var mask_graphics_67 = new cjs.Graphics().p(
      "ApdQdIgVgDIgRgHIgNgJIgNgJIgJgMIgNgJIgFgPIgIgMIgFgPIgFgPIgBgSIADgVIAEgVIALgbIAmgiIgDgEMAnjgdAIArgSIAdgDIAZAAIARAGIAQAHIARAGIANAJIAJAMIAJAMIAJAMIAJAMIAFAPIAAASIABASIABASIgHAYIgMAbIgeAjMgnkAdAIguAVIgdADIgVgDg"
    );
    var mask_graphics_68 = new cjs.Graphics().p(
      "AseQdIgVgDIgRgHIgNgJIgNgJIgJgMIgNgJIgFgPIgIgMIgFgPIgFgPIgBgSIADgVIAEgVIALgbIAmgiIgDgEMAnjgdAIArgSIAdgDIAZAAIARAGIARAHIAQAGIANAJIAJAMIAJAMIAJAMIAJAMIAFAPIABASIAAASIABASIgHAYIgLAbIgfAjMgnkAdAIguAVIgdADIgVgDg"
    );
    var mask_graphics_69 = new cjs.Graphics().p(
      "AvfQdIgVgDIgRgHIgNgJIgNgJIgJgMIgNgJIgEgPIgJgMIgFgPIgFgPIgBgSIADgVIAEgVIALgbIAmgiIgDgEMAnkgdAIAqgSIAdgDIAZAAIARAGIARAHIARAGIAMAJIAJAMIAJAMIAJAMIAJAMIAFAPIABASIAAASIABASIgHAYIgLAbIgfAjMgnjAdAIgvAVIgdADIgVgDg"
    );
    var mask_graphics_70 = new cjs.Graphics().p(
      "AygQdIgVgDIgRgHIgNgJIgNgJIgJgMIgNgJIgEgPIgJgMIgFgPIgFgPIgBgSIAEgVIADgVIALgbIAmgiIgDgEMAnkgdAIAqgSIAdgDIAZAAIARAGIARAHIARAGIANAJIAIAMIAJAMIAJAMIAJAMIAFAPIABASIAAASIABASIgHAYIgLAbIgfAjMgnjAdAIgvAVIgdADIgVgDg"
    );
    var mask_graphics_71 = new cjs.Graphics().p(
      "A0DRyIgVgEIgRgGIgNgJIgNgJIgJgMIgNgJIgFgPIgJgNIgEgPIgFgPIgBgSIADgVIADgVIAMgaIAmgiIgDgEMAnjgdAIArgTIAdgCIAZAAIARAGIAQAGIARAGIANAKIAJAMIAJAMIAJAMIAJAMIAFAPIAAASIABASIABASIgHAYIgMAbIgeAiMgnkAdAIguAWIgdADIgVgDg"
    );
    var mask_graphics_72 = new cjs.Graphics().p(
      "A0DT5IgVgDIgRgGIgNgJIgNgJIgJgMIgNgJIgFgQIgJgMIgEgPIgFgPIgBgSIADgVIADgVIAMgbIAmghIgDgEMAnjgdAIArgTIAdgCIAZAAIAQAGIARAGIARAGIANAKIAJAMIAJAMIAJAMIAJAMIAFAPIAAASIABASIABASIgHAYIgMAbIgeAiMgnkAdAIguAWIgdADIgVgEg"
    );
    var mask_graphics_73 = new cjs.Graphics().p(
      "A0DWBIgVgDIgRgGIgNgJIgNgJIgJgMIgNgJIgFgQIgJgMIgEgPIgFgPIgBgSIADgVIADgVIAMgbIAmghIgDgEMAnjgdAIArgTIAdgDIAZABIAQAGIARAGIARAGIANAJIAJANIAJAMIAJAMIAJAMIAFAPIAAASIABASIABASIgHAYIgMAbIgeAiMgnkAdAIguAWIgdADIgVgEg"
    );
    var mask_graphics_74 = new cjs.Graphics().p(
      "A0DYJIgVgDIgRgGIgNgJIgNgJIgJgMIgNgKIgFgPIgJgMIgEgPIgFgPIgBgSIADgVIADgVIAMgbIAmghIgDgFMAnjgc/IArgTIAdgDIAZABIAQAGIARAGIARAGIANAJIAJANIAJAMIAJAMIAJAMIAFAPIAAASIABASIABASIgHAYIgMAbIgeAiMgnkAdAIguAWIgdADIgVgEg"
    );
    var mask_graphics_75 = new cjs.Graphics().p(
      "A0DaRIgVgDIgRgGIgNgJIgNgJIgJgMIgNgKIgFgPIgJgMIgEgPIgFgPIgBgSIADgVIADgVIAMgbIAmgiIgDgEMAnjgc/IArgTIAdgDIAZABIAQAGIARAGIARAGIANAJIAJAMIAJANIAJAMIAJAMIAFAPIAAASIABASIABASIgHAYIgMAbIgeAiMgnkAdAIguAWIgdADIgVgEg"
    );
    var mask_graphics_76 = new cjs.Graphics().p(
      "A0DcZIgVgDIgRgGIgNgJIgNgJIgJgMIgNgKIgFgPIgJgMIgEgPIgFgPIgBgSIADgVIADgVIAMgbIAmgiIgDgEMAnjgc/IArgTIAdgDIAZABIAQAGIARAGIARAGIANAJIAJAMIAJANIAJAMIAJAMIAFAPIAAASIABASIABASIgHAYIgMAbIgeAiMgnkAdAIguAWIgdADIgVgEg"
    );
    var mask_graphics_77 = new cjs.Graphics().p(
      "A0DehIgVgDIgRgGIgNgJIgNgJIgJgMIgNgKIgFgPIgJgMIgEgPIgFgPIgBgSIADgVIADgVIAMgbIAmgiIgDgEMAnjgc/IArgTIAdgDIAZABIARAGIAQAGIARAGIANAJIAJAMIAJANIAJAMIAJAMIAFAPIAAASIABASIABASIgHAXIgMAbIgeAiMgnkAdBIguAWIgdADIgVgEg"
    );
    var mask_graphics_78 = new cjs.Graphics().p(
      "EgU2AgpIgUgDIgRgGIgNgJIgNgJIgJgNIgNgJIgFgPIgJgMIgFgPIgEgPIgBgSIADgVIADgVIAMgbIAlgiIgDgEMAnkgdAIAqgSIAdgDIAZABIARAGIARAGIARAGIANAIIAJAMIAJAMIAJANIAJAMIAEAPIABASIABASIABASIgHAYIgMAbIgeAiMgnkAdBIgvAWIgdADIgVgEg"
    );

    this.timeline.addTween(
      cjs.Tween.get(mask)
        .to({
          graphics: null,
          x: 0,
          y: 0
        })
        .wait(15)
        .to({ graphics: mask_graphics_15, x: 265.084, y: -79.5095 })
        .wait(1)
        .to({
          graphics: mask_graphics_16,
          x: 245.7905,
          y: -50.6452
        })
        .wait(1)
        .to({ graphics: mask_graphics_17, x: 226.4976, y: -21.7809 })
        .wait(1)
        .to({
          graphics: mask_graphics_18,
          x: 207.2048,
          y: 7.0834
        })
        .wait(1)
        .to({ graphics: mask_graphics_19, x: 187.9119, y: 35.9477 })
        .wait(1)
        .to({
          graphics: mask_graphics_20,
          x: 168.619,
          y: 64.8119
        })
        .wait(1)
        .to({ graphics: mask_graphics_21, x: 149.3262, y: 93.6762 })
        .wait(1)
        .to({
          graphics: mask_graphics_22,
          x: 120.6681,
          y: 114.0753
        })
        .wait(1)
        .to({ graphics: mask_graphics_23, x: 82.0824, y: 127.6712 })
        .wait(1)
        .to({
          graphics: mask_graphics_24,
          x: 43.4966,
          y: 141.2676
        })
        .wait(1)
        .to({ graphics: mask_graphics_25, x: 4.9109, y: 154.8641 })
        .wait(1)
        .to({
          graphics: mask_graphics_26,
          x: -33.6748,
          y: 168.4605
        })
        .wait(1)
        .to({ graphics: mask_graphics_27, x: -72.2605, y: 182.0569 })
        .wait(1)
        .to({
          graphics: mask_graphics_28,
          x: -110.8462,
          y: 195.6533
        })
        .wait(1)
        .to({ graphics: mask_graphics_29, x: -144.416, y: 209.2503 })
        .wait(1)
        .to({
          graphics: null,
          x: 0,
          y: 0
        })
        .wait(34)
        .to({ graphics: mask_graphics_64, x: 265.084, y: -79.5095 })
        .wait(1)
        .to({
          graphics: mask_graphics_65,
          x: 245.7905,
          y: -50.6452
        })
        .wait(1)
        .to({ graphics: mask_graphics_66, x: 226.4976, y: -21.7809 })
        .wait(1)
        .to({
          graphics: mask_graphics_67,
          x: 207.2048,
          y: 7.0834
        })
        .wait(1)
        .to({ graphics: mask_graphics_68, x: 187.9119, y: 35.9477 })
        .wait(1)
        .to({
          graphics: mask_graphics_69,
          x: 168.619,
          y: 64.8119
        })
        .wait(1)
        .to({ graphics: mask_graphics_70, x: 149.3262, y: 93.6762 })
        .wait(1)
        .to({
          graphics: mask_graphics_71,
          x: 120.6681,
          y: 114.0753
        })
        .wait(1)
        .to({ graphics: mask_graphics_72, x: 82.0824, y: 127.6712 })
        .wait(1)
        .to({
          graphics: mask_graphics_73,
          x: 43.4966,
          y: 141.2676
        })
        .wait(1)
        .to({ graphics: mask_graphics_74, x: 4.9109, y: 154.8641 })
        .wait(1)
        .to({
          graphics: mask_graphics_75,
          x: -33.6748,
          y: 168.4605
        })
        .wait(1)
        .to({ graphics: mask_graphics_76, x: -72.2605, y: 182.0569 })
        .wait(1)
        .to({
          graphics: mask_graphics_77,
          x: -110.8462,
          y: 195.6533
        })
        .wait(1)
        .to({ graphics: mask_graphics_78, x: -144.416, y: 209.2503 })
        .wait(1)
        .to({
          graphics: null,
          x: 0,
          y: 0
        })
        .wait(1)
    );

    // Layer_1
    this.instance = new lib._6_star_f2();
    this.instance.parent = this;
    this.instance._off = true;

    var maskedShapeInstanceList = [this.instance];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(
      cjs.Tween.get(this.instance)
        .wait(19)
        .to({ _off: false }, 0)
        .wait(4)
        .to(
          {
            x: -52,
            y: 42
          },
          0
        )
        .wait(1)
        .to({ x: -92, y: 72 }, 0)
        .wait(1)
        .to({ x: -126, y: 98 }, 0)
        .wait(1)
        .to(
          {
            x: -168,
            y: 126
          },
          0
        )
        .wait(1)
        .to({ x: -208, y: 152 }, 0)
        .wait(1)
        .to({ x: -322, y: 234 }, 0)
        .wait(1)
        .to(
          {
            x: -550,
            y: 398
          },
          0
        )
        .to({ _off: true }, 1)
        .wait(38)
        .to({ _off: false, x: 0, y: 0 }, 0)
        .wait(4)
        .to(
          {
            x: -52,
            y: 42
          },
          0
        )
        .wait(1)
        .to({ x: -92, y: 72 }, 0)
        .wait(1)
        .to({ x: -126, y: 98 }, 0)
        .wait(1)
        .to(
          {
            x: -168,
            y: 126
          },
          0
        )
        .wait(1)
        .to({ x: -208, y: 152 }, 0)
        .wait(1)
        .to({ x: -322, y: 234 }, 0)
        .wait(1)
        .to(
          {
            x: -550,
            y: 398
          },
          0
        )
        .to({ _off: true }, 1)
        .wait(1)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-294.1, 0, 583.1, 423.8);

  (lib.q_bb = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#3EFFC8")
      .s()
      .p(
        "AleHpQjLh/g1jpQg1jnB/jLQB+jLDpg1QDog2DKB/QDLB/A2DpQAWBfgLBgQgKBegoBWIBuBFQAZAQAHAdQAHAdgQAZQgQAZgdAHQgdAGgZgPIhvhFQg7BJhQAyQhTA1hfAVQhFAQhCAAQidAAiPhZgAiNmlQiuAohfCYQhgCZAoCtQAoCvCZBfQCYBfCugoQCGgfBchnIhphCQgZgQgHgdQgGgdAPgZQAQgZAdgHQAdgHAaAQIBpBCQA1iBgfiFQgoiviZhfQhrhDh2AAQgxAAg0AMg"
      );
    this.shape.setTransform(0.0387, 0.0272);

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
  }).prototype = getMCSymbolPrototype(lib.q_bb, new cjs.Rectangle(-62.2, -57.8, 124.5, 115.69999999999999), null);

  (lib.Path = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .lf(["rgba(154,255,238,0)", "#9AFFEE"], [0, 1], 0, 12.6, 0, -133.6)
      .s()
      .p("AqkQnMACOghNIQtAAMACOAhNg");
    this.shape.setTransform(67.7, 106.275);

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
  }).prototype = getMCSymbolPrototype(lib.Path, new cjs.Rectangle(0, 0, 135.4, 212.6), null);

  (lib.Group = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .lf(["rgba(154,255,238,0)", "#9AFFEE"], [0, 1], 0, 25.3, 0, 92.1)
      .s()
      .p(
        "AhNKMQggghAAgtIAAiRImNAAIhdCyQgPAbgaAQQgbAQgeAAQgcAAgXgNQgogVgOgsQgOgsAWgoIAnhLIjhAAQgrAAgfgeQgegfAAgsQAAgrAegfQAfgfArAAIBAAAQAEleDukDQDskBFZgkIC7AAQFZAkDsEBQDuEDADFeIBAAAQAsAAAfAfQAeAfAAArQAAAsgeAfQgfAegsAAIjlAAIAsBUQAVApgNArQgOAsgoAVQgZANgaAAQgfAAgagQQgagPgPgcIhii7ImIAAIAACRQAAAtggAhQggAgguAAQgtAAggggg"
      );
    this.shape.setTransform(108.525, 81.475);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics
      .lf(["rgba(154,255,238,0)", "#9AFFEE"], [0, 1], 0, 100.2, 0, 167)
      .s()
      .p("AhdBCIAAgmQAAglAcgcQAbgbAmgBQAnABAbAbQAcAcAAAlIAAAmg");
    this.shape_1.setTransform(108.525, 6.55);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({ state: [{ t: this.shape_1 }, { t: this.shape }] })
        .wait(1)
    );
  }).prototype = getMCSymbolPrototype(lib.Group, new cjs.Rectangle(0, 0, 217.1, 149.9), null);

  (lib.deli_bb = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#FFFFFF")
      .s()
      .p(
        "EAtgAIwIk5kIIAADRQAAAegVAVQgVAVgeAAQgeAAgVgVQgVgVAAgeIAAtlQAAgeAVgVQAVgVAeAAQAeAAAVAVQAVAVAAAeIAAICIE5kIQAXgTAeADQAdACAUAXQATAXgDAdQgCAegXATIkODjIEODjQAXATACAeQADAegTAXQgXAZggAAQgbAAgUgRgAdaImQhBgbgzg0QgygzgahBQgahCABhIQACiNBlhlQBmhnCNgDQBhgCBVAwQBUAvAxBTQAPAagIAdQgIAdgZAPQgaAPgdgIQgdgIgPgaQgcgwgxgdQgxgcg4AAIgFAAQhUACg8A9Qg9A9gBBUQAAAqAQAnQAPAnAeAfQAeAeAnAQQAmAQArAAQA4AAAxgcQAxgcAcgxQAPgaAdgHQAdgIAaAPQAZAPAIAdQAIAcgPAaQgvBShTAwQhTAvheAAQhIAAhBgbgAWJIsQgVgVAAgeIAAqEQAAgdAVgWQAVgVAeAAQAdAAAVAVQAVAWAAAdIAAKEQAAAegVAVQgVAVgdAAQgeAAgVgVgARyIxQgTgQgGgYQhTA4hjAAQiGAAhghfQhfhfAAiGIAAkRQAAgeAVgVQAWgVAdAAQAeAAAVAVQAVAVAAAeIAAERQAABKA1A1QA1A1BLAAQBLAAA1g1QA1g1AAhKIAAkRQAAgeAVgVQAVgVAdAAQAeAAAVAVQAVAVAAAeIAAINQAAAegVAVQgVAVgeAAQgZAAgUgQgAuvIsQgVgVAAgeIAAqEQAAgdAVgWQAVgVAeAAQAeAAAVAVQAVAWAAAdIAAKEQAAAegVAVQgVAVgeAAQgeAAgVgVgAzOIsQgVgVAAgeIAAtqQAAgdAVgWQAVgVAeAAQAeAAAVAVQAVAWAAAdIAANqQAAAegVAVQgVAVgeAAQgeAAgVgVgA8pImQhBgbgyg0QgzgzgahBQgahCABhIQACiNBlhlQBmhnCNgDQBhgCBVAwQBVAvAwBTIABACQALAUAKAcQAQAtAAAoQAAAegVAVQgVAVgeAAInhAAQAJBEAxAzQAeAeAnAQQAnAQAqAAQA4AAAxgcQAxgcAdgxQAOgaAdgHQAdgIAaAPQAaAPAHAdQAIAcgPAaQgvBShTAwQhSAvhfAAQhHAAhCgbgA8HAoQgtAZgcArIFhAAQgdgtgvgZQgvgag2AAIgFAAQg0ABguAbgEguNAJBQgeAAgVgVQgVgVAAgeIAAvxQAAgeAVgVQAVgVAeAAID8AAQDvAACpCpQCpCpAADuQAADvipCpQipCpjvAAgEgtFAGxIC0AAQCzAAB/h/QB/h/AAizQAAiyh/h/Qh/h/izAAIi0AAgAWHk5QgWgWAAgfQAAgfAWgVQAWgWAfAAQAfAAAVAWQAWAWAAAeQAAAfgWAWQgVAWgfAAQgfAAgWgWgAuxk5QgVgWAAgfQAAgfAVgVQAWgWAfAAQAfAAAWAWQAWAWAAAeQAAAfgWAWQgWAWgfAAQgfAAgWgWg"
      );
    this.shape.setTransform(-0.0105, 0.025);

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
  }).prototype = getMCSymbolPrototype(lib.deli_bb, new cjs.Rectangle(-303, -57.7, 606.1, 115.5), null);

  (lib.deli_b = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#FFFFFF")
      .s()
      .p(
        "ADPE/QgTgQgGgYQhTA4hjAAQiFAAhfhfQhghfAAiGIAAkRQABgeAUgVQAWgVAdAAQAeAAAWAVQAUAVABAeIAAERQAABKA1A1QA0A1BKAAQBLAAA1g1QA1g1AAhKIAAkRQAAgeAVgVQAVgVAdAAQAeAAAWAVQAUAVAAAeIAAINQAAAegUAVQgWAVgeAAQgZAAgUgQg"
      );
    this.shape.setTransform(93.05, 24.225);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics
      .f("#FFFFFF")
      .s()
      .p(
        "ACpHqIk4kIIAADRQAAAegVAVQgVAVgeAAQgeAAgVgVQgVgVAAgeIAAtlQAAgeAVgVQAVgVAeAAQAeAAAVAVQAVAVAAAeIAAICIE4kIQAXgTAeADQAdACAUAXQATAXgDAdQgCAegXATIkNDjIENDjQAXATACAeQADAegTAXQgXAZggAAQgbAAgUgRg"
      );
    this.shape_1.setTransform(274.2645, 7.025);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics
      .f("#FFFFFF")
      .s()
      .p(
        "AiDFHQhCgbgygzQgygzgahCQgbhBABhHQACiNBmhnQBlhmCOgDQBggCBUAvQBVAwAxBUIAAABQAMAUAJAcQARAuAAAnQAAAegVAUQgVAVgeAAInhABQAJBDAyAzQAeAeAmARQAnAQAqAAQA4AAAxgdQAxgcAcgxQAPgZAdgIQAcgIAaAPQAaAPAIAdQAHAdgOAZQgwBShSAwQhTAwheAAQhHAAhBgcgAhii2QgtAagcArIFgAAQgdgtgvgaQgvgZg1AAIgFAAQg0ABguAag"
      );
    this.shape_2.setTransform(-170.1768, 22.296);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics
      .f("#FFFFFF")
      .s()
      .p(
        "Ah1FHQhBgbgzgzQgygzgahCQgahBABhHQACiNBlhnQBmhmCMgDQBhgCBVAvQBUAwAxBUQAPAagIAcQgIAdgZAPQgaAPgdgIQgdgHgPgaQgcgxgxgcQgxgcg4AAIgFAAQhTACg8A8Qg9A9gBBUQAAAqAQAnQAPAnAeAeQAeAeAnARQAmAQAqAAQA4AAAxgdQAxgcAcgxQAPgZAdgIQAdgIAaAPQAZAPAIAdQAIAdgPAZQgvBShTAwQhTAwheAAQhHAAhBgcg"
      );
    this.shape_3.setTransform(199.9982, 22.296);

    this.shape_4 = new cjs.Shape();
    this.shape_4.graphics
      .f("#FFFFFF")
      .s()
      .p("Ag0A1QgWgWAAgfQAAgeAWgWQAWgVAeAAQAfAAAWAVQAWAWAAAeQAAAfgWAWQgWAWgfgBQgeAAgWgVg");
    this.shape_4.setTransform(146.725, -36.65);

    this.shape_5 = new cjs.Shape();
    this.shape_5.graphics
      .f("#FFFFFF")
      .s()
      .p("AgyF1QgVgVAAgeIAAqDQAAgeAVgWQAVgUAdAAQAeAAAVAUQAVAWAAAeIAAKDQAAAegVAVQgVAWgegBQgdABgVgWg");
    this.shape_5.setTransform(146.725, 18.3);

    this.shape_6 = new cjs.Shape();
    this.shape_6.graphics
      .f("#FFFFFF")
      .s()
      .p("Ag0A1QgVgWAAgfQAAgeAVgWQAWgVAeAAQAfAAAWAVQAVAWAAAeQAAAfgVAWQgWAWgfgBQgeAAgWgVg");
    this.shape_6.setTransform(-89.3, -36.65);

    this.shape_7 = new cjs.Shape();
    this.shape_7.graphics
      .f("#FFFFFF")
      .s()
      .p("AgyF1QgVgVAAgeIAAqDQAAgeAVgWQAVgUAdAAQAeAAAVAUQAVAWAAAeIAAKDQAAAegVAVQgVAWgegBQgdABgVgWg");
    this.shape_7.setTransform(-89.3, 18.3);

    this.shape_8 = new cjs.Shape();
    this.shape_8.graphics
      .f("#FFFFFF")
      .s()
      .p(
        "Al6JBQgeAAgVgVQgVgVAAgeIAAvxQAAgeAVgVQAVgVAeAAID9AAQDtAACqCpQCpCpAADuQAADvipCpQiqCpjtAAgAkyGxIC1AAQCyAAB/h/QB/h/AAizQAAiyh/h/Qh/h/iyAAIi1AAg"
      );
    this.shape_8.setTransform(-257.975, 0.025);

    this.shape_9 = new cjs.Shape();
    this.shape_9.graphics
      .f("#FFFFFF")
      .s()
      .p("AgyHoQgVgVAAgeIAAtqQAAgdAVgVQAVgWAdAAQAeAAAVAWQAVAVAAAdIAANqQAAAegVAVQgVAWgegBQgdABgVgWg");
    this.shape_9.setTransform(-118.025, 6.8);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({
          state: [
            { t: this.shape_9 },
            { t: this.shape_8 },
            { t: this.shape_7 },
            { t: this.shape_6 },
            { t: this.shape_5 },
            { t: this.shape_4 },
            { t: this.shape_3 },
            { t: this.shape_2 },
            { t: this.shape_1 },
            { t: this.shape }
          ]
        })
        .wait(1)
    );
  }).prototype = getMCSymbolPrototype(lib.deli_b, new cjs.Rectangle(-303, -57.7, 606.1, 115.5), null);

  (lib.beam2_b = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .lf(["rgba(154,255,238,0)", "#CEFFF6"], [0, 1], 0, -17.2, 0, -188.5)
      .s()
      .p("At1QnMAC5ghNIV4AAMAC6AhNg");
    this.shape.setTransform(0.025, 0.025);

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
  }).prototype = getMCSymbolPrototype(lib.beam2_b, new cjs.Rectangle(-88.6, -106.2, 177.3, 212.5), null);

  (lib.Symbol15 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib.Symbol16("synched", 0);
    this.instance.parent = this;
    this.instance.setTransform(22, 22, 1, 1, 0, 0, 0, 22, 22);

    this.timeline.addTween(
      cjs.Tween.get(this.instance)
        .to(
          {
            scaleX: 1.5886,
            scaleY: 1.5886
          },
          10
        )
        .wait(8)
        .to({ startPosition: 0 }, 0)
        .to(
          {
            scaleX: 1,
            scaleY: 1
          },
          8
        )
        .wait(22)
        .to({ startPosition: 0 }, 0)
        .to(
          {
            scaleX: 1.5886,
            scaleY: 1.5886
          },
          10
        )
        .wait(8)
        .to({ startPosition: 0 }, 0)
        .to({ scaleX: 1, scaleY: 1 }, 8)
        .to({ _off: true }, 1)
        .wait(5)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-12.9, -12.9, 69.9, 69.9);

  (lib.Symbol14 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib.Tween20("synched", 0);
    this.instance.parent = this;
    this.instance.setTransform(12, 11);
    this.instance._off = true;

    this.instance_1 = new lib.Tween21("synched", 0);
    this.instance_1.parent = this;
    this.instance_1.setTransform(12, 11);

    this.instance_2 = new lib.Tween22("synched", 0);
    this.instance_2.parent = this;
    this.instance_2.setTransform(12, 11);
    this.instance_2._off = true;

    this.instance_3 = new lib.Tween23("synched", 0);
    this.instance_3.parent = this;
    this.instance_3.setTransform(12, 11);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({ state: [] })
        .to({ state: [{ t: this.instance }] }, 10)
        .to({ state: [{ t: this.instance_1 }] }, 9)
        .to({ state: [{ t: this.instance_2 }] }, 6)
        .to({ state: [{ t: this.instance_3 }] }, 6)
        .to({ state: [] }, 1)
        .to({ state: [{ t: this.instance }] }, 26)
        .to({ state: [{ t: this.instance_1 }] }, 9)
        .to({ state: [{ t: this.instance_2 }] }, 6)
        .to({ state: [{ t: this.instance_3 }] }, 6)
        .wait(1)
    );
    this.timeline.addTween(
      cjs.Tween.get(this.instance)
        .wait(10)
        .to({ _off: false }, 0)
        .to({ _off: true }, 9)
        .wait(39)
        .to({ _off: false }, 0)
        .to({ _off: true }, 9)
        .wait(13)
    );
    this.timeline.addTween(
      cjs.Tween.get(this.instance_2)
        .wait(25)
        .to({ _off: false }, 0)
        .to({ _off: true }, 6)
        .wait(42)
        .to({ _off: false }, 0)
        .to({ _off: true }, 6)
        .wait(1)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(0, 0, 24, 22);

  (lib.Symbol12 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib.Symbol13("synched", 0);
    this.instance.parent = this;
    this.instance.setTransform(1.5, -0.5, 1, 1, 0, 0, 0, 14, 13.5);

    this.timeline.addTween(
      cjs.Tween.get(this.instance)
        .to({ startPosition: 0 }, 18)
        .wait(27)
        .to({ startPosition: 0 }, 0)
        .to({ startPosition: 0 }, 16)
        .wait(1)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-12.5, -14, 28, 27);

  (lib.Symbol11 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib.Tween8("synched", 0);
    this.instance.parent = this;
    this.instance.setTransform(14.5, 14.5);
    this.instance._off = true;

    this.instance_1 = new lib.Tween9("synched", 0);
    this.instance_1.parent = this;
    this.instance_1.setTransform(14.2, 14.2, 1.3448, 1.3448);
    this.instance_1._off = true;

    this.timeline.addTween(
      cjs.Tween.get(this.instance)
        .wait(19)
        .to({ _off: false }, 0)
        .to(
          {
            _off: true,
            scaleX: 1.3448,
            scaleY: 1.3448,
            x: 14.2,
            y: 14.2
          },
          14
        )
        .wait(47)
    );
    this.timeline.addTween(
      cjs.Tween.get(this.instance_1)
        .wait(19)
        .to({ _off: false }, 14)
        .wait(7)
        .to({ startPosition: 0 }, 0)
        .to(
          {
            scaleX: 1,
            scaleY: 1,
            x: 14.5,
            y: 14.5
          },
          11
        )
        .to({ _off: true }, 1)
        .wait(28)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-5.3, -5.3, 39, 39);

  (lib.Symbol9 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_2 (mask)
    var mask = new cjs.Shape();
    mask._off = true;
    var mask_graphics_43 = new cjs.Graphics().p(
      "AhyIeIgKgBIgLgCIgIgDIgLgCIgIgDIgHgEIgGgFIgGgFIgHgEIgGgFIgFgGIgEgGIgHgEIgCgIIgFgGIgEgGIgEgGIgDgIIgCgHIgDgIIgCgHIAAgJIgBgJIAAgJIABgLIACgKIACgLIADgMIAIgPIAHgLIAFgGIAGgIIAUgPIgBgCIRps6IAWgNIARgGIATgEIAMAAIANAAIAMAAIALACIAIADIAJADIAIADIAGAFIAJADIAGAEIAHAFIAEAGIAHAFIAEAGIAEAGIAFAGIAEAGIAFAGIACAHIADAIIACAHIACAIIADAHIAAAJIABAJIAAAJIAAAJIgBALIgEAMIgEAMIgFANIgHAMIgFAGIgGAIIgTARIxpM6IgWAMIgRAHIgTAEIgOABIgNAAg"
    );
    var mask_graphics_44 = new cjs.Graphics().p(
      "AjfIeIgKgBIgLgCIgIgDIgLgCIgIgDIgHgEIgGgFIgGgFIgHgEIgGgFIgFgGIgEgGIgHgEIgCgIIgFgGIgEgGIgEgGIgDgIIgCgHIgDgIIgCgHIAAgJIgBgJIAAgJIABgLIACgKIACgLIADgMIAIgPIAHgLIAFgGIAGgIIAUgPIgBgCIRps6IAWgNIARgGIATgEIAMAAIANAAIAMAAIALACIAIADIAJADIAIADIAGAFIAJADIAGAEIAHAFIAEAGIAHAFIAEAGIAEAGIAFAGIAEAGIAFAGIACAHIADAIIACAHIACAIIADAHIAAAJIABAJIAAAJIAAAJIgBALIgEAMIgEAMIgFANIgHAMIgFAGIgGAIIgTARIxpM6IgWAMIgRAHIgTAEIgOABIgNAAg"
    );
    var mask_graphics_45 = new cjs.Graphics().p(
      "AlMIeIgKgBIgLgCIgIgDIgLgCIgIgDIgHgEIgGgFIgGgFIgHgEIgGgFIgFgGIgEgGIgHgEIgCgIIgFgGIgEgGIgEgGIgDgIIgCgHIgDgIIgCgHIAAgJIgBgJIAAgJIABgLIACgKIACgLIADgMIAIgPIAHgLIAFgGIAGgIIAUgPIgBgCIRps6IAWgNIARgGIATgEIAMAAIANAAIAMAAIALACIAIADIAIADIAJADIAGAFIAJADIAGAEIAHAFIAEAGIAHAFIAEAGIAEAGIAFAGIAEAGIAFAGIACAHIADAIIACAHIACAIIADAHIAAAJIABAJIAAAJIAAAJIgBALIgEAMIgEAMIgFANIgHAMIgFAGIgGAIIgTARIxpM6IgWAMIgRAHIgTAEIgOABIgNAAg"
    );
    var mask_graphics_46 = new cjs.Graphics().p(
      "Am5ImIgKgBIgLgCIgIgDIgLgCIgIgDIgHgEIgGgFIgGgFIgHgEIgGgFIgFgGIgEgGIgHgEIgCgIIgFgGIgEgGIgEgGIgDgIIgCgHIgDgIIgCgHIAAgJIgBgJIAAgJIABgLIACgKIACgLIADgMIAIgPIAHgLIAFgGIAGgIIAUgPIgBgCIRps6IAWgNIARgGIATgEIAMAAIANAAIAMAAIALACIAIADIAIADIAJADIAGAFIAJADIAGAEIAHAFIAEAGIAHAFIAEAGIAEAGIAFAGIAEAGIAFAGIACAHIADAIIACAHIACAIIADAHIAAAJIABAJIAAAJIAAAJIgBALIgEAMIgEAMIgFANIgHAMIgFAGIgGAIIgTARIxpM6IgWAMIgRAHIgTAEIgOABIgNAAg"
    );
    var mask_graphics_47 = new cjs.Graphics().p(
      "AomJ1IgKgBIgLgCIgIgDIgLgCIgIgDIgHgEIgGgFIgGgFIgHgEIgGgFIgFgGIgEgGIgHgEIgCgIIgFgGIgEgGIgEgGIgDgIIgCgHIgDgIIgCgHIAAgJIgBgJIAAgJIABgLIACgKIACgLIADgMIAIgPIAHgLIAFgGIAGgIIAUgPIgBgCIRps6IAWgNIARgGIATgEIAMAAIANAAIAMAAIALACIAIADIAIADIAJADIAGAFIAJADIAGAEIAHAFIAEAGIAHAFIAEAGIAEAGIAFAGIAEAGIAFAGIACAHIADAIIACAHIACAIIADAHIAAAJIABAJIAAAJIAAAJIgBALIgEAMIgEAMIgFANIgHAMIgFAGIgGAIIgTARIxpM6IgWAMIgRAHIgTAEIgOABIgNAAg"
    );
    var mask_graphics_48 = new cjs.Graphics().p(
      "Ao7LEIgKgBIgLgCIgIgDIgLgCIgIgDIgHgEIgGgFIgHgFIgGgEIgGgFIgFgGIgEgGIgHgEIgCgIIgFgGIgEgGIgEgGIgDgIIgCgHIgDgIIgCgHIgBgJIAAgJIAAgJIABgLIACgKIACgLIADgMIAIgPIAHgLIAFgGIAGgIIAUgPIgBgCIRps6IAWgNIARgGIASgEIANAAIAMAAIANAAIAKACIAJADIAIADIAJADIAGAFIAJADIAGAEIAHAFIAEAGIAHAFIAEAGIAEAGIAFAGIAEAGIAFAGIACAHIACAIIADAHIACAIIADAHIAAAJIABAJIAAAJIAAAJIgBALIgEAMIgEAMIgFANIgHAMIgFAGIgHAIIgSARIxqM6IgVAMIgRAHIgTAEIgOABIgNAAg"
    );
    var mask_graphics_49 = new cjs.Graphics().p(
      "Ao7L3IgKgCIgLgBIgIgEIgLgBIgIgDIgHgFIgGgEIgHgFIgGgFIgGgEIgFgGIgEgGIgHgFIgCgHIgFgGIgEgHIgEgGIgDgHIgCgIIgDgHIgCgIIgBgJIAAgJIAAgJIABgKIACgLIACgKIADgMIAIgPIAHgLIAFgHIAGgIIAUgPIgBgCIRps5IAWgNIARgGIASgFIANAAIAMABIANAAIAKABIAJADIAIAEIAJADIAGAEIAJADIAGAFIAHAEIAEAGIAHAFIAEAGIAEAGIAFAGIAEAGIAFAGIACAIIACAHIADAIIACAHIADAIIAAAJIABAJIAAAJIAAAJIgBAKIgEAMIgEAMIgFAOIgHALIgFAHIgHAIIgSAQIxqM6IgVANIgRAGIgTAFIgOABIgNAAg"
    );
    var mask_graphics_50 = new cjs.Graphics().p(
      "Ao7MpIgKgBIgLgCIgIgDIgLgCIgIgDIgHgEIgGgFIgHgEIgGgFIgGgFIgFgGIgEgGIgHgEIgCgIIgFgGIgEgGIgEgGIgDgIIgCgHIgDgIIgCgHIgBgJIAAgJIAAgJIABgLIACgKIACgLIADgMIAIgOIAHgMIAFgGIAGgIIAUgPIgBgCIRps6IAWgNIARgGIASgEIANAAIAMAAIANAAIAKACIAJADIAIADIAJADIAGAFIAJADIAGAEIAHAFIAEAGIAHAFIAEAGIAEAGIAFAGIAEAGIAFAGIACAHIACAIIADAHIACAIIADAIIAAAJIABAJIAAAJIAAAJIgBAKIgEAMIgEAMIgFANIgHAMIgFAGIgHAIIgSARIxqM6IgVANIgRAGIgTAEIgOABIgNAAg"
    );
    var mask_graphics_51 = new cjs.Graphics().p(
      "Ao7NcIgKgCIgLgBIgIgDIgLgCIgIgDIgHgFIgGgEIgHgFIgGgEIgGgFIgFgGIgEgGIgHgFIgCgHIgFgGIgEgGIgEgGIgDgIIgCgIIgDgHIgCgIIgBgJIAAgJIAAgJIABgKIACgLIACgKIADgMIAIgPIAHgLIAFgHIAGgIIAUgOIgBgCIRps6IAWgNIARgGIASgFIANAAIAMABIANAAIAKABIAJAEIAIADIAJADIAGAEIAJADIAGAFIAHAFIAEAGIAHAEIAEAGIAEAGIAFAGIAEAGIAFAGIACAIIACAIIADAHIACAIIADAHIAAAJIABAJIAAAJIAAAJIgBALIgEAMIgEALIgFAOIgHALIgFAHIgHAIIgSAPIxqM7IgVANIgRAGIgTAFIgOABIgNAAg"
    );
    var mask_graphics_52 = new cjs.Graphics().p(
      "Ao7OOIgKgBIgLgCIgIgDIgLgCIgIgDIgHgEIgGgFIgHgEIgGgFIgGgFIgFgGIgEgGIgHgEIgCgIIgFgGIgEgGIgEgGIgDgHIgCgIIgDgHIgCgIIgBgJIAAgJIAAgJIABgLIACgKIACgKIADgMIAIgPIAHgMIAFgGIAGgIIAUgPIgBgCIRps6IAWgNIARgGIASgEIANAAIAMAAIANAAIAKACIAJADIAIADIAJADIAGAFIAJADIAGAFIAHAEIAEAGIAHAFIAEAGIAEAGIAFAGIAEAGIAFAGIACAIIACAHIADAIIACAHIADAIIAAAJIABAJIAAAJIAAAJIgBAKIgEAMIgEALIgFANIgHAMIgFAGIgHAIIgSARIxqM7IgVANIgRAGIgTAEIgOACIgNgBg"
    );
    var mask_graphics_53 = new cjs.Graphics().p(
      "Ao7PBIgKgCIgLgBIgIgDIgLgCIgIgDIgHgFIgGgEIgHgFIgGgEIgGgFIgFgGIgEgGIgHgFIgCgHIgFgGIgEgGIgEgGIgDgIIgCgHIgDgIIgCgHIgBgJIAAgJIAAgJIABgLIACgKIACgLIADgMIAIgPIAHgLIAFgHIAGgIIAUgOIgBgCIRps6IAWgNIARgGIASgFIANABIAMAAIANAAIAKACIAJADIAIADIAJADIAGAEIAJAEIAGAEIAHAFIAEAGIAHAEIAEAGIAEAGIAFAGIAEAGIAFAHIACAHIACAIIADAHIACAIIADAHIAAAJIABAIIAAAJIAAAJIgBALIgEAMIgEAMIgFANIgHALIgFAHIgHAIIgSARIxqM6IgVANIgRAHIgTAEIgOABIgNAAg"
    );
    var mask_graphics_54 = new cjs.Graphics().p(
      "Ao7P0IgKgCIgLgCIgIgDIgLgBIgIgEIgHgEIgGgFIgHgEIgGgFIgGgEIgFgGIgEgHIgHgEIgCgIIgFgGIgEgGIgEgGIgDgHIgCgIIgDgHIgCgIIgBgJIAAgJIAAgJIABgKIACgLIACgKIADgMIAIgPIAHgMIAFgGIAGgIIAUgPIgBgCIRps6IAWgMIARgHIASgEIANAAIAMAAIANAAIAKACIAJADIAIADIAJADIAGAFIAJADIAGAFIAHAEIAEAGIAHAFIAEAGIAEAGIAFAGIAEAGIAFAGIACAHIACAHIADAIIACAHIADAIIAAAJIABAJIAAAJIAAAJIgBAKIgEAMIgEAMIgFAOIgHALIgFAGIgHAIIgSARIxqM7IgVANIgRAGIgTAEIgOACIgNAAg"
    );
    var mask_graphics_55 = new cjs.Graphics().p(
      "Ao7QmIgKgCIgLgBIgIgDIgLgCIgIgDIgHgFIgGgEIgHgFIgGgEIgGgFIgFgGIgEgGIgHgFIgCgHIgFgGIgEgGIgEgGIgDgIIgCgHIgDgIIgCgHIgBgJIAAgJIAAgJIABgLIACgKIACgLIADgMIAIgPIAHgLIAFgHIAGgHIAUgPIgBgCIRps7IAWgMIARgGIASgEIANAAIAMAAIANAAIAKACIAJADIAIADIAJADIAGAFIAJADIAGADIAHAFIAEAGIAHAEIAEAGIAEAHIAFAGIAEAGIAFAGIACAHIACAIIADAHIACAIIADAHIAAAJIABAJIAAAJIAAAJIgBALIgEAMIgEAMIgFANIgHAMIgFAGIgHAIIgSARIxqM7IgVAMIgRAHIgTAEIgOABIgNAAg"
    );
    var mask_graphics_56 = new cjs.Graphics().p(
      "Ao7RZIgKgCIgLgCIgIgDIgLgBIgIgDIgHgFIgGgFIgHgEIgGgFIgGgEIgFgGIgEgGIgHgFIgCgIIgFgGIgEgGIgEgGIgDgHIgCgIIgDgHIgCgIIgBgJIAAgJIAAgJIABgKIACgLIACgKIADgMIAIgPIAHgMIAFgGIAGgIIAUgPIgBgCIRps7IAWgMIARgHIASgEIANAAIAMAAIANABIAKABIAJADIAIADIAJADIAGAFIAJADIAGAFIAHAEIAEAGIAHAFIAEAGIAEAGIAFAGIAEAGIAFAGIACAIIACAHIADAIIACAHIADAIIAAAJIABAJIAAAJIAAAJIgBAKIgEAMIgEAMIgFAOIgHALIgFAHIgHAHIgSARIxqM7IgVANIgRAGIgTAEIgOACIgNAAg"
    );
    var mask_graphics_57 = new cjs.Graphics().p(
      "ApUSLIgLgBIgKgCIgJgDIgKgCIgJgDIgGgEIgHgFIgGgFIgHgEIgGgFIgFgGIgEgGIgGgEIgDgIIgEgGIgFgGIgEgGIgCgIIgDgHIgCgIIgDgHIAAgJIAAgJIgBgJIACgLIABgKIACgLIAEgMIAHgPIAHgLIAFgGIAHgIIAUgPIgCgCIRqs7IAVgNIARgGIATgEIAMAAIANAAIAMAAIALACIAIADIAJADIAIADIAHAFIAIADIAHAEIAGAFIAEAGIAHAFIAEAGIAFAGIAEAGIAFAGIAEAGIACAHIADAIIACAHIADAIIACAHIABAJIAAAJIAAAJIABAJIgCALIgEAMIgDAMIgGANIgHAMIgFAGIgGAIIgTARIxpM7IgWAMIgRAHIgSAEIgPABIgMAAg"
    );

    this.timeline.addTween(
      cjs.Tween.get(mask)
        .to({
          graphics: null,
          x: 0,
          y: 0
        })
        .wait(43)
        .to({ graphics: mask_graphics_43, x: 115.1403, y: 8.4363 })
        .wait(1)
        .to({
          graphics: mask_graphics_44,
          x: 104.2403,
          y: 24.2363
        })
        .wait(1)
        .to({ graphics: mask_graphics_45, x: 93.3403, y: 40.0363 })
        .wait(1)
        .to({
          graphics: mask_graphics_46,
          x: 82.4403,
          y: 55.0365
        })
        .wait(1)
        .to({ graphics: mask_graphics_47, x: 71.5403, y: 62.9365 })
        .wait(1)
        .to({
          graphics: mask_graphics_48,
          x: 51.8511,
          y: 70.8365
        })
        .wait(1)
        .to({ graphics: mask_graphics_49, x: 37.8066, y: 75.8921 })
        .wait(1)
        .to({
          graphics: mask_graphics_50,
          x: 23.7622,
          y: 80.9476
        })
        .wait(1)
        .to({ graphics: mask_graphics_51, x: 9.7177, y: 86.0032 })
        .wait(1)
        .to({
          graphics: mask_graphics_52,
          x: -4.3267,
          y: 91.0587
        })
        .wait(1)
        .to({ graphics: mask_graphics_53, x: -18.3711, y: 96.1143 })
        .wait(1)
        .to({
          graphics: mask_graphics_54,
          x: -32.4156,
          y: 101.1698
        })
        .wait(1)
        .to({ graphics: mask_graphics_55, x: -46.46, y: 106.2254 })
        .wait(1)
        .to({
          graphics: mask_graphics_56,
          x: -60.5045,
          y: 111.2809
        })
        .wait(1)
        .to({ graphics: mask_graphics_57, x: -71.9892, y: 116.3365 })
        .wait(6)
        .to({
          graphics: null,
          x: 0,
          y: 0
        })
        .wait(17)
    );

    // Layer_1
    this.instance = new lib._7_star_f3();
    this.instance.parent = this;

    this.instance_1 = new lib.Tween6("synched", 0);
    this.instance_1.parent = this;
    this.instance_1.setTransform(89, 59.5);
    this.instance_1._off = true;

    this.instance_2 = new lib.Tween7("synched", 0);
    this.instance_2.parent = this;
    this.instance_2.setTransform(-42, 156.5);
    this.instance_2._off = true;

    var maskedShapeInstanceList = [this.instance, this.instance_1, this.instance_2];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({ state: [] })
        .to({ state: [{ t: this.instance }] }, 43)
        .to({ state: [{ t: this.instance_1 }] }, 5)
        .to({ state: [{ t: this.instance_2 }] }, 9)
        .to({ state: [{ t: this.instance_2 }] }, 5)
        .to({ state: [] }, 1)
        .wait(17)
    );
    this.timeline.addTween(
      cjs.Tween.get(this.instance_1)
        .wait(48)
        .to({ _off: false }, 0)
        .to(
          {
            _off: true,
            x: -42,
            y: 156.5
          },
          9
        )
        .wait(23)
    );
    this.timeline.addTween(
      cjs.Tween.get(this.instance_2)
        .wait(48)
        .to({ _off: false }, 9)
        .to(
          {
            x: -154,
            y: 234.5
          },
          5
        )
        .to({ _off: true }, 1)
        .wait(17)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-149.1, 0, 327.1, 237.9);

  (lib.Symbol5 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_6 (mask)
    var mask = new cjs.Shape();
    mask._off = true;
    var mask_graphics_4 = new cjs.Graphics().p(
      "ACjMnIgLgBIgKgBIgJgDIgKgBIgJgDIgHgFIgGgEIgHgFIgGgEIgHgEIgEgGIgFgGIgHgFQAAgEgCgDIgFgGIgEgGIgFgGIgDgHQAAgEgCgEQgBgEgCgDQAAgEgCgEIgBgJIAAgJIgBgJIABgKIACgLIABgKIADgMIAIgQIAGgLIAFgHIAGgIIAUgPIgCgCIbd1NIAWgNIAQgHIATgFIANAAIAMAAIANAAIAKACIAIACIAJADIAJADIAGAEIAJADIAGAFIAHAEIAFAGIAGAEIAFAGIAEAGIAFAGIAEAGIAFAGIADAIIACAHIADAHIACAIIADAHIABAJIAAAJIABAJIABAKIgCAKIgDAMIgDAMIgGAOIgHALIgEAHIgGAIIgTARI7dVNIgVANIgRAHIgSAFIgPABIgGABIgGgBg"
    );
    var mask_graphics_5 = new cjs.Graphics().p(
      "AA3MnIgLgBIgKgBIgJgDIgKgBIgJgDIgGgFIgGgEIgHgFIgGgEIgHgEIgEgGIgFgGIgHgFQAAgEgCgDIgFgGIgEgGIgFgGIgDgHQAAgEgCgEQgBgEgCgDQAAgEgCgEIgBgJIAAgJIgBgJIABgKIACgLIABgKIADgMIAIgQIAGgLIAFgHIAGgIIAUgPIgCgCIbc1NIAWgNIAQgHIATgFIANAAIAMAAIANAAIAKACIAIACIAJADIAJADIAGAEIAJADIAGAFIAHAEIAFAGIAGAEIAFAGIAEAGIAFAGIAEAGIAFAGIADAIIACAHIADAHIACAIIADAHIABAJIAAAJIABAJIABAKIgCAKIgDAMIgDAMIgGAOIgHALIgEAHIgGAIIgTARI7dVNIgVANIgRAHIgSAFIgPABIgGABIgGgBg"
    );
    var mask_graphics_6 = new cjs.Graphics().p(
      "Ag0MnIgLgBIgKgBIgJgDIgKgBIgJgDIgHgFIgGgEIgHgFIgGgEIgHgEIgEgGIgFgGIgHgFQAAgEgCgDIgFgGIgEgGIgFgGIgDgHQAAgEgCgEQgBgEgCgDQAAgEgCgEIgBgJIAAgJIgBgJIABgKIACgLIABgKIADgMIAIgQIAGgLIAFgHIAGgIIAUgPIgCgCIbc1NIAWgNIAQgHIATgFIANAAIAMAAIANAAIAKACIAIACIAJADIAJADIAGAEIAJADIAGAFIAHAEIAFAGIAGAEIAFAGIAEAGIAFAGIAEAGIAFAGIADAIIACAHIADAHIACAIIADAHIABAJIAAAJIABAJIABAKIgCAKIgDAMIgDAMIgGAOIgHALIgEAHIgGAIIgTARI7dVNIgVANIgQAHIgSAFIgPABIgGABIgGgBg"
    );
    var mask_graphics_7 = new cjs.Graphics().p(
      "AoWMnIgKgBIgLgBIgIgDIgLgBIgIgDIgHgFIgHgEIgGgFIgHgEIgGgEIgFgGIgFgGIgGgFQgBgEgCgDIgEgGIgFgGIgEgGIgDgHQgBgEgCgEQAAgEgCgDQgBgEgCgEIAAgJIgBgJIgBgJIACgKIABgLIABgKIAEgMIAHgQIAHgLIAEgHIAHgIIATgPIgBgCIbc1NIAVgNIARgHIATgFIAMAAIANAAIAMAAIALACIAIACIAJADIAIADIAHAEIAIADIAHAFIAGAEIAFAGIAHAEIAEAGIAFAGIAEAGIAFAGIAFAGIACAIIADAHIACAHIADAIIADAHIAAAJIABAJIAAAJIABAKIgBAKIgDAMIgEAMIgFAOIgHALIgEAHIgHAIIgSARI7cVNIgVANIgRAHIgTAFIgOABIgHABIgGgBg"
    );
    var mask_graphics_8 = new cjs.Graphics().p(
      "AqZNqIgKgBIgLgBIgIgDIgLgBIgIgDIgHgFIgHgEIgGgFIgHgEIgGgEIgFgGIgFgGIgGgFQgBgEgCgDIgEgGIgFgGIgEgGIgDgHQgBgEgCgEQAAgEgCgDQgBgEgCgEIAAgJIgBgJIgBgJIACgKIABgLIABgKIAEgMIAHgQIAHgLIAEgHIAHgIIATgPIgBgCIbc1NIAVgNIARgHIATgFIAMAAIANAAIAMAAIALACIAIACIAJADIAIADIAHAEIAIADIAHAFIAGAEIAFAGIAHAEIAEAGIAFAGIAEAGIAFAGIAFAGIACAIIADAHIACAHIADAIIADAHIAAAJIABAJIAAAJIABAKIgBAKIgDAMIgEAMIgFAOIgHALIgEAHIgHAIIgSARI7cVNIgVANIgRAHIgTAFIgOABIgHABIgGgBg"
    );
    var mask_graphics_9 = new cjs.Graphics().p(
      "AscPRIgKgCIgLgBIgIgDIgLgBIgIgDIgHgEIgHgFIgGgEIgHgFIgGgEIgFgGIgFgGIgGgEQgBgEgCgEIgEgGIgFgGIgEgFIgDgIQgBgEgCgDQAAgEgCgEQgBgEgCgDIAAgJIgBgJIgBgKIACgKIABgKIABgLIAEgMIAHgPIAHgMIAEgGIAHgIIATgQIgBgCIbc1MIAVgOIARgGIATgFIAMAAIANAAIAMAAIALABIAIACIAJADIAIADIAHAFIAIADIAHAEIAGAFIAFAFIAHAFIAEAGIAFAGIAEAGIAFAGIAFAGIACAHIADAHIACAIIADAHIADAIIAAAJIABAJIAAAJIABAJIgBAKIgDAMIgEAMIgFAOIgHAMIgEAGIgHAIIgSASI7cVMIgVAOIgRAGIgTAFIgOACIgGAAIgHAAg"
    );
    var mask_graphics_10 = new cjs.Graphics().p(
      "AtxQ3IgLgBIgKgBIgJgDIgKgBIgJgDIgHgFIgGgEIgGgFIgHgEIgHgEIgEgGIgFgGIgGgFQgBgEgCgDIgEgGIgFgGIgEgGIgEgHQAAgEgCgEQAAgEgCgDQgBgEgCgEIAAgJIgBgJIgBgJIACgKIABgLIABgKIADgMIAIgQIAHgLIAEgHIAGgIIAUgPIgCgCIbc1NIAWgNIAQgHIAUgFIAMAAIAMAAIANAAIALACIAIACIAJADIAIADIAGAEIAJADIAGAFIAHAEIAFAGIAGAEIAFAGIAEAGIAFAGIAEAGIAGAGIACAIIACAHIADAHIADAIIACAHIABAJIAAAJIABAJIABAKIgCAKIgCAMIgEAMIgFAOIgIALIgDAHIgHAIIgSARI7cVNIgWANIgRAHIgSAFIgOABIgHABIgGgBg"
    );
    var mask_graphics_11 = new cjs.Graphics().p(
      "AtxSqIgLgBIgKgBIgIgDIgLgBIgIgDIgIgFIgGgEIgGgFIgHgEIgHgEIgEgGIgFgGIgHgFQAAgEgCgDIgEgGIgFgGIgEgGIgEgHQAAgEgCgEQgBgEgBgDQgBgEgCgEIgBgJIAAgJIgBgJIABgKIACgLIABgKIADgMIAIgQIAHgLIAEgHIAHgIIATgPIgBgCIbb1NIAWgNIAQgHIATgFIANAAIAMAAIANAAIALACIAIACIAJADIAIADIAGAEIAJADIAHAFIAGAEIAFAGIAHAEIAEAGIAFAGIAEAGIAFAGIAEAGIADAIIADAHIACAHIADAIIADAHIAAAJIAAAJIABAJIABAKIgCAKIgCAMIgEAMIgFAOIgHALIgFAHIgGAIIgSARI7dVNIgVANIgRAHIgSAFIgOABIgHABIgGgBg"
    );
    var mask_graphics_12 = new cjs.Graphics().p(
      "AtxUdIgKgBIgLgBIgIgDIgLgBIgIgDIgHgFIgHgEIgHgFIgGgEIgGgEIgFgGIgFgGIgHgFQAAgEgCgDIgFgGIgEgGIgFgGIgDgHQAAgEgCgEQAAgEgDgDQAAgEgCgEIgBgJIAAgJIgBgJIABgKIACgLIABgKIAEgMIAHgQIAGgLIAFgHIAHgIIATgPIgBgCIbc1NIAVgNIAQgHIATgFIANAAIANAAIAMAAIAKACIAIACIAJADIAJADIAGAEIAJADIAHAFIAGAEIAFAGIAHAEIAEAGIAFAGIAEAGIAFAGIAEAGIADAIIADAHIACAHIACAIIAEAHIAAAJIABAJIAAAJIABAKIgBAKIgEAMIgDAMIgGAOIgGALIgFAHIgGAIIgTARI7cVNIgUANIgSAHIgSAFIgPABIgGABIgGgBg"
    );
    var mask_graphics_13 = new cjs.Graphics().p(
      "AtxWQIgKgBIgLgBIgJgDIgKgBIgJgDIgGgFIgHgEIgHgFIgGgEIgGgEIgFgGIgFgGIgGgFQgBgEgCgDIgFgGIgEgGIgFgGIgCgHQgBgEgCgEQgBgEgCgDQAAgEgCgEIAAgJIgBgJIgBgJIACgKIABgLIABgKIAEgMIAHgQIAGgLIAFgHIAGgIIAUgPIgCgCIbd1NIAVgNIARgHIATgFIAMAAIANAAIAMAAIAKACIAIACIAJADIAJADIAHAEIAIADIAGAFIAHAEIAFAGIAGAEIAFAGIAEAGIAFAGIAEAGIAFAGIADAIIADAHIACAHIACAIIADAHIABAJIABAJIAAAJIABAKIgBAKIgEAMIgDAMIgGAOIgHAKIgEAHIgGAIIgTARI7cVOIgUANIgRAHIgTAFIgPABIgGABIgGgBg"
    );
    var mask_graphics_14 = new cjs.Graphics().p(
      "AtxYDIgLgBIgKgBIgJgDIgKgBIgJgDIgHgFIgGgEIgGgFIgHgEIgHgEIgEgGIgFgGIgGgFQgBgEgCgDIgEgGIgFgGIgEgGIgDgHQgBgEgCgEQAAgEgDgDQAAgEgCgEIAAgJIgBgJIgBgJIACgKIABgLIABgKIADgMIAIgQIAHgLIAEgHIAGgIIAUgPIgCgCIbc1NIAWgNIARgHIATgFIAMAAIAMAAIANAAIAKACIAJACIAJADIAIADIAHAEIAIADIAGAFIAHAEIAFAGIAGAEIAFAGIAEAGIAFAGIAEAGIAGAGIACAIIACAGIADAHIADAIIACAHIABAJIAAAJIABAJIABAKIgCAKIgCAMIgEAMIgGAOIgHALIgDAHIgHAIIgSARI7cVOIgWANIgQAHIgTAFIgOABIgHABIgGgBg"
    );
    var mask_graphics_15 = new cjs.Graphics().p(
      "AtxZ0IgKgCIgLgBIgIgDIgLgBIgIgDIgHgEIgHgFIgHgEIgGgFIgHgEIgEgGIgFgGIgHgEQAAgEgCgEIgFgGIgEgGIgFgFIgDgIQAAgEgCgDQgBgEgBgEQgBgEgCgDIgBgJIAAgJIgBgKIABgKIACgKIABgLIAEgMIAHgPIAGgMIAFgGIAHgIIATgQIgBgCIbc1NIAVgOIAQgGIATgFIANAAIAMAAIANAAIALABIAHACIAJADIAJADIAGAFIAJADIAHAEIAGAFIAFAFIAHAFIAEAGIAFAGIAEAGIAFAGIAEAGIADAHIADAHIACAIIACAHIAEAIIAAAJIABAJIAAAJIABAJIgBAKIgDAMIgEAMIgFAOIgHAMIgFAGIgGAIIgSASI7dVNIgVAOIgRAGIgSAFIgPACIgGAAIgGAAg"
    );
    var mask_graphics_16 = new cjs.Graphics().p(
      "AtxbNIgKgCIgLgBIgIgDIgLgBIgIgDIgHgEIgHgFIgHgEIgGgFIgHgEIgEgGIgFgGIgHgEQAAgEgCgEIgFgGIgEgGIgFgFIgDgIQAAgEgCgDQgBgEgBgEQgBgEgCgDIgBgJIAAgJIgBgKIABgKIACgKIABgLIAEgMIAHgPIAGgMIAFgGIAHgIIATgQIgBgCIbc1NIAVgOIAQgGIATgFIANAAIAMAAIANAAIALABIAHACIAJADIAJADIAGAFIAJADIAHAEIAGAFIAFAFIAHAFIAEAGIAFAGIAEAGIAFAGIAEAGIADAHIADAHIACAIIACAHIAEAIIAAAJIABAJIAAAJIABAJIgBAKIgDAMIgEAMIgFAOIgHAMIgFAGIgGAIIgSASI7dVNIgVAOIgRAGIgSAFIgPACIgGAAIgGAAg"
    );
    var mask_graphics_17 = new cjs.Graphics().p(
      "Auqc1IgKgCIgLgBIgIgDIgLgBIgIgDIgHgEIgHgFIgGgEIgHgFIgGgEIgFgGIgFgGIgGgEQgBgEgCgEIgEgGIgFgGIgEgFIgDgIQgBgEgCgDQAAgEgCgEQgBgEgCgDIAAgJIgBgJIgBgKIACgKIABgKIABgLIAEgMIAHgPIAHgMIAEgGIAHgIIATgQIgBgCIbc1NIAVgOIARgGIATgFIAMAAIANAAIAMAAIALABIAIACIAJADIAIADIAHAFIAIADIAHAEIAGAFIAFAFIAHAFIAEAGIAFAGIAEAGIAFAGIAFAGIACAHIADAHIACAIIADAHIADAIIAAAJIABAJIAAAJIABAJIgBAKIgDAMIgEAMIgFAOIgHAMIgEAGIgHAIIgSASI7cVNIgVAOIgRAGIgTAFIgOACIgGAAIgHAAg"
    );
    var mask_graphics_18 = new cjs.Graphics().p(
      "EgTAAgQIgKgCIgLgBIgIgDIgLgBIgIgDIgHgEIgHgFIgGgEIgHgFIgGgEIgFgGIgFgGIgGgEQgBgEgCgEIgEgGIgFgGIgEgFIgDgIQgBgEgCgDQAAgEgCgEQgBgEgCgDIAAgJIgBgJIgBgKIACgKIABgKIABgLIAEgMIAHgPIAHgMIAEgGIAHgIIATgQIgBgCIbc1NIAVgOIARgGIATgFIAMAAIANAAIAMAAIALABIAIACIAJADIAIADIAHAFIAIADIAHAEIAGAFIAFAFIAHAFIAEAGIAFAGIAEAGIAFAGIAFAGIACAHIADAHIACAIIADAHIADAIIAAAJIABAJIAAAJIABAJIgBAKIgDAMIgEAMIgFAOIgHAMIgEAGIgHAIIgSASI7cVNIgVAOIgRAGIgTAFIgOACIgHAAIgGAAg"
    );
    var mask_graphics_19 = new cjs.Graphics().p(
      "EgdrAoRIgLgBIgKgBIgJgDIgKgBIgIgDIgIgFIgGgEIgGgFIgHgEIgHgEIgEgGIgFgGIgHgFQAAgEgCgDIgEgGIgFgGIgEgGIgEgHQAAgEgCgEQAAgEgCgDQgBgEgCgEIgBgJIAAgJIgBgJIABgKIACgLIABgKIADgMIAIgQIAHgLIAEgHIAHgIIATgPIgBgCIbc1OIAWgNIAQgHIATgFIANAAIAMAAIANAAIALACIAIACIAJADIAIADIAGAEIAJADIAGAFIAHAEIAFAGIAGAEIAFAGIAEAGIAFAGIAEAGIAGAGIACAIIACAHIADAHIADAIIACAHIABAJIAAAJIABAJIABAKIgCAKIgCAMIgEAMIgFAOIgIALIgDAHIgHAIIgSARI7dVOIgWANIgRAHIgSAFIgOABIgHABIgGgBg"
    );

    this.timeline.addTween(
      cjs.Tween.get(mask)
        .to({
          graphics: null,
          x: 0,
          y: 0
        })
        .wait(4)
        .to({ graphics: mask_graphics_4, x: 205.275, y: -34.8875 })
        .wait(1)
        .to({
          graphics: mask_graphics_5,
          x: 194.4719,
          y: -18.5062
        })
        .wait(1)
        .to({ graphics: mask_graphics_6, x: 183.6688, y: -2.125 })
        .wait(1)
        .to({
          graphics: mask_graphics_7,
          x: 135.5125,
          y: 73.6375
        })
        .wait(1)
        .to({ graphics: mask_graphics_8, x: 122.425, y: 87.4542 })
        .wait(1)
        .to({
          graphics: mask_graphics_9,
          x: 109.3375,
          y: 97.7083
        })
        .wait(1)
        .to({ graphics: mask_graphics_10, x: 91.7, y: 107.9625 })
        .wait(1)
        .to({
          graphics: mask_graphics_11,
          x: 61.6875,
          y: 119.4625
        })
        .wait(1)
        .to({ graphics: mask_graphics_12, x: 31.675, y: 130.9625 })
        .wait(1)
        .to({
          graphics: mask_graphics_13,
          x: 1.6625,
          y: 142.4625
        })
        .wait(1)
        .to({ graphics: mask_graphics_14, x: -28.35, y: 153.9625 })
        .wait(1)
        .to({
          graphics: mask_graphics_15,
          x: -60.16,
          y: 165.2225
        })
        .wait(1)
        .to({ graphics: mask_graphics_16, x: -83.47, y: 174.1075 })
        .wait(1)
        .to({
          graphics: mask_graphics_17,
          x: -106.4565,
          y: 184.4925
        })
        .wait(1)
        .to({ graphics: mask_graphics_18, x: -134.2785, y: 206.3775 })
        .wait(1)
        .to({
          graphics: mask_graphics_19,
          x: -202.6,
          y: 257.7625
        })
        .wait(61)
    );

    // Layer_1
    this.instance = new lib._5_star_f();
    this.instance.parent = this;

    this.instance_1 = new lib.Tween1("synched", 0);
    this.instance_1.parent = this;
    this.instance_1.setTransform(140.5, 104);
    this.instance_1._off = true;

    this.instance_2 = new lib.Tween2("synched", 0);
    this.instance_2.parent = this;
    this.instance_2.setTransform(-60.5, 252);

    var maskedShapeInstanceList = [this.instance, this.instance_1, this.instance_2];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({ state: [] })
        .to({ state: [{ t: this.instance }] }, 4)
        .to({ state: [{ t: this.instance_1 }] }, 6)
        .to({ state: [{ t: this.instance_2 }] }, 9)
        .to({ state: [] }, 1)
        .wait(60)
    );
    this.timeline.addTween(
      cjs.Tween.get(this.instance_1)
        .wait(10)
        .to({ _off: false }, 0)
        .to(
          {
            _off: true,
            x: -60.5,
            y: 252
          },
          9
        )
        .wait(61)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-178.6, 0, 459.6, 339.6);

  (lib.Q_b = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#FFFFFF")
      .s()
      .p(
        "AleHpQjLh/g1jpQg1jnB/jLQB+jLDpg1QDog2DKB/QDLB/A2DpQAWBfgLBgQgKBegoBWIBuBFQAZAQAHAdQAHAdgQAZQgQAZgdAHQgdAGgZgPIhvhFQg7BJhQAyQhTA1hfAVQhFAQhCAAQidAAiPhZgAiNmlQiuAohfCYQhgCZAoCtQAoCvCZBfQCYBfCugoQCGgfBchnIhphCQgZgQgHgdQgGgdAPgZQAQgZAdgHQAdgHAaAQIBpBCQA1iBgfiFQgoiviZhfQhrhDh2AAQgxAAg0AMg"
      );
    this.shape.setTransform(0.0387, 0.0272);

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(40));

    // Layer_3
    this.instance = new lib.q_bb();
    this.instance.parent = this;
    this.instance.filters = [new cjs.BlurFilter(26, 26, 3)];
    this.instance.cache(-64, -60, 129, 120);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(40));

    // Layer_4
    this.instance_1 = new lib.q_bb();
    this.instance_1.parent = this;
    this.instance_1.filters = [new cjs.BlurFilter(52, 52, 3)];
    this.instance_1.cache(-64, -60, 129, 120);

    this.timeline.addTween(
      cjs.Tween.get(this.instance_1)
        .to({ alpha: 0 }, 19)
        .to({ alpha: 1 }, 20)
        .wait(1)
    );

    // Layer_2
    this.instance_2 = new lib.q_bb();
    this.instance_2.parent = this;
    this.instance_2.filters = [new cjs.BlurFilter(80, 80, 3)];
    this.instance_2.cache(-64, -60, 129, 120);

    this.timeline.addTween(cjs.Tween.get(this.instance_2).wait(40));
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-202.2, -197.8, 411, 401);

  (lib.Q = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib.Q_b();
    this.instance.parent = this;

    this.timeline.addTween(
      cjs.Tween.get(this.instance)
        .to({ y: -1 }, 5)
        .to({ x: 1, y: 0 }, 5)
        .to(
          {
            x: -1,
            y: 1
          },
          5
        )
        .to({ x: 0, y: 0 }, 5)
        .wait(1)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-171.2, -166.8, 346, 336.8);

  (lib.deli = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_2
    this.instance = new lib.deli_b();
    this.instance.parent = this;

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(80));

    // Layer_3
    this.instance_1 = new lib.deli_bb();
    this.instance_1.parent = this;
    this.instance_1.alpha = 0.5;
    this.instance_1.filters = [new cjs.BlurFilter(10, 10, 3)];
    this.instance_1.cache(-305, -60, 610, 120);

    this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(80));

    // Layer_1
    this.instance_2 = new lib.deli_bb();
    this.instance_2.parent = this;
    this.instance_2.alpha = 0.8008;
    this.instance_2.filters = [new cjs.BlurFilter(35, 35, 3)];
    this.instance_2.cache(-305, -60, 610, 120);

    this.timeline.addTween(
      cjs.Tween.get(this.instance_2)
        .to({ alpha: 0.3008 }, 40)
        .to({ alpha: 0.8008 }, 39)
        .wait(1)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-399, -153.7, 805, 313);

  (lib.beam2 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .lf(["rgba(154,255,238,0)", "#CEFFF6"], [0, 1], 0, -17.2, 0, -188.5)
      .s()
      .p("At1QnMAC5ghNIV4AAMAC6AhNg");
    this.shape.setTransform(0.025, 0.025);

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

    // Layer_2
    this.instance = new lib.beam2_b();
    this.instance.parent = this;
    this.instance.filters = [new cjs.BlurFilter(120, 120, 1)];
    this.instance.cache(-91, -108, 181, 217);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-148.6, -166.2, 300, 336);

  (lib.beam1 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib.Path();
    this.instance.parent = this;
    this.instance.setTransform(0, -0.05, 1, 1, 0, 0, 0, 67.7, 106.2);
    this.instance.alpha = 0.8008;

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    // Layer_2
    this.instance_1 = new lib.Path();
    this.instance_1.parent = this;
    this.instance_1.setTransform(0, -0.05, 1, 1, 0, 0, 0, 67.7, 106.2);
    this.instance_1.filters = [new cjs.BlurFilter(120, 120, 1)];
    this.instance_1.cache(-2, -2, 139, 217);

    this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(1));
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-127.7, -166.2, 258, 336);

  (lib.beam_M = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib.beam1();
    this.instance.parent = this;

    this.instance_1 = new lib.beam2();
    this.instance_1.parent = this;

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({ state: [{ t: this.instance_1 }, { t: this.instance }] })
        .wait(1)
    );
  }).prototype = getMCSymbolPrototype(lib.beam_M, new cjs.Rectangle(-148.6, -166.2, 300, 336), null);

  (lib.ufo_g = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_2
    this.instance = new lib.Group();
    this.instance.parent = this;
    this.instance.setTransform(0, 0, 1, 1, 0, 0, 0, 108.5, 74.9);
    this.instance.alpha = 0.8008;

    this.timeline.addTween(
      cjs.Tween.get(this.instance)
        .wait(4)
        .to({ _off: true }, 1)
        .wait(35)
    );

    // Layer_3
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#323248")
      .s()
      .p("AqsBpQgsAAgegeQgegfgBgsQABgrAegeQAegfAsAAIVZAAQArAAAfAfQAfAeAAArQAAAsgfAfQgfAegrAAg");
    this.shape.setTransform(-29.55, 39);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics
      .f("#4C5672")
      .s()
      .p("AvUBpQgrAAgfgeQgegfAAgsQAAgrAegeQAfgfArAAIeoAAQAsAAAfAfQAeAeAAArQAAAsgeAfQgfAegsAAg");
    this.shape_1.setTransform(0.025, 39);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics
      .lf(["#CC1F72", "#FF2E48", "#FF7237"], [0, 0.502, 1], -58, 44.9, 96.4, -32.8)
      .s()
      .p("As9HzQgkAAgZgZQgagaAAgjQAAliDvkFQBwh8CVhNQCZhOCqgRIC7AAQCqARCZBOQCVBNBwB8QDvEFAAFiQAAAjgaAaQgZAZgkAAg");
    this.shape_2.setTransform(0.025, -11.875);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics
      .f("#4C5672")
      .s()
      .p("AhdBBIAAgkQAAgnAcgbQAbgbAmAAQAnAAAbAbQAcAbAAAnIAAAkg");
    this.shape_3.setTransform(0.025, -68.35);

    this.shape_4 = new cjs.Shape();
    this.shape_4.graphics
      .f("#323248")
      .s()
      .p("AA4E1QgagQgPgcIjgmqQgVgoAOgsQANgrApgWQAogVAsAOQArANAVApIDgGqQAVAogNAsQgOArgoAWQgZAMgaAAQgfAAgagPg");
    this.shape_4.setTransform(58.5381, 41.6489);

    this.shape_5 = new cjs.Shape();
    this.shape_5.graphics
      .f("#323248")
      .s()
      .p("AiiE3QgpgVgNgrQgOgsAVgoIDgmrQAVgoAsgOQArgNApAVQAoAVAOAsQANAsgVAoIjgGqQgOAcgbAQQgaAPgeAAQgcAAgXgNg");
    this.shape_5.setTransform(-58.5119, 40.7396);

    this.shape_6 = new cjs.Shape();
    this.shape_6.graphics
      .f("#323248")
      .s()
      .p("AhNEtQggggAAguIAAm9QAAgtAgggQAgghAtAAQAuAAAgAhQAgAgAAAtIAAG9QAAAuggAgQggAgguAAQgtAAggggg");
    this.shape_6.setTransform(0.025, 41.65);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({
          state: [
            { t: this.shape_6 },
            { t: this.shape_5 },
            { t: this.shape_4 },
            { t: this.shape_3 },
            { t: this.shape_2 },
            { t: this.shape_1 },
            { t: this.shape }
          ]
        })
        .to(
          {
            state: [
              { t: this.shape_6 },
              { t: this.shape_5 },
              { t: this.shape_4 },
              { t: this.shape_3 },
              { t: this.shape_2 },
              { t: this.shape_1 },
              { t: this.shape }
            ]
          },
          4
        )
        .to({ state: [] }, 1)
        .wait(35)
    );

    // Layer_6
    this.instance_1 = new lib.ufo_glow();
    this.instance_1.parent = this;
    this.instance_1.alpha = 0.6992;
    this.instance_1.filters = [new cjs.BlurFilter(20, 20, 3)];
    this.instance_1.cache(-110, -77, 221, 154);

    this.timeline.addTween(
      cjs.Tween.get(this.instance_1)
        .wait(4)
        .to({ _off: true }, 1)
        .wait(35)
    );

    // Layer_1_copy
    this.instance_2 = new lib.ufo_glow();
    this.instance_2.parent = this;
    this.instance_2.filters = [new cjs.BlurFilter(120, 120, 1)];
    this.instance_2.cache(-110, -77, 221, 154);

    this.timeline.addTween(
      cjs.Tween.get(this.instance_2)
        .to({ alpha: 0.8008 }, 4)
        .to({ _off: true }, 1)
        .wait(35)
    );

    // Layer_4
    this.instance_3 = new lib.beam_M();
    this.instance_3.parent = this;
    this.instance_3.setTransform(0, 146.5);

    this.timeline.addTween(
      cjs.Tween.get(this.instance_3)
        .wait(4)
        .to({ _off: true }, 1)
        .wait(35)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-228.5, -194.9, 463, 511.20000000000005);

  (lib.ufo = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib.ufo_g("synched", 0);
    this.instance.parent = this;
    this.instance.setTransform(-1, -2);

    this.timeline.addTween(
      cjs.Tween.get(this.instance)
        .to({ x: -4, y: -6 }, 5)
        .to({ x: -2, y: -2 }, 5)
        .to(
          {
            x: 4,
            y: -6
          },
          5
        )
        .to({ x: 3, y: 2 }, 5)
        .to({ x: -1, y: -2 }, 5)
        .wait(1)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-172.5, -140.9, 348, 459.4);

  // stage content:
  (lib.movie_HTML5Canvas = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Q
    this.instance = new lib.Q();
    this.instance.parent = this;
    this.instance.setTransform(952.1, 590.8, 0.7, 0.7);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(80));

    // ufo
    this.instance_1 = new lib.ufo();
    this.instance_1.parent = this;
    this.instance_1.setTransform(950.1, 457.45, 0.7, 0.7);

    this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(80));

    // DELI
    this.instance_2 = new lib.deli();
    this.instance_2.parent = this;
    this.instance_2.setTransform(959.95, 615.8, 0.7, 0.7);

    this.timeline.addTween(
      cjs.Tween.get(this.instance_2)
        .to({ y: 613.8 }, 40)
        .to({ y: 615.8 }, 39)
        .wait(1)
    );

    // star_f1
    this.instance_3 = new lib.Symbol5();
    this.instance_3.parent = this;
    this.instance_3.setTransform(592.5, 386, 1, 1, 0, 0, 0, 140.5, 104);

    this.timeline.addTween(cjs.Tween.get(this.instance_3).wait(80));

    // star_f2
    this.instance_4 = new lib.Symbol7();
    this.instance_4.parent = this;
    this.instance_4.setTransform(1647.5, 232, 1, 1, 0, 0, 0, 144.5, 101);

    this.timeline.addTween(cjs.Tween.get(this.instance_4).wait(80));

    // star_f3
    this.instance_5 = new lib.Symbol9();
    this.instance_5.parent = this;
    this.instance_5.setTransform(394, 830.5, 1, 1, 0, 0, 0, 89, 59.5);

    this.timeline.addTween(cjs.Tween.get(this.instance_5).wait(80));

    // star_1
    this.instance_6 = new lib.Symbol11();
    this.instance_6.parent = this;
    this.instance_6.setTransform(1144.5, 893.5, 1, 1, 0, 0, 0, 14.5, 14.5);

    this.timeline.addTween(cjs.Tween.get(this.instance_6).wait(80));

    // star_2
    this.instance_7 = new lib.Symbol12();
    this.instance_7.parent = this;
    this.instance_7.setTransform(789, 382.5, 1, 1, 0, 0, 0, 14, 13.5);

    this.timeline.addTween(cjs.Tween.get(this.instance_7).wait(80));

    // star_3
    this.instance_8 = new lib.Symbol14();
    this.instance_8.parent = this;
    this.instance_8.setTransform(567, 140, 1, 1, 0, 0, 0, 12, 11);

    this.timeline.addTween(cjs.Tween.get(this.instance_8).wait(80));

    // star_4
    this.instance_9 = new lib.Symbol15();
    this.instance_9.parent = this;
    this.instance_9.setTransform(1414, 99, 1, 1, 0, 0, 0, 22, 22);

    this.timeline.addTween(cjs.Tween.get(this.instance_9).wait(80));

    // bg
    this.instance_10 = new lib.bgpngcopy();
    this.instance_10.parent = this;

    this.timeline.addTween(cjs.Tween.get(this.instance_10).wait(80));
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(960, 540, 960, 540);
  // library properties:
  lib.properties = {
    id: "B5920EBE86C94D4AA43BA1543A066BC9",
    width: 1920,
    height: 1080,
    fps: 30,
    color: "#FFFFFF",
    opacity: 1.0,
    manifest: [{ src: atlasSrc, id: "movie_HTML5 Canvas_atlas_" }],
    preloads: []
  };

  // bootstrap callback support:

  (lib.Stage = function(canvas) {
    createjs.Stage.call(this, canvas);
  }).prototype = p = new createjs.Stage();

  p.setAutoPlay = function(autoPlay) {
    this.tickEnabled = autoPlay;
  };
  p.play = function() {
    this.tickEnabled = true;
    this.getChildAt(0).gotoAndPlay(this.getTimelinePosition());
  };
  p.stop = function(ms) {
    if (ms) this.seek(ms);
    this.tickEnabled = false;
  };
  p.seek = function(ms) {
    this.tickEnabled = true;
    this.getChildAt(0).gotoAndStop((lib.properties.fps * ms) / 1000);
  };
  p.getDuration = function() {
    return (this.getChildAt(0).totalFrames / lib.properties.fps) * 1000;
  };

  p.getTimelinePosition = function() {
    return (this.getChildAt(0).currentFrame / lib.properties.fps) * 1000;
  };

  an.bootcompsLoaded = an.bootcompsLoaded || [];
  if (!an.bootstrapListeners) {
    an.bootstrapListeners = [];
  }

  an.bootstrapCallback = function(fnCallback) {
    an.bootstrapListeners.push(fnCallback);
    if (an.bootcompsLoaded.length > 0) {
      for (var i = 0; i < an.bootcompsLoaded.length; ++i) {
        fnCallback(an.bootcompsLoaded[i]);
      }
    }
  };

  an.compositions = an.compositions || {};
  an.compositions["B5920EBE86C94D4AA43BA1543A066BC9"] = {
    getStage: function() {
      return exportRoot.getStage();
    },
    getLibrary: function() {
      return lib;
    },
    getSpriteSheet: function() {
      return ss;
    },
    getImages: function() {
      return img;
    }
  };

  an.compositionLoaded = function(id) {
    an.bootcompsLoaded.push(id);
    for (var j = 0; j < an.bootstrapListeners.length; j++) {
      an.bootstrapListeners[j](id);
    }
  };

  an.getComposition = function(id) {
    return an.compositions[id];
  };

  an.makeResponsive = function(isResp, respDim, isScale, scaleType, domContainers) {
    var lastW,
      lastH,
      lastS = 1;
    window.addEventListener("resize", resizeCanvas);
    resizeCanvas();

    function resizeCanvas() {
      var w = lib.properties.width,
        h = lib.properties.height;
      var iw = window.innerWidth,
        ih = window.innerHeight;
      var pRatio = window.devicePixelRatio || 1,
        xRatio = iw / w,
        yRatio = ih / h,
        sRatio = 1;
      if (isResp) {
        // 짤리는 지점
        if (iw < 700) {
          if ((respDim === "width" && lastW === iw) || (respDim === "height" && lastH === ih)) {
            sRatio = lastS;
          } else if (!isScale) {
            if (iw < w || ih < h) sRatio = Math.min(xRatio, yRatio);
          } else if (scaleType === 1) {
            sRatio = Math.min(xRatio, yRatio);
          } else if (scaleType === 2) {
            sRatio = Math.max(xRatio, yRatio);
          }
        }
      }
      domContainers[0].width = w * pRatio * sRatio;
      domContainers[0].height = h * pRatio * sRatio;
      domContainers.forEach(function(container) {
        container.style.width = w * sRatio + "px";
        container.style.height = h * sRatio + "px";
      });
      stage.scaleX = pRatio * sRatio;
      stage.scaleY = pRatio * sRatio;
      lastW = iw;
      lastH = ih;
      lastS = sRatio;

      stage.tickOnUpdate = false;
      stage.update();
      stage.tickOnUpdate = true;
      $("#canvas").css("margin-left", () => (iw - $(this).width()) / 2);
    }
  };
})((createjs = createjs || {}), (AdobeAn = AdobeAn || {}));

function init() {
  createjs = window.createjs;
  canvas = document.getElementById("canvas");
  anim_container = document.getElementById("animation_container");
  dom_overlay_container = document.getElementById("dom_overlay_container");
  let comp = AdobeAn.getComposition("B5920EBE86C94D4AA43BA1543A066BC9");
  let lib = comp.getLibrary();
  let loader = new createjs.LoadQueue(false);
  loader.addEventListener("fileload", function(evt) {
    handleFileLoad(evt, comp);
  });
  loader.addEventListener("complete", function(evt) {
    handleComplete(evt, comp);
  });
  loader.loadManifest(lib.properties.manifest);
}

function handleFileLoad(evt, comp) {
  var images = comp.getImages();
  if (evt && evt.item.type === "image") {
    images[evt.item.id] = evt.result;
  }
}

function handleComplete(evt, comp) {
  //This function is always called, irrespective of the content. You can use the variable "stage" after it is created in token create_stage.
  var lib = comp.getLibrary();
  var ss = comp.getSpriteSheet();
  var queue = evt.target;
  var ssMetadata = lib.ssMetadata;
  for (let i = 0; i < ssMetadata.length; i++) {
    ss[ssMetadata[i].name] = new createjs.SpriteSheet({
      images: [queue.getResult(ssMetadata[i].name)],
      frames: ssMetadata[i].frames
    });
  }
  var preloaderDiv = document.getElementById("_preload_div_");
  preloaderDiv.style.display = "none";
  canvas.style.display = "block";
  exportRoot = new lib.movie_HTML5Canvas();
  stage = new lib.Stage(canvas);
  //Registers the "tick" event listener.
  fnStartAnimation = function() {
    stage.addChild(exportRoot);
    createjs.Ticker.setFPS(lib.properties.fps);
    createjs.Ticker.addEventListener("tick", stage);
  };
  //Code to support hidpi screens and responsive scaling.
  AdobeAn.makeResponsive(true, "both", true, 2, [canvas, preloaderDiv, anim_container, dom_overlay_container]);
  AdobeAn.compositionLoaded(lib.properties.id);
  fnStartAnimation();
}

const Section1 = ({ curPage }) => {
  useEffect(() => {
    init();
  }, []);
  return (
    <>
      <div id="animation_container" style={{ background: "#37374f", position: "relative" }}>
        <canvas
          id="canvas"
          style={{
            height: "100%",
            display: "none",
            background: "#37374f"
          }}
        />
        <div
          id="dom_overlay_container"
          style={{
            pointerEvents: "none",
            overflow: "hidden",
            height: "100vh"
          }}
        />
      </div>

      <div
        id="_preload_div_"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContents: "center",
          width: "100%",
          height: "100vh",
          background: "#37374f",
          overflow: "hidden"
        }}
      ></div>
    </>
  );
};

export default Section1;
