import React, { useEffect } from "react";
import atlasSrc from "../images/atlas3.png";
import $ from "jquery";

let createjs, AdobeAn;

let canvas, stage, exportRoot, anim_container, dom_overlay_container3, fnStartAnimation;

(function(_, an) {
  const cjs = window.createjs;
  const createjs = window.createjs;

  var p; // shortcut to reference prototypes
  var lib = {};
  var ss = {};
  var img = {};
  lib.ssMetadata = [{ name: "03_1_HTML5 Canvas_atlas_", frames: [[0, 0, 1921, 1081]] }];

  // symbols:

  (lib.bg = function() {
    this.initialize(ss["03_1_HTML5 Canvas_atlas_"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  // helper functions:

  function mc_symbol_clone() {
    var clone = this._cloneProps(new this.constructor(this.mode, this.startPosition, this.loop));
    clone.gotoAndStop(this.currentFrame);
    clone.paused = this.paused;
    clone.framerate = this.framerate;
    return clone;
  }

  function getMCSymbolPrototype(symbol, nominalBounds, frameBounds) {
    var prototype = cjs.extend(symbol, cjs.MovieClip);
    prototype.clone = mc_symbol_clone;
    prototype.nominalBounds = nominalBounds;
    prototype.frameBounds = frameBounds;
    return prototype;
  }

  (lib.Tween6 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#536E78")
      .s()
      .p(
        "AhOAUQgFgEgCgHQgDgHACgGQACgHAFgCQAFgDAGAEQAyAgAsgXQAXgNAOgVQAEgFAGAAQAFAAAFAFQAEAGABAHQABAIgEAFQAAAFgVASQgaAXgeAGQgIACgKAAQghAAgjgXg"
      );
    this.shape.setTransform(0.0406, 0.0103);

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-8.7, -4.3, 17.5, 8.6);

  (lib.Tween5 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#536E78")
      .s()
      .p(
        "AhOAUQgFgEgCgHQgDgHACgGQACgHAFgCQAFgDAGAEQAyAgAsgXQAXgNAOgVQAEgFAGAAQAFAAAFAFQAEAGABAHQABAIgEAFQAAAFgVASQgaAXgeAGQgIACgKAAQghAAgjgXg"
      );
    this.shape.setTransform(0.0243, 0.0103, 0.7955, 1);

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-6.9, -4.3, 13.9, 8.6);

  (lib.Tween4 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#536E78")
      .s()
      .p(
        "AhOAUQgFgEgCgHQgDgHACgGQACgHAFgCQAFgDAGAEQAyAgAsgXQAXgNAOgVQAEgFAGAAQAFAAAFAFQAEAGABAHQABAIgEAFQAAAFgVASQgaAXgeAGQgIACgKAAQghAAgjgXg"
      );
    this.shape.setTransform(0.0243, 0.0103, 0.7955, 1);

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-6.9, -4.3, 13.9, 8.6);

  (lib.Tween3 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#536E78")
      .s()
      .p(
        "AhOAUQgFgEgCgHQgDgHACgGQACgHAFgCQAFgDAGAEQAyAgAsgXQAXgNAOgVQAEgFAGAAQAFAAAFAFQAEAGABAHQABAIgEAFQAAAFgVASQgaAXgeAGQgIACgKAAQghAAgjgXg"
      );
    this.shape.setTransform(0.0243, 0.0103, 0.7955, 1);

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-6.9, -4.3, 13.9, 8.6);

  (lib.star031 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#FFFFFF")
      .s()
      .p("AgBAjQgPgBgKgLQgJgLABgOQABgOALgJQALgKANABQAPABAKALQAJALgBAOQgBAOgLAKQgKAIgNAAIgBAAg");

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-3.5, -3.5, 7, 7);

  (lib.Path = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .lf(["#FFFFFF", "rgba(255,255,255,0)"], [0, 1], -44.4, 33, 10.9, -9.6)
      .s()
      .p("ArFHbQgFgEgBgHQgBgNAMgFIgBAAIWNucI15O5IgBAAQgEAEgGAAIgCAAQgGAAgFgEg");
    this.shape.setTransform(71.5667, 47.9333);

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
  }).prototype = getMCSymbolPrototype(lib.Path, new cjs.Rectangle(0, 0, 143.2, 95.9), null);

  (lib.Path_1 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics
      .lf(["#FFFFFF", "rgba(255,255,255,0)"], [0, 1], -128, 98.8, 117.5, -95.5)
      .s()
      .p("Ax8MeQgKgLAAgOQAAgWATgKMAj6gYMMgjTAZHIAAgBQgKAJgNAAQgPAAgKgKg");
    this.shape_1.setTransform(115.925, 80.775);

    this.timeline.addTween(cjs.Tween.get(this.shape_1).wait(1));
  }).prototype = getMCSymbolPrototype(lib.Path_1, new cjs.Rectangle(0, 0, 231.9, 161.6), null);

  (lib.Path_2 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics
      .lf(["#FFFFFF", "rgba(255,255,255,0)"], [0, 1], -95.6, 66.1, 53.5, -34.5)
      .s()
      .p("AxgM7QgKgKgBgOQgBgVARgKIAAgBMAjHgZHMgifAaBIgBABIAAAAQgJAGgJAAIgDABQgNAAgKgKg");
    this.shape_2.setTransform(113.2223, 83.6545);

    this.timeline.addTween(cjs.Tween.get(this.shape_2).wait(1));
  }).prototype = getMCSymbolPrototype(lib.Path_2, new cjs.Rectangle(0, 0, 226.5, 167.3), null);

  (lib.Path_3 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics
      .f("#FFFFFF")
      .s()
      .p("AgGAQQgFgCgCgCIgCgFIgwgHIAwgGQABgDADgDIAFgCIAGgxIAHAwQAEACACACIACAFIAxAGIgxAHIgDAGIgFADIgHAwg");
    this.shape_3.setTransform(6.4, 6.4);

    this.timeline.addTween(cjs.Tween.get(this.shape_3).wait(1));
  }).prototype = getMCSymbolPrototype(lib.Path_3, new cjs.Rectangle(0, 0, 12.8, 12.8), null);

  (lib.Path_4 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape_4 = new cjs.Shape();
    this.shape_4.graphics
      .f("#FFFFFF")
      .s()
      .p("AgLAhQgPgGgFgOIiNgNICNgLQAFgPAPgGIALiMIANCMQAPAGAFAPICLALIiLANQgFAOgPAGIgNCLg");
    this.shape_4.setTransform(17.25, 17.25);

    this.timeline.addTween(cjs.Tween.get(this.shape_4).wait(1));
  }).prototype = getMCSymbolPrototype(lib.Path_4, new cjs.Rectangle(0, 0, 34.5, 34.5), null);

  (lib.b_legR = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#181821")
      .s()
      .p("AArHcIAAAAIp8pMIHimIIGSGBQBIgPBHATQBKATA4A1QAdAbABAmQABAogbAdIlhF2QgiAkgyADIgIABQgsAAgkgdg");
    this.shape.setTransform(-11.7482, -8.137);

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-71.1, -58.6, 118.8, 101);

  (lib.b_legL = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#141417")
      .s()
      .p("AgRGNIAAAAInBlBIFenqIEHC8QBFgZBJAJQBMAJA+AtQAhAXAGAnQAHAngXAgIkrGiQgdAogxAKQgOACgNAAQgiAAgdgSg");
    this.shape.setTransform(-0.0034, -0.0111);

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-46.7, -41.5, 93.5, 83.1);

  (lib.b_body = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#181821")
      .s()
      .p("AKIBTI0VhJQgTgBgNgNQgNgPABgTQABgTAPgNQAOgNATABIUWBJQATABANANQANAPgBATQgBATgPANQgNAMgSAAIgDAAg");
    this.shape.setTransform(2.7249, -20.075);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics
      .f("#771B2A")
      .s()
      .p("AgfLVQmEgWjXiWQjtilARkoQAbjnBNjqQA8i2BXisIUVBJQBDC1AoC7QAzDxAADoQgQEoj+CKQjEBrk4AAQg1AAg4gDg");
    this.shape_1.setTransform(-1.9787, 49.0828);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics
      .f("#771B2A")
      .s()
      .p(
        "AgmLDQklgRjDjaQhdhoguiBQgwiHAIiOQAIiQA/iAQA8h8BohcQBnhdCCguQCGgwCPAIQEkAPDEDbQBcBoAuCBQAxCGgICQQgREkjaDEQhnBciCAuQh0Aqh6AAIgngBg"
      );
    this.shape_2.setTransform(4.4495, -51.1505);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics
      .f("#181821")
      .s()
      .p("AgbH/QjcgMiTieQiTifAMjTQALjUCkiNQCkiNDbANQDbAMCTCeQCTCfgLDSQgMDUikCNQiXCCjGAAIghgBg");
    this.shape_3.setTransform(27.0464, -49.8788);

    this.shape_4 = new cjs.Shape();
    this.shape_4.graphics
      .f("#292D3F")
      .s()
      .p("AgYHHQjKgLiHiNQiHiOAKi7QALi9CWh9QCWh9DIALQDKALCHCNQCHCOgKC7QgLC9iWB9QiJBzi1AAIgggBg");
    this.shape_4.setTransform(37.025, -49.3212);

    this.shape_5 = new cjs.Shape();
    this.shape_5.graphics
      .f("#501534")
      .s()
      .p("AgbAuQgRgMgEgVIgBgHQgEgUALgRQAMgSAVgEQATgDASALQARAMAEAVIABAHQAEAUgLARQgMASgVAEIgKABQgOAAgNgJg");
    this.shape_5.setTransform(76.225, 7.9842);

    this.shape_6 = new cjs.Shape();
    this.shape_6.graphics
      .f("#5E182D")
      .s()
      .p("AgRD0QgpghgLg3Ig0kMQgKg3AagtQAaguAvgJIAAAAQAdgFAXATQAYATAHAhIBIF4QAHAggQAcQgPAbgcAFIgBAAQgKACgKAAQgkAAgfgZg");
    this.shape_6.setTransform(75.095, 19.8859);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({
          state: [
            { t: this.shape_6 },
            { t: this.shape_5 },
            { t: this.shape_4 },
            { t: this.shape_3 },
            { t: this.shape_2 },
            { t: this.shape_1 },
            { t: this.shape }
          ]
        })
        .wait(1)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-87.6, -121.9, 175.2, 243.8);

  (lib.b_armR = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#501534")
      .s()
      .p("AikClQhEhEAAhhQAAhgBEhEQBEhFBgAAQBhAABEBFQBFBEAABgQAABhhFBEQhEBFhhAAQhgAAhEhFg");
    this.shape.setTransform(-6.9, -9.1);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics
      .f("#181821")
      .s()
      .p("Ah5E4QhgAAhUguQhTgtg0hRQg0hQgHheQgHhgAnhXIArheQDcBeFDgmQCigSB2gmQAtAzAYA+QAaBCAABGQAACbhuBtQhuBuibAAg");
    this.shape_1.setTransform(-4.3527, 46.175);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics
      .f("#5F182D")
      .s()
      .p("AoEGyIE8rJQBAiNCRg3QCRg2COBAQCNBAA3CSQA2CRhACMIiwGUQh2AmiiASQhbALhVAAQjRAAidhDg");
    this.shape_2.setTransform(2.4033, -27.2584);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({
          state: [{ t: this.shape_2 }, { t: this.shape_1 }, { t: this.shape }]
        })
        .wait(1)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-54.1, -77.3, 108.2, 154.7);

  (lib.b_armL = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#141417")
      .s()
      .p("Ah5E4QhgAAhUguQhUgtg0hRQg0hQgGheQgHhgAnhXIArheQDbBeFEglQCigTB2gmQAtAzAYA+QAZBCAABGQAACbhuBtQhtBuicAAg");
    this.shape.setTransform(-5.7777, 46.725);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics
      .f("#5F182D")
      .s()
      .p("AoEGxIE8rIQBAiNCRg3QCRg3COBBQCNBAA3CRQA2CRhACNIivGTQh2AmiiATQhbALhUAAQjTAAidhEg");
    this.shape_1.setTransform(3.8033, -27.8403);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({ state: [{ t: this.shape_1 }, { t: this.shape }] })
        .wait(1)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-55.5, -77.9, 111, 155.9);

  (lib.a_eyes = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#536E78")
      .s()
      .p("AgTBJQgJgJAAgNIAAhmQAAgNAJgIQAIgJALAAQAMAAAJAJQAHAIABANIAABmQgBANgHAJQgJAJgMAAQgLAAgIgJg");
    this.shape.setTransform(0, 0.025);

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-2.8, -8.2, 5.699999999999999, 16.5);

  (lib.a_armL = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#6C1D2A")
      .s()
      .p("AirCsQhIhHAAhlQAAhkBIhHQBHhIBkAAQBlAABHBIQBIBHAABkQAABlhIBHQhHBIhlAAQhkAAhHhIg");
    this.shape.setTransform(50.675, 22.525);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics
      .f("#181821")
      .s()
      .p("AjGDpQh0hDgkiCQgjiBBCh0QBDh1CCgjQCAgkB1BDQCCBKBuAgIj+JXQiUg0ifhag");
    this.shape_1.setTransform(-44.0928, 20.5322);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics
      .f("#6C271F")
      .s()
      .p(
        "ArqJEID+pXQCpAwB6gvQB1gwBfiQQBDhlAdhiIABAAQAbhrBXhEQBXhGBxAAQCHAABfBfQBfBfgBCGQABAwgQAxIABAAQgvCshyC0QjQFMk9CBQi1BLjGAAQjHAAjWhLg"
      );
    this.shape_2.setTransform(41.5, 0);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics
      .lf(["#323248", "#272739"], [0, 1], -11, 20.7, 1, 0)
      .s()
      .p("AAOE8QgOgIgKgOIlIn7QgNgUAKgXQAKgWAYgEID7gnQARgCAPAHQAPAHAJAOIFWIsQAJANgIAOQgHAPgQABIkSASIgEAAQgPAAgNgGg");
    this.shape_3.setTransform(-81.412, -18.777);

    this.shape_4 = new cjs.Shape();
    this.shape_4.graphics
      .lf(["#323248", "#272739"], [0, 1], -21.8, -35.9, 9.5, 18.1)
      .s()
      .p(
        "AARE9QgPgHgJgPIk9npIgGAAIgYgkIAYggQALgSAVgDID7gnQARgDAPAHQAPAHAIAOIFXIsQAJAPgJAOIAAABIgZAgIgmAAIAFgOIj0ARIgEAAQgPAAgNgHg"
      );
    this.shape_4.setTransform(-79.8061, -21.827);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({
          state: [{ t: this.shape_4 }, { t: this.shape_3 }, { t: this.shape_2 }, { t: this.shape_1 }, { t: this.shape }]
        })
        .wait(1)
    );
  }).prototype = getMCSymbolPrototype(lib.a_armL, new cjs.Rectangle(-116.1, -65.5, 232.3, 131), null);

  (lib.star04 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib.star031("synched", 0);
    this.instance.parent = this;
    this.instance.setTransform(0, 0, 1.8571, 1.8571);
    this.instance.alpha = 0;
    this.instance._off = true;

    this.timeline.addTween(
      cjs.Tween.get(this.instance)
        .wait(23)
        .to({ _off: false }, 0)
        .to({ alpha: 0.1484 }, 8)
        .wait(11)
        .to({ startPosition: 0 }, 0)
        .to({ alpha: 0 }, 6)
        .to({ _off: true }, 1)
        .wait(31)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-6.5, -6.5, 13.1, 13);

  (lib.star03 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib.star031("synched", 0);
    this.instance.parent = this;
    this.instance.setTransform(0, 0, 0.887, 0.887);
    this.instance.alpha = 0.5;
    this.instance._off = true;

    this.timeline.addTween(
      cjs.Tween.get(this.instance)
        .wait(9)
        .to({ _off: false }, 0)
        .to({ scaleX: 0.95, scaleY: 0.95, alpha: 0.1484 }, 5)
        .wait(9)
        .to({ scaleX: 1, scaleY: 1 }, 0)
        .to({ scaleX: 0.88, scaleY: 0.88, alpha: 0 }, 6)
        .to({ _off: true }, 1)
        .wait(30)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-3.5, -3.5, 7, 7);

  (lib.star31 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib.Path();
    this.instance.parent = this;
    this.instance.setTransform(-0.05, 0, 1, 1, 0, 0, 0, 71.5, 47.9);
    this.instance.alpha = 0.1992;

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-71.5, -47.9, 143.1, 95.9);

  (lib.star3 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib.star31("synched", 0);
    this.instance.parent = this;
    this.instance.setTransform(161.05, -102.05);
    this.instance.alpha = 0;
    this.instance._off = true;

    this.timeline.addTween(
      cjs.Tween.get(this.instance)
        .wait(21)
        .to({ _off: false }, 0)
        .to({ x: 58.05, y: -40, alpha: 1 }, 7)
        .to({ scaleX: 0.2695, scaleY: 0.2695, x: -60.7, y: 39.75, alpha: 0 }, 10)
        .to({ _off: true }, 1)
        .wait(17)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-79.9, -149.9, 312.6, 202.60000000000002);

  (lib.star021 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib.Path_3();
    this.instance.parent = this;
    this.instance.setTransform(0, 0.9, 2.8993, 2.8993, 0, 0, 0, 6.4, 6.7);
    this.instance.alpha = 0.1992;

    this.timeline.addTween(
      cjs.Tween.get(this.instance)
        .to({ regY: 6.5, scaleX: 3.3, scaleY: 3.3, x: 0.05, y: 0.75 }, 5)
        .wait(4)
        .to({ regY: 6.7, scaleX: 3, scaleY: 3, y: 0.9 }, 0)
        .to({ scaleX: 2.8993, scaleY: 2.8993, x: 0 }, 4)
        .wait(4)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-21, -20.7, 42.2, 42.3);

  (lib.star02 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib.star021("synched", 0);
    this.instance.parent = this;
    this.instance.setTransform(0, 0, 0.3801, 0.3801);
    this.instance._off = true;

    this.timeline.addTween(
      cjs.Tween.get(this.instance)
        .wait(37)
        .to({ _off: false }, 0)
        .to({ scaleX: 1, scaleY: 1 }, 8)
        .wait(7)
        .to({ startPosition: 0 }, 0)
        .to({ scaleX: 0.3801, scaleY: 0.3801 }, 7)
        .wait(1)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-21, -20.7, 42.2, 42.3);

  (lib.star21 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib.Path_1();
    this.instance.parent = this;
    this.instance.setTransform(0, 0.05, 1, 1, 0, 0, 0, 115.9, 80.8);
    this.instance.alpha = 0.3984;

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-115.9, -80.7, 231.9, 161.5);

  (lib.star2 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib.star21("synched", 0);
    this.instance.parent = this;
    this.instance.setTransform(218.9, -156.6, 0.349, 0.349);
    this.instance.alpha = 0;
    this.instance._off = true;

    this.timeline.addTween(
      cjs.Tween.get(this.instance)
        .wait(28)
        .to({ _off: false }, 0)
        .to({ scaleX: 1, scaleY: 1, x: 0, y: 0, alpha: 1 }, 10)
        .to({ x: -152, y: 106.05 }, 7)
        .to({ scaleX: 0.2311, scaleY: 0.2885, x: -297.05, y: 216.35, alpha: 0 }, 6)
        .to({ _off: true }, 1)
        .wait(28)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-323.8, -184.7, 583.2, 424.4);

  (lib.star011 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib.Path_4();
    this.instance.parent = this;
    this.instance.setTransform(-0.05, -0.05, 1, 1, 0, 0, 0, 17.2, 17.2);
    this.instance.alpha = 0.3984;

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));
  }).prototype = getMCSymbolPrototype(lib.star011, new cjs.Rectangle(-17.2, -17.2, 34.5, 34.5), null);

  (lib.star01 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib.star011("synched", 0);
    this.instance.parent = this;
    this.instance._off = true;

    this.timeline.addTween(
      cjs.Tween.get(this.instance)
        .wait(13)
        .to({ _off: false }, 0)
        .to({ scaleX: 1.7536, scaleY: 1.7536 }, 10)
        .wait(3)
        .to({ startPosition: 0 }, 0)
        .to({ scaleX: 0.9403, scaleY: 0.9403 }, 7)
        .to({ _off: true }, 1)
        .wait(16)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-30.2, -30.2, 60.5, 60.5);

  (lib.star11 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib.Path_2();
    this.instance.parent = this;
    this.instance.setTransform(0, 0.05, 1, 1, 0, 0, 0, 113.2, 83.7);
    this.instance.alpha = 0.3984;

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-113.2, -83.6, 226.5, 167.3);

  (lib.star1 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib.star11("synched", 0);
    this.instance.parent = this;
    this.instance.setTransform(161.65, -119.45, 0.379, 0.379);
    this.instance.alpha = 0;

    this.timeline.addTween(
      cjs.Tween.get(this.instance)
        .to({ scaleX: 1, scaleY: 1, x: 0, y: 0, alpha: 1 }, 8)
        .to({ x: -95.7, y: 71.5 }, 7)
        .to(
          {
            regY: 0.1,
            scaleX: 0.3466,
            scaleY: 0.3466,
            x: -241.2,
            y: 172.2,
            alpha: 0
          },
          7
        )
        .to({ _off: true }, 1)
        .wait(37)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-280.4, -151.1, 485, 352.29999999999995);

  (lib.b = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // b_armR
    this.instance = new lib.b_armR("synched", 0);
    this.instance.parent = this;
    this.instance.setTransform(-27.6, 44.5);

    this.timeline.addTween(
      cjs.Tween.get(this.instance)
        .wait(7)
        .to({ startPosition: 0 }, 0)
        .to({ regX: -0.1, regY: 0.1, rotation: 1.9999, x: -27.65 }, 9)
        .wait(4)
        .to({ startPosition: 0 }, 0)
        .to({ regX: 0, regY: 0, rotation: 0, x: -27.6 }, 7)
        .wait(2)
        .to({ startPosition: 0 }, 0)
        .to({ regX: -0.1, regY: 0.1, rotation: 1.9999, x: -27.65 }, 9)
        .wait(4)
        .to({ startPosition: 0 }, 0)
        .to({ regX: 0, regY: 0, rotation: 0, x: -27.6 }, 7)
        .wait(1)
    );

    // b_body
    this.instance_1 = new lib.b_body("synched", 0);
    this.instance_1.parent = this;
    this.instance_1.setTransform(-14.55, -10.05);

    this.timeline.addTween(
      cjs.Tween.get(this.instance_1)
        .wait(7)
        .to({ startPosition: 0 }, 0)
        .to({ regX: -0.1, regY: -0.1, rotation: 1.9999, x: -14.6 }, 9)
        .wait(4)
        .to({ startPosition: 0 }, 0)
        .to({ regX: 0, regY: 0, rotation: 0, x: -14.55 }, 7)
        .wait(2)
        .to({ startPosition: 0 }, 0)
        .to({ regX: -0.1, regY: -0.1, rotation: 1.9999, x: -14.6 }, 9)
        .wait(4)
        .to({ startPosition: 0 }, 0)
        .to({ regX: 0, regY: 0, rotation: 0, x: -14.55 }, 7)
        .wait(1)
    );

    // b_legR
    this.instance_2 = new lib.b_legR("synched", 0);
    this.instance_2.parent = this;
    this.instance_2.setTransform(69.1, 89.65);

    this.timeline.addTween(
      cjs.Tween.get(this.instance_2)
        .wait(22)
        .to({ startPosition: 0 }, 0)
        .to({ rotation: 5.0001, x: 69.05 }, 6)
        .wait(1)
        .to({ rotation: 0, x: 69.1 }, 0)
        .to({ rotation: -28.9997, x: 69.05, y: 83.35 }, 9)
        .wait(4)
        .to({ rotation: -35, y: 83.3 }, 0)
        .to({ rotation: 0, x: 69.1, y: 89.65 }, 7)
        .wait(1)
    );

    // b_legL
    this.instance_3 = new lib.b_legL("synched", 0);
    this.instance_3.parent = this;
    this.instance_3.setTransform(69.15, 65.4);

    this.timeline.addTween(
      cjs.Tween.get(this.instance_3)
        .to({ rotation: 4.9993, x: 69.1, y: 65.35 }, 6)
        .wait(1)
        .to({ rotation: 0, x: 69.15, y: 65.4 }, 0)
        .to({ regX: 0.1, regY: 0.1, rotation: -29.0002, x: 74.7, y: 59.05 }, 9)
        .wait(4)
        .to({ startPosition: 0 }, 0)
        .to({ regX: 0, regY: 0, rotation: 0, x: 69.15, y: 65.4 }, 7)
        .wait(7)
        .to({ startPosition: 0 }, 0)
        .wait(16)
    );

    // b_armL
    this.instance_4 = new lib.b_armL("synched", 0);
    this.instance_4.parent = this;
    this.instance_4.setTransform(-61.2, 22);

    this.timeline.addTween(
      cjs.Tween.get(this.instance_4)
        .wait(7)
        .to({ startPosition: 0 }, 0)
        .to({ rotation: 1.0002, x: -61.15, y: 21.95 }, 9)
        .wait(4)
        .to({ startPosition: 0 }, 0)
        .to({ rotation: 0, x: -61.2, y: 22 }, 7)
        .wait(2)
        .to({ startPosition: 0 }, 0)
        .to({ rotation: 1.0002, x: -61.15, y: 21.95 }, 9)
        .wait(4)
        .to({ startPosition: 0 }, 0)
        .to({ rotation: 0, x: -61.2, y: 22 }, 7)
        .wait(1)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-117.3, -131.9, 234.3, 263.9);

  (lib.a_mouse = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#536E78")
      .s()
      .p(
        "AhOAUQgFgEgCgHQgDgHACgGQACgHAFgCQAFgDAGAEQAyAgAsgXQAXgNAOgVQAEgFAGAAQAFAAAFAFQAEAGABAHQABAIgEAFQAAAFgVASQgaAXgeAGQgIACgKAAQghAAgjgXg"
      );
    this.shape.setTransform(0.0406, 0.0103);

    this.instance = new lib.Tween3("synched", 0);
    this.instance.parent = this;
    this.instance.setTransform(0.05, 0);
    this.instance._off = true;

    this.instance_1 = new lib.Tween4("synched", 0);
    this.instance_1.parent = this;
    this.instance_1.setTransform(0.05, 0);

    this.instance_2 = new lib.Tween5("synched", 0);
    this.instance_2.parent = this;
    this.instance_2.setTransform(0.05, 0);
    this.instance_2._off = true;

    this.instance_3 = new lib.Tween6("synched", 0);
    this.instance_3.parent = this;

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({ state: [{ t: this.shape }] })
        .to({ state: [{ t: this.instance }] }, 24)
        .to({ state: [{ t: this.instance_1 }] }, 2)
        .to({ state: [{ t: this.instance_2 }] }, 1)
        .to({ state: [{ t: this.instance_3 }] }, 2)
        .wait(21)
    );
    this.timeline.addTween(
      cjs.Tween.get(this.instance)
        .wait(24)
        .to({ _off: false }, 0)
        .to({ _off: true }, 2)
        .wait(24)
    );
    this.timeline.addTween(
      cjs.Tween.get(this.instance_2)
        .wait(27)
        .to({ _off: false }, 0)
        .to({ _off: true, x: 0 }, 2)
        .wait(21)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-8.7, -4.3, 17.5, 8.6);

  (lib.a_eye = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib.a_eyes("synched", 0);
    this.instance.parent = this;

    this.timeline.addTween(
      cjs.Tween.get(this.instance)
        .wait(6)
        .to({ startPosition: 0 }, 0)
        .to({ scaleY: 0.0732 }, 6)
        .wait(1)
        .to({ scaleY: 0.1463 }, 0)
        .to({ scaleY: 1 }, 6)
        .wait(41)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-2.8, -8.2, 5.699999999999999, 16.5);

  (lib.star06 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib.star021();
    this.instance.parent = this;
    this.instance.setTransform(0.05, 0, 0.45, 0.45, 0, 0, 0, 0.1, 0);
    this.instance.alpha = 0;
    this.instance._off = true;

    this.timeline.addTween(
      cjs.Tween.get(this.instance)
        .wait(59)
        .to({ _off: false }, 0)
        .to({ scaleX: 0.5741, scaleY: 0.5741, alpha: 1 }, 4)
        .wait(4)
        .to({ scaleX: 0.45, scaleY: 0.45 }, 4)
        .to({ _off: true }, 1)
        .wait(28)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-10.6, -10.6, 21.299999999999997, 21.299999999999997);

  (lib.star05 = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // Layer_1
    this.instance = new lib.star011();
    this.instance.parent = this;
    this.instance.setTransform(0.05, 0, 0.5942, 0.5942, 0, 0, 0, 0.1, 0);

    this.timeline.addTween(
      cjs.Tween.get(this.instance)
        .to({ regX: 0, scaleX: 0.7, scaleY: 0.7, x: 0 }, 7)
        .wait(5)
        .to({ regX: 0.1, scaleX: 0.5942, scaleY: 0.5942, x: 0.05 }, 5)
        .wait(8)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-12, -12, 24.1, 24.1);

  (lib.a_head = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // eyes
    this.instance = new lib.a_eye("synched", 0);
    this.instance.parent = this;
    this.instance.setTransform(42.6, 51.8, 1, 1, -10.2405);

    this.instance_1 = new lib.a_eye("synched", 0);
    this.instance_1.parent = this;
    this.instance_1.setTransform(16, 57, 1, 1, -10.2405);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({ state: [{ t: this.instance_1 }, { t: this.instance }] })
        .wait(60)
    );

    // mouse
    this.instance_2 = new lib.a_mouse();
    this.instance_2.parent = this;
    this.instance_2.setTransform(30.35, 64.7);

    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#2A2E40")
      .s()
      .p("Ag0AoQgKgMAJgUQAKgUAVgQQAWgRAVgEQAXgDAJAMQAKANgKAUQgJAUgWARQgVAQgVADIgJABQgPAAgIgKg");
    this.shape.setTransform(19.3, 35.85);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics
      .f("#181821")
      .s()
      .p("AkmEoQh6h6AAiuQAAisB6h7QB6h7CsAAQCtAAB6B7QB6B7AACsQAACuh6B6Qh6B7itAAQisAAh6h7g");
    this.shape_1.setTransform(42.675, 61.575);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics
      .f("#6B6B77")
      .s()
      .p("AlAFMQiFiJAAjDQAAjBCFiKQCFiKC7AAQC8AACFCKQCFCKAADBQAADDiFCJQiFCKi8AAQi7AAiFiKg");
    this.shape_2.setTransform(45.375, 61.575);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics
      .f("#773A22")
      .s()
      .p("AnWGyQi9iygBkAQABj/C9iyQC/izEXAAQEYAAC/CzQC9CyABD/QgBEAi9CyQi/CzkYAAQkXAAi/izg");
    this.shape_3.setTransform(68.2, 61.325);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({
          state: [
            { t: this.shape_3 },
            { t: this.shape_2 },
            { t: this.shape_1 },
            { t: this.shape },
            { t: this.instance_2 }
          ]
        })
        .wait(60)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(0, 0, 134.3, 122.7);

  (lib.a = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // a_armL
    this.instance = new lib.a_armL();
    this.instance.parent = this;
    this.instance.setTransform(184.1, 146.9);

    this.timeline.addTween(
      cjs.Tween.get(this.instance)
        .wait(8)
        .to({ regX: 0.1, regY: 0.1, rotation: 0.9993, x: 181.75, y: 143.7 }, 10)
        .wait(3)
        .to({ regX: 0, regY: 0, rotation: 2.0007, x: 181.65, y: 143.65 }, 0)
        .to({ rotation: 0, x: 184.1, y: 146.9 }, 4)
        .wait(55)
    );

    // a_legL
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f("#6C1D2A")
      .s()
      .p("AirCsQhIhHAAhlQAAhkBIhHQBHhIBkAAQBlAABHBIQBIBHAABkQAABlhIBHQhHBIhlAAQhkAAhHhIg");
    this.shape.setTransform(144.225, 192.575);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics
      .f("#773A22")
      .s()
      .p("AruGmIgBtLIQ4AAQCxAAB8B+QB8B+gDCwQgDCth+B5Qh+B5iuAAg");
    this.shape_1.setTransform(175.4035, 204.825);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics
      .f("#181821")
      .s()
      .p("AkDGYQhJhJAAhmIAAoBQAAg9AhgxQAegtAzgYQAzgYA3AGQA6AGAvAnIBQBEIEDAAIABNLImiABQhmAAhIhIg");
    this.shape_2.setTransform(67.025, 199.14);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({
          state: [{ t: this.shape_2 }, { t: this.shape_1 }, { t: this.shape }]
        })
        .wait(80)
    );

    // a_legR
    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics
      .f("#60182E")
      .s()
      .p("AirCsQhIhHAAhlQAAhkBIhHQBHhIBkAAQBlAABHBIQBIBHAABkQAABlhIBHQhHBIhlAAQhkAAhHhIg");
    this.shape_3.setTransform(110.475, 162.875);

    this.shape_4 = new cjs.Shape();
    this.shape_4.graphics
      .f("#6D2720")
      .s()
      .p("ArvmlIQ4AAQCwgBB9B/QB8B+gDCwQgDCth+B5Qh9B5ivAAIwxABg");
    this.shape_4.setTransform(141.7025, 175.15);

    this.shape_5 = new cjs.Shape();
    this.shape_5.graphics
      .f("#141517")
      .s()
      .p("AhVHfQhmAAhIhIQhJhIAAhmIAAoBQAAg9AhgxQAeguAzgYQAzgXA3AGQA7AGAuAnIBRBEIEDAAIAANLg");
    this.shape_5.setTransform(33.275, 169.464);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({
          state: [{ t: this.shape_5 }, { t: this.shape_4 }, { t: this.shape_3 }]
        })
        .wait(80)
    );

    // a_body
    this.shape_6 = new cjs.Shape();
    this.shape_6.graphics
      .f("#6C1D2A")
      .s()
      .p("AgaCGQgMgNAAgRIAAjPQAAgRAMgNQALgMAPAAQAQAAALAMQAMANAAARIAADPQAAARgMANQgLAMgQAAQgPAAgLgMg");
    this.shape_6.setTransform(127.875, 128.575);

    this.shape_7 = new cjs.Shape();
    this.shape_7.graphics
      .f("#6C1D2A")
      .s()
      .p("AgaCGQgLgMAAgSIgBjPQAAgRAMgNQALgMAPAAQAQAAAMAMQALAMAAASIAADPQAAASgLAMQgMAMgQAAQgPAAgLgMg");
    this.shape_7.setTransform(141.425, 128.575);

    this.shape_8 = new cjs.Shape();
    this.shape_8.graphics
      .f("#6C1D2A")
      .s()
      .p("AgaCGQgMgNAAgRIAAjPQAAgSALgMQAMgMAPAAQAQAAALAMQAMANAAARIAADPQAAARgMANQgLAMgQAAQgPAAgLgMg");
    this.shape_8.setTransform(155.025, 128.575);

    this.shape_9 = new cjs.Shape();
    this.shape_9.graphics
      .f("#232736")
      .s()
      .p("AhfBnQgogrAAg8QAAg8AogqQAogrA3AAQA5AAAnArQAoAqAAA8QAAA8goArQgnArg5AAQg3AAgogrg");
    this.shape_9.setTransform(203.625, 128.575);

    this.shape_10 = new cjs.Shape();
    this.shape_10.graphics
      .f("#773A22")
      .s()
      .p(
        "AmZMNQi9hQiSiSQiSiRhPi9QhTjEAAjVIAAkrQAAibBuhvQBuhuCcAAIS2AAQDZAACZCaQCZCZAADZIAACXQAADVhTDEQhPC9iSCRQiSCSi9BQQjEBTjWAAQjVAAjEhTg"
      );
    this.shape_10.setTransform(221.275, 159.975);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({
          state: [
            { t: this.shape_10 },
            { t: this.shape_9 },
            { t: this.shape_8 },
            { t: this.shape_7 },
            { t: this.shape_6 }
          ]
        })
        .wait(80)
    );

    // a_head
    this.instance_1 = new lib.a_head();
    this.instance_1.parent = this;
    this.instance_1.setTransform(217.8, 61.3, 1, 1, 0, 0, 0, 67.2, 61.2);

    this.timeline.addTween(
      cjs.Tween.get(this.instance_1)
        .wait(8)
        .to({ regX: 67.3, rotation: 4.9995, x: 221.85, y: 59.65 }, 10)
        .wait(3)
        .to({ regX: 67.2, rotation: 0, x: 217.8, y: 61.3 }, 4)
        .wait(28)
        .to({ regX: 67.3, rotation: 4.9995, x: 221.85, y: 59.65 }, 10)
        .wait(3)
        .to({ regX: 67.2, rotation: 0, x: 217.8, y: 61.3 }, 4)
        .wait(10)
    );
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(0, -6.9, 326.6, 254);

  // stage content:
  (lib._031_HTML5Canvas = function(mode, startPosition, loop) {
    this.initialize(mode, startPosition, loop, {});

    // chair
    this.shape = new cjs.Shape();
    this.shape.graphics
      .lf(["#323248", "#272739"], [0, 1], 3.8, -405.8, -0.6, 76.4)
      .s()
      .p(
        "AqpWLIhkAAQgIAAgFgGQgGgFAAgIIAAi6QAAgIAGgGQAFgFAJgBQAJAAAGgIQAGgIgDgJIkouWIAAAAQgEgNgPAAIgmAAQgIAAgGgGQgGgGAAgIIAAi6QAAgEADgGImf0NQgOgtAVgpQAVgpAtgPQAsgOApAVQAqAVAOAtIGzVJMAmBAAAQAIAAAGAGQAFAFAAAIIAAVYQAAAIgFAFQgGAGgIAAMginAAAIgBABgAsvD2QgGAIADAJIEoOWQAFANANAAIcWAAQAIAAAGgFQAFgGAAgIIAAuWQAAgIgFgGQgGgFgIAAMgg+AAAQgKAAgFAIg"
      );
    this.shape.setTransform(278.645, 807.895);

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(240));

    // a
    this.instance = new lib.a();
    this.instance.parent = this;
    this.instance.setTransform(1592.6, 702.7, 1, 1, 0, 0, 0, 163.3, 123.5);

    this.timeline.addTween(
      cjs.Tween.get(this.instance)
        .wait(31)
        .to({ regY: 123.6, scaleY: 1.001, y: 702.75 }, 0)
        .wait(1)
        .to({ scaleY: 1.003 }, 0)
        .wait(1)
        .to({ scaleY: 1.005 }, 0)
        .wait(1)
        .to({ regY: 247.1, scaleY: 1.0129, y: 826.3 }, 0)
        .wait(1)
        .to({ regY: 247.2, scaleY: 1.0099 }, 0)
        .wait(1)
        .to({ scaleY: 1.0079, y: 826.35 }, 0)
        .wait(1)
        .to({ regY: 247.1, scaleY: 1.0049, y: 826.25 }, 0)
        .wait(1)
        .to({ scaleY: 1.003, y: 826.3 }, 0)
        .wait(1)
        .to({ regY: 123.5, scaleY: 1, y: 702.7 }, 0)
        .wait(201)
    );

    // b
    this.instance_1 = new lib.b();
    this.instance_1.parent = this;
    this.instance_1.setTransform(375.35, 757.5);

    this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(240));

    // fallingstar1
    this.instance_2 = new lib.star1();
    this.instance_2.parent = this;
    this.instance_2.setTransform(663.45, 350.7);

    this.timeline.addTween(cjs.Tween.get(this.instance_2).wait(240));

    // fallingstar2
    this.instance_3 = new lib.star2();
    this.instance_3.parent = this;
    this.instance_3.setTransform(1514.35, 226.7);

    this.timeline.addTween(cjs.Tween.get(this.instance_3).wait(240));

    // fallingstar3
    this.instance_4 = new lib.star3();
    this.instance_4.parent = this;
    this.instance_4.setTransform(503.5, 709.45);

    this.timeline.addTween(cjs.Tween.get(this.instance_4).wait(240));

    // stars
    this.instance_5 = new lib.star06();
    this.instance_5.parent = this;
    this.instance_5.setTransform(1437.85, 76.2);

    this.instance_6 = new lib.star01();
    this.instance_6.parent = this;
    this.instance_6.setTransform(334.1, 337.1);

    this.instance_7 = new lib.star02();
    this.instance_7.parent = this;
    this.instance_7.setTransform(1239.4, 534.65);

    this.instance_8 = new lib.star03();
    this.instance_8.parent = this;
    this.instance_8.setTransform(609.3, 85.5);

    this.instance_9 = new lib.star04();
    this.instance_9.parent = this;
    this.instance_9.setTransform(1646.4, 348.85);

    this.instance_10 = new lib.star05();
    this.instance_10.parent = this;
    this.instance_10.setTransform(1214.85, 715.15);
    this.instance_10.alpha = 0.4414;

    this.instance_11 = new lib.star021();
    this.instance_11.parent = this;
    this.instance_11.setTransform(330.85, 544.8, 0.4505, 0.4505);

    this.instance_12 = new lib.star06();
    this.instance_12.parent = this;
    this.instance_12.setTransform(546, 860.3);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({
          state: [
            { t: this.instance_12 },
            { t: this.instance_11 },
            { t: this.instance_10 },
            { t: this.instance_9 },
            { t: this.instance_8 },
            { t: this.instance_7 },
            { t: this.instance_6 },
            { t: this.instance_5 }
          ]
        })
        .wait(240)
    );

    // bg
    this.instance_13 = new lib.bg();
    this.instance_13.parent = this;
    this.instance_13.setTransform(-1, -1);

    this.timeline.addTween(cjs.Tween.get(this.instance_13).wait(240));
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(959, 539, 961, 541);
  // library properties:
  lib.properties = {
    id: "6BFEE77C0F8B496094ADD8D2739B7738",
    width: 1920,
    height: 1080,
    fps: 30,
    color: "#FFFFFF",
    opacity: 1.0,
    manifest: [
      {
        src: atlasSrc,
        id: "03_1_HTML5 Canvas_atlas_"
      }
    ],
    preloads: []
  };

  // bootstrap callback support:

  (lib.Stage = function(canvas) {
    createjs.Stage.call(this, canvas);
  }).prototype = p = new createjs.Stage();

  p.setAutoPlay = function(autoPlay) {
    this.tickEnabled = autoPlay;
  };
  p.play = function() {
    this.tickEnabled = true;
    this.getChildAt(0).gotoAndPlay(this.getTimelinePosition());
  };
  p.stop = function(ms) {
    if (ms) this.seek(ms);
    this.tickEnabled = false;
  };
  p.seek = function(ms) {
    this.tickEnabled = true;
    this.getChildAt(0).gotoAndStop((lib.properties.fps * ms) / 1000);
  };
  p.getDuration = function() {
    return (this.getChildAt(0).totalFrames / lib.properties.fps) * 1000;
  };

  p.getTimelinePosition = function() {
    return (this.getChildAt(0).currentFrame / lib.properties.fps) * 1000;
  };

  an.bootcompsLoaded = an.bootcompsLoaded || [];
  if (!an.bootstrapListeners) {
    an.bootstrapListeners = [];
  }

  an.bootstrapCallback = function(fnCallback) {
    an.bootstrapListeners.push(fnCallback);
    if (an.bootcompsLoaded.length > 0) {
      for (var i = 0; i < an.bootcompsLoaded.length; ++i) {
        fnCallback(an.bootcompsLoaded[i]);
      }
    }
  };

  an.compositions = an.compositions || {};
  an.compositions["6BFEE77C0F8B496094ADD8D2739B7738"] = {
    getStage: function() {
      return exportRoot.getStage();
    },
    getLibrary: function() {
      return lib;
    },
    getSpriteSheet: function() {
      return ss;
    },
    getImages: function() {
      return img;
    }
  };

  an.compositionLoaded = function(id) {
    an.bootcompsLoaded.push(id);
    for (var j = 0; j < an.bootstrapListeners.length; j++) {
      an.bootstrapListeners[j](id);
    }
  };

  an.getComposition = function(id) {
    return an.compositions[id];
  };

  an.makeResponsive = function(isResp, respDim, isScale, scaleType, domContainers) {
    var lastW,
      lastH,
      lastS = 1;
    window.addEventListener("resize", resizeCanvas);
    resizeCanvas();

    function resizeCanvas() {
      var w = lib.properties.width,
        h = lib.properties.height;
      var iw = window.innerWidth,
        ih = window.innerHeight;
      var pRatio = window.devicePixelRatio || 1,
        xRatio = iw / w,
        yRatio = ih / h,
        sRatio = 1;
      if (isResp) {
        if ((respDim === "width" && lastW === iw) || (respDim === "height" && lastH === ih)) {
          sRatio = lastS;
        } else if (!isScale) {
          if (iw < w || ih < h) sRatio = Math.min(xRatio, yRatio);
        } else if (scaleType === 1) {
          sRatio = Math.min(xRatio, yRatio);
        } else if (scaleType === 2) {
          sRatio = Math.max(xRatio, yRatio);
        }
      }
      domContainers[0].width = w * pRatio * sRatio;
      domContainers[0].height = h * pRatio * sRatio;
      domContainers.forEach(function(container) {
        container.style.width = w * sRatio + "px";
        let tt2 = h * sRatio;
        container.style.height = tt2 > ih ? ih + "px" : tt2 + "px";
      });
      stage.scaleX = pRatio * sRatio;
      stage.scaleY = pRatio * sRatio;
      lastW = iw;
      lastH = ih;
      lastS = sRatio;
      stage.tickOnUpdate = false;
      stage.update();
      stage.tickOnUpdate = true;

      $("#canvas3").css("margin-left", function() {
        return ($(window).width() - $(this).width()) / 2;
      });
    }
  };
})((createjs = createjs || {}), (AdobeAn = AdobeAn || {}));

function init() {
  const createjs = window.createjs;
  canvas = document.getElementById("canvas3");
  anim_container = document.getElementById("animation_container3");
  dom_overlay_container3 = document.getElementById("dom_overlay_container3");
  let comp = AdobeAn.getComposition("6BFEE77C0F8B496094ADD8D2739B7738");
  let lib = comp.getLibrary();
  let loader = new createjs.LoadQueue(false);
  loader.addEventListener("fileload", function(evt) {
    handleFileLoad(evt, comp);
  });
  loader.addEventListener("complete", function(evt) {
    handleComplete(evt, comp);
  });
  loader.loadManifest(lib.properties.manifest);
}

function handleFileLoad(evt, comp) {
  let images = comp.getImages();
  if (evt && evt.item.type === "image") {
    images[evt.item.id] = evt.result;
  }
}

function handleComplete(evt, comp) {
  const createjs = window.createjs;
  //This function is always called, irrespective of the content. You can use the variable "stage" after it is created in token create_stage.
  var lib = comp.getLibrary();
  var ss = comp.getSpriteSheet();
  var queue = evt.target;
  var ssMetadata = lib.ssMetadata;
  for (let i = 0; i < ssMetadata.length; i++) {
    ss[ssMetadata[i].name] = new createjs.SpriteSheet({
      images: [queue.getResult(ssMetadata[i].name)],
      frames: ssMetadata[i].frames
    });
  }
  canvas.style.display = "block";
  exportRoot = new lib._031_HTML5Canvas();
  stage = new lib.Stage(canvas);
  //Registers the "tick" event listener.
  fnStartAnimation = function() {
    stage.addChild(exportRoot);
    createjs.Ticker.setFPS(lib.properties.fps);
    createjs.Ticker.addEventListener("tick", stage);
  };
  //Code to support hidpi screens and responsive scaling.
  AdobeAn.makeResponsive(true, "both", true, 2, [canvas, anim_container, dom_overlay_container3]);
  AdobeAn.compositionLoaded(lib.properties.id);
  fnStartAnimation();
}

const Section3 = () => {
  useEffect(() => {
    init();
  }, []);
  return (
    <>
      <div id="animation_container3" style={{ background: "#37374f", minWidth: "100%", overflow: "hidden" }}>
        <canvas
          id="canvas3"
          style={{
            minWidth: "100%",
            height: "100vh",
            display: "none",
            background: "#37374f"
          }}
        />
        <div
          id="dom_overlay_container3"
          style={{
            pointerEvents: "none",
            overflow: "hidden",
            width: "100%",
            height: "100vh"
          }}
        />
      </div>
    </>
  );
};

export default Section3;
