import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Scene from 'scenejs';
import GetAddress from './GetAddress';
import GetTime from './GetTime';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
  opacity: 0;
  margin-bottom: 5.5rem;
  float: left;
`;

const BTN_ACTIVE = `linear-gradient(to top, #f22c5d, #ff464b);`;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 200;
  display: flex;
  border-top: 1px solid #e6e6e6;
`;

const Button = styled.div`
  background: ${({ active }) => (active ? BTN_ACTIVE : 'white')};
  transition: all 0.2s ease-out;
  font-size: 16px;
  border-radius: 15px;
  border: ${({ active }) => (active ? '1px solid white' : '1px solid #e6e6e6')};
  min-width: 230px;
  width: 90%;
  padding: 6px 6px;
  cursor: pointer;
  color: ${({ active }) => (active ? '#fff' : '#333')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 5px 0;
  text-align: center;
`;

const Button2 = styled.div`
  cursor: pointer;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: all 0.15s ease-in;
  color: #666;
  font-weight: bold;
  background: white;
  border-bottom: 1px solid #e6e6e6;
  &:hover {
    color: #ff3250;
  }
`;

const InputButton = styled.input`
  all: reset;
  border: 1px solid #e6e6e6;
  transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  outline: none;
  text-align: center;
  font-size: 16px;
  border-radius: 15px;
  color: #333;
  min-width: 230px;
  width: 90%;
  padding: 6px 6px;
  margin: 5px 0;
  min-height: 24px;
  box-shadow: ${({ active }) => (active ? '0px 0px 4px 0.5px #ff3250' : 'none')};
  &:focus {
    border: none;
    box-shadow: 0px 0px 4px 0.5px #ff3250;
  }
  &::placeholder {
    color: #333;
  }
`;

export const InputButton2 = styled.input`
  all: reset;
  border: 1px solid #e6e6e6;
  transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  outline: none;
  text-align: center;
  font-size: 16px;
  border-radius: 15px;
  color: #333;
  width: 90%;
  min-width: 230px;
  padding: 6px 6px;
  margin: 5px 0;
  min-height: 24px;
  &:focus {
    border: none;
    box-shadow: 0px 0px 4px 0.5px #ff3250;
  }
  &::placeholder {
    color: #ccc;
  }
`;

const TextArea = styled.textarea`
  outline: none;
  border: 1px solid #e6e6e6;
  margin: 5px 0;
  font-size: 16px;
  padding: 0.5rem;
  width: 90%;
  min-width: 280px;
  height: 190px;
  border-radius: 15px;
  color: #333;
  resize: none;
`;

const Item2 = ({ data, page, setPage, lastPage, currentData, setData, direction, setDirection, onSubmit, length }) => {
  const [selected, setSelected] = useState(currentData[page]);
  const [error, setError] = useState('');

  const setHidden = () => {
    new Scene(
      {
        '#item-container': {
          0: {
            opacity: 1,
          },
          0.15: {
            opacity: 0,
          },
        },
      },
      {
        selector: true,
        easing: 'ease-out',
      }
    ).play();
  };

  useEffect(() => {
    const toLeft = new Scene(
      {
        '#item-container': {
          0: {
            opacity: 0,
            transform: 'translateX(100%)',
          },
          0.3: {
            opacity: 1,
            transform: 'translateX(0%)',
          },
        },
      },
      {
        selector: true,
        easing: 'ease-in',
      }
    );
    const toRight = new Scene(
      {
        '#item-container': {
          0: {
            opacity: 0,
            transform: 'translateX(-100%)',
          },
          0.3: {
            opacity: 1,
            transform: 'translateX(0%)',
          },
        },
      },
      {
        selector: true,
        easing: 'ease-in',
      }
    );
    if (direction === 'left') {
      toLeft.play();
    } else {
      toRight.play();
    }
  }, [page, direction]);

  return (
    <>
      <Container id="item-container">
        <div className="lg" style={{ margin: '0.3rem 0', textAlign: 'center' }}>
          Q{page}
        </div>
        <div
          className="md"
          style={{ margin: '0.3rem 0', textAlign: 'center' }}
          dangerouslySetInnerHTML={{ __html: data.question }}
        />

        <div
          style={{
            fontSize: '12px',
            margin: '0.25rem 0',
            color: '#ff3250',
            textAlign: 'center',
          }}
        >
          {error}
        </div>

        {data.answerArray &&
          data.answerArray.map((answer, index) =>
            answer !== '기타' ? (
              <Button
                key={index}
                active={selected === answer}
                onClick={() => {
                  setSelected(answer);
                }}
              >
                {answer}
              </Button>
            ) : (
              <InputButton
                key={index}
                active={selected && selected.split(':')[0] === '기타'}
                placeholder={answer + ` (직접 입력)`}
                defaultValue={selected && selected.split(':').length > 0 && selected.split(':')[1]}
                onClick={() => setSelected('기타')}
                onChange={(e) => {
                  setSelected('기타: ' + e.target.value);
                }}
              />
            )
          )}
        {data.input && (
          <InputButton2
            placeholder={data.input}
            defaultValue={currentData[page]}
            onChange={(e) => {
              setSelected(e.target.value);
            }}
          />
        )}
        {data.time && (
          <>
            <GetTime
              title="영업시간 (오픈시간)"
              setTime={(time) => {
                setSelected({ ...selected, openTime: time });
              }}
            />
            <GetTime
              title="영업시간 (마감시간)"
              setTime={(time) => {
                setSelected({ ...selected, closeTime: time });
              }}
            />
            <div style={{ fontSize: '14px' }}>
              <b>휴무일</b>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {['일', '월', '화', '수', '목', '금', '토'].map((item, index) => (
                <React.Fragment key={index}>
                  <input
                    type="checkbox"
                    value={index}
                    defaultChecked={data && data.holidays && data.holidays.includes(index)}
                    onChange={() => {
                      setSelected({ ...selected, holidays: item });
                    }}
                  />
                  <span style={{ marginRight: '0.8rem' }}>{item}</span>
                </React.Fragment>
              ))}
            </div>
          </>
        )}

        {page === length && (
          <TextArea
            placeholder={data.textarea}
            defaultValue={currentData[page]}
            onChange={(e) => {
              setSelected(e.target.value);
            }}
          />
        )}
        {data.address && (
          <InputButton2
            placeholder="주소찾기를 눌러주세요."
            defaultValue={selected || currentData[page]}
            disabled
            onChange={(e) => {
              setSelected(e.target.value);
            }}
          />
        )}
      </Container>
      {data.address && <GetAddress setData={setSelected} />}
      <div
        style={{
          fontSize: '12px',
          margin: '1rem 0',
          textAlign: 'center',
          color: '#ccc',
          fontWeight: 'bold',
          position: 'fixed',
          paddingBottom: '4px',
          bottom: '32px',
          width: '100%',
          background: 'white',
        }}
      >
        {page} / {lastPage}
      </div>
      <Footer>
        {page < 2 && (
          <Button2
            onClick={() => {
              if (!selected) {
                setError(data.answerArray ? '*보기를 선택해 주세요' : '*답변을 입력해주세요');
                return;
              }
              setData({ ...currentData, [page]: selected });
              setHidden();
              setTimeout(() => {
                setDirection('left');
                setPage(page + 1);
              }, 200);
            }}
          >
            다음
          </Button2>
        )}
        {page >= 2 && page < length && (
          <>
            <Button2
              style={{ width: '50%', borderRight: '1px solid #e6e6e6' }}
              onClick={() => {
                setHidden();
                setTimeout(() => {
                  setDirection('right');
                  setPage(page - 1);
                  setData({ ...currentData, [page]: selected });
                }, 200);
              }}
            >
              이전
            </Button2>
            <Button2
              style={{ width: '50%' }}
              onClick={() => {
                if (!selected) {
                  setError(data.answerArray ? '*보기를 선택해 주세요' : '*답변을 입력해주세요');
                  return;
                }

                setHidden();
                setTimeout(() => {
                  setDirection('left');
                  setData({ ...currentData, [page]: selected });
                  setPage(page + 1);
                }, 200);
              }}
            >
              다음
            </Button2>
          </>
        )}
        {page === length && (
          <>
            <Button2
              style={{ width: '50%', borderRight: '1px solid #e6e6e6' }}
              onClick={() => {
                setHidden();
                setTimeout(() => {
                  setDirection('right');
                  setPage(page - 1);
                  setData({ ...currentData, [page]: selected });
                }, 200);
              }}
            >
              이전
            </Button2>
            <Button2
              style={{ width: '50%' }}
              onClick={() => {
                const newData = { ...currentData, [page]: selected };
                // console.log(newData);
                setTimeout(() => {
                  setPage(page + 1);
                  onSubmit(newData);
                }, 200);
              }}
            >
              제출
            </Button2>
          </>
        )}
      </Footer>
    </>
  );
};

export default Item2;
