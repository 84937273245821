import React from 'react';
import styled from 'styled-components';

const FluidDiv = styled.div`
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  position: absolute;
  width: 50px;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 5%;
`;

const Dot = styled.div`
  width: 13px;
  height: ${props => (props.active ? '35px' : '13px')};
  border-radius: 6.5px;
  margin-top: 0.5rem;
  transition: height 0.25s ease-in;
  cursor: pointer;
  border: ${props =>
    props.active ? 'none' : props.black ? '0.5px solid rgba(0, 0, 0, 0.3)' : '0.5px solid rgba(255, 255, 255, 0.5)'};
  background: ${props =>
    props.active ? (props.black ? 'rgba(0,0,0,0.3)' : 'rgba(255, 255, 255, 0.5)') : 'transparent'};
  &:hover {
    opacity: 0.9;
  }
`;

const Pager = ({ currentPage, onClick }) => {
  const flag = currentPage === 2 || currentPage === 3;
  return (
    <FluidDiv>
      <Container>
        <Dot active={currentPage === 1} black={flag} onClick={() => onClick(1)} />
        <Dot active={currentPage === 2} black={flag} onClick={() => onClick(2)} />
        <Dot active={currentPage === 3} black={flag} onClick={() => onClick(3)} />
        <Dot active={currentPage === 4} black={flag} onClick={() => onClick(4)} />
        <Dot active={currentPage === 5} black={flag} onClick={() => onClick(5)} />
      </Container>
    </FluidDiv>
  );
};

export default Pager;
