import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import arrow from '../images/arrow1.svg';
import arrow2 from '../images/arrow2.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 100;
  @media (max-width: 992px) {
    top: 2%;
  }
`;
const Button = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.5px solid #fff;
  padding: 0.4rem 0.8rem;
  border-radius: 16px;
  color: #fff;
  margin-bottom: 0.4rem;
  font-size: 12px;
  width: 110px;
  user-select: none;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

const data = [
  {
    name: '한국어',
    value: 'kr'
  },
  {
    name: 'English',
    value: 'en'
  },
  {
    name: '中国(简体中文)',
    value: 'cn'
  },
  {
    name: '日本語',
    value: 'jp'
  }
];

const SelectLang = ({ current }) => {
  const currentData = current && data.filter(item => item.value === current)[0];
  const [open, setOpen] = useState(false);
  const openRef = useRef();
  const arrowRef = useRef();

  const openList = () => {
    openRef.current.style.height = !open ? '200px' : 0;
    setOpen(!open);
  };

  const rotateArrow = () => {
    arrowRef.current.style.transform = open ? 'rotate(0deg)' : 'rotate(180deg)';
  };

  return (
    <Container>
      <Button href="partners" style={{ display: 'block', textAlign: 'center' }}>
        서비스 제휴 안내{' '}
        <img
          src={arrow}
          style={{ height: '11px', position: 'absolute', right: '15px', top: '9px' }}
          alt=""
        />
      </Button>
      <Button
        onClick={() => {
          openList();
          rotateArrow();
        }}
      >
        <div style={{ width: '10px', height: '10px' }} />
        {currentData.name}
        <img
          src={arrow2}
          style={{ width: '11px', marginLeft: '0.5rem', transition: 'all 0.2s ease-out' }}
          alt=""
          ref={arrowRef}
        />
      </Button>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          transition: 'all 0.3s ease-out',
          height: 0,
          overflow: 'hidden'
        }}
        ref={openRef}
      >
        {data
          .filter(item => item.value !== current)
          .map(item => (
            <Button
              href={`?lang=${item.value}`}
              style={{ justifyContent: 'center' }}
              key={item.value}
            >
              {item.name}
            </Button>
          ))}
      </div>
    </Container>
  );
};

export default SelectLang;
