import React from 'react';
import styled from 'styled-components';
import arrow from '../images/arrow1.svg';

const FluidDiv = styled.div`
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 25px;
  justify-content: end;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 30px;
  right: 30px;
`;
const Button = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.5px solid white;
  padding: 0.5rem 1.5rem 0.5rem 1rem;
  border-radius: 16px;
  color: white;
  margin-bottom: 0.4rem;
  font-size: 12px;
  user-select: none;
  cursor: pointer;
  margin-left: 0.5rem;
`;

const TopButton = () => {
  return (
    <FluidDiv>
      <Container>
        <div style={{}}>
          <Button
            href={`https://firebasestorage.googleapis.com/v0/b/deliquick-93b6b.appspot.com/o/%E1%84%83%E1%85%A6%E1%86%AF%E1%84%85%E1%85%B5%E1%84%8F%E1%85%B1%E1%86%A8_%E1%84%8C%E1%85%A6%E1%84%8B%E1%85%A1%E1%86%AB%E1%84%89%E1%85%A5.pdf?alt=media&token=0b2aa1e3-faa3-4006-a445-3f1fc6321ad5`}
            download
            target="_blank"
            rel="noopener noreferrer"
          >
            서비스 소개서 다운로드
            <img
              src={arrow}
              style={{ height: '11px', marginLeft: '0.5rem', position: 'absolute', right: '10px' }}
              alt=""
            />
          </Button>
          <Button href="ask">
            문의하기
            <img
              src={arrow}
              style={{ height: '11px', marginLeft: '0.5rem', position: 'absolute', right: '10px' }}
              alt=""
            />
          </Button>
        </div>
      </Container>
    </FluidDiv>
  );
};

export default TopButton;
