import React from "react";
import img_5 from "./images/copyright.png";
import styled from "styled-components";

const Copyright = styled.div`
  display: block;
  margin: 0 auto;
  max-width: 500px;
  @media screen and (max-width: 1200px) {
  }
`;

const Footer = () => {
    return (
        <Copyright>
            <img src={img_5} alt="" style={{maxWidth: '100%', maxHeight: '100%'}}/>
        </Copyright>
    );
};

export default Footer;
