import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  border: 1px solid #ccc;
  padding: 0.5rem;
  margin: 0.5rem 0;
  align-items: center;
  select {
    margin-right: 0.25rem;
  }
`;

function GetTime({ title, setTime }) {
  const [data, setData] = useState({ meridiem: 'AM' });

  const onChange = (e) => {
    const { value, name } = e.target;
    setData({ ...data, [name]: value });
  };
  const time = useMemo(() => {
    if (data) return `${data.hour}:${data.minute}${data.meridiem}`;
  }, [data]);

  useEffect(() => {
    setTime(time);
  }, [time]); // eslint-disable-line

  return (
    <>
      <div style={{ fontSize: '14px' }}>
        <b>{title}</b>
      </div>
      <Container>
        <select defaultValue="시" name="hour" onChange={onChange}>
          {['시', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item, index) => (
            <option key={index}>{item}</option>
          ))}
        </select>{' '}
        <span>:</span>
        <select defaultValue="분" name="minute" onChange={onChange}>
          {['분', '00', 15, 30, 45].map((item, index) => (
            <option key={index}>{item}</option>
          ))}
        </select>
        <select defaultValue="AM" name="meridiem" onChange={onChange}>
          {['AM', 'PM'].map((item, index) => (
            <option key={index}>{item}</option>
          ))}
        </select>
      </Container>
    </>
  );
}

export default GetTime;
